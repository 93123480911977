import { OrganizationUser } from 'interfaces/organizationUserInterfaces';
import React from 'react';
import { abbrevDate } from 'utils/dateUtils';

interface ViewAdminProfileProps {
  admin: OrganizationUser;
}

const ViewAdminProfile: React.FC<ViewAdminProfileProps> = ({ admin }) => {
  const profileFields: { [key: string]: string } = {
    Email: admin.email,
    'Profile Claimed': admin.claimedAt ? abbrevDate(admin.claimedAt!) : 'N/A',
    'Profile Created': abbrevDate(admin.createdAt),
  };

  return (
    <>
      {/* Description list */}
      <div className="mt-6 mx-auto">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          {Object.keys(profileFields).map((field) => (
            <div key={field} className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">{field}</dt>
              <dd className="mt-1 text-sm text-gray-900">{profileFields[field]}</dd>
            </div>
          ))}
        </dl>
      </div>
    </>
  );
};

export default ViewAdminProfile;
