import ParticipantTuitionReimbursementGrant from 'participant/TuitionReimbursement/ParticipantTuitionReimbursementGrant/ParticipantTuitionReimbursementGrant';
import ParticipantTuitionReimbursementGrantsPage from 'participant/TuitionReimbursement/ParticipantTuitionReimbursementGrantsPage';
import ParticipantTuitionReimbursementRequestsPage from 'participant/TuitionReimbursement/ParticipantTuitionReimbursementRequests/ParticipantTuitionReimbursementRequestsPage';

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import AuthenticatedRoute from 'routes/AuthenticatedRoute';
import { classNames } from 'utils/tailwindUtils';

interface ParticipantTuitionReimbursementTab {
  name: string;
  href: string;
  current: boolean;
}

const ParticipantTuitionReimbursementPage: React.FC = () => {
  const location = useLocation();
  const locationPath = location.pathname;

  const tabs: ParticipantTuitionReimbursementTab[] = [
    {
      name: 'Grants',
      href: `/tuition-reimbursement/list/grants`,
      current: locationPath === `/tuition-reimbursement/list/grants`,
    },
    {
      name: 'Requests',
      href: `/tuition-reimbursement/list/requests`,
      current: locationPath === `/tuition-reimbursement/list/requests`,
    },
  ];
  return (
    <div className="my-6 px-8 mx-auto max-w-7xl">
      <AuthenticatedRoute
        path={`/tuition-reimbursement/list`}
        render={() => {
          return (
            <>
              <h1 className="text-2xl font-bold text-gray-900">Tuition Reimbursement</h1>

              <div className="mt-6 sm:mt-2 2xl:mt-5 border-b border-gray-200 overflow-x-auto scrollbar-hide">
                <nav className="flex space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <Link
                      key={tab.name}
                      to={tab.href}
                      className={classNames(
                        tab.current
                          ? 'border-purple-500 text-gray-900'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                        'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                      )}
                      aria-current={tab.current ? 'page' : undefined}
                    >
                      {tab.name}
                    </Link>
                  ))}
                </nav>
              </div>
              <AuthenticatedRoute
                path={`/tuition-reimbursement/list/grants`}
                render={() => {
                  return <ParticipantTuitionReimbursementGrantsPage />;
                }}
              />
              <AuthenticatedRoute
                path={`/tuition-reimbursement/list/requests`}
                render={() => {
                  return <ParticipantTuitionReimbursementRequestsPage />;
                }}
              />
            </>
          );
        }}
      />

      <AuthenticatedRoute
        path={`/tuition-reimbursement/grants/:tuitionReimbursementGrantId`}
        render={() => {
          return <ParticipantTuitionReimbursementGrant />;
        }}
      />
    </div>
  );
};

export default ParticipantTuitionReimbursementPage;
