import { logOut } from 'config/firebaseConfig';
import { UserDataContext } from 'context/UserContext';
import logo from 'images/logo-w-title.png';
import React, { useContext } from 'react';

const UnexpectedErrorPage: React.FC = () => {
  const { firebaseUser } = useContext(UserDataContext);

  return (
    <>
      <div className="flex h-screen items-center justify-center py-12 sm:px-6 lg:px-8">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="sm:mx-auto sm:w-full sm:max-w-md mb-4">
              <img className="mx-auto h-16 w-auto" src={logo} alt="unsaddl" />
              <svg
                className="mx-auto mt-8 h-12 w-12 text-gray-800"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg>
              <h2 className="mt-2 text-center text-2xl font-bold text-gray-800">Unexpected error</h2>
              <p className="mt-2 text-md text-gray-700">
                Looks like we're having some issues! Please try to log in again later, or reach out to us at{' '}
                <a href="mailto: support@unsaddl.com" className="cursor-pointer underline">
                  support@unsaddl.com
                </a>
                .
              </p>
            </div>
            <div className="flex w-full mt-16">
              <span className="text-gray-500 text-sm ml-auto">
                Not {firebaseUser?.email}?{' '}
                <a onClick={logOut} className="cursor-pointer underline">
                  Sign out
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnexpectedErrorPage;
