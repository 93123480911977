import { Dialog, Transition } from '@headlessui/react';
import { CheckCircleIcon, ClockIcon, MailIcon, ReceiptRefundIcon, XIcon } from '@heroicons/react/outline';
import { TuitionReimbursementPayment } from 'interfaces/tuitionReimbursementInterfaces';
import React, { Fragment } from 'react';
import { abbrevDate } from 'utils/dateUtils';
import { formatMoney } from 'utils/numberFormatter';

interface ViewTuitionReimbursementPaymentModalProps {
  tuitionReimbursementPayment?: TuitionReimbursementPayment;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ViewTuitionReimbursementPaymentModal: React.FC<ViewTuitionReimbursementPaymentModalProps> = ({
  tuitionReimbursementPayment,
  open,
  setOpen,
}) => {
  const treasuryPrimeAchs = tuitionReimbursementPayment?.treasuryPrimeAchs;
  const recipient = tuitionReimbursementPayment?.tuitionReimbursementRequest?.participantProfile?.organizationUser;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div className="absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="px-4">
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      <h1 className="text-xl font-semibold text-gray-900">
                        {tuitionReimbursementPayment?.tuitionReimbursementRequest?.courseName}
                      </h1>
                      <h1 className="text-lg text-gray-500">
                        {tuitionReimbursementPayment?.tuitionReimbursementRequest?.institutionName}
                      </h1>

                      <p className="mt-2 text-sm text-gray-700">
                        Reimbursement for {recipient?.firstName} {recipient?.lastName} {`(${recipient?.email})`}{' '}
                        {tuitionReimbursementPayment?.createdAt &&
                          `started on ${abbrevDate(tuitionReimbursementPayment?.createdAt)}`}
                        .
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th scope="col" className="py-3.5 w-1/3 text-left text-sm font-semibold text-gray-900">
                            <div>Bank name</div>
                          </th>
                          <th scope="col" className="py-3.5 px-1 text-left text-sm font-semibold text-gray-900">
                            Routing/Account #
                          </th>
                          <th scope="col" className="py-3.5 text-right text-sm font-semibold text-gray-900">
                            Amount submitted
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <React.Fragment>
                          <tr className="bg-gray-50">
                            <td className="py-4 text-sm">
                              <div className="font-medium text-gray-900">
                                {tuitionReimbursementPayment?.institutionName}
                              </div>
                            </td>
                            <td className="py-4 px-1 text-left text-sm text-gray-500">
                              <div>{tuitionReimbursementPayment?.routingNumber}</div>
                              <div>{tuitionReimbursementPayment?.accountNumber}</div>
                            </td>
                            {/* <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
                                  {formatMoney(Number(loanPayment.amountSent))}
                                </td> */}
                            <td className="py-4 text-right text-sm text-gray-500">
                              {formatMoney(Number(tuitionReimbursementPayment?.amountSubmitted))}
                            </td>
                          </tr>

                          <tr key={tuitionReimbursementPayment?.id + 'transactionHeader'}>
                            <td className="text-sm text-gray-500 py-2">
                              <div className="font-medium">Transactions</div>
                            </td>
                          </tr>
                          {tuitionReimbursementPayment?.treasuryPrimeAchs?.length === 0 && (
                            <tr key={'no-transactions'}>
                              <td className="py-4 text-sm text-gray-500">
                                <div className="font-medium">No payments sent yet</div>
                              </td>
                            </tr>
                          )}
                          {treasuryPrimeAchs?.map((treasuryPrimeAch) => (
                            <tr key={treasuryPrimeAch.id}>
                              <td className="py-4 text-sm text-gray-500">
                                <div className="font-medium">{treasuryPrimeAch.id}</div>
                              </td>
                              <td className="py-4 px-1 text-left text-sm text-gray-500">
                                <span className="inline-flex text-xs leading-5 font-semibold ">
                                  <MailIcon className="flex-shrink-0 mr-1 h-5 w-5 text-sky-400" aria-hidden="true" />
                                  Effective date
                                </span>
                                {treasuryPrimeAch.effectiveDate && (
                                  <div className="font-medium">{abbrevDate(treasuryPrimeAch.effectiveDate)}</div>
                                )}
                              </td>

                              <td className="py-4 text-right text-sm text-gray-500">
                                {
                                  {
                                    pending: (
                                      <span className="inline-flex px-1 text-xs leading-5 font-semibold rounded-md bg-yellow-100 text-yellow-800">
                                        <ClockIcon
                                          className="hidden md:flex flex-shrink-0 mr-1 h-5 w-5 text-yellow-400"
                                          aria-hidden="true"
                                        />
                                        Pending
                                      </span>
                                    ),
                                    canceled: (
                                      <span className="inline-flex px-1 text-xs leading-5 font-semibold rounded-md bg-red-100 text-red-800">
                                        <XIcon
                                          className="hidden md:flex flex-shrink-0 mr-1 h-5 w-5 text-red-400"
                                          aria-hidden="true"
                                        />
                                        Canceled
                                      </span>
                                    ),
                                    processing: (
                                      <span className="inline-flex px-1 text-xs leading-5 font-semibold rounded-md bg-yellow-100 text-yellow-800">
                                        <ClockIcon
                                          className="hidden md:flex flex-shrink-0 mr-1 h-5 w-5 text-yellow-400"
                                          aria-hidden="true"
                                        />
                                        Processing
                                      </span>
                                    ),
                                    sent: (
                                      <span className="inline-flex px-1 text-xs leading-5 font-semibold rounded-md bg-green-100 text-green-800">
                                        <CheckCircleIcon
                                          className="hidden md:flex flex-shrink-0 mr-1 h-5 w-5 text-green-400"
                                          aria-hidden="true"
                                        />
                                        Sent
                                      </span>
                                    ),
                                    error: (
                                      <span className="inline-flex px-1 text-xs leading-5 font-semibold rounded-md bg-red-100 text-red-800">
                                        <XIcon
                                          className="hidden md:flex flex-shrink-0 mr-1 h-5 w-5 text-red-400"
                                          aria-hidden="true"
                                        />
                                        Error
                                      </span>
                                    ),
                                    returned: (
                                      <span className="inline-flex px-1 text-xs leading-5 font-semibold rounded-md bg-red-100 text-red-800">
                                        <ReceiptRefundIcon
                                          className="hidden md:flex flex-shrink-0 mr-1 h-5 w-5 text-red-400"
                                          aria-hidden="true"
                                        />
                                        Returned
                                      </span>
                                    ),
                                  }[treasuryPrimeAch.status]
                                }
                                <div className="font-semibold">{formatMoney(Number(treasuryPrimeAch.amount))}</div>
                              </td>
                            </tr>
                          ))}
                          <tr key={tuitionReimbursementPayment?.id + 'border'} className="border-b border-gray-200" />
                        </React.Fragment>
                      </tbody>
                    </table>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ViewTuitionReimbursementPaymentModal;
