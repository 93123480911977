import ListPlanRuns from 'admin/ContributionPlanRuns/ListPlanRuns/ListPlanRuns';
import ViewPlanRun from 'admin/ContributionPlanRuns/ViewPlanRun/ViewPlanRun';
import EmptyPlaceholder from 'common/EmptyPlaceholder';
import { UserDataContext } from 'context/UserContext';

import React, { useContext } from 'react';
import AuthenticatedRoute from 'routes/AuthenticatedRoute';

const ContributionPlanRunsPage: React.FC = () => {
  const { currentOrganizationUser } = useContext(UserDataContext);

  return currentOrganizationUser?.adminProfile?.adminPermissions.includes('payment_plan_management') ? (
    <>
      <AuthenticatedRoute
        path={`/runs/list`}
        render={() => {
          return <ListPlanRuns />;
        }}
      />
      <AuthenticatedRoute
        path={`/runs/:contributionPlanRunId/view`}
        render={() => {
          return <ViewPlanRun />;
        }}
      />
    </>
  ) : (
    <div className="my-6 px-8 mx-auto max-w-7xl">
      <EmptyPlaceholder
        title="Access denied"
        subtitle="Your organization super admin has not given you permission to access this page"
      />
    </div>
  );
};

export default ContributionPlanRunsPage;
