import React from 'react';

interface PlanFilterDropdownsProps {
  selectedPlanType: string;
  setSelectedPlanType: React.Dispatch<React.SetStateAction<string>>;
  selectedPlanStatus: string;
  setSelectedPlanStatus: React.Dispatch<React.SetStateAction<string>>;
}

const PlanFilterDropdowns: React.FC<PlanFilterDropdownsProps> = ({
  selectedPlanStatus,
  selectedPlanType,
  setSelectedPlanStatus,
  setSelectedPlanType,
}) => {
  const handlePlanTypeChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setSelectedPlanType(value);
  };

  const handlePlanStatusChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setSelectedPlanStatus(value);
  };
  return (
    <div className="flex flex-col sm:flex-row space-y-1 sm:space-y-0 sm:space-x-2">
      <div>
        <select
          id="plan-type"
          name="plan-type"
          className="block w-full pl-3 pr-10 py-2 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm rounded-md"
          onChange={handlePlanTypeChange}
        >
          <option>Recurring plans</option>
          <option>One-time plans</option>
        </select>
      </div>
      <div>
        <select
          id="plan-status"
          name="plan-status"
          className="block w-full pl-3 pr-10 py-2 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm rounded-md"
          onChange={handlePlanStatusChange}
        >
          <option>Active</option>
          <option>Disabled</option>
        </select>
      </div>
    </div>
  );
};

export default PlanFilterDropdowns;
