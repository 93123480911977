import { ChevronDownIcon, ChevronUpIcon, CurrencyDollarIcon } from '@heroicons/react/outline';
import EmptyTablePlaceholder from 'common/EmptyTablePlaceholder';
import Pagination from 'components/Tables/Pagination';
import { TuitionReimbursementGrant, TuitionReimbursementPlan } from 'interfaces/tuitionReimbursementInterfaces';

import React, { useEffect } from 'react';
import Highlighter from 'react-highlight-words';
import { useHistory } from 'react-router-dom';
import { CellProps, Column, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { formatMoney } from 'utils/numberFormatter';

interface ViewGrantsTableProps {
  searchTerm: string;
  tuitionReimbursementPlan: TuitionReimbursementPlan;
  tuitionReimbursementGrants: TuitionReimbursementGrant[];
}

const ViewGrantsTable: React.FC<ViewGrantsTableProps> = ({
  searchTerm,
  tuitionReimbursementPlan,
  tuitionReimbursementGrants,
}) => {
  const history = useHistory();

  const tuitionReimbursementGrantsMemo = React.useMemo<TuitionReimbursementGrant[]>(
    () => tuitionReimbursementGrants,
    [tuitionReimbursementGrants]
  );
  const columns: Column<TuitionReimbursementGrant>[] = React.useMemo(
    () => [
      {
        id: 'recipient',
        Header: 'Recipient',
        accessor: (grant: TuitionReimbursementGrant) => {
          const granteeOrganizationUser = grant.grantee.organizationUser;

          return (
            granteeOrganizationUser?.firstName +
            ' ' +
            granteeOrganizationUser?.lastName +
            ' ' +
            granteeOrganizationUser?.email
          );
        },
        Cell: (props: CellProps<TuitionReimbursementGrant>) => {
          const grant = props.row.original;
          const granteeOrganizationUser = grant.grantee.organizationUser;
          const firstName = granteeOrganizationUser?.firstName;
          const lastName = granteeOrganizationUser?.lastName;
          const email = granteeOrganizationUser?.email;

          return (
            <div className="flex items-center">
              <div>
                <Highlighter
                  className="block font-medium text-gray-900 break-all"
                  highlightClassName="bg-yellow-200"
                  searchWords={[props.state.globalFilter]}
                  autoEscape={true}
                  textToHighlight={`${firstName} ${lastName}`}
                />
                <Highlighter
                  className="block text-gray-500 break-all"
                  highlightClassName="bg-yellow-200"
                  searchWords={[props.state.globalFilter]}
                  autoEscape={true}
                  textToHighlight={`${email}`}
                />
              </div>
            </div>
          );
        },
        className: 'py-4 text-sm font-medium text-gray-900',
        headerClassName: 'py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },

      {
        id: 'grant-details',
        Header: 'Grant details',
        accessor: (tuitionreimbursementGrant: TuitionReimbursementGrant) => {
          return `${formatMoney(
            Number(tuitionreimbursementGrant.annualReimbursementMax)
          )} annual reimbursement maximum`;
        },
        Cell: (props: CellProps<TuitionReimbursementGrant>) => {
          const grant = props.row.original;

          return (
            <div className="flex-col space-y-1">
              <div className="flex">
                <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-purple-100 text-purple-800">
                  <CurrencyDollarIcon
                    className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-purple-400"
                    aria-hidden="true"
                  />
                  {formatMoney(Number(grant.annualReimbursementMax))} annual reimbursement maximum
                </span>
              </div>
            </div>
          );
        },
        className: 'py-4 whitespace-nowrap text-sm text-gray-500',
        headerClassName: 'py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
    ],
    []
  ) as any;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex },
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: tuitionReimbursementGrantsMemo,
      initialState: { pageSize: 10, globalFilter: searchTerm },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setGlobalFilter(searchTerm);
  }, [searchTerm]);

  return (
    <div>
      <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={`${column.headerClassName}`}
                    >
                      {
                        // Render the header
                        column.render('Header')
                      }
                      <span className="inline-flex ml-1">
                        {column.isSorted ? (
                          <span className="w-3 h-3">
                            {column.isSortedDesc ? <ChevronDownIcon /> : <ChevronUpIcon />}
                          </span>
                        ) : (
                          ''
                        )}
                      </span>
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()} className="divide-y divide-gray-200">
          {
            // Loop over the table rows
            page.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr
                  {...row.getRowProps()}
                  onClick={() =>
                    history.push(
                      `/tuition-reimbursement/plans/${tuitionReimbursementPlan.id}/view/grants/${row.original.id}/requests`
                    )
                  }
                  className="hover:bg-gray-50 cursor-pointer"
                >
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td {...cell.getCellProps()} className={`${cell.column.className}`}>
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>

      {page.length === 0 && (
        <EmptyTablePlaceholder
          title="No grants"
          subtitle="This tuitionreimbursement plan does not have any active grants."
        />
      )}

      <Pagination
        pageIndex={pageIndex}
        numPages={pageOptions.length}
        nextPage={nextPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
      />
    </div>
  );
};

export default ViewGrantsTable;
