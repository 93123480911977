/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Ring } from '@uiball/loaders';
import { apiGetExternalBankAccounts } from 'api/externalBankAccountAPI';
import { ExternalBankAccount } from 'interfaces/externalBankAccountInterfaces';
import { TuitionReimbursementPayment } from 'interfaces/tuitionReimbursementInterfaces';
import SelectTuitionReimbursementAccountBody from 'participant/TuitionReimbursement/SelectTuitionReimbursementPaymentAccountBody';

import { Fragment, useEffect, useState } from 'react';
import { formatMoney } from 'utils/numberFormatter';

interface SelectTuitionReimbursementPaymentAccountSlideoverProps {
  tuitionReimbursementPayment?: TuitionReimbursementPayment;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelectTuitionReimbursementPaymentAccountSlideover: React.FC<
  SelectTuitionReimbursementPaymentAccountSlideoverProps
> = ({ tuitionReimbursementPayment, open, setOpen }) => {
  const [externalBankAccounts, setExternalBankAccounts] = useState<ExternalBankAccount[]>([]);
  const [isLoadingPaymentAccounts, setIsLoadingPaymentAccounts] = useState(true);
  const [paymentBankName, setPaymentBankName] = useState(tuitionReimbursementPayment?.institutionName);
  const [paymentAccountType, setPaymentAccountType] = useState(tuitionReimbursementPayment?.accountType);
  const [paymentRoutingNumber, setPaymentRoutingNumber] = useState(tuitionReimbursementPayment?.routingNumber);
  const [paymentAccountNumber, setPaymentAccountNumber] = useState(tuitionReimbursementPayment?.accountNumber);

  const getPaymentAccounts = async () => {
    setIsLoadingPaymentAccounts(true);
    apiGetExternalBankAccounts().then(
      (data) => {
        setExternalBankAccounts(data.externalBankAccounts);
        setIsLoadingPaymentAccounts(false);
      },
      (error) => {
        setIsLoadingPaymentAccounts(false);
      }
    );
  };

  useEffect(() => {
    if (open) {
      getPaymentAccounts();
      setPaymentBankName(tuitionReimbursementPayment?.institutionName);
      setPaymentAccountNumber(tuitionReimbursementPayment?.accountNumber);
      setPaymentRoutingNumber(tuitionReimbursementPayment?.routingNumber);
      setPaymentAccountType(tuitionReimbursementPayment?.accountType);
    }
  }, [open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll border-l bg-white shadow-xl">
                    <div className="px-4 sm:px-6 sticky top-0 z-10 border-b py-6 bg-white">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          {paymentAccountNumber
                            ? 'Tuition reimbursement payment information'
                            : 'Select tuition reimbursement payment account'}
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-4 flex">
                        <div className="flex-1">
                          <label className="text-md font-medium text-gray-700">Payment amount</label>
                          <fieldset className="mt-2">
                            <legend className="sr-only">Payment amount</legend>
                            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                              {formatMoney(Number(tuitionReimbursementPayment?.amountSubmitted))}
                            </div>
                          </fieldset>
                        </div>
                        <div className="flex-1">
                          <dl>
                            <dt className="text-md font-medium text-gray-700">Bank name</dt>
                            <dd>
                              <div className="text-sm font-medium text-gray-700 flex flex-row">
                                {paymentBankName ? `${paymentBankName}` : 'N/A'}
                                {paymentAccountType &&
                                  ` (${paymentAccountType.charAt(0).toUpperCase()}${paymentAccountType.slice(1)})`}
                              </div>
                            </dd>
                          </dl>
                        </div>
                      </div>
                      <div className="mt-4 flex">
                        <div className="flex-1">
                          <dl>
                            <dt className="text-md font-medium text-gray-700">Routing #</dt>
                            <dd>
                              <div className="text-sm font-medium text-gray-700 flex flex-row">
                                {paymentRoutingNumber ? paymentRoutingNumber : 'N/A'}
                              </div>
                            </dd>
                          </dl>
                        </div>

                        <div className="flex-1">
                          <dl>
                            <dt className="text-md font-medium text-gray-700">Account #</dt>
                            <dd>
                              <div className="text-sm font-medium text-gray-700">
                                {paymentAccountNumber ? paymentAccountNumber : 'N/A'}
                              </div>
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>

                    <div className="relative flex-1 px-4 sm:px-6 pb-4">
                      {isLoadingPaymentAccounts ? (
                        <div className="py-8 flex justify-center">
                          <Ring size={40} lineWeight={5} speed={2.0} color="gray" />
                        </div>
                      ) : tuitionReimbursementPayment ? (
                        <SelectTuitionReimbursementAccountBody
                          tuitionReimbursementPayment={tuitionReimbursementPayment}
                          externalBankAccounts={externalBankAccounts}
                          setPaymentBankName={setPaymentBankName}
                          setPaymentRoutingNumber={setPaymentRoutingNumber}
                          setPaymentAccountNumber={setPaymentAccountNumber}
                          setPaymentAccountType={setPaymentAccountType}
                        />
                      ) : (
                        <>No payment found.</>
                      )}

                      {/* /End replace */}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SelectTuitionReimbursementPaymentAccountSlideover;
