import EmptyPlaceholder from 'common/EmptyPlaceholder';
import { ContributionPlan } from 'interfaces/contributionsInterfaces';
import { LoanServicer } from 'interfaces/loansInterfaces';
import { Savings529Account } from 'interfaces/savings529Interfaces';
import ElectContributions529Account from 'participant/ContributionPlans/ElectContributions/ElectContributions529Account';
import ElectContributionsServicer from 'participant/ContributionPlans/ElectContributions/ElectContributionsServicer';

import React from 'react';

interface ElectContributionsBodyProps {
  contributionPlan: ContributionPlan;
  loanServicers: LoanServicer[];
  savings529Accounts: Savings529Account[];
  contributionElectionAmountsByLoan: { [key: string]: number };
  setContributionElectionAmountForLoan: (loanAccountId: string, amount: number) => void;
  contributionElectionPercentagesByLoan: { [key: string]: number };
  setContributionElectionPercentageForLoan: (loanAccountId: string, amount: number) => void;
  contributionElectionAmountsBy529Account: { [key: string]: number };
  setContributionElectionAmountFor529Account: (savings529AccountId: string, amount: number) => void;
  contributionElectionPercentagesBy529Account: { [key: string]: number };
  setContributionElectionPercentageFor529Account: (savings529AccountId: string, amount: number) => void;
  isEditingContributions: boolean;
  electionType: string;
}

const ElectContributionsBody: React.FC<ElectContributionsBodyProps> = ({
  contributionPlan,
  loanServicers,
  savings529Accounts,
  contributionElectionAmountsByLoan,
  setContributionElectionAmountForLoan,
  contributionElectionPercentagesByLoan,
  setContributionElectionPercentageForLoan,
  contributionElectionAmountsBy529Account,
  setContributionElectionAmountFor529Account,
  contributionElectionPercentagesBy529Account,
  setContributionElectionPercentageFor529Account,
  isEditingContributions,
  electionType,
}) => {
  return (
    <div className="py-6 space-y-4">
      {loanServicers.length > 0 && contributionPlan.contributeStudentLoansEnabled && (
        <>
          <div className="border-b border-gray-200 py-5">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Student loans</h3>
          </div>
          {loanServicers.map((loanServicer) => (
            <ElectContributionsServicer
              loanServicer={loanServicer}
              key={loanServicer.id}
              contributionElectionAmountsByLoan={contributionElectionAmountsByLoan}
              setContributionElectionAmountForLoan={setContributionElectionAmountForLoan}
              contributionElectionPercentagesByLoan={contributionElectionPercentagesByLoan}
              setContributionElectionPercentageForLoan={setContributionElectionPercentageForLoan}
              isEditingContributions={isEditingContributions}
              electionType={electionType}
            />
          ))}
        </>
      )}

      {savings529Accounts.length > 0 && contributionPlan.contribute529Enabled && (
        <>
          <div className="border-b border-gray-200 py-5">
            <h3 className="text-lg font-medium leading-6 text-gray-900">529 accounts</h3>
          </div>
          {savings529Accounts.map((savings529Account) => (
            <ElectContributions529Account
              savings529Account={savings529Account}
              key={savings529Account.id}
              contributionElectionAmountsBy529Account={contributionElectionAmountsBy529Account}
              setContributionElectionAmountFor529Account={setContributionElectionAmountFor529Account}
              contributionElectionPercentagesBy529Account={contributionElectionPercentagesBy529Account}
              setContributionElectionPercentageFor529Account={setContributionElectionPercentageFor529Account}
              isEditingContributions={isEditingContributions}
              electionType={electionType}
            />
          ))}
        </>
      )}

      {!(loanServicers.length > 0 && contributionPlan.contributeStudentLoansEnabled) &&
        !(savings529Accounts.length > 0 && contributionPlan.contribute529Enabled) && (
          <EmptyPlaceholder
            title="No accounts added yet!"
            subtitle="Add connected accounts so you can start electing your contributions."
          />
        )}
    </div>
  );
};

export default ElectContributionsBody;
