import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

interface MenuOptionsProps {
  menuButton: React.ReactNode;
  menuItems: React.ReactNode;
}

const MenuOptions: React.FC<MenuOptionsProps> = ({ menuButton, menuItems }) => {
  return (
    <>
      <Menu as="div" className="relative inline-block whitespace-nowrap">
        <div>{menuButton}</div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute z-10 right-0 mt-2 origin-top-right rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
            {menuItems}
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default MenuOptions;
