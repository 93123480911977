import SubmitFeedbackModal from 'admin/TuitionReimbursement/ListRequests/OrganizationRequestActions/SubmitFeedbackModal';
import { TuitionReimbursementRequest } from 'interfaces/tuitionReimbursementInterfaces';
import { useState } from 'react';

interface CourseReviewActionsProps {
  tuitionReimbursementRequest?: TuitionReimbursementRequest;
}

const CourseReviewActions: React.FC<CourseReviewActionsProps> = ({ tuitionReimbursementRequest }) => {
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [approved, setApproved] = useState(false);

  return (
    <>
      <h2 className="text-lg font-medium text-gray-900">Needs company approval</h2>
      <div className="mt-2">
        <button
          className="inline-flex px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => {
            setApproved(true);
            setOpenFeedbackModal(true);
          }}
        >
          Approve
        </button>
        <button
          className="inline-flex items-center ml-2 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => {
            setApproved(false);
            setOpenFeedbackModal(true);
          }}
        >
          Deny
        </button>
      </div>
      <SubmitFeedbackModal
        tuitionReimbursementRequest={tuitionReimbursementRequest}
        open={openFeedbackModal}
        setOpen={setOpenFeedbackModal}
        approved={approved}
      />
    </>
  );
};

export default CourseReviewActions;
