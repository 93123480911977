import PageLoad from 'components/Load/PageLoad';
import { UserDataContext } from 'context/UserContext';
import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

const UnauthenticatedRoute: React.FC<RouteProps> = ({ ...routeProps }) => {
  const { firebaseUser, isAuthenticating } = useContext(UserDataContext);

  return <>{isAuthenticating ? <PageLoad /> : !firebaseUser ? <Route {...routeProps} /> : <Redirect to="/home" />}</>;
};

export default UnauthenticatedRoute;
