import EditParticipantModal from 'admin/ManageOrganizationUsers/ViewUser/ViewParticipant/EditParticipantModal';
import ViewParticipantBreadcrumb from 'admin/ManageOrganizationUsers/ViewUser/ViewParticipant/ViewParticipantBreadcrumb';
import ViewParticipantHeader from 'admin/ManageOrganizationUsers/ViewUser/ViewParticipant/ViewParticipantHeader';
import ViewParticipantPayments from 'admin/ManageOrganizationUsers/ViewUser/ViewParticipant/ViewParticipantPayments';
import ViewParticipantPlans from 'admin/ManageOrganizationUsers/ViewUser/ViewParticipant/ViewParticipantPlans';
import ViewParticipantProfile from 'admin/ManageOrganizationUsers/ViewUser/ViewParticipant/ViewParticipantProfile';
import ViewParticipantStatementUploads from 'admin/ManageOrganizationUsers/ViewUser/ViewParticipant/ViewParticipantStatementUploads';

import { apiGetParticipant } from 'api/organizationUsersAPI';
import EmptyPlaceholder from 'common/EmptyPlaceholder';
import PageLoad from 'components/Load/PageLoad';
import { OrganizationUser } from 'interfaces/organizationUserInterfaces';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AuthenticatedRoute from 'routes/AuthenticatedRoute';

interface ViewParticipantRouteParams {
  organizationUserId: string;
}

const ViewParticipant: React.FC = () => {
  const { organizationUserId } = useParams<ViewParticipantRouteParams>();
  const [isLoadingParticipant, setIsLoadingParticipant] = useState(true);
  const [participant, setParticipant] = useState<OrganizationUser>();
  const [openEditParticipantModal, setOpenEditParticipantModal] = useState(false);

  const getParticipant = async () => {
    setIsLoadingParticipant(true);
    apiGetParticipant(organizationUserId).then(
      (data) => {
        const loadedParticipant: OrganizationUser = data.participant;
        setParticipant(loadedParticipant);
        setIsLoadingParticipant(false);
      },
      (error) => {
        setIsLoadingParticipant(false);
      }
    );
  };

  useEffect(() => {
    getParticipant();
  }, []);
  return (
    <>
      {isLoadingParticipant ? (
        <PageLoad />
      ) : (
        <>
          {participant ? (
            <>
              <main className="flex-1 relative z-0 focus:outline-none xl:order-last">
                <ViewParticipantBreadcrumb participant={participant} />
                <ViewParticipantHeader
                  participant={participant}
                  setOpenEditParticipantModal={setOpenEditParticipantModal}
                />
                <AuthenticatedRoute
                  path={`/participants/${participant?.id}/view/profile`}
                  render={() => {
                    return <ViewParticipantProfile participant={participant} />;
                  }}
                />
                <AuthenticatedRoute
                  path={`/participants/${participant?.id}/view/plans`}
                  render={() => {
                    return <ViewParticipantPlans participant={participant} />;
                  }}
                />
                <AuthenticatedRoute
                  path={`/participants/${participant?.id}/view/payments`}
                  render={() => {
                    return <ViewParticipantPayments participant={participant} />;
                  }}
                />
                <AuthenticatedRoute
                  path={`/participants/${participant?.id}/view/statement-uploads`}
                  render={() => {
                    return <ViewParticipantStatementUploads participant={participant} />;
                  }}
                />
                <EditParticipantModal
                  open={openEditParticipantModal}
                  setOpen={setOpenEditParticipantModal}
                  participant={participant}
                  refreshParticipant={getParticipant}
                />
              </main>
            </>
          ) : (
            <EmptyPlaceholder title={'Participant not found'} subtitle={'This participant does not exist.'} />
          )}
        </>
      )}
    </>
  );
};

export default ViewParticipant;
