import api from 'config/axiosConfig';

export const apiGetUserAccount = () =>
  api
    .get(`/user-accounts`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiCreateUserAccount = () =>
  api
    .post(`/user-accounts`, {})
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });
