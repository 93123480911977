import HSAListTable from 'admin/HSA/HSAListTable';
import HSASetContributionModal from 'admin/HSA/HSASetContributionModal';
import React, { useState } from 'react';
import { formatMoney } from 'utils/numberFormatter';

const HSAList: React.FC = () => {
  const [openSetContributionModal, setOpenSetContributionModal] = useState(false);
  const [contributionAmount, setContributionAmount] = useState(250);
  const [hsaContributionRuns, setHsaContributionRuns] = useState([
    {
      id: '9d1527f0-79c8-435a-9901-6a3d519b7b81',
      name: 'Sept 30, 2023 Contributions',
      organizationId: 'string',
      match401kGrantRuns: [],
      totalContributionAmount: 3300,
      createdAt: '09-30-2023',
      updatedAt: '09-30-2023',
    },
    {
      id: '9d1527f0-79c8-435a-9901-6a3d519b7b81',
      name: 'Sept 15, 2023 Contributions',
      organizationId: 'string',
      match401kGrantRuns: [],
      totalContributionAmount: 2500,
      createdAt: '09-15-2023',
      updatedAt: '09-15-2023',
    },
  ]);

  return (
    <>
      <div className="my-6 px-8 mx-auto max-w-7xl">
        <div>
          <div className="flex justify-between">
            <h1 className="text-2xl font-bold text-gray-900">HSA Contributions</h1>
            <button
              onClick={() => {
                setOpenSetContributionModal(true);
              }}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-full sm:w-auto my-2 sm:my-0"
            >
              Manage contribution amount: {formatMoney(contributionAmount)}
            </button>
          </div>

          <HSAListTable hsaContributionRuns={hsaContributionRuns} />
          <HSASetContributionModal
            open={openSetContributionModal}
            setOpen={setOpenSetContributionModal}
            contributionAmount={contributionAmount}
            setContributionAmount={setContributionAmount}
          />
        </div>
      </div>
    </>
  );
};

export default HSAList;
