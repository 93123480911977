import { Dialog, Transition } from '@headlessui/react';
import { LightningBoltIcon } from '@heroicons/react/outline';
import { apiCreateNewPlan } from 'api/contributionsAPI';
import LoadingButton from 'components/Buttons/LoadingButton';
import { Fragment, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';

interface CreateNewPlanModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateNewPlanModal: React.FC<CreateNewPlanModalProps> = ({ open, setOpen }) => {
  const history = useHistory();

  const [error, setError] = useState('');

  const [isSubmittingNewPlan, setIsSubmittingNewPlan] = useState(false);

  const [values, setValues] = useState({
    name: '',
    description: '',
    defaultContributionAmount: 0.0,
    type: 'recurring',
    schedule: 'monthly',
    contributeStudentLoansEnabled: true,
    contribute529Enabled: false,
    employeeMatchRequired: false,
    matchPercentage: 0,
    eligibilityTenureDays: 0,
    electionDeadlineDay: 1,
    electionDeadlineDate: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    const checked = e.target.checked;
    setValues((prevValues) => ({ ...prevValues, [name]: checked }));
  };

  const handleClose = () => {
    setOpen(false);
    setError('');
  };

  const handleSubmitNewPlan = async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsSubmittingNewPlan(true);
    apiCreateNewPlan(
      values.name,
      values.description,
      values.defaultContributionAmount,
      values.type === 'recurring',
      values.schedule,
      values.eligibilityTenureDays,
      values.electionDeadlineDay,
      values.electionDeadlineDate,
      values.contributeStudentLoansEnabled,
      values.contribute529Enabled,
      values.employeeMatchRequired,
      values.matchPercentage
    ).then(
      (data) => {
        const newOrganizationPlan = data.newOrganizationPlan;
        setOpen(false);
        toast.success(`Successfully created new plan!`);
        history.push(`/plans/${newOrganizationPlan.id}/edit`);
        setIsSubmittingNewPlan(false);
      },
      (error) => {
        setError(error.message);
        setIsSubmittingNewPlan(false);
      }
    );
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={handleClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full">
              <form onSubmit={handleSubmitNewPlan}>
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                      <LightningBoltIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Create New Contribution Plan
                      </Dialog.Title>

                      <div className="mt-4">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                          Name
                        </label>
                        <div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            onChange={handleChange}
                            value={values.name}
                            className="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                            required
                          />
                        </div>
                      </div>

                      <div className="mt-4">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                          Description (optional)
                        </label>
                        <div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
                          <input
                            type="text"
                            name="description"
                            id="description"
                            onChange={handleChange}
                            value={values.description}
                            className="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div className="mt-4">
                        <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                          Default Contribution Amount
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm">$</span>
                          </div>
                          <input
                            type="number"
                            step=".01"
                            min="0.00"
                            max="10000.00"
                            name="defaultContributionAmount"
                            id="defaultContributionAmount"
                            onChange={handleChange}
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                            placeholder="0.00"
                            value={values.defaultContributionAmount}
                            required
                            aria-describedby="price-currency"
                          />
                          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                              USD
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">Eligibility tenure</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <input
                            type="number"
                            step="1"
                            min="0"
                            max="10000"
                            name="eligibilityTenureDays"
                            id="eligibilityTenureDays"
                            onChange={handleChange}
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                            placeholder="0"
                            value={values.eligibilityTenureDays}
                            required
                          />
                          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm">days</span>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4">
                        <label className="text-sm font-medium text-gray-700">Type</label>
                        <fieldset className="mt-2">
                          <legend className="sr-only">Type</legend>
                          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div className="flex items-center">
                              <input
                                id="recurring"
                                name="type"
                                type="radio"
                                value="recurring"
                                checked={values.type === 'recurring'}
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                onChange={handleChange}
                              />
                              <label htmlFor="recurring" className="ml-3 block text-sm font-medium text-gray-700">
                                Recurring
                              </label>
                            </div>
                            <div className="flex items-center">
                              <input
                                id="one-time"
                                name="type"
                                type="radio"
                                value="one-time"
                                checked={values.type === 'one-time'}
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                onChange={handleChange}
                              />
                              <label htmlFor="one-time" className="ml-3 block text-sm font-medium text-gray-700">
                                One time
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>

                      {values.type === 'recurring' ? (
                        <div className="mt-4">
                          <label className="block text-sm font-medium text-gray-700">
                            Election deadline (Day of the month)
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="number"
                              step="1"
                              min="1"
                              max="30"
                              name="electionDeadlineDay"
                              id="electionDeadlineDay"
                              onChange={handleChange}
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              placeholder="1"
                              value={values.electionDeadlineDay}
                              required
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="mt-4">
                          <label className="block text-sm font-medium text-gray-700">Election deadline (date)</label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="date"
                              name="electionDeadlineDate"
                              id="electionDeadlineDate"
                              onChange={handleChange}
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              placeholder="0"
                              value={values.electionDeadlineDate}
                              required
                            />
                          </div>
                        </div>
                      )}

                      {values.type === 'recurring' && (
                        <div className="mt-4">
                          <label htmlFor="schedule" className="block text-sm font-medium text-gray-700">
                            Schedule
                          </label>
                          <select
                            id="schedule"
                            name="schedule"
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            value={values.schedule}
                            onChange={handleChange}
                          >
                            <option value="monthly">Monthly</option>
                            <option value="every_3_months">Every 3 months</option>
                            <option value="every_6_months">Every 6 months</option>
                            <option value="yearly">Yearly</option>
                          </select>
                        </div>
                      )}

                      <div className="mt-4 relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="contributeStudentLoansEnabled"
                            name="contributeStudentLoansEnabled"
                            type="checkbox"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            checked={values.contributeStudentLoansEnabled}
                            onChange={handleCheckboxChange}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="contributeStudentLoansEnabled" className="font-medium text-gray-700">
                            Allow student loans
                          </label>
                        </div>
                      </div>

                      <div className="mt-4 relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="contribute529Enabled"
                            name="contribute529Enabled"
                            type="checkbox"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            checked={values.contribute529Enabled}
                            onChange={handleCheckboxChange}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="contribute529Enabled" className="font-medium text-gray-700">
                            Allow 529 accounts
                          </label>
                        </div>
                      </div>

                      <div className="mt-4 relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="employeeMatchRequired"
                            name="employeeMatchRequired"
                            type="checkbox"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            checked={values.employeeMatchRequired}
                            onChange={handleCheckboxChange}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="employeeMatchRequired" className="font-medium text-gray-700">
                            Employee match required
                          </label>
                        </div>
                      </div>
                      {values.employeeMatchRequired && (
                        <div className="mt-4">
                          <label className="block text-sm font-medium text-gray-700">Match percentage</label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="number"
                              step="1"
                              min="0"
                              max="100"
                              name="matchPercentage"
                              id="matchPercentage"
                              onChange={handleChange}
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                              placeholder="0"
                              value={values.matchPercentage}
                              required
                            />
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                              <span className="text-gray-500 sm:text-sm">%</span>
                            </div>
                          </div>
                        </div>
                      )}

                      {error !== '' && (
                        <div className="mt-2">
                          <p className="text-sm text-red-500">{error}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <LoadingButton
                    type="submit"
                    styles="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                    text="Submit"
                    loadingText="Processing..."
                    isLoading={isSubmittingNewPlan}
                  />
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateNewPlanModal;
