import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import React from 'react';

interface InactiveLoanToggleProps {
  showInactiveAccounts: boolean;
  setShowInactiveAccounts: React.Dispatch<React.SetStateAction<boolean>>;
}

const InactiveLoanToggle: React.FC<InactiveLoanToggleProps> = ({ showInactiveAccounts, setShowInactiveAccounts }) => {
  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-center">
        <button
          type="button"
          className="inline-flex items-center shadow-sm px-4 py-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => {
            setShowInactiveAccounts(!showInactiveAccounts);
          }}
        >
          {showInactiveAccounts ? (
            <>
              <ChevronUpIcon className="-ml-1.5 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
              <span>Hide inactive accounts</span>
            </>
          ) : (
            <>
              <ChevronDownIcon className="-ml-1.5 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
              <span>Show inactive accounts</span>
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default InactiveLoanToggle;
