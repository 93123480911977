import { ChevronRightIcon, HomeIcon } from '@heroicons/react/solid';
import { TuitionReimbursementGrant } from 'interfaces/tuitionReimbursementInterfaces';
import React from 'react';
import { Link } from 'react-router-dom';

interface ViewGrantBreadcrumbProps {
  tuitionReimbursementGrant: TuitionReimbursementGrant;
}

const ViewGrantBreadcrumb: React.FC<ViewGrantBreadcrumbProps> = ({ tuitionReimbursementGrant }) => {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div>
            <Link to="/" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        <li>
          <div className="flex items-center">
            <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
            <Link
              to="/tuition-reimbursement/plans"
              className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
            >
              Plans
            </Link>
          </div>
        </li>
        <li>
          <div className="flex items-center">
            <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
            <Link
              to={`/tuition-reimbursement/plans/${tuitionReimbursementGrant.tuitionReimbursementPlan?.id}/view`}
              className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
            >
              {tuitionReimbursementGrant.tuitionReimbursementPlan?.name}
            </Link>
          </div>
        </li>
        <li>
          <div className="flex items-center">
            <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
            <div className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
              {tuitionReimbursementGrant.grantee.organizationUser?.firstName}{' '}
              {tuitionReimbursementGrant.grantee.organizationUser?.lastName}
            </div>
          </div>
        </li>
      </ol>
    </nav>
  );
};

export default ViewGrantBreadcrumb;
