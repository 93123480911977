import { CurrencyDollarIcon, MinusSmIcon, PlusCircleIcon, XIcon } from '@heroicons/react/outline';
import { OrganizationUser } from 'interfaces/organizationUserInterfaces';
import React from 'react';
import { formatMoney } from 'utils/numberFormatter';

interface ContributionGrantColumnProps {
  participant: OrganizationUser;
  oldContributionGrantsByGrantee: { [key: string]: number };
  newContributionGrantsByGrantee: { [key: string]: number };
}

const ContributionGrantColumn: React.FC<ContributionGrantColumnProps> = ({
  participant,
  oldContributionGrantsByGrantee,
  newContributionGrantsByGrantee,
}) => {
  const oldContributionAmount = oldContributionGrantsByGrantee[participant.id];
  const newContributionAmount = newContributionGrantsByGrantee[participant.id];

  return (
    <div className="flex">
      {!oldContributionAmount && !newContributionAmount && (
        <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-gray-100 text-gray-800">
          <MinusSmIcon className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
          None
        </span>
      )}
      {oldContributionAmount &&
        (newContributionAmount && newContributionAmount !== oldContributionAmount ? (
          <span className="inline-flex line-through px-2 text-xs leading-5 font-semibold rounded-md bg-red-100 text-red-800">
            <XIcon className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-red-400" aria-hidden="true" />
            {formatMoney(Number(oldContributionGrantsByGrantee[participant.id]))}
          </span>
        ) : (
          <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-purple-100 text-purple-800">
            <CurrencyDollarIcon
              className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-purple-400"
              aria-hidden="true"
            />
            {formatMoney(Number(oldContributionGrantsByGrantee[participant.id]))}
          </span>
        ))}
      {newContributionAmount && newContributionAmount !== 0 && newContributionAmount !== oldContributionAmount && (
        <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-green-100 text-green-800">
          <PlusCircleIcon className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" aria-hidden="true" />
          {formatMoney(Number(newContributionGrantsByGrantee[participant.id]))}
        </span>
      )}
    </div>
  );
};

export default ContributionGrantColumn;
