import { CheckCircleIcon, PencilIcon, XCircleIcon } from '@heroicons/react/solid';
import { OrganizationUser } from 'interfaces/organizationUserInterfaces';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { classNames } from 'utils/tailwindUtils';

interface ViewParticipantHeaderProps {
  participant: OrganizationUser;
  setOpenEditParticipantModal: React.Dispatch<React.SetStateAction<boolean>>;
}
interface ViewParticipantTabs {
  name: string;
  href: string;

  current: boolean;
}

const ViewParticipantHeader: React.FC<ViewParticipantHeaderProps> = ({ participant, setOpenEditParticipantModal }) => {
  const location = useLocation();
  const locationPath = location.pathname;
  const firstName = participant?.firstName;
  const lastName = participant?.lastName;

  const tabs: ViewParticipantTabs[] = [
    {
      name: 'Profile',
      href: `/participants/${participant?.id}/view/profile`,
      current: locationPath === `/participants/${participant?.id}/view/profile`,
    },
    {
      name: 'Plans',
      href: `/participants/${participant?.id}/view/plans`,
      current: locationPath === `/participants/${participant?.id}/view/plans`,
    },
    {
      name: 'Payments',
      href: `/participants/${participant?.id}/view/payments`,
      current: locationPath === `/participants/${participant?.id}/view/payments`,
    },
    {
      name: 'Uploaded statements',
      href: `/participants/${participant?.id}/view/statement-uploads`,
      current: locationPath === `/participants/${participant?.id}/view/statement-uploads`,
    },
  ];
  return (
    <article>
      {/* Profile header */}
      <div className="mt-4 mx-auto sm:flex sm:items-center sm:justify-between sm:space-x-5 ">
        <div className="flex items-center space-x-5">
          <div className="flex-shrink-0">
            <div className="relative">
              <span className="inline-flex items-center justify-center h-16 w-16 rounded-full bg-indigo-500">
                <span className="text-xl font-medium leading-none text-white">
                  {firstName?.charAt(0).toUpperCase()}
                  {lastName?.charAt(0).toUpperCase()}
                </span>
              </span>
              <span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true" />
            </div>
          </div>
          <div>
            <h1 className="text-2xl font-bold text-gray-900">
              {firstName} {lastName}
            </h1>
            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
              <div className="mt-2 flex items-center text-sm text-gray-500">
                {participant.active ? (
                  <>
                    <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    Active
                  </>
                ) : (
                  <>
                    <XCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    Disabled
                  </>
                )}
              </div>
              <div className="mt-2 flex items-center text-sm text-gray-500">
                {participant.claimed ? (
                  <>
                    <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    Claimed
                  </>
                ) : (
                  <>
                    <XCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    Unclaimed
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
          <button
            type="button"
            className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => setOpenEditParticipantModal(true)}
          >
            <PencilIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
            <span>Edit</span>
          </button>
        </div>
      </div>

      {/* Tabs */}
      <div className="mt-6 sm:mt-2 2xl:mt-5 border-b border-gray-200 overflow-x-auto scrollbar-hide">
        <nav className="flex space-x-8" aria-label="Tabs">
          {tabs.map((tab) => (
            <Link
              key={tab.name}
              to={tab.href}
              className={classNames(
                tab.current
                  ? 'border-purple-500 text-gray-900'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              {tab.name}
            </Link>
          ))}
        </nav>
      </div>
    </article>
  );
};

export default ViewParticipantHeader;
