import { Menu } from '@headlessui/react';
import { CheckCircleIcon, ClockIcon, CogIcon, DuplicateIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import { ExclamationIcon } from '@heroicons/react/solid';
import { apiFixPlaidLoanServicer, apiGetPlaidLinkUpdateToken, apiLoanServicerPlaidRefresh } from 'api/loansAPI';
import MenuOptions from 'components/MenuOptions';
import { LoanAccount, LoanServicer } from 'interfaces/loansInterfaces';
import ActiveLoanAccountsTable from 'participant/Accounts/LoanAccount/ActiveLoanAccounts/ActiveLoanAccountsTable';
import InactiveLoanAccountsTable from 'participant/Accounts/LoanAccount/InactiveLoanAccounts/InactiveLoanAccountsTable';
import InactiveLoanToggle from 'participant/Accounts/LoanServicer/InactiveLoanToggle';
import LoanServicerSettingsOptions from 'participant/Accounts/LoanServicer/LoanServicerSettingsOptions';
import AddManualLoanAccountModal from 'participant/Accounts/LoanServicer/modals/AddManualLoanAccountModal';
import DeactivateLoanAccountModal from 'participant/Accounts/LoanServicer/modals/DeactivateLoanAccountModal';
import DeleteLoanServicerModal from 'participant/Accounts/LoanServicer/modals/DeleteLoanServicerModal';
import ManageStatementUploadsModal from 'participant/Accounts/LoanServicer/modals/ManageStatementUploadsModal';
import ReactivateLoanAccountModal from 'participant/Accounts/LoanServicer/modals/ReactivateLoanAccountModal';
import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { PlaidLinkOnSuccess, PlaidLinkOptions, usePlaidLink } from 'react-plaid-link';
import { formatMoney } from 'utils/numberFormatter';
import { classNames } from 'utils/tailwindUtils';

interface LoanServicerElementProps {
  loanServicer: LoanServicer;
  refreshAccountData: () => void;
}

const LoanServicerElement: React.FC<LoanServicerElementProps> = ({ loanServicer, refreshAccountData }) => {
  const [showInactiveAccounts, setShowInactiveAccounts] = useState(false);

  const [openDeactivateAccountModal, setOpenDeactivateAccountModal] = useState(false);
  const [toDeactivateAccount, setToDeactivateAccount] = useState<LoanAccount>();
  const [openReactivateAccountModal, setOpenReactivateAccountModal] = useState(false);
  const [toReactivateAccount, setToReactivateAccount] = useState<LoanAccount>();
  const [openDeleteLoanServicerModal, setOpenDeleteLoanServicerModal] = useState(false);
  const [openManageStatementUploadsModal, setOpenManageStatementUploadsModal] = useState(false);
  const [openAddManualLoanAccountModal, setOpenAddManualLoanAccountModal] = useState(false);
  const [plaidLinkToken, setPlaidLinkToken] = useState('');
  const [linkLoad, setLinkLoad] = useState(true);
  const [linkError, setLinkError] = useState('');

  const startDeactivateAccount = (loanAccount: LoanAccount) => {
    setToDeactivateAccount(loanAccount);
    setOpenDeactivateAccountModal(true);
  };
  const startReactivateAccount = (loanAccount: LoanAccount) => {
    setToReactivateAccount(loanAccount);
    setOpenReactivateAccountModal(true);
  };

  const refreshPlaidLoanServicer = async () => {
    await apiLoanServicerPlaidRefresh(loanServicer.id).then(
      (data) => {
        refreshAccountData();
      },
      (error) => {}
    );
  };

  const loanAccounts = loanServicer.loanAccounts;
  const activeLoanAccounts = loanAccounts.filter((loanAccount) => loanAccount.active);
  const inactiveLoanAccounts = loanAccounts.filter((loanAccount) => !loanAccount.active);
  const plaidItem = loanServicer.plaidItem;
  const updateMode = loanServicer.plaidItem?.updateMode;
  const approvedStatementUploadMatchSum = loanServicer.statementUploads.reduce(
    (loanServicerMatchableEmployeeContribution, statementUpload) =>
      loanServicerMatchableEmployeeContribution +
      (statementUpload.statementUploadReviewStatus === 'approved' && statementUpload.matchableEmployeeContribution
        ? Number(statementUpload.matchableEmployeeContribution)
        : 0),
    0
  );

  const onSuccess = useCallback<PlaidLinkOnSuccess>(async (public_token, metadata) => {
    //update loans if added loans
    apiFixPlaidLoanServicer(public_token, metadata).then(
      (data) => {
        refreshAccountData();
        toast.success('Successfully re-authenticated with your loan servicer!');
      },
      (error) => {
        toast.error(error.message);
      }
    );
  }, []);

  const config: PlaidLinkOptions = {
    token: plaidLinkToken,
    onSuccess,
    // onExit
    // onEvent
  };

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    const generateToken = async () => {
      apiGetPlaidLinkUpdateToken(plaidItem!.itemId).then(
        (data) => {
          setPlaidLinkToken(data.link_token);
          setLinkLoad(false);
        },
        (error) => {
          setLinkError(error.message);
          setLinkLoad(false);
        }
      );
    };
    if (updateMode) {
      generateToken();
    }
  }, [updateMode]);

  return (
    <>
      <div className="px-8 py-4 ring-1 ring-black ring-opacity-10 rounded">
        <div>
          <div className="sm:items-center flex flex-col sm:flex-row">
            <div className="flex-auto">
              <h1 className="text-xl font-bold text-gray-900">{loanServicer.name}</h1>
            </div>

            <MenuOptions
              menuButton={
                <Menu.Button className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-full sm:w-auto my-2 sm:my-0">
                  Manage servicer
                </Menu.Button>
              }
              menuItems={
                <LoanServicerSettingsOptions
                  loanServicer={loanServicer}
                  setOpenDeleteLoanServicerModal={setOpenDeleteLoanServicerModal}
                  setOpenManageStatementUploadsModal={setOpenManageStatementUploadsModal}
                  refreshPlaidLoanServicer={refreshPlaidLoanServicer}
                  setOpenAddManualLoanAccountModal={setOpenAddManualLoanAccountModal}
                />
              }
            />
          </div>

          {approvedStatementUploadMatchSum > 0 && (
            <div className="mt-1 ">
              <div className="inline-flex rounded-md bg-green-100 px-2 py-1 text-sm leading-5 text-green-800">
                <DuplicateIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" aria-hidden="true" />
                Qualifying payments for employer match: {formatMoney(Number(approvedStatementUploadMatchSum))}
              </div>
            </div>
          )}
          {updateMode && (
            <div className="mt-1 ">
              <button
                className="inline-flex rounded-md shadow-sm bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 px-2 py-1 text-sm leading-5 text-white"
                onClick={() => open()}
                disabled={ready === false}
              >
                <ExclamationCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-white" aria-hidden="true" />
                Click here to fix connection
              </button>
            </div>
          )}
          {!loanServicer.plaidItem && (
            <div className="mt-1 ">
              <div className="inline-flex rounded-md bg-gray-100 px-2 text-sm leading-5 text-gray-800">
                <CogIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                Manual connection
              </div>
            </div>
          )}
          {!loanServicer.plaidItem && !loanServicer.verified && (
            <div className="mt-1 ">
              <div className="inline-flex rounded-md bg-yellow-100 px-2 text-sm leading-5 text-yellow-800">
                <ClockIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-yellow-400" aria-hidden="true" />
                Pending verification...
              </div>
            </div>
          )}
          {!loanServicer.plaidItem && loanServicer.verified && (
            <div className="mt-1 ">
              <div className="inline-flex rounded-md bg-green-100 px-2 text-sm leading-5 text-green-800">
                <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" aria-hidden="true" />
                Verified
              </div>
            </div>
          )}
          {!loanServicer.lastRefresh && (
            <div className="mt-1 ">
              <div className="inline-flex rounded-md bg-yellow-100 px-2 text-sm leading-5 text-yellow-800">
                <ClockIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-yellow-400" aria-hidden="true" />
                Account sync in progress...
              </div>
            </div>
          )}
        </div>

        {/* active loan table */}
        <div className="mt-4 flex flex-col">
          <div className="inline-block min-w-full py-2 align-middle">
            <ActiveLoanAccountsTable
              loanAccounts={activeLoanAccounts}
              startDeactivateAccount={startDeactivateAccount}
            />
          </div>
        </div>

        {/* Inactive loan switcher */}
        {inactiveLoanAccounts.length > 0 && (
          <>
            <InactiveLoanToggle
              showInactiveAccounts={showInactiveAccounts}
              setShowInactiveAccounts={setShowInactiveAccounts}
            />
            {/* Inactive loan table */}

            <div className={classNames(!showInactiveAccounts ? 'hidden' : '', 'mt-4 flex flex-col')}>
              <div className="inline-block min-w-full py-2 align-middle">
                <InactiveLoanAccountsTable
                  loanAccounts={inactiveLoanAccounts}
                  startReactivateAccount={startReactivateAccount}
                />
              </div>
            </div>
          </>
        )}

        {loanAccounts.length === 0 && (
          <div className="rounded-md bg-red-50 p-4 mt-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">No accounts added</h3>
                <div className="mt-2 text-sm text-red-700">
                  <p>
                    You must manually enter your loan accounts with accurate account numbers in order to set your
                    payment elections. Submit each individual account by clicking{' '}
                    <span className="font-bold">Manage servicer</span> {'>'}{' '}
                    <span className="font-bold">Add manual account</span>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <DeactivateLoanAccountModal
        institutionName={loanServicer.name}
        loanAccount={toDeactivateAccount}
        open={openDeactivateAccountModal}
        setOpen={setOpenDeactivateAccountModal}
        refreshAccountData={refreshAccountData}
      />
      <ReactivateLoanAccountModal
        institutionName={loanServicer.name}
        loanAccount={toReactivateAccount}
        open={openReactivateAccountModal}
        setOpen={setOpenReactivateAccountModal}
        refreshAccountData={refreshAccountData}
      />
      <DeleteLoanServicerModal
        loanServicer={loanServicer}
        open={openDeleteLoanServicerModal}
        setOpen={setOpenDeleteLoanServicerModal}
        refreshAccountData={refreshAccountData}
      />
      <ManageStatementUploadsModal
        loanServicer={loanServicer}
        open={openManageStatementUploadsModal}
        setOpen={setOpenManageStatementUploadsModal}
      />
      <AddManualLoanAccountModal
        loanServicer={loanServicer}
        open={openAddManualLoanAccountModal}
        setOpen={setOpenAddManualLoanAccountModal}
        refreshAccountData={refreshAccountData}
      />
    </>
  );
};

export default LoanServicerElement;
