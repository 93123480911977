import { CalendarIcon, UsersIcon } from '@heroicons/react/outline';
import DisablePlanModal from 'admin/TuitionReimbursement/ListPlans/DisablePlanModal';

import { TuitionReimbursementPlan } from 'interfaces/tuitionReimbursementInterfaces';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { classNames } from 'utils/tailwindUtils';

interface TuitionReimbursementPlanElementProps {
  tuitionReimbursementPlan: TuitionReimbursementPlan;
  refreshTuitionReimbursementPlans: () => void;
}

const TuitionReimbursementPlanElement: React.FC<TuitionReimbursementPlanElementProps> = ({
  tuitionReimbursementPlan,
  refreshTuitionReimbursementPlans,
}) => {
  const [openDisablePlanModal, setOpenDisablePlanModal] = useState(false);

  const cards = [
    {
      name: 'Eligible participants',
      href: '#',
      icon: UsersIcon,
      amount: tuitionReimbursementPlan._count?.tuitionReimbursementGrants,
      logoBgColor: 'bg-purple-100',
      logoTextColor: 'text-purple-600',
    },

    {
      name: 'Annual reimbursement maximum',
      href: '#',
      icon: CalendarIcon,
      amount: tuitionReimbursementPlan.annualReimbursementMax,
      logoBgColor: 'bg-sky-100',
      logoTextColor: 'text-sky-600',
    },
    {
      name: 'Credit hour maximum',
      href: '#',
      icon: CalendarIcon,
      amount: tuitionReimbursementPlan.creditHourMax,
      logoBgColor: 'bg-sky-100',
      logoTextColor: 'text-sky-600',
    },
    // More items...
  ];

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8 py-6 ring-1 ring-black ring-opacity-10 rounded">
        <div className="sm:items-center flex flex-col sm:flex-row">
          <div className="flex-auto">
            <h1 className="text-xl font-bold text-gray-900">{tuitionReimbursementPlan.name}</h1>
          </div>

          <Link
            to={`/tuition-reimbursement/plans/${tuitionReimbursementPlan.id}/view`}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-full sm:w-auto my-2 sm:my-0"
          >
            Manage plan
          </Link>
        </div>

        <div>
          {tuitionReimbursementPlan.active ? (
            <span className="inline-flex rounded-md bg-green-100 px-2 text-sm leading-5 text-green-800">Active</span>
          ) : (
            <span className="inline-flex rounded-md bg-red-100 px-2 text-sm leading-5 text-red-800">Disabled</span>
          )}
        </div>

        <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {/* Card */}
          {cards.map((card) => (
            <div
              key={card.name}
              className="bg-white ring-1 ring-black ring-opacity-5 overflow-hidden shadow-md rounded-md"
            >
              <div className="p-5">
                <div className="flex items-center">
                  <div
                    className={classNames(
                      'flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full',
                      card.logoBgColor
                    )}
                  >
                    <card.icon className={classNames('h-6 w-6', card.logoTextColor)} aria-hidden="true" />
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">{card.name}</dt>
                      <dd>
                        <div className="text-lg font-medium text-gray-900">{card.amount}</div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <DisablePlanModal
        tuitionReimbursementPlan={tuitionReimbursementPlan}
        open={openDisablePlanModal}
        setOpen={setOpenDisablePlanModal}
        onDisableSuccess={refreshTuitionReimbursementPlans}
      />
    </>
  );
};

export default TuitionReimbursementPlanElement;
