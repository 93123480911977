import { Dialog, Transition } from '@headlessui/react';
import { CurrencyDollarIcon } from '@heroicons/react/outline';
import LoadingButton from 'components/Buttons/LoadingButton';
import { ExternalBankAccount } from 'interfaces/externalBankAccountInterfaces';
import { TuitionReimbursementPayment } from 'interfaces/tuitionReimbursementInterfaces';
import { Fragment } from 'react';

interface ConfirmTuitionReimbursementPaymentAccountModalProps {
  tuitionReimbursementPayment?: TuitionReimbursementPayment;
  selectedExternalBankAccount: ExternalBankAccount;
  isSubmittingTuitionReimbursementPaymentAccount: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  submitTuitionReimbursementPaymentAccount: () => void;
}

const ConfirmTuitionReimbursementPaymentAccountModal: React.FC<ConfirmTuitionReimbursementPaymentAccountModalProps> = ({
  tuitionReimbursementPayment,
  selectedExternalBankAccount,
  isSubmittingTuitionReimbursementPaymentAccount,
  open,
  setOpen,
  submitTuitionReimbursementPaymentAccount,
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => setOpen(false)}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                      <CurrencyDollarIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Are you sure you want to send your tuition reimbursement to this external bank account?
                    </Dialog.Title>

                    <div className="mt-4">
                      <label htmlFor="bankName" className="block text-sm font-medium text-gray-700">
                        Bank name
                      </label>
                      <div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
                        {selectedExternalBankAccount.institutionName}
                      </div>
                    </div>
                    <div className="mt-4">
                      <label htmlFor="accountType" className="block text-sm font-medium text-gray-700">
                        Account type
                      </label>
                      <div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
                        {selectedExternalBankAccount.accountType?.charAt(0).toUpperCase()}
                        {selectedExternalBankAccount.accountType?.slice(1)}
                      </div>
                    </div>
                    <div className="mt-4">
                      <label htmlFor="routingNumber" className="block text-sm font-medium text-gray-700">
                        Routing number
                      </label>
                      <div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
                        {selectedExternalBankAccount.routingNumber}
                      </div>
                    </div>
                    <div className="mt-4">
                      <label htmlFor="accountNumber" className="block text-sm font-medium text-gray-700">
                        Account number
                      </label>
                      <div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
                        {selectedExternalBankAccount.accountNumber}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <LoadingButton
                  type="button"
                  styles="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                  text="Confirm"
                  loadingText="Processing..."
                  isLoading={isSubmittingTuitionReimbursementPaymentAccount}
                  onClick={submitTuitionReimbursementPaymentAccount}
                />
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ConfirmTuitionReimbursementPaymentAccountModal;
