import ListMatch401kPlanRunsTable from 'admin/Match401k/ListMatch401kPlanRunsTable';
import PageLoad from 'components/Load/PageLoad';
import { UserDataContext } from 'context/UserContext';
import { Match401kPlanRun } from 'interfaces/match401kInterfaces';
import React, { useContext, useEffect, useState } from 'react';

const ListMatch401kPlanRuns: React.FC = () => {
  const { firebaseUser, currentOrganizationUser } = useContext(UserDataContext);
  const [match401kPlanRuns, setMatch401kPlanRuns] = useState<Match401kPlanRun[]>([
    {
      id: '9d1527f0-79c8-435a-9901-6a3d519b7b81',
      name: 'December 2022 Contributions',
      organizationId: 'string',
      match401kGrantRuns: [],
      createdAt: '01-01-2023',
      updatedAt: '01-01-2023',
    },
  ]);
  const [isLoadingMatch401kPlanRuns, setIsLoadingMatch401kPlanRuns] = useState(true);

  const getMatch401kPlanRuns = async () => {
    setIsLoadingMatch401kPlanRuns(false);
  };

  useEffect(() => {
    if (firebaseUser) {
      getMatch401kPlanRuns();
    }
  }, []);
  return (
    <>
      {isLoadingMatch401kPlanRuns ? (
        <PageLoad />
      ) : (
        <>
          <div className="my-6 px-8 mx-auto max-w-7xl">
            <div>
              <div className="flex justify-between">
                <h1 className="text-2xl font-bold text-gray-900">401(k) Match Runs</h1>
              </div>

              <ListMatch401kPlanRunsTable match401kPlanRuns={match401kPlanRuns} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ListMatch401kPlanRuns;
