import CreateNewPlanModal from 'admin/ContributionPlans/ListPlans/CreateNewPlanModal';
import ListPlansBody from 'admin/ContributionPlans/ListPlans/ListPlansBody';
import PlanFilterDropdowns from 'admin/ContributionPlans/ListPlans/PlanFilterDropdowns';
import { apiGetOrganizationPlans } from 'api/contributionsAPI';
import PageLoad from 'components/Load/PageLoad';
import { UserDataContext } from 'context/UserContext';
import { ContributionPlan } from 'interfaces/contributionsInterfaces';
import React, { useContext, useEffect, useState } from 'react';

const ListPlans: React.FC = () => {
  const { firebaseUser, currentOrganizationUser } = useContext(UserDataContext);
  const [organizationPlans, setOrganizationPlans] = useState<ContributionPlan[]>([]);
  const [isLoadingOrganizationPlans, setIsLoadingOrganizationPlans] = useState(true);
  const [openCreateNewPlanModal, setOpenCreateNewPlanModal] = useState(false);
  const [selectedPlanType, setSelectedPlanType] = useState('Recurring plans');
  const [selectedPlanStatus, setSelectedPlanStatus] = useState('Active');

  const getOrganizationPlans = async () => {
    setIsLoadingOrganizationPlans(true);
    apiGetOrganizationPlans().then(
      (data) => {
        setOrganizationPlans(data.organizationPlans);
        setIsLoadingOrganizationPlans(false);
      },
      (error) => {
        setIsLoadingOrganizationPlans(false);
      }
    );
  };

  useEffect(() => {
    if (firebaseUser) {
      getOrganizationPlans();
    }
  }, []);
  return (
    <>
      {isLoadingOrganizationPlans ? (
        <PageLoad />
      ) : (
        <>
          <div className="my-6 px-8 mx-auto max-w-7xl">
            <div>
              <div className="flex justify-between">
                <h1 className="text-2xl font-bold text-gray-900">Contribution Plans</h1>
              </div>

              <div className="border-b border-gray-200 py-2 flex flex-col sm:flex-row sm:items-center space-y-2 sm:space-y-0">
                <PlanFilterDropdowns
                  selectedPlanType={selectedPlanType}
                  setSelectedPlanType={setSelectedPlanType}
                  selectedPlanStatus={selectedPlanStatus}
                  setSelectedPlanStatus={setSelectedPlanStatus}
                />

                <div className="sm:ml-auto flex">
                  <button
                    type="button"
                    className="w-full sm:w-auto justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => {
                      setOpenCreateNewPlanModal(true);
                    }}
                  >
                    + Create New Plan
                  </button>
                </div>
              </div>

              <ListPlansBody
                plans={organizationPlans}
                selectedPlanType={selectedPlanType}
                selectedPlanStatus={selectedPlanStatus}
                refreshOrganizationPlans={getOrganizationPlans}
              />
            </div>
          </div>
          <CreateNewPlanModal open={openCreateNewPlanModal} setOpen={setOpenCreateNewPlanModal} />
        </>
      )}
    </>
  );
};

export default ListPlans;
