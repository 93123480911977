import { InformationCircleIcon } from '@heroicons/react/solid';
import React, { useEffect } from 'react';

const credibleEnv = process.env.REACT_APP_CREDIBLE_ENV;

interface Window {
  [key: string]: any;
}

const LoanRefinancingPage: React.FC = () => {
  const CredibleSDK = (window as Window)['CredibleSDK'];

  useEffect(() => {
    CredibleSDK.initWidget('#student-loan-refi-embedded-prequal', {
      environment: credibleEnv,
      product: {
        marketplace: 'student-loan-refi',
        type: 'embedded-prequal',
      },
      analytics: {
        source: 'unsaddl',
      },
    });
  }, []);

  return (
    <>
      <div className="my-6 px-8 mx-auto max-w-7xl">
        <h1 className="text-2xl font-bold text-gray-900">Student Loan Refinancing</h1>
        <div className="rounded-md bg-blue-50 p-4 mt-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-blue-800">Special offer!</h3>
              <div className="mt-2 text-sm text-blue-700">
                <p>
                  Receive a $500 gift card when you refinance at least $100,000 in student loans. Receive a $250 gift
                  card when you refinance any other amount of student loans.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4" id="student-loan-refi-embedded-prequal" />
        <div className="mt-4">
          <span className="text-gray-500 text-sm">
            Note: The refinancing offers that appear on this site are from Credible from which Unsaddl receives
            compensation. Unsaddl does not include all student loan companies or all student loan offers available in
            the marketplace.
          </span>
        </div>
      </div>
    </>
  );
};

export default LoanRefinancingPage;
