import HSAContributions from 'admin/HSA/HSAContributions';
import HSAList from 'admin/HSA/HSAList';

import React from 'react';
import AuthenticatedRoute from 'routes/AuthenticatedRoute';

const HSAPage: React.FC = () => {
  return (
    <>
      <AuthenticatedRoute
        path={`/hsa/list`}
        render={() => {
          return <HSAList />;
        }}
      />
      <AuthenticatedRoute
        path={`/hsa/contributions`}
        render={() => {
          return <HSAContributions />;
        }}
      />
    </>
  );
};

export default HSAPage;
