import { initializeApp } from 'firebase/app';
import {
  browserLocalPersistence,
  EmailAuthProvider,
  getAuth,
  GoogleAuthProvider,
  isSignInWithEmailLink,
  setPersistence,
} from 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

initializeApp(firebaseConfig);

setPersistence(getAuth(), browserLocalPersistence);

// Configure FirebaseUI.
const firebaseUiConfig = {
  signInFlow: isSignInWithEmailLink(getAuth(), window.location.href) ? 'redirect' : 'popup',
  signInOptions: [
    GoogleAuthProvider.PROVIDER_ID,
    {
      provider: EmailAuthProvider.PROVIDER_ID,
      signInMethod: EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
    },
  ],
  callbacks: { signInSuccessWithAuthResult: () => false },
};

const logOut = async () => {
  getAuth().signOut();
};

export { firebaseUiConfig, logOut };
