import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import AssignGrantModal from 'admin/ContributionPlans/EditGrants/AssignGrants/AssignGrantModal';
import ContributionGrantColumn from 'admin/ContributionPlans/EditGrants/ContributionGrantColumn';
import EmptyTablePlaceholder from 'common/EmptyTablePlaceholder';
import Pagination from 'components/Tables/Pagination';
import { ContributionPlan } from 'interfaces/contributionsInterfaces';
import { OrganizationUser } from 'interfaces/organizationUserInterfaces';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { CellProps, Column, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { calculateDateDiff } from 'utils/dateUtils';

interface AssignGrantsTableProps {
  participants: OrganizationUser[];
  searchTerm: string;
  contributionPlan: ContributionPlan | undefined;
  oldContributionGrantsByGrantee: { [key: string]: number };
  newContributionGrantsByGrantee: { [key: string]: number };
  setNewContributionGrant: (participantId: string, amount: number) => void;
}

const AssignGrantsTable: React.FC<AssignGrantsTableProps> = ({
  participants,
  searchTerm,
  contributionPlan,
  oldContributionGrantsByGrantee,
  newContributionGrantsByGrantee,
  setNewContributionGrant,
}) => {
  const [openAssignGrantModal, setOpenAssignGrantModal] = useState(false);
  const [toAssignContributionParticipant, setToAssignContributionParticipant] = useState<OrganizationUser>();

  const startAssignContributionParticipant = (participant: OrganizationUser) => {
    setToAssignContributionParticipant(participant);
    setOpenAssignGrantModal(true);
  };

  const participantsMemo = React.useMemo<OrganizationUser[]>(() => participants, [participants]);
  const columns: Column<OrganizationUser>[] = React.useMemo(
    () => [
      {
        id: 'recipient',
        Header: 'Recipient',
        accessor: (recipient: OrganizationUser) => {
          return recipient.firstName + ' ' + recipient.lastName + ' ' + recipient.email;
        },
        Cell: (props: CellProps<OrganizationUser>) => {
          const participant = props.row.original;
          const firstName = participant.firstName;
          const lastName = participant.lastName;
          const email = participant.email;

          return (
            <div className="flex items-center">
              <div>
                <Highlighter
                  className="block font-medium text-gray-900 break-all"
                  highlightClassName="bg-yellow-200"
                  searchWords={[props.state.globalFilter]}
                  autoEscape={true}
                  textToHighlight={`${firstName} ${lastName}`}
                />
                <Highlighter
                  className="block text-gray-500 break-all"
                  highlightClassName="bg-yellow-200"
                  searchWords={[props.state.globalFilter]}
                  autoEscape={true}
                  textToHighlight={`${email}`}
                />
              </div>
            </div>
          );
        },
        className: 'py-4 text-sm font-medium text-gray-900',
        headerClassName: 'py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
      {
        id: 'employment-tenure',
        Header: 'Employment tenure',
        accessor: (participant: OrganizationUser) => {
          return participant.participantProfile?.startDate
            ? new Date(participant.participantProfile?.startDate)
            : new Date();
        },
        Cell: (props: CellProps<OrganizationUser>) => {
          const participant = props.row.original;
          const startDate = participant.participantProfile?.startDate
            ? new Date(participant.participantProfile?.startDate)
            : new Date();
          const todaysDate = new Date();
          const diffDays = calculateDateDiff(startDate, todaysDate);
          const startDateMoment = moment.parseZone(startDate);
          const startDateString = startDateMoment.format('MMMM Do, YYYY');
          return (
            <div className="flex items-center">
              <div>
                {participant.participantProfile?.startDate ? (
                  <>
                    <div className="text-sm font-medium text-gray-900">{diffDays} days</div>
                    <div className="text-sm text-gray-500">{startDateString}</div>
                  </>
                ) : (
                  <>
                    <div className="text-sm font-medium text-gray-900">N/A</div>
                    <div className="text-sm text-gray-500">No start date provided</div>
                  </>
                )}
              </div>
            </div>
          );
        },
        sortType: 'datetime',
        className: 'hidden sm:inline py-4 whitespace-nowrap text-sm text-gray-500',
        headerClassName: 'hidden sm:block py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
      {
        id: 'grant',
        Header: 'Contribution Grant',
        accessor: (participant: OrganizationUser) => {
          return oldContributionGrantsByGrantee[participant.id] ? oldContributionGrantsByGrantee[participant.id] : 0;
        },
        Cell: (props: CellProps<OrganizationUser>) => {
          const participant = props.row.original;
          return (
            <ContributionGrantColumn
              participant={participant}
              oldContributionGrantsByGrantee={oldContributionGrantsByGrantee}
              newContributionGrantsByGrantee={newContributionGrantsByGrantee}
            />
          );
        },
        className: 'py-4 whitespace-nowrap text-sm text-gray-500',
        headerClassName: 'py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
      {
        id: 'options',
        Cell: (props: CellProps<OrganizationUser>) => {
          const participant = props.row.original;
          return (
            <a
              className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
              onClick={() => {
                startAssignContributionParticipant(participant);
              }}
            >
              Set grant
            </a>
          );
        },
        className: 'py-4 whitespace-nowrap text-right text-sm font-medium',
      },
    ],
    [oldContributionGrantsByGrantee, newContributionGrantsByGrantee]
  ) as any;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex },
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: participantsMemo,
      initialState: { pageSize: 10, globalFilter: searchTerm },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setGlobalFilter(searchTerm);
  }, [searchTerm]);

  return (
    <div>
      {page.length > 0 ? (
        <div>
          <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
            <thead>
              {
                // Loop over the header rows
                headerGroups.map((headerGroup) => (
                  // Apply the header row props
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {
                      // Loop over the headers in each row
                      headerGroup.headers.map((column) => (
                        // Apply the header cell props
                        <th
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          className={`${column.headerClassName}`}
                        >
                          {
                            // Render the header
                            column.render('Header')
                          }
                          <span className="inline-flex ml-1">
                            {column.isSorted ? (
                              <span className="w-3 h-3">
                                {column.isSortedDesc ? <ChevronDownIcon /> : <ChevronUpIcon />}
                              </span>
                            ) : (
                              ''
                            )}
                          </span>
                        </th>
                      ))
                    }
                  </tr>
                ))
              }
            </thead>
            <tbody {...getTableBodyProps()} className="divide-y divide-gray-200">
              {
                // Loop over the table rows
                page.map((row) => {
                  // Prepare the row for display
                  prepareRow(row);
                  return (
                    // Apply the row props
                    <tr {...row.getRowProps()}>
                      {
                        // Loop over the rows cells
                        row.cells.map((cell) => {
                          // Apply the cell props
                          return (
                            <td {...cell.getCellProps()} className={`${cell.column.className}`}>
                              {
                                // Render the cell contents
                                cell.render('Cell')
                              }
                            </td>
                          );
                        })
                      }
                    </tr>
                  );
                })
              }
            </tbody>
          </table>

          <Pagination
            pageIndex={pageIndex}
            numPages={pageOptions.length}
            nextPage={nextPage}
            previousPage={previousPage}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            gotoPage={gotoPage}
          />
        </div>
      ) : (
        <div className="mt-4">
          <EmptyTablePlaceholder
            title="No participants"
            subtitle="Your organization does not have any active or invited participants who can receive contribution grants."
          />
        </div>
      )}

      <AssignGrantModal
        participant={toAssignContributionParticipant}
        contributionPlan={contributionPlan}
        open={openAssignGrantModal}
        setOpen={setOpenAssignGrantModal}
        setNewContributionGrant={setNewContributionGrant}
      />
    </div>
  );
};

export default AssignGrantsTable;
