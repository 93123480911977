import { Role } from 'enums/RolesEnum';

export const getRoleText = (role: string | undefined) => {
  const roleText =
    role === Role.Participant
      ? 'Participant'
      : role === Role.Admin
      ? 'Admin'
      : role === Role.SuperAdmin
      ? 'Super Admin'
      : role === Role.InternalAdmin
      ? 'Internal Admin'
      : '';
  return roleText;
};
