import { ContributionPlanRun } from 'interfaces/contributionsInterfaces';
import React from 'react';
import { abbrevDate } from 'utils/dateUtils';

interface ViewPlanRunHeaderProps {
  contributionPlanRun: ContributionPlanRun;
}

const ViewPlanRunHeader: React.FC<ViewPlanRunHeaderProps> = ({ contributionPlanRun }) => {
  const contributionPlan = contributionPlanRun.contributionPlan;

  return (
    <article>
      <div className="lg:flex lg:items-center lg:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="mt-2 text-2xl font-bold text-gray-900">
            {contributionPlan?.name} | {abbrevDate(contributionPlanRun.createdAt)}
          </h2>
        </div>
      </div>
    </article>
  );
};

export default ViewPlanRunHeader;
