import api from 'config/axiosConfig';
import { Role } from 'enums/RolesEnum';

export const apiGetAdminHomeData = () =>
  api
    .get(`/organization-users/home/admin`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiGetParticipantHomeData = () =>
  api
    .get(`/organization-users/home/participant`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiGetOrganizationUsers = (role: string) =>
  api
    .get(`/organization-users/role/${role}`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiUpdateOrganizationUserActiveStatus = (
  toUpdateOrganizationUserId: string | undefined,
  active: boolean
) =>
  api
    .put(`/organization-users/${toUpdateOrganizationUserId}/active`, { active })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiUpdateOrganizationUserName = (
  toUpdateOrganizationUserId: string | undefined,
  firstName: string,
  lastName: string
) =>
  api
    .post(`/organization-users/${toUpdateOrganizationUserId}/name`, { firstName, lastName })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiUpdateAdminInfo = (
  toUpdateOrganizationUserId: string | undefined,
  firstName: string,
  lastName: string,
  adminPermissions: string[]
) =>
  api
    .post(`/organization-users/${toUpdateOrganizationUserId}/info`, { firstName, lastName, adminPermissions })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiCreateOrganizationUserInvite = (
  firstName: string,
  lastName: string,
  email: string,
  role: Role,
  adminPermissions: string[]
) =>
  api
    .post(`/organization-users/invites`, { firstName, lastName, email, role, adminPermissions })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiRevokeOrganizationUserInvite = (invitedOrganizationUserId: string | undefined) =>
  api
    .delete(`/organization-users/invites/${invitedOrganizationUserId}`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiSyncWithPayroll = () =>
  api
    .get(`/organization-users/sync-with-payroll`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiAddPayrollConnection = (code: string) =>
  api
    .post(`/organization-users/payroll-connections`, { code })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiDeletePayrollConnection = (payrollConnectionId: string) =>
  api
    .delete(`/organization-users/payroll-connections/${payrollConnectionId}`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiGetPayrollConnections = () =>
  api
    .get('/organization-users/payroll-connections')
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiGetParticipantsForPlan = () =>
  api
    .get(`/organization-users/role/participants/plan-eligible`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiGetParticipant = (organizationUserId: string) =>
  api
    .get(`/organization-users/role/participants/${organizationUserId}`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiGetAdmin = (organizationUserId: string) =>
  api
    .get(`/organization-users/role/admins/${organizationUserId}`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiCreateMergeLinkToken = () =>
  api
    .get('/organization-users/merge-link-token')
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiAddMergePayrollConnection = (publicToken: string) =>
  api
    .post('/organization-users/payroll-connections/merge', { public_token: publicToken })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });
