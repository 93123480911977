import { ArrowUpIcon } from '@heroicons/react/outline';
import React from 'react';
import { Link } from 'react-router-dom';
import { formatMoney } from 'utils/numberFormatter';

interface HSAInvestmentsProps {
  hsaInvestments: { [fundName: string]: number };
}

const HSAInvestments: React.FC<HSAInvestmentsProps> = ({ hsaInvestments }) => {
  return (
    <>
      <div className="mt-4 mx-auto max-w-7xl">
        <>
          <div className="sm:items-center">
            <div className="flex-auto">
              <h1 className="text-xl font-bold text-gray-900">Your portfolio</h1>
            </div>
          </div>

          <div className="px-8 py-4 ring-1 ring-black ring-opacity-10 rounded mt-4">
            <div>
              <div className="flex flex-row items-center">
                <div className="flex-auto">
                  <h1 className="text-md text-gray-900">Total value</h1>
                  <div className="flex-auto">
                    <h1 className="text-3xl font-bold text-gray-900">{formatMoney(3710.67)}</h1>
                  </div>
                </div>
                <div className="flex-auto">
                  <h1 className="text-md text-gray-900">Last 7 days</h1>
                  <p className="text-green-600 flex items-baseline text-3xl font-semibold">
                    <ArrowUpIcon className="h-5 w-5 flex-shrink-0 self-center text-green-500" aria-hidden="true" />
                    0.56%
                  </p>
                </div>
                <div className="flex-auto">
                  <h1 className="text-md text-gray-900">All time</h1>
                  <p className="text-green-600 flex items-baseline text-3xl font-semibold">
                    <ArrowUpIcon className="h-5 w-5 flex-shrink-0 self-center text-green-500" aria-hidden="true" />
                    10.76%
                  </p>
                </div>

                <Link
                  to="/hsa/investments/change-portfolio"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-full sm:w-auto"
                >
                  Change Portfolio
                </Link>
              </div>
            </div>
          </div>

          <div className="px-6 py-4 shadow-md ring-1 ring-black ring-opacity-10 rounded-lg bg-white my-6">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pr-2 text-left text-sm font-semibold text-gray-500">
                    Fund name
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 px-2 text-left text-sm font-semibold text-gray-500 hidden xs:table-cell"
                  >
                    Current weight
                  </th>

                  <th scope="col" className="py-3.5 pl-2 text-right text-sm font-semibold text-gray-500">
                    Market value
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {Object.entries(hsaInvestments).map(([fundName, investmentPercentage]) => (
                  <tr key={fundName}>
                    <td className="pr-2 py-4 text-sm font-medium text-gray-900">{fundName.toUpperCase()}</td>
                    <td className="py-4 px-2 text-sm text-left text-gray-500 hidden xs:table-cell">
                      {investmentPercentage}%
                    </td>

                    <td className="relative py-4 pl-2 text-right text-sm font-medium">
                      {formatMoney(investmentPercentage * 3710.67 * 0.01)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      </div>
    </>
  );
};

export default HSAInvestments;
