import logo from 'images/logo-w-title.png';
import React from 'react';
import { BarLoader } from 'react-spinners';

const PageLoad: React.FC = () => {
  return (
    <div className="flex flex-col h-screen justify-center items-center">
      <div className="mx-auto w-full mb-4 max-w-md">
        <img className="mx-auto h-16 w-auto" src={logo} alt="unsaddl" />
      </div>
      <BarLoader width={275} color={'#6e3cbc'} />
    </div>
  );
};

export default PageLoad;
