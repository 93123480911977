/* This example requires Tailwind CSS v2.0+ */
import { ContributionPlanRun } from 'interfaces/contributionsInterfaces';
import { formatMoney } from 'utils/numberFormatter';

interface ViewPlanRunSummaryProps {
  contributionPlanRun: ContributionPlanRun;
}

const ViewPlanRunSummary: React.FC<ViewPlanRunSummaryProps> = ({ contributionPlanRun }) => {
  const contributionGrantRuns = contributionPlanRun.contributionGrantRuns;
  const fundStatus = contributionPlanRun.fundStatus;
  const disbursementStatus = contributionPlanRun.disbursementStatus;
  let grantRunElectedTotal = 0;
  for (const contributionGrantRun of contributionGrantRuns) {
    const grantRunLoanPayments = contributionGrantRun.loanPayments;
    const grantRunSavings529Payments = contributionGrantRun.savings529Payments;
    for (const loanPayment of grantRunLoanPayments) {
      grantRunElectedTotal = grantRunElectedTotal + Number(loanPayment.amountSubmitted);
    }
    for (const savings529Payment of grantRunSavings529Payments) {
      grantRunElectedTotal = grantRunElectedTotal + Number(savings529Payment.amountSubmitted);
    }
  }
  const stats = [
    { name: 'Funding status', stat: fundStatus, change: '12%', changeType: 'increase' },
    { name: 'Disbursement status', stat: disbursementStatus, change: '2.02%', changeType: 'increase' },
    {
      name: 'Total payments submitted',
      stat: formatMoney(Number(grantRunElectedTotal)),
      change: '4.05%',
      changeType: 'decrease',
    },
  ];
  return (
    <div>
      <dl className="mt-5 grid grid-cols-1 rounded bg-white overflow-hidden border shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
        {stats.map((item) => (
          <div key={item.name} className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-900">{item.name}</dt>
            <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-indigo-600 capitalize">
                {item.stat}
                {/* <span className="ml-2 text-sm font-medium text-gray-500">from {item.previousStat}</span> */}
              </div>

              {/* <div
                className={classNames(
                  item.changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                  'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                )}
              >
                {item.changeType === 'increase' ? (
                  <ArrowSmUpIcon
                    className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                    aria-hidden="true"
                  />
                ) : (
                  <ArrowSmDownIcon
                    className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                )}

                <span className="sr-only">{item.changeType === 'increase' ? 'Increased' : 'Decreased'} by</span>
                {item.change}
              </div> */}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

export default ViewPlanRunSummary;
