import EmptyTablePlaceholder from 'common/EmptyTablePlaceholder';
import { UserDataContext } from 'context/UserContext';
import { TuitionReimbursementRequest } from 'interfaces/tuitionReimbursementInterfaces';
import ParticipantTuitionReimbursementRequestElement from 'participant/TuitionReimbursement/ParticipantTuitionReimbursementRequestElement/ParticipantTuitionReimbursementRequestElement';
import React, { useContext } from 'react';

interface ParticipantTuitionReimbursementRequestsProps {
  tuitionReimbursementRequests?: TuitionReimbursementRequest[];
  getTuitionReimbursementGrant: () => void;
}

const ParticipantTuitionReimbursementRequests: React.FC<ParticipantTuitionReimbursementRequestsProps> = ({
  tuitionReimbursementRequests,
  getTuitionReimbursementGrant,
}) => {
  const { firebaseUser, currentOrganizationUser } = useContext(UserDataContext);

  return (
    <>
      <div className="my-6 mx-auto max-w-7xl">
        <div>
          <>
            {currentOrganizationUser?.organization?.tuitionReimbursementEnabled === false ? (
              <div className="mt-4">
                <EmptyTablePlaceholder
                  title={'Tuition reimbursement is not enabled for your organization'}
                  subtitle={
                    'Reach out to your organization administrator or Unsaddl support if you believe this is a mistake.'
                  }
                />
              </div>
            ) : tuitionReimbursementRequests?.length === 0 ? (
              <div className="mt-4">
                <EmptyTablePlaceholder
                  title={'No tuition reimbursement requests'}
                  subtitle={'You have not submitted any tuition reimbursement requests for review'}
                />
              </div>
            ) : (
              tuitionReimbursementRequests?.map((tuitionReimbursementRequest) => (
                <ParticipantTuitionReimbursementRequestElement
                  key={tuitionReimbursementRequest.id}
                  tuitionReimbursementRequest={tuitionReimbursementRequest}
                  refreshData={getTuitionReimbursementGrant}
                />
              ))
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default ParticipantTuitionReimbursementRequests;
