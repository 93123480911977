import { Menu } from '@headlessui/react';
import { DotsVerticalIcon, IdentificationIcon, UserAddIcon, UserCircleIcon } from '@heroicons/react/outline';
import DisabledOrganizationUserMenuOptions from 'admin/ManageOrganizationUsers/DisabledOrganizationUsers/DisabledOrganizationUsersTable/DisabledOrganizationUserMenuOptions';
import ManageOrganizationUsersTable from 'admin/ManageOrganizationUsers/ManageOrganizationUsersTable';
import MenuOptions from 'components/MenuOptions';
import { UserDataContext } from 'context/UserContext';
import { Role } from 'enums/RolesEnum';
import { OrganizationUser } from 'interfaces/organizationUserInterfaces';
import React, { useContext } from 'react';
import Highlighter from 'react-highlight-words';
import { CellProps, Column } from 'react-table';
import { abbrevDate } from 'utils/dateUtils';

interface DisabledOrganizationUsersTableProps {
  searchTerm: string;
  disabledOrganizationUsers: OrganizationUser[];
  startReactivateUser: (disabledOrganizationUser: OrganizationUser) => void;
}

const DisabledOrganizationUsersTable: React.FC<DisabledOrganizationUsersTableProps> = ({
  searchTerm,
  disabledOrganizationUsers,
  startReactivateUser,
}) => {
  const { userAccount } = useContext(UserDataContext);
  const disabledOrganizationUsersMemo = React.useMemo<OrganizationUser[]>(
    () => disabledOrganizationUsers,
    [disabledOrganizationUsers]
  );
  const columns: Column<OrganizationUser>[] = React.useMemo(
    () => [
      {
        id: 'name',
        Header: 'Name',
        accessor: (disabledOrganizationUser: OrganizationUser) => {
          return (
            disabledOrganizationUser.firstName +
            ' ' +
            disabledOrganizationUser.lastName +
            ' ' +
            disabledOrganizationUser.email
          );
        },
        Cell: (props: CellProps<OrganizationUser>) => {
          const disabledOrganizationUser = props.row.original;
          const firstName = disabledOrganizationUser.firstName;
          const lastName = disabledOrganizationUser.lastName;
          const email = disabledOrganizationUser.email;
          const isSelfRow = userAccount?.email.toLowerCase() === disabledOrganizationUser.email.toLowerCase();

          return (
            <div className="flex items-center">
              <span className="hidden sm:inline-flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500">
                <span className="text-xl font-medium leading-none text-white">
                  {firstName?.charAt(0).toUpperCase()}
                  {lastName?.charAt(0).toUpperCase()}
                </span>
              </span>
              <div className="sm:ml-4 text-sm">
                <div>
                  <Highlighter
                    className="font-medium text-gray-900 break-all"
                    highlightClassName="bg-yellow-200"
                    searchWords={[props.state.globalFilter]}
                    autoEscape={true}
                    textToHighlight={`${firstName} ${lastName}`}
                  />
                  {isSelfRow && <span className="font-light text-gray-400"> (You)</span>}
                </div>
                <Highlighter
                  className="block text-gray-500 break-all"
                  highlightClassName="bg-yellow-200"
                  searchWords={[props.state.globalFilter]}
                  autoEscape={true}
                  textToHighlight={email}
                />
              </div>
            </div>
          );
        },
        className: 'py-4 text-sm font-medium text-gray-900',
        headerClassName: 'py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },

      {
        id: 'source',
        Header: 'Source',
        Cell: (props: CellProps<OrganizationUser>) => {
          const disabledOrganizationUser = props.row.original;
          return (
            <div className="flex">
              {disabledOrganizationUser.inviter ? (
                <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-sky-100 text-sky-800">
                  <UserAddIcon className="flex flex-shrink-0 mr-1.5 h-5 w-5 text-sky-400" aria-hidden="true" />
                  Invite
                </span>
              ) : disabledOrganizationUser.role === Role.SuperAdmin ? (
                <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-sky-100 text-sky-800">
                  <UserCircleIcon className="flex flex-shrink-0 mr-1.5 h-5 w-5 text-sky-400" aria-hidden="true" />
                  Super Admin
                </span>
              ) : (
                <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-sky-100 text-sky-800">
                  <IdentificationIcon className="flex flex-shrink-0 mr-1.5 h-5 w-5 text-sky-400" aria-hidden="true" />
                  Payroll
                </span>
              )}
            </div>
          );
        },
        className: 'hidden sm:table-cell py-4 text-sm text-gray-500',
        headerClassName:
          'hidden sm:table-cell py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },

      {
        id: 'date-registerd',
        Header: 'Date Registered',
        accessor: (disabledOrganizationUser: OrganizationUser) => {
          return new Date(disabledOrganizationUser.claimedAt!);
        },
        Cell: (props: CellProps<OrganizationUser>) => {
          const disabledOrganizationUser = props.row.original;
          const registeredDateString = abbrevDate(disabledOrganizationUser.claimedAt!);
          return <div>{registeredDateString}</div>;
        },
        sortType: 'datetime',
        className: 'hidden sm:table-cell py-4 text-sm text-gray-500',
        headerClassName:
          'hidden sm:table-cell py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },

      {
        id: 'options',
        Cell: (props: CellProps<OrganizationUser>) => {
          const disabledOrganizationUser = props.row.original;

          return (
            <div>
              <MenuOptions
                menuButton={
                  <Menu.Button className="group-hover:visible inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <DotsVerticalIcon className="h-5 w-5 text-gray-500" />
                  </Menu.Button>
                }
                menuItems={
                  <DisabledOrganizationUserMenuOptions
                    disabledOrganizationUser={disabledOrganizationUser}
                    startReactivateUser={startReactivateUser}
                  />
                }
              />
            </div>
          );
        },
        className: 'py-4 text-right',
      },
    ],
    []
  ) as any;
  return (
    <ManageOrganizationUsersTable
      searchTerm={searchTerm}
      organizationUsers={disabledOrganizationUsersMemo}
      columns={columns}
    />
  );
};

export default DisabledOrganizationUsersTable;
