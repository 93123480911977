import ViewParticipantPlansTable from 'admin/ManageOrganizationUsers/ViewUser/ViewParticipant/ViewParticipantPlansTable';
import EmptyPlaceholder from 'common/EmptyPlaceholder';
import { OrganizationUser } from 'interfaces/organizationUserInterfaces';
import React from 'react';

interface ViewParticipantPlansProps {
  participant: OrganizationUser;
}

const ViewParticipantPlans: React.FC<ViewParticipantPlansProps> = ({ participant }) => {
  const participantGrants = participant.participantProfile?.contributionGrants;

  return (
    <>
      {/* Description list */}
      <div className="mt-6 mx-auto">
        {participantGrants && participantGrants.length > 0 ? (
          <ViewParticipantPlansTable contributionGrants={participantGrants} />
        ) : (
          <EmptyPlaceholder title={'No plans'} subtitle={'This participant is not enrolled in any plans.'} />
        )}
      </div>
    </>
  );
};

export default ViewParticipantPlans;
