import api from 'config/axiosConfig';

export const apiGetParticipantTuitionReimbursementGrant = (tuitionReimbursementGrantId: string) =>
  api
    .get(`/tuition-reimbursement/participant/grants/${tuitionReimbursementGrantId}`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiAdminGetTuitionReimbursementGrant = (tuitionReimbursementGrantId: string) =>
  api
    .get(`/tuition-reimbursement/admin/grants/${tuitionReimbursementGrantId}`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiGetTuitionReimbursementRequestsByReviewer = () =>
  api
    .get(`/tuition-reimbursement/requests/reviews`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiAdminGetTuitionReimbursementRequestsAll = () =>
  api
    .get(`/tuition-reimbursement/admin/requests/all`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiAdminGetTuitionReimbursementRequestById = (tuitionReimbursementRequestId: string) =>
  api
    .get(`/tuition-reimbursement/admin/requests/${tuitionReimbursementRequestId}`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiGetTuitionReimbursementDocumentUploadLink = (
  tuitionReimbursementRequestId: string,
  tuitionReimbursementDocumentUploadId: string
) =>
  api
    .get(
      `/tuition-reimbursement/requests/${tuitionReimbursementRequestId}/documents/${tuitionReimbursementDocumentUploadId}`
    )
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiDeleteTuitionReimbursementDocumentUpload = (
  tuitionReimbursementRequestId: string,
  tuitionReimbursementDocumentUploadId: string
) =>
  api
    .delete(
      `/tuition-reimbursement/requests/${tuitionReimbursementRequestId}/documents/${tuitionReimbursementDocumentUploadId}`
    )
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiCreateParticipantTuitionReimbursementRequest = (
  tuitionReimbursementGrantId: string,
  courseName: string,
  institutionName: string,
  courseDescription: string,
  startDate: string,
  endDate: string,
  courseCost: number,
  creditHours: number,
  reviewers: string[]
) =>
  api
    .post(`/tuition-reimbursement/requests/participant`, {
      tuitionReimbursementGrantId,
      courseName,
      institutionName,
      courseDescription,
      startDate,
      endDate,
      courseCost,
      creditHours,
      reviewers,
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiEditParticipantTuitionReimbursementRequest = (
  tuitionReimbursementRequestId: string,
  courseName: string,
  institutionName: string,
  courseDescription: string,
  startDate: string,
  endDate: string,
  courseCost: number,
  creditHours: number
) =>
  api
    .post(`/tuition-reimbursement/requests/${tuitionReimbursementRequestId}/participant`, {
      courseName,
      institutionName,
      courseDescription,
      startDate,
      endDate,
      courseCost,
      creditHours,
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiAdminCreateParticipantTuitionReimbursementRequest = (
  tuitionReimbursementGrantId: string,
  courseName: string,
  institutionName: string,
  courseDescription: string,
  startDate: string,
  endDate: string,
  courseCost: number,
  creditHours: number,
  reviewers: string[]
) =>
  api
    .post(`/tuition-reimbursement/admin/requests/participant`, {
      tuitionReimbursementGrantId,
      courseName,
      institutionName,
      courseDescription,
      startDate,
      endDate,
      courseCost,
      creditHours,
      reviewers,
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiAdminEditParticipantTuitionReimbursementRequest = (
  tuitionReimbursementRequestId: string,
  courseName: string,
  institutionName: string,
  courseDescription: string,
  startDate: string,
  endDate: string,
  courseCost: number,
  creditHours: number
) =>
  api
    .post(`/tuition-reimbursement/requests/${tuitionReimbursementRequestId}/admin`, {
      courseName,
      institutionName,
      courseDescription,
      startDate,
      endDate,
      courseCost,
      creditHours,
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiUploadTuitionReimbursementDocument = (
  tuitionReimbursementRequestId: string | undefined,
  formData: FormData
) =>
  api
    .post(`/tuition-reimbursement/requests/participant/${tuitionReimbursementRequestId}/document`, formData, {})
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiSendTuitionReimbursementRequestCourseReview = (
  tuitionReimbursementRequestId: string,
  approved: boolean,
  summary: string,
  amount: number
) =>
  api
    .post(`/tuition-reimbursement/requests/organization/${tuitionReimbursementRequestId}/course-review`, {
      approved,
      summary,
      amount,
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiSendTuitionReimbursementRequestCompletionReview = (
  tuitionReimbursementRequestId: string,
  approved: boolean,
  summary: string,
  amount: number
) =>
  api
    .post(`/tuition-reimbursement/requests/organization/${tuitionReimbursementRequestId}/completion-review`, {
      approved,
      summary,
      amount,
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiSendTuitionReimbursementRequestParticipantCompletion = (tuitionReimbursementRequestId: string) =>
  api
    .post(`/tuition-reimbursement/requests/participant/${tuitionReimbursementRequestId}/completion`, {})
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiGetTuitionReimbursementReviewers = () =>
  api
    .get(`/tuition-reimbursement/reviewers`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiGetOrganizationTuitionReimbursementPlans = () =>
  api
    .get(`/tuition-reimbursement/organization/plans`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiCreateNewTuitionReimbursementPlan = (
  name: string,
  description: string,
  annualReimbursementMax: number,
  creditHourMax: number,
  eligibilityTenureDays: number,
  instructions: string,
  terms: string,
  unsaddlManaged: boolean
) =>
  api
    .post(`/tuition-reimbursement/organization/plans`, {
      name,
      description,
      annualReimbursementMax,
      creditHourMax,
      eligibilityTenureDays,
      instructions,
      terms,
      unsaddlManaged,
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiDisableTuitionReimbursementPlan = (tuitionReimbursementPlanId: string) =>
  api
    .post(`/tuition-reimbursement/organization/plans/${tuitionReimbursementPlanId}/disable`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiSetTuitionReimbursementGrants = (tuitionReimbursementPlanId: string, grants: string) =>
  api
    .post(`/tuition-reimbursement/organization/plans/${tuitionReimbursementPlanId}/grants`, { grants })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiGetOrganizationTuitionReimbursementPlan = (tuitionReimbursementPlanId: string) =>
  api
    .get(`/tuition-reimbursement/organization/plans/${tuitionReimbursementPlanId}`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiGetParticipantTuitionReimbursementGrants = () =>
  api
    .get(`/tuition-reimbursement/participant/grants`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiEditTuitionReimbursementPlanDetails = (
  tuitionReimbursementPlanId: string,
  name?: string,
  description?: string,
  instructions?: string,
  terms?: string,
  eligibilityTenureDays?: number
) =>
  api
    .post(`/tuition-reimbursement/organization/plans/${tuitionReimbursementPlanId}/details`, {
      name,
      description,
      instructions,
      terms,
      eligibilityTenureDays,
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiSubmitTuitionReimbursementPaymentAccount = (
  tuitionReimbursementPaymentId: string,
  bankName: string,
  routingNumber: string,
  accountNumber: string,
  accountType: string
) =>
  api
    .post(`/tuition-reimbursement/payment/${tuitionReimbursementPaymentId}`, {
      bankName,
      accountNumber,
      routingNumber,
      accountType,
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiParticipantGetTuitionReimbursementRequests = () =>
  api
    .get(`/tuition-reimbursement/participant/requests`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiParticipantGetTuitionReimbursementPayments = () =>
  api
    .get(`/tuition-reimbursement/participant/payments`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiAdminGetTuitionReimbursementPayments = () =>
  api
    .get(`/tuition-reimbursement/admin/payments`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });
