import { CalendarIcon, CashIcon, UploadIcon, UsersIcon } from '@heroicons/react/outline';
import { Ring } from '@uiball/loaders';
import { apiUploadTuitionReimbursementDocument } from 'api/tuitionReimbursementAPI';
import TuitionReimbursementDetails from 'common/TuitionReimbursement/TuitionReimbursementDetails';
import TuitionReimbursementTimeline from 'common/TuitionReimbursement/TuitionReimbursementTimeline';
import { TuitionReimbursementRequest } from 'interfaces/tuitionReimbursementInterfaces';
import EditTuitionReimbursementRequestModal from 'participant/TuitionReimbursement/EditTuitionReimbursementRequestModal';
import ConfirmCourseCompletionModal from 'participant/TuitionReimbursement/ParticipantTuitionReimbursementRequestElement/ConfirmCourseCompletionModal';
import React, { useState } from 'react';
import toast from 'react-hot-toast';

interface ParticipantTuitionReimbursementRequestElementProps {
  tuitionReimbursementRequest: TuitionReimbursementRequest;
  refreshData: () => void;
}

const ParticipantTuitionReimbursementRequestElement: React.FC<ParticipantTuitionReimbursementRequestElementProps> = ({
  tuitionReimbursementRequest,
  refreshData,
}) => {
  const [isUploadingDocument, setIsUploadingDocument] = useState(false);
  const [openConfirmCourseCompletionModal, setOpenConfirmCourseCompletionModal] = useState(false);
  const [openEditRequestlModal, setOpenEditRequestModal] = useState(false);

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleFileUploadClick = () => {
    if (hiddenFileInput.current != null) {
      hiddenFileInput.current.click();
    }
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsUploadingDocument(true);
    const files = e.target.files;
    const formData = new FormData();
    if (files) {
      formData.append('file', files[0]);
      apiUploadTuitionReimbursementDocument(tuitionReimbursementRequest?.id, formData).then(
        (data) => {
          setIsUploadingDocument(false);
          toast.success('Successfully uploaded document!');
          refreshData();
        },
        (error) => {
          setIsUploadingDocument(false);
          toast.error(error.message);
        }
      );
    }
  };

  const cards = [
    {
      name: 'Enrolled participants',
      href: '#',
      icon: UsersIcon,
      amount: '0',
      logoBgColor: 'bg-purple-100',
      logoTextColor: 'text-purple-600',
    },
    {
      name: 'Total granted',
      href: '#',
      icon: CashIcon,
      amount: '0',
      logoBgColor: 'bg-green-100',
      logoTextColor: 'text-green-600',
    },
    {
      name: 'Next election deadline',
      href: '#',
      icon: CalendarIcon,
      amount: '0',
      logoBgColor: 'bg-sky-100',
      logoTextColor: 'text-sky-600',
    },
    // More items...
  ];

  return (
    <>
      <div className="mt-6 px-4 sm:px-6 lg:px-8 py-6 ring-1 ring-black ring-opacity-10 rounded">
        <div className="flex items-center">
          <div className="flex-auto">
            <h1 className="text-xl font-bold text-gray-900">{tuitionReimbursementRequest.courseName}</h1>
          </div>

          <div className="flex flex-col-reverse space-y-2 space-y-reverse sm:space-y-0 sm:space-x-2 sm:flex-row">
            {(tuitionReimbursementRequest.reviewStatus === 'submitted' ||
              tuitionReimbursementRequest.reviewStatus === 'course_approved' ||
              tuitionReimbursementRequest.reviewStatus === 'course_completed') && (
              <button
                onClick={handleFileUploadClick}
                className="inline-flex items-center justify-center rounded-md border bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 sm:w-auto"
              >
                {isUploadingDocument ? (
                  <>
                    <div className="mr-1.5">
                      <Ring size={20} lineWeight={5} speed={2} color="gray" />
                    </div>
                    Uploading...
                  </>
                ) : (
                  <>
                    <UploadIcon className="flex flex-shrink-0 mr-1.5 h-5 w-5" aria-hidden="true" />
                    Upload documents
                  </>
                )}
              </button>
            )}
            {tuitionReimbursementRequest.reviewStatus === 'course_approved' && (
              <button
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                onClick={() => setOpenConfirmCourseCompletionModal(true)}
              >
                Mark as completed
              </button>
            )}

            <button
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              onClick={() => setOpenEditRequestModal(true)}
            >
              Edit request
            </button>

            <input type="file" ref={hiddenFileInput} hidden onChange={handleFileUpload} />
          </div>
        </div>

        <div>
          <h1 className="text-md text-gray-900">{tuitionReimbursementRequest.institutionName}</h1>
        </div>
        <div className="mt-4 md:flex">
          <div className="grow">
            <TuitionReimbursementDetails
              tuitionReimbursementRequest={tuitionReimbursementRequest}
              onDocumentDelete={refreshData}
            />
          </div>

          <div className="flex md:ml-4 items-center">
            <TuitionReimbursementTimeline tuitionReimbursementRequest={tuitionReimbursementRequest} />
          </div>
        </div>
      </div>
      <ConfirmCourseCompletionModal
        tuitionReimbursementRequest={tuitionReimbursementRequest}
        open={openConfirmCourseCompletionModal}
        setOpen={setOpenConfirmCourseCompletionModal}
        onConfirmation={refreshData}
      />
      <EditTuitionReimbursementRequestModal
        open={openEditRequestlModal}
        setOpen={setOpenEditRequestModal}
        onCreateRequestSuccess={refreshData}
        tuitionReimbursementRequest={tuitionReimbursementRequest}
      />
    </>
  );
};

export default ParticipantTuitionReimbursementRequestElement;
