import ListPlanRunsTable from 'admin/ContributionPlanRuns/ListPlanRuns/ListPlanRunsTable';
import { apiGetOrganizationPlanRuns } from 'api/contributionsAPI';
import PageLoad from 'components/Load/PageLoad';
import { UserDataContext } from 'context/UserContext';
import { ContributionPlanRun } from 'interfaces/contributionsInterfaces';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AuthenticatedRoute from 'routes/AuthenticatedRoute';
import { classNames } from 'utils/tailwindUtils';

interface ListPlanRunsTab {
  name: string;
  href: string;
  current: boolean;
}

const ListPlanRuns: React.FC = () => {
  const { currentOrganizationUser } = useContext(UserDataContext);
  const location = useLocation();
  const locationPath = location.pathname;
  const [isLoadingOrganizationPlan, setIsLoadingOrganizationPlan] = useState(false);
  const [organizationPlanRuns, setOrganizationPlanRuns] = useState<ContributionPlanRun[]>([]);

  const completedOrganizationPlanRuns = organizationPlanRuns.filter(
    (organizationPlanRun) => organizationPlanRun.disbursementStatus === 'disbursed'
  );

  const processingOrganizationPlanRuns = organizationPlanRuns.filter(
    (organizationPlanRun) => organizationPlanRun.disbursementStatus !== 'disbursed'
  );

  const getOrganizationPlanRuns = async () => {
    setIsLoadingOrganizationPlan(true);
    apiGetOrganizationPlanRuns().then(
      (data) => {
        const loadedOrganizationPlanRuns: ContributionPlanRun[] = data.organizationPlanRuns;
        setOrganizationPlanRuns(loadedOrganizationPlanRuns);
        setIsLoadingOrganizationPlan(false);
      },
      (error) => {
        setIsLoadingOrganizationPlan(false);
      }
    );
  };

  useEffect(() => {
    getOrganizationPlanRuns();
  }, []);

  const tabs: ListPlanRunsTab[] = [
    {
      name: 'Processing',
      href: `/runs/list/processing`,
      current: locationPath === `/runs/list/processing`,
    },
    {
      name: 'Completed',
      href: `/runs/list/completed`,
      current: locationPath === `/runs/list/completed`,
    },
  ];

  return (
    <>
      {isLoadingOrganizationPlan ? (
        <PageLoad />
      ) : (
        <div className="my-6 px-8 mx-auto max-w-7xl">
          <div>
            <div className="flex justify-between">
              <h1 className="text-2xl font-bold text-gray-900">Contribution Plan Runs</h1>
            </div>
            <div className="mt-6 sm:mt-2 2xl:mt-5 border-b border-gray-200 overflow-x-auto scrollbar-hide">
              <nav className="flex space-x-8" aria-label="Tabs">
                {tabs.map((tab) => (
                  <Link
                    key={tab.name}
                    to={tab.href}
                    className={classNames(
                      tab.current
                        ? 'border-purple-500 text-gray-900'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                      'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                    )}
                    aria-current={tab.current ? 'page' : undefined}
                  >
                    {tab.name}
                  </Link>
                ))}
              </nav>
            </div>

            <div>
              <AuthenticatedRoute
                path={`/runs/list/processing`}
                render={() => {
                  return <ListPlanRunsTable contributionPlanRuns={processingOrganizationPlanRuns} />;
                }}
              />
              <AuthenticatedRoute
                path={`/runs/list/completed`}
                render={() => {
                  return <ListPlanRunsTable contributionPlanRuns={completedOrganizationPlanRuns} />;
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ListPlanRuns;
