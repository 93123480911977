import { CalendarIcon, CashIcon, ChevronRightIcon, ReceiptTaxIcon, UserGroupIcon } from '@heroicons/react/solid';
import { DotWave, RaceBy } from '@uiball/loaders';
import { apiGetAdminHomeData } from 'api/organizationUsersAPI';
import HomePageHeader from 'common/HomePageHeader';
import { ContributionPlanRun } from 'interfaces/contributionsInterfaces';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { abbrevDate } from 'utils/dateUtils';
import { formatMoney } from 'utils/numberFormatter';
import { classNames } from 'utils/tailwindUtils';

const statusStyles: Record<string, string> = {
  disbursed: 'bg-green-100 text-green-800',
  disbursing: 'bg-yellow-100 text-yellow-800',
  pending: 'bg-gray-100 text-gray-800',
};

const AdminHomePage: React.FC = () => {
  const [isLoadingHomeData, setIsLoadingHomeData] = useState(true);
  const [numActivePlans, setNumActivePlans] = useState(0);
  const [numActiveParticipants, setNumActiveParticipants] = useState(0);
  const [adminHomePlanRuns, setAdminHomePlanRuns] = useState<ContributionPlanRun[]>([]);
  const [organizationLoanPaymentsTotal, setOrganizationLoanPaymentsTotal] = useState(0);

  const getAdminHomeData = async () => {
    setIsLoadingHomeData(true);
    apiGetAdminHomeData().then(
      (data) => {
        setNumActivePlans(data.numActivePlans);
        setNumActiveParticipants(data.numActiveParticipants);
        setAdminHomePlanRuns(data.adminHomePlanRuns);
        setOrganizationLoanPaymentsTotal(data.organizationLoanPaymentsTotal);
        setIsLoadingHomeData(false);
      },
      (error) => {
        setIsLoadingHomeData(false);
      }
    );
  };

  const stats = [
    {
      id: 1,
      name: 'Active plans',
      stat: numActivePlans,
      icon: CalendarIcon,
      link: 'View plans',
      href: '/plans/list',
    },
    {
      id: 2,
      name: 'Active participants',
      stat: numActiveParticipants,
      icon: UserGroupIcon,
      link: 'View participants',
      href: '/participants/list',
    },
    {
      id: 3,
      name: 'Cumulative contributions',
      stat: formatMoney(Number(organizationLoanPaymentsTotal)),
      icon: ReceiptTaxIcon,
      link: 'View contributions',
      href: '/runs/list/processing',
    },
  ];

  useEffect(() => {
    getAdminHomeData();
  }, []);

  return (
    <>
      <HomePageHeader />

      <div className="mt-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-lg leading-6 font-medium text-gray-900">Overview</h2>

          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            {stats.map((item) => (
              <div
                key={item.id}
                className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 rounded-lg overflow-hidden shadow-md ring-1 ring-black ring-opacity-10"
              >
                <dt>
                  <div className="absolute bg-indigo-500 rounded-md p-3">
                    <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-sm font-medium text-gray-500 truncate">{item.name}</p>
                </dt>
                <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                  {isLoadingHomeData ? (
                    <div className="py-3">
                      <RaceBy size={80} lineWeight={5} speed={1.4} color="gray" />
                    </div>
                  ) : (
                    <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
                  )}

                  <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                    <div className="text-sm">
                      <Link to={item.href} className="font-medium text-indigo-600 hover:text-indigo-500">
                        {' '}
                        {item.link}
                        <span className="sr-only"> {item.name} stats</span>
                      </Link>
                    </div>
                  </div>
                </dd>
              </div>
            ))}
          </dl>
        </div>

        <h2 className="max-w-7xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
          Recent activity
        </h2>

        {/* Activity list (smallest breakpoint only) */}
        <div className="shadow sm:hidden">
          <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
            {isLoadingHomeData ? (
              <li key="loading">
                <div className="flex px-4 py-4 justify-center">
                  <DotWave size={47} speed={1} color="gray" />
                </div>
              </li>
            ) : adminHomePlanRuns.length > 0 ? (
              adminHomePlanRuns.map((planRun) => (
                <li key={planRun.id}>
                  <Link to={`/runs/${planRun.id}/view`} className="block px-4 py-4 bg-white hover:bg-gray-50">
                    <span className="flex items-center space-x-4">
                      <span className="flex-1 flex space-x-2">
                        <CashIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                        <span className="flex flex-col text-gray-500 text-sm space-y-1">
                          <span className="block">{planRun.contributionPlan?.name}</span>

                          <div>
                            <span
                              className={classNames(
                                statusStyles[planRun.disbursementStatus],
                                'inline-flex  px-2.5 py-0.5 rounded-md text-xs font-medium capitalize'
                              )}
                            >
                              {planRun.disbursementStatus}
                            </span>
                          </div>
                          <time dateTime={planRun.createdAt}>{abbrevDate(planRun.createdAt)}</time>
                        </span>
                      </span>
                      <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Link>
                </li>
              ))
            ) : (
              <li key="empty-list">
                <div className="block px-4 py-4 bg-white">
                  <span className="flex items-center space-x-4">
                    <span className="flex-1 flex space-x-2">
                      <CashIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span className="flex flex-col text-gray-500 text-sm ">
                        <span className="block">No plan runs yet!</span>
                      </span>
                    </span>
                  </span>
                </div>
              </li>
            )}
          </ul>

          {/* <nav
            className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
            aria-label="Pagination"
          >
            <div className="flex-1 flex justify-between">
              <a
                href="#"
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
              >
                Previous
              </a>
              <a
                href="#"
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
              >
                Next
              </a>
            </div>
          </nav> */}
        </div>

        {/* Activity table (small breakpoint and up) */}
        <div className="hidden sm:block">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col mt-2">
              <div className="align-middle min-w-full overflow-x-auto overflow-hidden sm:rounded-lg shadow-md ring-1 ring-black ring-opacity-10">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Plan name
                      </th>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Status
                      </th>
                      <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {isLoadingHomeData ? (
                      <tr key={'loading'} className="bg-white">
                        <td colSpan={4}>
                          <div className="flex px-4 py-6 justify-center">
                            <DotWave size={47} speed={1} color="gray" />
                          </div>
                        </td>
                      </tr>
                    ) : adminHomePlanRuns.length > 0 ? (
                      adminHomePlanRuns.map((planRun) => (
                        <tr key={planRun.id} className="bg-white">
                          <td className="max-w-0 w-full px-6 py-4  text-sm text-gray-900">
                            <div className="flex">
                              <Link to={`/runs/${planRun.id}/view`} className="group inline-flex space-x-2 text-sm">
                                <CashIcon
                                  className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                  aria-hidden="true"
                                />
                                <p className="text-gray-500 group-hover:text-gray-900">
                                  {planRun.contributionPlan?.name}
                                </p>
                              </Link>
                            </div>
                          </td>

                          <td className=" px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            <span
                              className={classNames(
                                statusStyles[planRun.disbursementStatus],
                                'inline-flex  px-2.5 py-0.5 rounded-md text-xs font-medium capitalize'
                              )}
                            >
                              {planRun.disbursementStatus}
                            </span>
                          </td>
                          <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                            <time dateTime={planRun.createdAt}>{abbrevDate(planRun.createdAt)}</time>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr key={'empty'} className="bg-white">
                        <td className="max-w-0 w-full px-6 py-4  text-sm text-gray-900">
                          <div className="flex">
                            <div className="group inline-flex space-x-2 text-sm">
                              <CashIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                              <p className="text-gray-500">No payments yet!</p>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500"></td>
                        <td className=" px-6 py-4 whitespace-nowrap text-sm text-gray-500"></td>
                        <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500"></td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {/* Pagination */}
                {/* <nav
                  className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                  aria-label="Pagination"
                >
                  <div className="hidden sm:block">
                    <p className="text-sm text-gray-700">
                      Showing <span className="font-medium">1</span> to <span className="font-medium">10</span> of{' '}
                      <span className="font-medium">20</span> results
                    </p>
                  </div>
                  <div className="flex-1 flex justify-between sm:justify-end">
                    <a
                      href="#"
                      className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Previous
                    </a>
                    <a
                      href="#"
                      className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Next
                    </a>
                  </div>
                </nav> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminHomePage;
