import EmptyPlaceholder from 'common/EmptyPlaceholder';
import { ExternalBankAccount } from 'interfaces/externalBankAccountInterfaces';
import { TuitionReimbursementPayment } from 'interfaces/tuitionReimbursementInterfaces';
import SelectTuitionReimbursementPaymentAccount from 'participant/TuitionReimbursement/SelectTuitionReimbursementPaymentAccount';

import React from 'react';

interface SelectTuitionReimbursementAccountBodyProps {
  tuitionReimbursementPayment: TuitionReimbursementPayment;
  externalBankAccounts: ExternalBankAccount[];
  setPaymentBankName: React.Dispatch<React.SetStateAction<string | undefined>>;
  setPaymentRoutingNumber: React.Dispatch<React.SetStateAction<string | undefined>>;
  setPaymentAccountNumber: React.Dispatch<React.SetStateAction<string | undefined>>;
  setPaymentAccountType: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const SelectTuitionReimbursementAccountBody: React.FC<SelectTuitionReimbursementAccountBodyProps> = ({
  tuitionReimbursementPayment,
  externalBankAccounts,
  setPaymentBankName,
  setPaymentRoutingNumber,
  setPaymentAccountNumber,
  setPaymentAccountType,
}) => {
  return (
    <div className="py-6 space-y-4">
      {externalBankAccounts.length > 0 && (
        <>
          <div className="border-b border-gray-200 py-5">
            <h3 className="text-lg font-medium leading-6 text-gray-900">External bank accounts</h3>
          </div>
          {externalBankAccounts.map((externalBankAccount) => (
            <SelectTuitionReimbursementPaymentAccount
              externalBankAccount={externalBankAccount}
              tuitionReimbursementPayment={tuitionReimbursementPayment}
              setPaymentBankName={setPaymentBankName}
              setPaymentRoutingNumber={setPaymentRoutingNumber}
              setPaymentAccountNumber={setPaymentAccountNumber}
              setPaymentAccountType={setPaymentAccountType}
              key={externalBankAccount.id}
            />
          ))}
        </>
      )}

      {!(externalBankAccounts.length > 0) && (
        <EmptyPlaceholder
          title="No accounts added yet!"
          subtitle="Add external bank accounts so you can receive your tuition reimbursement payment."
        />
      )}
    </div>
  );
};

export default SelectTuitionReimbursementAccountBody;
