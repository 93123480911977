import { ContributionPlanScheduleEnum } from 'enums/ContributionPlanScheduleEnum';
import { ContributionPlan } from 'interfaces/contributionsInterfaces';

export const getContributionPlanScheduleText = (contributionPlan?: ContributionPlan) => {
  const contributionPlanSchedule = contributionPlan?.schedule;

  const contributionPlanScheduleText = !contributionPlan?.recurring
    ? 'One-time'
    : contributionPlanSchedule === ContributionPlanScheduleEnum.Monthly
    ? 'Monthly'
    : contributionPlanSchedule === ContributionPlanScheduleEnum.Every3Months
    ? 'Every 3 Months'
    : contributionPlanSchedule === ContributionPlanScheduleEnum.Every6Months
    ? 'Every 6 Months'
    : contributionPlanSchedule === ContributionPlanScheduleEnum.Yearly
    ? 'Yearly'
    : '';
  return contributionPlanScheduleText;
};
