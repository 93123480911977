import AssignGrants from 'admin/ContributionPlans/EditGrants/AssignGrants/AssignGrants';
import ReviewGrants from 'admin/ContributionPlans/EditGrants/ReviewGrants';
import SubmitGrantsConfirmation from 'admin/ContributionPlans/EditGrants/SubmitGrantsConfirmation';
import { apiGetOrganizationPlan, apiSetGrants } from 'api/contributionsAPI';
import { apiGetParticipantsForPlan } from 'api/organizationUsersAPI';
import PageLoad from 'components/Load/PageLoad';
import { ContributionPlan } from 'interfaces/contributionsInterfaces';
import { OrganizationUser } from 'interfaces/organizationUserInterfaces';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import AuthenticatedRoute from 'routes/AuthenticatedRoute';

interface EditGrantsRouteParams {
  contributionPlanId: string;
}

const EditGrants: React.FC = () => {
  let { contributionPlanId } = useParams<EditGrantsRouteParams>();

  const history = useHistory();

  const [participants, setParticipants] = useState<OrganizationUser[]>([]);
  const [isLoadingContributionPlan, setIsLoadingContributionPlan] = useState(true);
  const [isSubmittingNewContributionGrants, setIsSubmittingNewContributionGrants] = useState(false);

  const [isLoadingParticipants, setIsLoadingParticipants] = useState(true);
  const [organizationPlan, setOrganizationPlan] = useState<ContributionPlan>();
  const [oldContributionGrantsByGrantee, setOldContributionGrantsByGrantee] = useState<{ [key: string]: number }>({});
  const [newContributionGrantsByGrantee, setNewContributionGrantsByGrantee] = useState<{ [key: string]: number }>({});
  const [participantIdsByEmail, setParticipantIdsByEmail] = useState<{ [key: string]: string }>({});

  const setNewContributionGrantsByEmail = (grantsByEmail: Array<any>) => {
    const grantsByParticipantId: { [key: string]: number } = {};
    for (var i = 0; i < grantsByEmail.length; i++) {
      const grant: Array<any> = grantsByEmail[i];
      const email: string = grant[0];
      const amount: number = grant[1];
      const participantId = participantIdsByEmail[email.toLowerCase()];
      if (participantId) {
        grantsByParticipantId[participantId] = amount;
      }
    }
    setNewContributionGrantsByGrantee({ ...newContributionGrantsByGrantee, ...grantsByParticipantId });
  };

  const setNewContributionGrant = (participantId: string, amount: number) => {
    setNewContributionGrantsByGrantee({ ...newContributionGrantsByGrantee, [participantId]: amount });
  };

  const submitNewContributionGrants = async () => {
    setIsSubmittingNewContributionGrants(true);
    apiSetGrants(contributionPlanId, JSON.stringify(newContributionGrantsByGrantee)).then(
      (data) => {
        setIsSubmittingNewContributionGrants(false);
        history.push(`/plans/${contributionPlanId}/edit/confirmed`);
      },
      (error) => {
        setIsSubmittingNewContributionGrants(false);
        toast.error(error.message);
      }
    );
  };

  const getOrganizationPlan = async () => {
    setIsLoadingContributionPlan(true);
    apiGetOrganizationPlan(contributionPlanId).then(
      (data) => {
        const organizationPlan: ContributionPlan = data.organizationPlan;
        setOrganizationPlan(organizationPlan);
        const contributionGrants: { [key: string]: number } = {};
        for (const contributionGrant of organizationPlan.contributionGrants!) {
          contributionGrants[contributionGrant.granteeId] = contributionGrant.amount;
        }
        setOldContributionGrantsByGrantee(contributionGrants);
        setIsLoadingContributionPlan(false);
      },
      (error) => {
        setIsLoadingContributionPlan(false);
      }
    );
  };

  const getParticipants = async () => {
    setIsLoadingParticipants(true);
    apiGetParticipantsForPlan().then(
      (data) => {
        const users: OrganizationUser[] = data.users;
        const getParticipantIdsByEmail: { [key: string]: string } = {};
        for (const organizationUser of users) {
          getParticipantIdsByEmail[organizationUser.email.toLowerCase()] = organizationUser.id;
        }
        setParticipantIdsByEmail(getParticipantIdsByEmail);
        setParticipants(users);
        setIsLoadingParticipants(false);
      },
      (error) => {
        setIsLoadingParticipants(false);
      }
    );
  };

  useEffect(() => {
    getOrganizationPlan();
    getParticipants();
  }, []);

  return (
    <>
      {isLoadingParticipants || isLoadingContributionPlan ? (
        <PageLoad />
      ) : (
        <div className="px-8 mt-8 mx-auto max-w-7xl">
          <h1 className="text-2xl font-bold text-gray-900">Edit "{organizationPlan?.name}" Plan Grants</h1>
          <AuthenticatedRoute
            path={`/plans/:contributionPlanId/edit`}
            render={() => {
              return (
                <AssignGrants
                  contributionPlan={organizationPlan}
                  participants={participants}
                  setNewContributionGrant={setNewContributionGrant}
                  setNewContributionGrantsByEmail={setNewContributionGrantsByEmail}
                  oldContributionGrantsByGrantee={oldContributionGrantsByGrantee}
                  newContributionGrantsByGrantee={newContributionGrantsByGrantee}
                />
              );
            }}
            exact
          />
          <AuthenticatedRoute
            path={`/plans/:contributionPlanId/edit/review`}
            render={() => {
              return (
                <ReviewGrants
                  contributionPlan={organizationPlan}
                  participants={participants}
                  oldContributionGrantsByGrantee={oldContributionGrantsByGrantee}
                  newContributionGrantsByGrantee={newContributionGrantsByGrantee}
                  submitNewContributionGrants={submitNewContributionGrants}
                  isSubmittingNewContributionGrants={isSubmittingNewContributionGrants}
                />
              );
            }}
            exact
          />
          <AuthenticatedRoute
            path={`/plans/:contributionPlanId/edit/confirmed`}
            render={() => {
              return <SubmitGrantsConfirmation />;
            }}
            exact
          />
        </div>
      )}
    </>
  );
};

export default EditGrants;
