import { SearchIcon } from '@heroicons/react/outline';
import { Ring } from '@uiball/loaders';
import ViewMatch401kPlanRunBreadcrumb from 'admin/Match401k/ViewMatch401kPlanRun/ViewMatch401kPlanRunBreadcrumb';
import ViewMatch401kPlanRunGrantsTable from 'admin/Match401k/ViewMatch401kPlanRun/ViewMatch401kPlanRunGrantsTable';
import ViewMatch401kPlanRunHeader from 'admin/Match401k/ViewMatch401kPlanRun/ViewMatch401kPlanRunHeader';
import EmptyPlaceholder from 'common/EmptyPlaceholder';
import PageLoad from 'components/Load/PageLoad';

import { Match401kPlanRun } from 'interfaces/match401kInterfaces';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAsyncDebounce } from 'react-table';

interface ViewMatch401kPlanRunRouteParams {
  match401kPlanRunId: string;
}

const ViewMatch401kPlanRun: React.FC = () => {
  let { match401kPlanRunId } = useParams<ViewMatch401kPlanRunRouteParams>();

  const [isLoadingMatch401KPlanRun, setIsLoadingMatch401KPlanRun] = useState(false);
  const [match401kPlanRun, setMatch401kPlanRun] = useState<Match401kPlanRun>({
    id: '9d1527f0-79c8-435a-9901-6a3d519b7b81',
    name: 'December 2022 Contributions',
    organizationId: 'string',
    match401kGrantRuns: [
      {
        id: 'id',
        participantProfile: {
          startDate: '01-01-2023',
          updatedAt: '01-01-2023',
          createdAt: '01-01-2023',
          organizationUserId: 'id',
          organizationUser: {
            id: 'id',
            firstName: 'Steve',
            lastName: 'Troy',
            email: 'steve@unsaddl.com',
            role: 'string',
            active: true,
            claimed: true,
            organizationId: 'org-id',
            createdAt: '01-01-2023',
          },
        },
        amountGranted: 500,
        matchPercentage: 6,
        participantSalary: 100000,
        status: 'sent',
        matchableEmployeeContribution: 500,
        amountSubmitted: 500,
        createdAt: '01-01-2023',
        updatedAt: '01-01-2023',
      },
      {
        id: 'id',
        participantProfile: {
          startDate: '01-01-2023',
          updatedAt: '01-01-2023',
          createdAt: '01-01-2023',
          organizationUserId: 'id',
          organizationUser: {
            id: 'id',
            firstName: 'Chris',
            lastName: 'Hsu',
            email: 'chris@unsaddl.com',
            role: 'string',
            active: true,
            claimed: true,
            organizationId: 'org-id',
            createdAt: '01-01-2023',
          },
        },
        amountGranted: 300,
        matchPercentage: 6,
        participantSalary: 60000,
        status: 'sent',
        matchableEmployeeContribution: 500,
        amountSubmitted: 300,
        createdAt: '01-01-2023',
        updatedAt: '01-01-2023',
      },
    ],
    createdAt: '01-01-2023',
    updatedAt: '01-01-2023',
  });

  const [searchTerm, setSearchTerm] = useState('');
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);

  const getMatch401kPlanRun = async () => {
    setIsLoadingMatch401KPlanRun(false);
  };

  const setSearchTermDebounced = useAsyncDebounce((searchText: string | undefined) => {
    setSearchTerm(searchText || '');
    setIsLoadingSearch(false);
  }, 1000);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoadingSearch(true);
    setSearchTermDebounced(event.target.value);
  };

  useEffect(() => {
    getMatch401kPlanRun();
  }, []);

  return (
    <>
      {isLoadingMatch401KPlanRun ? (
        <PageLoad />
      ) : (
        <>
          <div className="my-6 px-8 mx-auto max-w-7xl">
            {match401kPlanRun ? (
              <>
                <ViewMatch401kPlanRunBreadcrumb match401kPlanRun={match401kPlanRun} />
                <ViewMatch401kPlanRunHeader match401kPlanRun={match401kPlanRun} />

                <div className="flex flex-col mt-4 md:flex-row md:justify-between md:items-center md:space-x-4 space-y-2">
                  <h1 className="text-xl font-bold text-gray-900">401(k) Match Grants</h1>
                  <div className="w-full md:max-w-md">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        {isLoadingSearch ? (
                          <Ring size={15} lineWeight={7} speed={2.0} color="gray" />
                        ) : (
                          <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        )}
                      </div>
                      <input
                        id="search"
                        name="search"
                        className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-sm"
                        placeholder="Search for a user"
                        type="search"
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                </div>
                <ViewMatch401kPlanRunGrantsTable searchTerm={searchTerm} match401kPlanRun={match401kPlanRun} />
              </>
            ) : (
              <EmptyPlaceholder title={'No plan run found'} subtitle={'This plan run does not exist.'} />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ViewMatch401kPlanRun;
