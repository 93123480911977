export const setItem = (name: string, value: string) => {
  localStorage.setItem(name, JSON.stringify(value));
};

export const getItem = (name: string) => {
  const item = localStorage.getItem(name);

  if (item === 'undefined') {
    return undefined;
  }

  return item !== null ? JSON.parse(item) : null;
};

export const clear = () => {
  localStorage.clear();
};
