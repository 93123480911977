import { ChevronDownIcon, ChevronUpIcon, CurrencyDollarIcon } from '@heroicons/react/outline';
import EmptyTablePlaceholder from 'common/EmptyTablePlaceholder';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { CellProps, Column, useSortBy, useTable } from 'react-table';
import { abbrevDate } from 'utils/dateUtils';
import { formatMoney } from 'utils/numberFormatter';

interface HSAListTableProps {
  hsaContributionRuns: any[];
}

const HSAListTable: React.FC<HSAListTableProps> = ({ hsaContributionRuns }) => {
  const history = useHistory();

  const hsaContributionRunsMemo = React.useMemo(() => hsaContributionRuns, [hsaContributionRuns]);
  const columns: Column[] = React.useMemo(
    () => [
      {
        id: 'plan-details',
        Header: 'Plan details',

        Cell: (props: CellProps<any>) => {
          const match401kPlanRun = props.row.original;

          return (
            <div>
              <div className="text-sm font-medium text-gray-900">{match401kPlanRun?.name}</div>
              <div className="text-sm text-gray-500">ID: {match401kPlanRun.id}</div>
            </div>
          );
        },
        className: 'py-4 text-sm font-medium text-gray-900',
        headerClassName: 'py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
      {
        id: 'execution-date',
        Header: 'Execution date',

        Cell: (props: CellProps<any>) => {
          const match401kPlanRun = props.row.original;
          const createdAt = match401kPlanRun.createdAt;

          return (
            <div className="items-center">
              <div className="text-sm font-medium text-gray-900">{abbrevDate(createdAt)}</div>
            </div>
          );
        },
        className: 'py-4 hidden sm:table-cell text-sm font-medium text-gray-900',
        headerClassName:
          'py-3 hidden sm:table-cell text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
      {
        id: 'total-contribution-amount',
        Header: 'Total contribution amount',
        Cell: (props: CellProps<any>) => {
          return (
            <div className="flex">
              <span className="inline-flex ml-auto px-2 text-xs leading-5 font-semibold rounded-md bg-green-100 text-green-800">
                <CurrencyDollarIcon
                  className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                  aria-hidden="true"
                />
                {formatMoney(props.row.original.totalContributionAmount)}
              </span>
            </div>
          );
        },
        className: 'py-4 whitespace-nowrap text-sm text-gray-500',
        headerClassName: 'py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
    ],
    []
  ) as any;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: hsaContributionRunsMemo,
    },
    useSortBy
  );

  return (
    <div>
      {rows.length === 0 ? (
        <div className="mt-4">
          <EmptyTablePlaceholder title="No HSA contributions" subtitle="No HSA contributions have been executed yet" />
        </div>
      ) : (
        <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        className={`${column.headerClassName}`}
                      >
                        {
                          // Render the header
                          column.render('Header')
                        }
                        <span className="inline-flex ml-1">
                          {column.isSorted ? (
                            <span className="w-3 h-3">
                              {column.isSortedDesc ? <ChevronDownIcon /> : <ChevronUpIcon />}
                            </span>
                          ) : (
                            ''
                          )}
                        </span>
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          <tbody {...getTableBodyProps()} className="divide-y divide-gray-200">
            {
              // Loop over the table rows
              rows.map((row) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr
                    {...row.getRowProps()}
                    onClick={() => history.push(`/hsa/contributions`)}
                    className="hover:bg-gray-50 cursor-pointer"
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td {...cell.getCellProps()} className={`${cell.column.className}`}>
                            {
                              // Render the cell contents
                              cell.render('Cell')
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      )}
    </div>
  );
};

export default HSAListTable;
