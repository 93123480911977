import PaymentsTable from 'common/Contributions/PaymentsTable';
import ViewGrantRunModal from 'common/Contributions/ViewGrantRunModal';
import { ContributionGrantRun } from 'interfaces/contributionsInterfaces';
import { OrganizationUser } from 'interfaces/organizationUserInterfaces';
import React, { useState } from 'react';

interface ViewParticipantPaymentsProps {
  participant: OrganizationUser;
}

const ViewParticipantPayments: React.FC<ViewParticipantPaymentsProps> = ({ participant }) => {
  const [viewParticipantGrantRun, setViewParticipantGrantRun] = useState<ContributionGrantRun>();
  const [openViewParticipantGrantRunModa, setOpenViewParticipantGrantRunModal] = useState(false);

  const contributionPlan = viewParticipantGrantRun?.contributionPlanRun?.contributionPlan;
  const participantGrantRuns = participant.participantProfile?.contributionGrantRuns;

  const startViewGrantRun = (participantGrantRun: ContributionGrantRun) => {
    setViewParticipantGrantRun(participantGrantRun);
    setOpenViewParticipantGrantRunModal(true);
  };

  return (
    <>
      {/* Description list */}
      <div className="mt-6 mx-auto">
        {participantGrantRuns && (
          <PaymentsTable contributionGrantRuns={participantGrantRuns} startViewGrantRun={startViewGrantRun} />
        )}
        <ViewGrantRunModal
          recipientOrganizationUser={participant}
          contributionPlan={contributionPlan}
          contributionGrantRun={viewParticipantGrantRun}
          open={openViewParticipantGrantRunModa}
          setOpen={setOpenViewParticipantGrantRunModal}
        />
      </div>
    </>
  );
};

export default ViewParticipantPayments;
