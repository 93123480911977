import { LoanAccount } from 'interfaces/loansInterfaces';
import ActiveLoanAccountRow from 'participant/Accounts/LoanAccount/ActiveLoanAccounts/ActiveLoanAccountRow';
import React from 'react';

interface ActiveLoanAccountsTableProps {
  loanAccounts: LoanAccount[];
  startDeactivateAccount: (loanAccount: LoanAccount) => void;
}

const ActiveLoanAccountsTable: React.FC<ActiveLoanAccountsTableProps> = ({ loanAccounts, startDeactivateAccount }) => {
  return (
    <table className="min-w-full table-auto divide-y divide-gray-300">
      <thead>
        <tr>
          <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-500">
            Account #
          </th>
          <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-500 hidden xs:table-cell">
            Name
          </th>
          <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-500 hidden sm:table-cell">
            Interest rate
          </th>
          <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-500">
            Current balance
          </th>
          <th scope="col" className="relative py-3.5 ">
            <span className="sr-only">Options</span>
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {loanAccounts.map((loanAccount) => (
          <ActiveLoanAccountRow
            loanAccount={loanAccount}
            startDeactivateAccount={startDeactivateAccount}
            key={loanAccount.id}
          />
        ))}
      </tbody>
    </table>
  );
};

export default ActiveLoanAccountsTable;
