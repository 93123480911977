import { SearchIcon } from '@heroicons/react/outline';
import { Ring } from '@uiball/loaders';
import ActiveOrganizationUsers from 'admin/ManageOrganizationUsers/ActiveOrganizationUsers/ActiveOrganizationUsers';
import DisabledOrganizationUsers from 'admin/ManageOrganizationUsers/DisabledOrganizationUsers/DisabledOrganizationUsers';
import InvitedUsers from 'admin/ManageOrganizationUsers/InvitedUsers/InvitedUsers';
import InviteOrganizationUsersModal from 'admin/ManageOrganizationUsers/InviteOrganizationUsers/InviteOrganizationUsersModal';
import { apiGetOrganizationUsers, apiSyncWithPayroll } from 'api/organizationUsersAPI';
import { OrganizationUser } from 'interfaces/organizationUserInterfaces';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { useAsyncDebounce } from 'react-table';
import AuthenticatedRoute from 'routes/AuthenticatedRoute';
import { classNames } from 'utils/tailwindUtils';

interface ManageOrganizationUsersTab {
  name: string;
  href: string;
  count: string;
  current: boolean;
}

interface ManageOrganizationUsersPageProps {
  role: string;
}

const ManageOrganizationUsersPage: React.FC<ManageOrganizationUsersPageProps> = ({ role }) => {
  const [openInviteUsersModal, setOpenInviteUsersModal] = useState(false);
  const [isSyncingPayroll, setIsSyncingPayroll] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [getOrganizationUsersError, setGetOrganizationUsersError] = useState('');

  const [organizationUsers, setOrganizationUsers] = useState<OrganizationUser[]>([]);

  const setSearchTermDebounced = useAsyncDebounce((searchText: string | undefined) => {
    setSearchTerm(searchText || '');
    setIsLoadingSearch(false);
  }, 1000);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoadingSearch(true);
    setSearchTermDebounced(event.target.value);
  };

  const removeOrganizationUser = (organizationUserId: string) => {
    setOrganizationUsers(organizationUsers.filter((organizationUser) => organizationUser.id !== organizationUserId));
  };

  const updateOrganizationUser = (updatedOrganizationUser: OrganizationUser) => {
    const newOrganizationUsers = organizationUsers.map((organizationUser) => {
      if (organizationUser.id === updatedOrganizationUser.id) {
        return updatedOrganizationUser;
      }
      return organizationUser;
    });

    setOrganizationUsers(newOrganizationUsers);
  };

  const addOrganizationUser = (newOrganizationUser: OrganizationUser) => {
    const newOrganizationUsers = organizationUsers.concat(newOrganizationUser);
    setOrganizationUsers(newOrganizationUsers);
  };

  const getOrganizationUsers = async () => {
    apiGetOrganizationUsers(role).then(
      (data) => {
        const users: OrganizationUser[] = data.users;
        setOrganizationUsers(users);
        setIsLoadingUsers(false);
      },
      (error) => {
        setIsLoadingUsers(false);
        setGetOrganizationUsersError(error.message);
      }
    );
  };

  const handleSyncWithPayroll = async () => {
    setIsSyncingPayroll(true);

    apiSyncWithPayroll().then(
      (data) => {
        getOrganizationUsers();
        toast.success(`Successfully synced with your payroll provider!`);
        setIsSyncingPayroll(false);
      },
      (error) => {
        setIsSyncingPayroll(false);
      }
    );
  };

  useEffect(() => {
    getOrganizationUsers();
  }, []);

  const location = useLocation();
  const locationPath = location.pathname;

  const activeOrganizationUsers = organizationUsers.filter(
    (organizationUser) => organizationUser.active && organizationUser.claimed
  );
  const disabledOrganizationUsers = organizationUsers.filter(
    (organizationUser) => !organizationUser.active && organizationUser.claimed
  );
  const invitedUsers = organizationUsers.filter(
    (organizationUser) => !organizationUser.active && !organizationUser.claimed
  );

  const tabs: ManageOrganizationUsersTab[] = [
    {
      name: 'Active',
      href: `/${role}/list/active`,
      count: activeOrganizationUsers.length.toString(),
      current: locationPath === `/${role}/list/active`,
    },
    {
      name: 'Disabled',
      href: `/${role}/list/disabled`,
      count: disabledOrganizationUsers.length.toString(),
      current: locationPath === `/${role}/list/disabled`,
    },
    {
      name: 'Invited',
      href: `/${role}/list/invited`,
      count: invitedUsers.length.toString(),
      current: locationPath === `/${role}/list/invited`,
    },
  ];

  return (
    <>
      <div className="flex flex-col-reverse lg:flex-row">
        <nav className="mt-2 border-b border-gray-200 flex space-x-8 overflow-x-auto scrollbar-hide" aria-label="Tabs">
          {tabs.map((tab) => (
            <Link
              key={tab.name}
              to={tab.href}
              className={classNames(
                tab.current
                  ? 'border-purple-500 text-purple-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
              )}
            >
              {tab.name}
              {tab.count ? (
                <span
                  className={classNames(
                    tab.current ? 'bg-purple-100 text-purple-600' : 'bg-gray-100 text-gray-900',
                    'hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                  )}
                >
                  {tab.count}
                </span>
              ) : null}
            </Link>
          ))}
        </nav>
        <div className="lg:ml-auto flex-col space-y-1 sm:space-y-0 sm:space-x-2 sm:flex-row flex items-center">
          <div className="w-full lg:max-w-lg">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                {isLoadingSearch ? (
                  <Ring size={15} lineWeight={7} speed={2.0} color="gray" />
                ) : (
                  <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                )}
              </div>
              <input
                id="search"
                name="search"
                className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-sm"
                placeholder="Search for a user"
                type="search"
                onChange={handleSearch}
              />
            </div>
          </div>

          <button
            type="button"
            className="justify-center w-full sm:w-auto rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => {
              setOpenInviteUsersModal(true);
            }}
          >
            Invite
          </button>
        </div>
      </div>

      <AuthenticatedRoute
        exact
        path={`/${role}/list`}
        render={() => {
          return <Redirect to={`/${role}/list/active`} />;
        }}
      />
      <AuthenticatedRoute
        path={`/${role}/list/active`}
        render={() => {
          return (
            <ActiveOrganizationUsers
              searchTerm={searchTerm}
              role={role}
              isLoadingUsers={isLoadingUsers}
              activeOrganizationUsers={activeOrganizationUsers}
              updateOrganizationUser={updateOrganizationUser}
            />
          );
        }}
      />
      <AuthenticatedRoute
        path={`/${role}/list/disabled`}
        render={() => {
          return (
            <DisabledOrganizationUsers
              searchTerm={searchTerm}
              role={role}
              isLoadingUsers={isLoadingUsers}
              disabledOrganizationUsers={disabledOrganizationUsers}
              updateOrganizationUser={updateOrganizationUser}
            />
          );
        }}
      />
      <AuthenticatedRoute
        path={`/${role}/list/invited`}
        render={() => {
          return (
            <InvitedUsers
              searchTerm={searchTerm}
              role={role}
              isLoadingUsers={isLoadingUsers}
              removeOrganizationUser={removeOrganizationUser}
              invitedUsers={invitedUsers}
            />
          );
        }}
      />

      <InviteOrganizationUsersModal
        role={role}
        open={openInviteUsersModal}
        setOpen={setOpenInviteUsersModal}
        addOrganizationUser={addOrganizationUser}
      />
    </>
  );
};

export default ManageOrganizationUsersPage;
