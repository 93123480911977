import PageLoad from 'components/Load/PageLoad';
import { UserDataContext } from 'context/UserContext';
import Dashboard from 'dashboard/Dashboard';
import NoProfilesPage from 'identity/NoProfilesPage';
import RegistrationPage from 'identity/RegistrationPage';
import SessionExpirationModal from 'identity/SessionExpirationModal';
import UnexpectedErrorPage from 'identity/UnexpectedErrorPage';
import { useContext } from 'react';

const IdentityGateway: React.FC = () => {
  const { userAccount, isLoadingUser, isUnexpectedError, currentOrganizationUser } = useContext(UserDataContext);

  return (
    <>
      {isLoadingUser ? (
        <PageLoad />
      ) : isUnexpectedError ? (
        <UnexpectedErrorPage />
      ) : !userAccount ? (
        <RegistrationPage />
      ) : !currentOrganizationUser ? (
        <NoProfilesPage />
      ) : (
        <Dashboard />
      )}
      <SessionExpirationModal />
    </>
  );
};

export default IdentityGateway;
