import { apiSubmitTuitionReimbursementPaymentAccount } from 'api/tuitionReimbursementAPI';
import { ExternalBankAccount } from 'interfaces/externalBankAccountInterfaces';
import { TuitionReimbursementPayment } from 'interfaces/tuitionReimbursementInterfaces';
import ConfirmTuitionReimbursementPaymentAccountModal from 'participant/TuitionReimbursement/ConfirmTuitionReimbursementPaymentAccountModal';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { classNames } from 'utils/tailwindUtils';

interface SelectTuitionReimbursementPaymentAccountProps {
  tuitionReimbursementPayment: TuitionReimbursementPayment;
  externalBankAccount: ExternalBankAccount;
  setPaymentBankName: React.Dispatch<React.SetStateAction<string | undefined>>;
  setPaymentRoutingNumber: React.Dispatch<React.SetStateAction<string | undefined>>;
  setPaymentAccountNumber: React.Dispatch<React.SetStateAction<string | undefined>>;
  setPaymentAccountType: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const SelectTuitionReimbursementPaymentAccount: React.FC<SelectTuitionReimbursementPaymentAccountProps> = ({
  tuitionReimbursementPayment,
  externalBankAccount,
  setPaymentBankName,
  setPaymentRoutingNumber,
  setPaymentAccountNumber,
  setPaymentAccountType,
}) => {
  const [isSubmittingTuitionReimbursementPaymentAccount, setIsSubmittingTuitionReimbursementPaymentAccount] =
    useState(false);
  const [openConfirmTuitionReimbursementPaymentModal, setOpenConfirmTuitionReimbursementPaymentModal] = useState(false);

  const submitTuitionReimbursementPaymentAccount = async () => {
    setIsSubmittingTuitionReimbursementPaymentAccount(true);
    apiSubmitTuitionReimbursementPaymentAccount(
      tuitionReimbursementPayment.id,
      externalBankAccount.institutionName,
      externalBankAccount.routingNumber,
      externalBankAccount.accountNumber,
      externalBankAccount.accountType
    ).then(
      (data) => {
        setIsSubmittingTuitionReimbursementPaymentAccount(false);
        const submittedPayment = data.submittedPayment;
        if (submittedPayment) {
          setPaymentBankName(submittedPayment.institutionName);
          setPaymentRoutingNumber(submittedPayment.routingNumber);
          setPaymentAccountNumber(submittedPayment.accountNumber);
          setPaymentAccountType(submittedPayment.accountType);
        }
        toast.success('Submitted tuition reimbursement payment information!');
        setOpenConfirmTuitionReimbursementPaymentModal(false);
      },
      (error) => {
        setIsSubmittingTuitionReimbursementPaymentAccount(false);
        toast.error(error.message);
      }
    );
  };

  return (
    <div
      className={classNames(
        tuitionReimbursementPayment.accountNumber ? 'opacity-50' : '',
        'px-6 py-4 shadow-md ring-1 ring-black ring-opacity-10 rounded-lg bg-white'
      )}
    >
      <div className="sm:items-center flex flex-col sm:flex-row">
        <div className="flex-auto">
          <h1 className="text-xl font-bold text-gray-900">
            {externalBankAccount.name}
            {externalBankAccount.accountType &&
              ` (${externalBankAccount.accountType.charAt(0).toUpperCase()}${externalBankAccount.accountType.slice(
                1
              )})`}
          </h1>
        </div>

        {!tuitionReimbursementPayment.accountNumber && (
          <button
            onClick={() => {
              setOpenConfirmTuitionReimbursementPaymentModal(true);
            }}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-full sm:w-auto my-2 sm:my-0"
          >
            Select as payment account
          </button>
        )}
      </div>

      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-500">
              Institution name
            </th>
            <th scope="col" className="py-3.5 px-3 text-right text-sm font-semibold text-gray-500 hidden sm:table-cell">
              Routing #
            </th>
            <th scope="col" className="py-3.5 px-3 text-right text-sm font-semibold text-gray-500 hidden xs:table-cell">
              Account #
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          <tr>
            <td className="py-4 pr-3 text-sm font-medium text-gray-900">{externalBankAccount.institutionName}</td>
            <td className="py-4 px-3 text-sm text-right text-gray-500 hidden sm:table-cell">
              {externalBankAccount.routingNumber}
            </td>
            <td className="py-4 px-3 text-sm text-right text-gray-500 hidden xs:table-cell">
              {externalBankAccount.accountNumber}
            </td>
          </tr>
        </tbody>
      </table>

      <ConfirmTuitionReimbursementPaymentAccountModal
        isSubmittingTuitionReimbursementPaymentAccount={isSubmittingTuitionReimbursementPaymentAccount}
        tuitionReimbursementPayment={tuitionReimbursementPayment}
        selectedExternalBankAccount={externalBankAccount}
        open={openConfirmTuitionReimbursementPaymentModal}
        setOpen={setOpenConfirmTuitionReimbursementPaymentModal}
        submitTuitionReimbursementPaymentAccount={submitTuitionReimbursementPaymentAccount}
      />
    </div>
  );
};

export default SelectTuitionReimbursementPaymentAccount;
