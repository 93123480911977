import {
  ChevronDownIcon,
  ChevronUpIcon,
  CurrencyDollarIcon,
  LibraryIcon,
  UserCircleIcon,
} from '@heroicons/react/outline';
import EmptyTablePlaceholder from 'common/EmptyTablePlaceholder';
import Pagination from 'components/Tables/Pagination';
import { Match401kGrantRun, Match401kPlanRun } from 'interfaces/match401kInterfaces';
import React, { useEffect } from 'react';
import Highlighter from 'react-highlight-words';
import { CellProps, Column, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { formatMoney } from 'utils/numberFormatter';

interface HSAContributionsTableProps {
  searchTerm: string;
  match401kPlanRun: Match401kPlanRun;
}

const HSAContributionsTable: React.FC<HSAContributionsTableProps> = ({ searchTerm, match401kPlanRun }) => {
  const match401kPlanRunGrantsMemo = React.useMemo<Match401kGrantRun[]>(
    () => match401kPlanRun.match401kGrantRuns,
    [match401kPlanRun.match401kGrantRuns]
  );
  const columns: Column<Match401kGrantRun>[] = React.useMemo(
    () => [
      {
        id: 'recipient',
        Header: 'Recipient',
        accessor: (match401kGrantRun: Match401kGrantRun) => {
          const granteeOrganizationUser = match401kGrantRun.participantProfile?.organizationUser;

          return (
            granteeOrganizationUser?.firstName +
            ' ' +
            granteeOrganizationUser?.lastName +
            ' ' +
            granteeOrganizationUser?.email
          );
        },
        Cell: (props: CellProps<Match401kGrantRun>) => {
          const match401kGrantRun = props.row.original;
          const granteeOrganizationUser = match401kGrantRun.participantProfile?.organizationUser;
          const firstName = granteeOrganizationUser?.firstName;
          const lastName = granteeOrganizationUser?.lastName;
          const email = granteeOrganizationUser?.email;

          return (
            <div className="flex items-center">
              <div>
                <Highlighter
                  className="block font-medium text-gray-900 break-all"
                  highlightClassName="bg-yellow-200"
                  searchWords={[props.state.globalFilter]}
                  autoEscape={true}
                  textToHighlight={`${firstName} ${lastName}`}
                />
                <Highlighter
                  className="block text-gray-500 break-all"
                  highlightClassName="bg-yellow-200"
                  searchWords={[props.state.globalFilter]}
                  autoEscape={true}
                  textToHighlight={`${email}`}
                />
              </div>
            </div>
          );
        },
        className: 'py-4 text-sm font-medium text-gray-900',
        headerClassName: 'py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
      {
        id: 'employee-contribution',
        Header: 'Employee Contribution',
        Cell: (props: CellProps<any>) => {
          const hsaContributions = props.row.original;
          return (
            <div className="flex items-center justify-end">
              <div className="flex-col">
                <div className="flex justify-end">
                  <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-green-100 text-green-800">
                    <UserCircleIcon
                      className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                      aria-hidden="true"
                    />
                    {formatMoney(Number(hsaContributions.employeeContribution))}
                  </span>
                </div>
              </div>
            </div>
          );
        },
        className: 'py-4 whitespace-nowrap text-sm text-gray-500',
        headerClassName: 'py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
      {
        id: 'employer-contribution',
        Header: 'Employer Contribution',
        Cell: (props: CellProps<any>) => {
          const hsaContributions = props.row.original;
          return (
            <div className="flex items-center justify-end">
              <div className="flex-col">
                <div className="flex justify-end">
                  <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-green-100 text-green-800">
                    <LibraryIcon
                      className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                      aria-hidden="true"
                    />
                    {formatMoney(Number(hsaContributions.employerContribution))}
                  </span>
                </div>
              </div>
            </div>
          );
        },
        className: 'py-4 whitespace-nowrap text-sm text-gray-500',
        headerClassName: 'py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
      {
        id: 'total',
        Header: 'Total',
        Cell: (props: CellProps<any>) => {
          const hsaContributions = props.row.original;
          return (
            <div className="flex items-center justify-end">
              <div className="flex-col">
                <div className="flex justify-end">
                  <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-green-100 text-green-800">
                    <CurrencyDollarIcon
                      className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                      aria-hidden="true"
                    />
                    {formatMoney(Number(hsaContributions.total))}
                  </span>
                </div>
              </div>
            </div>
          );
        },
        className: 'py-4 whitespace-nowrap text-sm text-gray-500',
        headerClassName: 'py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
    ],
    []
  ) as any;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex },
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: match401kPlanRunGrantsMemo,
      initialState: { pageSize: 10, globalFilter: searchTerm },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setGlobalFilter(searchTerm);
  }, [searchTerm]);

  return (
    <div className="mt-4">
      <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={`${column.headerClassName}`}
                    >
                      {
                        // Render the header
                        column.render('Header')
                      }
                      <span className="inline-flex ml-1">
                        {column.isSorted ? (
                          <span className="w-3 h-3">
                            {column.isSortedDesc ? <ChevronDownIcon /> : <ChevronUpIcon />}
                          </span>
                        ) : (
                          ''
                        )}
                      </span>
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()} className="divide-y divide-gray-200">
          {
            // Loop over the table rows
            page.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr {...row.getRowProps()} className="hover:bg-gray-50 cursor-pointer">
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td {...cell.getCellProps()} className={`${cell.column.className}`}>
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>

      {page.length === 0 && (
        <EmptyTablePlaceholder
          title="No grants"
          subtitle="This contribution plan run does not have any active grants."
        />
      )}

      <Pagination
        pageIndex={pageIndex}
        numPages={pageOptions.length}
        nextPage={nextPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
      />
    </div>
  );
};

export default HSAContributionsTable;
