import { Menu } from '@headlessui/react';
import { Role } from 'enums/RolesEnum';
import { OrganizationUser } from 'interfaces/organizationUserInterfaces';
import React from 'react';
import { Link } from 'react-router-dom';
import { classNames } from 'utils/tailwindUtils';

interface ActiveOrganizationUserMenuOptionsProps {
  activeOrganizationUser: OrganizationUser;
  startDisableOrganizationUser: (activeOrganizationUser: OrganizationUser) => void;
}

const ActiveOrganizationUserMenuOptions: React.FC<ActiveOrganizationUserMenuOptionsProps> = ({
  activeOrganizationUser,
  startDisableOrganizationUser,
}) => {
  const viewProfilePath =
    activeOrganizationUser.role === Role.Participant
      ? `/participants/${activeOrganizationUser.id}/view/profile`
      : `/admins/${activeOrganizationUser.id}/view/profile`;

  return (
    <>
      <div className="py-1 px-1">
        <Menu.Item>
          {({ active }) => (
            <Link
              className={classNames(
                active ? 'bg-gray-100' : '',
                'text-black block px-4 py-2 text-sm text-center cursor-pointer'
              )}
              to={viewProfilePath}
            >
              View profile
            </Link>
          )}
        </Menu.Item>
      </div>

      <div className="py-1 px-1">
        <Menu.Item>
          {({ active }) => (
            <a
              className={classNames(
                active ? 'bg-gray-100' : '',
                'text-red-500 block px-4 py-2 text-sm text-center cursor-pointer'
              )}
              onClick={() => {
                startDisableOrganizationUser(activeOrganizationUser);
              }}
            >
              Disable
            </a>
          )}
        </Menu.Item>
      </div>
    </>
  );
};

export default ActiveOrganizationUserMenuOptions;
