import { CloudDownloadIcon, PlusIcon } from '@heroicons/react/outline';
import { useMergeLink } from '@mergeapi/react-merge-link';
import { apiAddMergePayrollConnection, apiCreateMergeLinkToken } from 'api/organizationUsersAPI';
import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

interface ConnectPayrollMenuProps {
  refreshPayrollConnections: () => void;
  hasExistingPayrollConnections: boolean;
}

const ConnectPayrollMenu: React.FC<ConnectPayrollMenuProps> = ({
  refreshPayrollConnections,
  hasExistingPayrollConnections,
}) => {
  const [mergeLinkToken, setMergeLinkToken] = useState('');

  const onSuccess = useCallback((public_token) => {
    apiAddMergePayrollConnection(public_token).then(
      (data) => {
        refreshPayrollConnections();
        toast.success(`Successfully connected with your payroll provider!`);
      },
      (error) => {
        toast.error(error.message);
      }
    );
  }, []);

  const { open, isReady } = useMergeLink({
    linkToken: mergeLinkToken,
    onSuccess,
  });

  useEffect(() => {
    const generateToken = async () => {
      apiCreateMergeLinkToken().then(
        (data) => {
          setMergeLinkToken(data.merge_link_token);
        },
        (error) => {}
      );
    };
    generateToken();
  }, []);

  return (
    <div className="mt-4">
      {hasExistingPayrollConnections ? (
        <div className="relative">
          <div className="absolute inset-0 flex items-center px-8" aria-hidden="true">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <button
              type="button"
              className="inline-flex items-center shadow-sm px-4 py-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => open()}
              disabled={!isReady}
            >
              <>
                <PlusIcon className="-ml-1.5 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />

                <span>Add payroll connection</span>
              </>
            </button>
          </div>
        </div>
      ) : (
        <button
          type="button"
          className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => open()}
          disabled={!isReady}
        >
          <CloudDownloadIcon className="mx-auto h-24 w-24 text-gray-500" aria-hidden="true" />

          <span className="mt-2 block text-lg font-medium text-gray-900">Connect a payroll provider</span>
        </button>
      )}
    </div>
  );
};

export default ConnectPayrollMenu;
