import EditGrants from 'admin/TuitionReimbursement/EditGrants/EditGrants';
import ListAllRequests from 'admin/TuitionReimbursement/ListAllRequests/ListAllRequests';
import ListPayments from 'admin/TuitionReimbursement/ListPayments/ListPayments';
import ListPlans from 'admin/TuitionReimbursement/ListPlans/ListPlans';
import ViewGrant from 'admin/TuitionReimbursement/ViewPlan/ViewGrant/ViewGrant';
import ViewPlan from 'admin/TuitionReimbursement/ViewPlan/ViewPlan';
import EmptyPlaceholder from 'common/EmptyPlaceholder';
import EmptyTablePlaceholder from 'common/EmptyTablePlaceholder';
import { UserDataContext } from 'context/UserContext';
import { Role } from 'enums/RolesEnum';

import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AuthenticatedRoute from 'routes/AuthenticatedRoute';
import { classNames } from 'utils/tailwindUtils';

interface OrganizationTuitionReimbursementTab {
  name: string;
  href: string;
  current: boolean;
}

const OrganizationTuitionReimbursementPage: React.FC = () => {
  const { currentOrganizationUser } = useContext(UserDataContext);
  const location = useLocation();
  const locationPath = location.pathname;

  const tabs: OrganizationTuitionReimbursementTab[] = [
    {
      name: 'Plans',
      href: `/tuition-reimbursement/list/plans`,
      current: locationPath === `/tuition-reimbursement/list/plans`,
    },
    {
      name: 'Requests',
      href: `/tuition-reimbursement/list/requests/all`,
      current: locationPath === `/tuition-reimbursement/list/requests/all`,
    },
    ...(currentOrganizationUser?.role === Role.InternalAdmin || currentOrganizationUser?.role === Role.SuperAdmin
      ? [
          {
            name: 'Payments',
            href: `/tuition-reimbursement/list/payments`,
            current: locationPath === `/tuition-reimbursement/list/payments`,
          },
        ]
      : []),
  ];

  return currentOrganizationUser?.adminProfile?.adminPermissions &&
    currentOrganizationUser?.adminProfile?.adminPermissions.includes('tuition_reimbursement_review') ? (
    <div className="my-6 px-8 mx-auto max-w-7xl">
      {currentOrganizationUser?.organization?.tuitionReimbursementEnabled === false ? (
        <div className="mt-4">
          <EmptyTablePlaceholder
            title={'Tuition reimbursement is not enabled for your organization'}
            subtitle={
              'Reach out to the support team if you are interested in managing your tuition reimbursement program with Unsaddl!'
            }
          />
        </div>
      ) : (
        <>
          <AuthenticatedRoute
            path={`/tuition-reimbursement/list`}
            render={() => {
              return (
                <>
                  <h1 className="text-2xl font-bold text-gray-900">Tuition Reimbursement</h1>

                  <div className="mt-6 sm:mt-2 2xl:mt-5 border-b border-gray-200 overflow-x-auto scrollbar-hide">
                    <nav className="flex space-x-8" aria-label="Tabs">
                      {tabs.map((tab) => (
                        <Link
                          key={tab.name}
                          to={tab.href}
                          className={classNames(
                            tab.current
                              ? 'border-purple-500 text-gray-900'
                              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                            'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                          )}
                          aria-current={tab.current ? 'page' : undefined}
                        >
                          {tab.name}
                        </Link>
                      ))}
                    </nav>
                  </div>

                  {(currentOrganizationUser?.role === Role.InternalAdmin ||
                    currentOrganizationUser?.role === Role.SuperAdmin) && (
                    <AuthenticatedRoute
                      path={`/tuition-reimbursement/list/payments`}
                      render={() => {
                        return (
                          <>
                            <ListPayments />
                          </>
                        );
                      }}
                    />
                  )}

                  <AuthenticatedRoute
                    path={`/tuition-reimbursement/list/requests/all`}
                    render={() => {
                      return (
                        <>
                          <ListAllRequests />
                        </>
                      );
                    }}
                  />
                  <AuthenticatedRoute
                    path={`/tuition-reimbursement/list/plans`}
                    render={() => {
                      return (
                        <>
                          <ListPlans />
                        </>
                      );
                    }}
                  />
                </>
              );
            }}
          />
          <AuthenticatedRoute
            path={`/tuition-reimbursement/plans/:tuitionReimbursementPlanId/edit`}
            render={() => {
              return <EditGrants />;
            }}
          />
          <AuthenticatedRoute
            path={`/tuition-reimbursement/plans/:tuitionReimbursementPlanId/view`}
            render={() => {
              return <ViewPlan />;
            }}
            exact
          />
          <AuthenticatedRoute
            path={`/tuition-reimbursement/plans/:tuitionReimbursementPlanId/view/grants/:tuitionReimbursementGrantId`}
            render={() => {
              return <ViewGrant />;
            }}
          />
        </>
      )}
    </div>
  ) : (
    <div className="my-6 px-8 mx-auto max-w-7xl">
      <EmptyPlaceholder
        title="Access denied"
        subtitle="Your organization super admin has not given you permission to access this page"
      />
    </div>
  );
};

export default OrganizationTuitionReimbursementPage;
