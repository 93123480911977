import { InformationCircleIcon, SearchIcon } from '@heroicons/react/outline';
import { Ring } from '@uiball/loaders';
import AssignGrantsCSVReader from 'admin/ContributionPlans/EditGrants/AssignGrants/AssignGrantsCSVReader';
import AssignGrantsTable from 'admin/ContributionPlans/EditGrants/AssignGrants/AssignGrantsTable';
import ProgressBar from 'admin/ContributionPlans/EditGrants/ProgressBar';
import SettingSwitch from 'components/Buttons/SettingSwitch';
import { ContributionPlan } from 'interfaces/contributionsInterfaces';
import { OrganizationUser } from 'interfaces/organizationUserInterfaces';
import React, { useState } from 'react';
import { IFileInfo } from 'react-csv-reader';
import toast from 'react-hot-toast';
import { Prompt, useHistory } from 'react-router-dom';
import { useAsyncDebounce } from 'react-table';
import { calculateDateDiff } from 'utils/dateUtils';

interface AssignGrantsProps {
  contributionPlan: ContributionPlan | undefined;
  participants: OrganizationUser[];

  oldContributionGrantsByGrantee: { [key: string]: number };
  newContributionGrantsByGrantee: { [key: string]: number };

  setNewContributionGrant: (participantId: string, amount: number) => void;
  setNewContributionGrantsByEmail: (grantsByEmail: Array<any>) => void;
}

const AssignGrants: React.FC<AssignGrantsProps> = ({
  contributionPlan,
  participants,
  oldContributionGrantsByGrantee,
  newContributionGrantsByGrantee,
  setNewContributionGrant,
  setNewContributionGrantsByEmail,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [filterStartDateEnabled, setFilterStartDateEnabled] = useState(false);

  const history = useHistory();

  const saveNewContributionGrants = () => {
    history.push(`/plans/${contributionPlan?.id}/edit/review`);
  };

  const cancelContributionGrants = () => {
    history.push('/plans/list');
  };

  const handleCSVGrants = (data: Array<any>, fileInfo: IFileInfo) => {
    setNewContributionGrantsByEmail(data);
    toast.success('Grants imported from CSV!');
  };

  const setSearchTermDebounced = useAsyncDebounce((searchText: string | undefined) => {
    setSearchTerm(searchText || '');
    setIsLoadingSearch(false);
  }, 1000);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoadingSearch(true);
    setSearchTermDebounced(event.target.value);
  };

  let todaysDate = new Date();
  let eligibilityTenureDays = contributionPlan?.eligibilityTenureDays || 0;

  const filteredParticipants = participants.filter(
    (participant) =>
      participant.participantProfile?.startDate &&
      calculateDateDiff(new Date(participant.participantProfile.startDate), todaysDate) >= eligibilityTenureDays
  );

  return (
    <>
      <ProgressBar step={0} />

      <div className="mt-6 ">
        <div className="rounded-md bg-blue-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-blue-800">Editing grants</h3>
              <div className="mt-2 text-sm text-blue-700">
                <ul className="list-disc pl-5 space-y-1">
                  <li>To delete a grant, set the grant amount to $0.</li>
                  <li>
                    If a grant amount is edited, all elections on the grant will be voided, and your employee will need
                    to re-elect their contributions.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="mt-6 flex items-center">
            <h3 className="text-xl font-medium text-gray-900">Set contribution grants</h3>
          </div>

          <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center lg:space-x-4 space-y-2">
            <div className="flex items-center">
              <span className="text-gray-700 mr-1">Upload CSV: </span>
              <AssignGrantsCSVReader onFileLoaded={handleCSVGrants} />
            </div>

            <div className="w-full md:max-w-md">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  {isLoadingSearch ? (
                    <Ring size={15} lineWeight={7} speed={2.0} color="gray" />
                  ) : (
                    <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  )}
                </div>
                <input
                  id="search"
                  name="search"
                  className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-sm"
                  placeholder="Search for a user"
                  type="search"
                  onChange={handleSearch}
                />
              </div>
            </div>
          </div>
          <div className="py-2">
            <SettingSwitch
              switchTitle="Filter by eligibility tenure "
              switchSubtitle={`(${contributionPlan?.eligibilityTenureDays || 0} days)`}
              featureEnabled={filterStartDateEnabled}
              setFeatureEnabled={setFilterStartDateEnabled}
            />
          </div>

          <AssignGrantsTable
            participants={filterStartDateEnabled ? filteredParticipants : participants}
            searchTerm={searchTerm}
            contributionPlan={contributionPlan}
            oldContributionGrantsByGrantee={oldContributionGrantsByGrantee}
            newContributionGrantsByGrantee={newContributionGrantsByGrantee}
            setNewContributionGrant={setNewContributionGrant}
          />
        </div>

        <div className="py-5">
          <div className="flex justify-end">
            <button
              onClick={cancelContributionGrants}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
            <button
              onClick={saveNewContributionGrants}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Review
            </button>
          </div>
        </div>
      </div>

      <Prompt
        when={newContributionGrantsByGrantee.size !== 0}
        message={(params) =>
          params.pathname !== `/plans/${contributionPlan?.id}/edit/review`
            ? 'Your contribution plan has not been saved, are you sure you want to leave?'
            : true
        }
      />
    </>
  );
};

export default AssignGrants;
