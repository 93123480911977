import TuitionReimbursementPlanElement from 'admin/TuitionReimbursement/ListPlans/TuitionReimbursementPlanElement';
import EmptyTablePlaceholder from 'common/EmptyTablePlaceholder';
import { TuitionReimbursementPlan } from 'interfaces/tuitionReimbursementInterfaces';
import React from 'react';

interface ListPlansBodyProps {
  plans: TuitionReimbursementPlan[];
  refreshTuitionReimbursementPlans: () => void;
  selectedPlanStatus: string;
}

const ListPlansBody: React.FC<ListPlansBodyProps> = ({
  plans,
  selectedPlanStatus,
  refreshTuitionReimbursementPlans,
}) => {
  let filteredPlans = plans;

  if (selectedPlanStatus === 'Active') {
    filteredPlans = filteredPlans.filter((plan) => plan.active);
  } else {
    filteredPlans = filteredPlans.filter((plan) => !plan.active);
  }

  return (
    <>
      {filteredPlans.length === 0 ? (
        <div className="my-4">
          <EmptyTablePlaceholder title={'No plans'} subtitle={'There are no existing plans for your organization'} />
        </div>
      ) : (
        <div className="my-6 space-y-4">
          {filteredPlans.map((plan) => (
            <TuitionReimbursementPlanElement
              key={plan.id}
              tuitionReimbursementPlan={plan}
              refreshTuitionReimbursementPlans={refreshTuitionReimbursementPlans}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default ListPlansBody;
