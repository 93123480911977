import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import EmptyTablePlaceholder from 'common/EmptyTablePlaceholder';
import TuitionReimbursementPaymentColumn from 'common/TuitionReimbursement/TuitionReimbursementPaymentColumn';
import Pagination from 'components/Tables/Pagination';
import { TuitionReimbursementPayment } from 'interfaces/tuitionReimbursementInterfaces';
import React, { useEffect } from 'react';
import Highlighter from 'react-highlight-words';
import { CellProps, Column, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { abbrevDate } from 'utils/dateUtils';

interface TuitionReimbursementPaymentsTableProps {
  searchTerm: string;
  tuitionReimbursementPayments: TuitionReimbursementPayment[];
  startViewTuitionReimbursementPayment: (tuitionReimbursementPayment: TuitionReimbursementPayment) => void;
}

const TuitionReimbursementPaymentsTable: React.FC<TuitionReimbursementPaymentsTableProps> = ({
  searchTerm,
  tuitionReimbursementPayments,
  startViewTuitionReimbursementPayment,
}) => {
  const tuitionReimbursementPaymentsMemo = React.useMemo<TuitionReimbursementPayment[]>(
    () => tuitionReimbursementPayments,
    [tuitionReimbursementPayments]
  );
  const columns: Column<TuitionReimbursementPayment>[] = React.useMemo(
    () => [
      {
        id: 'execution-date',
        Header: 'Execution date',
        accessor: (tuitionReimbursementPayment: TuitionReimbursementPayment) => {
          return tuitionReimbursementPayment.createdAt ? new Date(tuitionReimbursementPayment.createdAt) : new Date();
        },
        Cell: (props: CellProps<TuitionReimbursementPayment>) => {
          const tuitionReimbursementPayment = props.row.original;
          const createdAt = abbrevDate(tuitionReimbursementPayment.createdAt);

          return (
            <Highlighter
              className="block font-medium text-gray-900 break-all"
              highlightClassName="bg-yellow-200"
              searchWords={[props.state.globalFilter]}
              autoEscape={true}
              textToHighlight={createdAt}
            />
          );
        },
        className: 'hidden sm:table-cell py-4 text-sm font-medium text-gray-900',
        headerClassName:
          'hidden sm:table-cell py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
      {
        id: 'participant',
        Header: 'Participant',
        accessor: (tuitionReimbursementPayment: TuitionReimbursementPayment) => {
          let recipient =
            tuitionReimbursementPayment?.tuitionReimbursementRequest?.participantProfile?.organizationUser;
          return recipient?.firstName + ' ' + recipient?.lastName + ' ' + recipient?.email;
        },
        Cell: (props: CellProps<TuitionReimbursementPayment>) => {
          const tuitionReimbursementPayment = props.row.original;
          const tuitionReimbursementRequest = tuitionReimbursementPayment.tuitionReimbursementRequest;
          const participant = tuitionReimbursementRequest?.participantProfile?.organizationUser;
          let firstName = participant?.firstName;
          let lastName = participant?.lastName;
          let email = participant?.email;

          return (
            <div className="flex items-center">
              <div>
                <Highlighter
                  className="block font-medium text-gray-900 break-all"
                  highlightClassName="bg-yellow-200"
                  searchWords={[props.state.globalFilter]}
                  autoEscape={true}
                  textToHighlight={`${firstName} ${lastName}`}
                />
                <Highlighter
                  className="block text-gray-500 break-all"
                  highlightClassName="bg-yellow-200"
                  searchWords={[props.state.globalFilter]}
                  autoEscape={true}
                  textToHighlight={`${email}`}
                />
              </div>
            </div>
          );
        },
        className: 'py-4 text-sm font-medium text-gray-900',
        headerClassName: 'py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
      {
        id: 'course-details',
        Header: 'Course details',
        accessor: (tuitionReimbursementPayment: TuitionReimbursementPayment) => {
          const tuitionReimbursementRequest = tuitionReimbursementPayment.tuitionReimbursementRequest;
          return tuitionReimbursementRequest?.courseName + ' ' + tuitionReimbursementRequest?.institutionName;
        },
        Cell: (props: CellProps<TuitionReimbursementPayment>) => {
          const tuitionReimbursementPayment = props.row.original;
          const tuitionReimbursementRequest = tuitionReimbursementPayment.tuitionReimbursementRequest;

          return (
            <div className="flex items-center">
              <div>
                <Highlighter
                  className="block font-medium text-gray-900 break-all"
                  highlightClassName="bg-yellow-200"
                  searchWords={[props.state.globalFilter]}
                  autoEscape={true}
                  textToHighlight={tuitionReimbursementRequest?.courseName || ''}
                />
                <Highlighter
                  className="block text-gray-500 break-all"
                  highlightClassName="bg-yellow-200"
                  searchWords={[props.state.globalFilter]}
                  autoEscape={true}
                  textToHighlight={tuitionReimbursementRequest?.institutionName || ''}
                />
              </div>
            </div>
          );
        },
        className: 'py-4 text-sm font-medium text-gray-900',
        headerClassName: 'py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },

      {
        id: 'payment',
        Header: 'Payment sent / submitted',
        Cell: (props: CellProps<TuitionReimbursementPayment>) => {
          const tuitionReimbursementPayment = props.row.original;
          return <TuitionReimbursementPaymentColumn tuitionReimbursementPayment={tuitionReimbursementPayment} />;
        },
        className: 'py-4 whitespace-nowrap text-sm text-gray-500',
        headerClassName: 'py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
    ],
    []
  ) as any;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex },
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: tuitionReimbursementPaymentsMemo,
      initialState: { pageSize: 10, globalFilter: searchTerm },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setGlobalFilter(searchTerm);
  }, [searchTerm]);

  return (
    <div>
      <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={`${column.headerClassName}`}
                    >
                      {
                        // Render the header
                        column.render('Header')
                      }
                      <span className="inline-flex ml-1">
                        {column.isSorted ? (
                          <span className="w-3 h-3">
                            {column.isSortedDesc ? <ChevronDownIcon /> : <ChevronUpIcon />}
                          </span>
                        ) : (
                          ''
                        )}
                      </span>
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()} className="divide-y divide-gray-200">
          {
            // Loop over the table rows
            page.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr
                  {...row.getRowProps()}
                  onClick={() => startViewTuitionReimbursementPayment(row.original)}
                  className="hover:bg-gray-50 cursor-pointer"
                >
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td {...cell.getCellProps()} className={`${cell.column.className}`}>
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>

      {page.length === 0 && <EmptyTablePlaceholder title="No payments" subtitle="No payments have been made yet." />}
      <Pagination
        pageIndex={pageIndex}
        numPages={pageOptions.length}
        nextPage={nextPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
      />
    </div>
  );
};

export default TuitionReimbursementPaymentsTable;
