import CSVReader, { IFileInfo } from 'react-csv-reader';

interface AssignGrantsCSVReaderProps {
  onFileLoaded: (data: any[], fileInfo: IFileInfo) => void;
}

const AssignGrantsCSVReader: React.FC<AssignGrantsCSVReaderProps> = ({ onFileLoaded }) => {
  return (
    <div className="border rounded-md">
      <CSVReader parserOptions={{ header: false }} onFileLoaded={onFileLoaded} />
    </div>
  );
};

export default AssignGrantsCSVReader;
