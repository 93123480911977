import { Dialog, Transition } from '@headlessui/react';
import { LibraryIcon } from '@heroicons/react/outline';
import {
  apiAdminEditParticipantTuitionReimbursementRequest,
  apiEditParticipantTuitionReimbursementRequest,
} from 'api/tuitionReimbursementAPI';
import LoadingButton from 'components/Buttons/LoadingButton';
import { UserDataContext } from 'context/UserContext';
import { Role } from 'enums/RolesEnum';
import { TuitionReimbursementRequest } from 'interfaces/tuitionReimbursementInterfaces';
import { Fragment, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { formDate } from 'utils/dateUtils';

interface EditTuitionReimbursementRequestModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onCreateRequestSuccess: () => void;
  tuitionReimbursementRequest: TuitionReimbursementRequest;
}

const EditTuitionReimbursementRequestModal: React.FC<EditTuitionReimbursementRequestModalProps> = ({
  open,
  setOpen,
  onCreateRequestSuccess,
  tuitionReimbursementRequest,
}) => {
  const { currentOrganizationUser } = useContext(UserDataContext);

  const [error, setError] = useState('');
  const [isSubmittingEditedRequest, setIsSubmittingEditedRequest] = useState(false);

  const [values, setValues] = useState({
    courseName: '',
    institutionName: '',
    degreeType: '',
    courseDescription: '',
    startDate: '',
    endDate: '',
    creditHours: 0,
    courseCost: 0.0,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleClose = () => {
    setOpen(false);
    setError('');
  };

  const handleSubmitNewRequest = async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsSubmittingEditedRequest(true);

    if (currentOrganizationUser?.role === Role.Participant) {
      apiEditParticipantTuitionReimbursementRequest(
        tuitionReimbursementRequest.id,
        values.courseName,
        values.institutionName,
        values.courseDescription,
        values.startDate,
        values.endDate,
        values.courseCost,
        values.creditHours
      ).then(
        (data) => {
          onCreateRequestSuccess();
          setOpen(false);
          toast.success(`Successfully created new tuition reimbursement request!`);
          setIsSubmittingEditedRequest(false);
        },
        (error) => {
          setError(error.message);
          setIsSubmittingEditedRequest(false);
        }
      );
    } else {
      apiAdminEditParticipantTuitionReimbursementRequest(
        tuitionReimbursementRequest.id,
        values.courseName,
        values.institutionName,
        values.courseDescription,
        values.startDate,
        values.endDate,
        values.courseCost,
        values.creditHours
      ).then(
        (data) => {
          onCreateRequestSuccess();
          setOpen(false);
          toast.success(`Successfully edited tuition reimbursement request!`);
          setIsSubmittingEditedRequest(false);
        },
        (error) => {
          setError(error.message);
          setIsSubmittingEditedRequest(false);
        }
      );
    }
  };

  useEffect(() => {
    setValues({
      courseName: tuitionReimbursementRequest.courseName,
      institutionName: tuitionReimbursementRequest.institutionName,
      degreeType: '',
      courseDescription: tuitionReimbursementRequest.courseDescription
        ? tuitionReimbursementRequest.courseDescription
        : '',
      startDate: formDate(tuitionReimbursementRequest.startDate),
      endDate: formDate(tuitionReimbursementRequest.endDate),
      creditHours: tuitionReimbursementRequest.creditHours,
      courseCost: tuitionReimbursementRequest.courseCost,
    });
  }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={handleClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <form onSubmit={handleSubmitNewRequest}>
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                      <LibraryIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Edit Tuition Reimbursement Request
                      </Dialog.Title>

                      <div className="mt-4">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                          Course name
                        </label>
                        <div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
                          <input
                            type="text"
                            name="courseName"
                            id="courseName"
                            onChange={handleChange}
                            value={values.courseName}
                            className="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                            required
                          />
                        </div>
                      </div>

                      <div className="mt-4">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                          Institution
                        </label>
                        <div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
                          <input
                            type="text"
                            name="institutionName"
                            id="institutionName"
                            onChange={handleChange}
                            value={values.institutionName}
                            className="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                            required
                          />
                        </div>
                      </div>

                      <div className="mt-4">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                          Course description
                        </label>
                        <div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
                          <input
                            type="text"
                            name="courseDescription"
                            id="courseDescription"
                            onChange={handleChange}
                            value={values.courseDescription}
                            className="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                            required
                          />
                        </div>
                      </div>

                      <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">Start date</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <input
                            type="date"
                            name="startDate"
                            id="startDate"
                            onChange={handleChange}
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="0"
                            value={values.startDate}
                            required
                          />
                        </div>
                      </div>

                      <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">End date</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <input
                            type="date"
                            name="endDate"
                            id="endDate"
                            onChange={handleChange}
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="0"
                            value={values.endDate}
                            required
                          />
                        </div>
                      </div>

                      <div className="mt-4">
                        <label htmlFor="courseCost" className="block text-sm font-medium text-gray-700">
                          Course cost
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm">$</span>
                          </div>
                          <input
                            type="number"
                            step=".01"
                            min="0.00"
                            max="10000.00"
                            name="courseCost"
                            id="courseCost"
                            onChange={handleChange}
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                            placeholder="0.00"
                            value={values.courseCost}
                            required
                            aria-describedby="price-currency"
                          />
                          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                              USD
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">Credit hours</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <input
                            type="number"
                            step="1"
                            min="0"
                            max="10000"
                            name="creditHours"
                            id="creditHours"
                            onChange={handleChange}
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                            placeholder="0"
                            value={values.creditHours}
                            required
                          />
                          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm">hours</span>
                          </div>
                        </div>
                      </div>

                      {error !== '' && (
                        <div className="mt-2">
                          <p className="text-sm text-red-500">{error}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <LoadingButton
                    type="submit"
                    styles="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                    text="Submit"
                    loadingText="Processing..."
                    isLoading={isSubmittingEditedRequest}
                  />
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default EditTuitionReimbursementRequestModal;
