import { Dialog, Transition } from '@headlessui/react';
import { UserIcon } from '@heroicons/react/outline';
import { apiUpdateAdminInfo } from 'api/organizationUsersAPI';
import LoadingButton from 'components/Buttons/LoadingButton';
import { OrganizationUser } from 'interfaces/organizationUserInterfaces';
import { Fragment, useState } from 'react';
import toast from 'react-hot-toast';

interface EditAdminModalProps {
  admin: OrganizationUser;
  refreshAdmin: () => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditAdminModal: React.FC<EditAdminModalProps> = ({ admin, refreshAdmin, open, setOpen }) => {
  const [error, setError] = useState('');

  const [isSubmittingAdmin, setIsSubmittingAdmin] = useState(false);

  const [values, setValues] = useState({
    firstName: admin.firstName,
    lastName: admin.lastName,
    userManagementEnabled: admin.adminProfile?.adminPermissions.includes('user_management'),
    paymentPlanManagementEnabled: admin.adminProfile?.adminPermissions.includes('payment_plan_management'),
    tuitionReimbursementReviewEnabled: admin.adminProfile?.adminPermissions.includes('tuition_reimbursement_review'),
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    const checked = e.target.checked;
    setValues((prevValues) => ({ ...prevValues, [name]: checked }));
  };

  const handleClose = () => {
    setOpen(false);
    setError('');
  };

  const handleSubmitAdmin = async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsSubmittingAdmin(true);

    const adminPermissions = [
      ...(values.userManagementEnabled ? ['user_management'] : []),
      ...(values.paymentPlanManagementEnabled ? ['payment_plan_management'] : []),
      ...(values.tuitionReimbursementReviewEnabled ? ['tuition_reimbursement_review'] : []),
    ];

    apiUpdateAdminInfo(admin.id, values.firstName!, values.lastName!, adminPermissions).then(
      (data) => {
        refreshAdmin();
        setOpen(false);
        toast.success(`Successfully updated admin!`);
        setIsSubmittingAdmin(false);
      },
      (error) => {
        toast.error(error.message);
        setError(error.message);
        setIsSubmittingAdmin(false);
      }
    );
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={handleClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full">
              <form onSubmit={handleSubmitAdmin}>
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                      <UserIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Edit Admin
                      </Dialog.Title>

                      <div className="mt-4">
                        <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                          First name
                        </label>
                        <div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
                          <input
                            type="text"
                            name="firstName"
                            id="firstName"
                            onChange={handleChange}
                            value={values.firstName}
                            className="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                            required
                          />
                        </div>
                      </div>

                      <div className="mt-4">
                        <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                          Last name
                        </label>
                        <div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
                          <input
                            type="text"
                            name="lastName"
                            id="lastName"
                            onChange={handleChange}
                            value={values.lastName}
                            className="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div className="mt-4">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                          Admin permissions
                        </label>
                        <div className="mt-4 relative flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              id="userManagementEnabled"
                              name="userManagementEnabled"
                              type="checkbox"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                              checked={values.userManagementEnabled}
                              onChange={handleCheckboxChange}
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label htmlFor="userManagementEnabled" className="font-medium text-gray-700">
                              User management
                            </label>
                          </div>
                        </div>

                        <div className="mt-1 relative flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              id="paymentPlanManagementEnabled"
                              name="paymentPlanManagementEnabled"
                              type="checkbox"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                              checked={values.paymentPlanManagementEnabled}
                              onChange={handleCheckboxChange}
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label htmlFor="paymentPlanManagementEnabled" className="font-medium text-gray-700">
                              Payment plan management
                            </label>
                          </div>
                        </div>

                        <div className="mt-1 relative flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              id="tuitionReimbursementReviewEnabled"
                              name="tuitionReimbursementReviewEnabled"
                              type="checkbox"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                              checked={values.tuitionReimbursementReviewEnabled}
                              onChange={handleCheckboxChange}
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label htmlFor="tuitionReimbursementReviewEnabled" className="font-medium text-gray-700">
                              Tuition reimbursement review
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <LoadingButton
                    type="submit"
                    styles="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                    text="Submit"
                    loadingText="Processing..."
                    isLoading={isSubmittingAdmin}
                  />
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default EditAdminModal;
