import React from 'react';

interface PaginationProps {
  pageIndex: number;
  numPages: number;
  previousPage: () => void;
  nextPage: () => void;
  canPreviousPage: boolean;
  canNextPage: boolean;
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  pageIndex,
  numPages,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  gotoPage,
}) => {
  return (
    <nav
      className="py-3 flex flex-col-reverse sm:flex-row sm:items-center sm:justify-between border-t border-gray-200"
      aria-label="Pagination"
    >
      <div className="ml-auto mt-2 sm:mt-0">
        <p className="text-sm text-gray-700">
          Page <span className="font-medium">{pageIndex + 1}</span> of <span className="font-medium">{numPages}</span> |
          Go to page:{' '}
          <input
            type="number"
            className="border-gray-300 rounded-md text-sm w-20 text-gray-700"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
          />
        </p>
      </div>
      <div className="flex-1 flex justify-between w-full sm:w-auto sm:justify-end">
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Previous
        </button>
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Next
        </button>
      </div>
    </nav>
  );
};

export default Pagination;
