import ActiveOrganizationUsersTable from 'admin/ManageOrganizationUsers/ActiveOrganizationUsers/ActiveOrganizationUsersTable/ActiveOrganizationUsersTable';
import DisableOrganizationUserModal from 'admin/ManageOrganizationUsers/ActiveOrganizationUsers/DisableOrganizationUserModal';
import PageLoad from 'components/Load/PageLoad';
import { OrganizationUser } from 'interfaces/organizationUserInterfaces';
import React, { useState } from 'react';

interface ActiveOrganizationUsersProps {
  searchTerm: string;
  role: string;
  isLoadingUsers: boolean;
  updateOrganizationUser: (organizationUser: OrganizationUser) => void;
  activeOrganizationUsers: OrganizationUser[];
}

const ActiveOrganizationUsers: React.FC<ActiveOrganizationUsersProps> = ({
  searchTerm,
  role,
  isLoadingUsers,
  updateOrganizationUser,
  activeOrganizationUsers,
}) => {
  const [openDisableOrganizationUserModal, setOpenDisableOrganizationUserModal] = useState(false);
  const [toDisableOrganizationUser, setToDisableOrganizationUser] = useState<OrganizationUser>();
  const startDisableOrganizationUser = (participant: OrganizationUser) => {
    setToDisableOrganizationUser(participant);
    setOpenDisableOrganizationUserModal(true);
  };

  const header = role === 'admins' ? 'Disable admin' : 'Disable participant';
  const descripton =
    role === 'admins'
      ? 'Are you sure you want to disable this admin? This admin will no longer be able to manage plans or users in your organization.'
      : 'Are you sure you want to disable this participant? This participant will no longer receive contributions and will no longer be able to access their account.';

  return (
    <>
      <div className="mx-auto">
        {isLoadingUsers ? (
          <PageLoad />
        ) : (
          <ActiveOrganizationUsersTable
            searchTerm={searchTerm}
            activeOrganizationUsers={activeOrganizationUsers}
            startDisableOrganizationUser={startDisableOrganizationUser}
          />
        )}
      </div>
      <DisableOrganizationUserModal
        open={openDisableOrganizationUserModal}
        setOpen={setOpenDisableOrganizationUserModal}
        activeOrganizationUser={toDisableOrganizationUser}
        updateOrganizationUser={updateOrganizationUser}
        header={header}
        description={descripton}
      />
    </>
  );
};

export default ActiveOrganizationUsers;
