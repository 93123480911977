import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { apiDeleteLoanServicerStatement } from 'api/loansAPI';
import LoadingButton from 'components/Buttons/LoadingButton';
import { LoanServicer, LoanServicerStatementUpload } from 'interfaces/loansInterfaces';
import { Fragment, useState } from 'react';
import toast from 'react-hot-toast';

interface DeleteStatementModalProps {
  loanServicer?: LoanServicer;
  statementUpload?: LoanServicerStatementUpload;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onStatementDelete: () => void;
}

const DeleteStatementModal: React.FC<DeleteStatementModalProps> = ({
  loanServicer,
  statementUpload,
  open,
  setOpen,
  onStatementDelete,
}) => {
  const [isDeletingStatement, setIsDeletingStatement] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const deleteStatementUpload = async () => {
    setIsDeletingStatement(true);
    if (loanServicer && statementUpload) {
      apiDeleteLoanServicerStatement(loanServicer.id, statementUpload.id).then(
        (data) => {
          setOpen(false);
          setIsDeletingStatement(false);
          onStatementDelete();
          toast.success('Successfully deleted loan statement!');
        },
        (error) => {
          setIsDeletingStatement(false);
          toast.error('Error deleting loan statement');
        }
      );
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={handleClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Delete loan servicer statement
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete this statement? Any approved matches associated with this
                        statement will be voided. You will need to upload additional statements in order to continue
                        receiving matched contributions from your employer.
                      </p>
                    </div>
                    <div>
                      <h4 className="mt-2 text-lg leading-6 font-medium text-gray-900">{loanServicer?.name}</h4>
                    </div>
                    <div>
                      <h4 className="mt-2 text-lg leading-6 font-medium text-gray-900">
                        {statementUpload?.gcsFileUpload?.fileName}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <LoadingButton
                  type="button"
                  styles="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  text="Delete"
                  isLoading={isDeletingStatement}
                  loadingText="Processing..."
                  onClick={() => deleteStatementUpload()}
                />
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DeleteStatementModal;
