import { Menu } from '@headlessui/react';
import { LoanServicer } from 'interfaces/loansInterfaces';
import React from 'react';
import toast from 'react-hot-toast';
import { classNames } from 'utils/tailwindUtils';

interface LoanServicerSettingsOptionsProps {
  loanServicer: LoanServicer;
  setOpenDeleteLoanServicerModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenManageStatementUploadsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenAddManualLoanAccountModal: React.Dispatch<React.SetStateAction<boolean>>;
  refreshPlaidLoanServicer: (loanServicer: LoanServicer) => Promise<void>;
}

const LoanServicerSettingsOptions: React.FC<LoanServicerSettingsOptionsProps> = ({
  loanServicer,
  setOpenDeleteLoanServicerModal,
  setOpenManageStatementUploadsModal,
  setOpenAddManualLoanAccountModal,
  refreshPlaidLoanServicer,
}) => {
  const triggerRefreshPlaidLoanServicer = () => {
    const refreshPlaidLoanServicerPromise = refreshPlaidLoanServicer(loanServicer);
    toast.promise(refreshPlaidLoanServicerPromise, {
      loading: `Refreshing ${loanServicer.name}...`,
      success: `Refreshed ${loanServicer.name}!`,
      error: `Error refreshing ${loanServicer.name}`,
    });
  };

  return (
    <>
      {loanServicer.plaidItem && (
        <div className="py-1 px-1">
          <Menu.Item>
            {({ active }) => (
              <div
                className={classNames(
                  active ? 'bg-gray-100' : '',
                  'text-gray-700 block px-4 py-2 text-sm text-center cursor-pointer'
                )}
                onClick={() => triggerRefreshPlaidLoanServicer()}
              >
                Refresh
              </div>
            )}
          </Menu.Item>
        </div>
      )}
      {!loanServicer.plaidItem && !loanServicer.verified && (
        <div className="py-1 px-1">
          <Menu.Item>
            {({ active }) => (
              <div
                className={classNames(
                  active ? 'bg-gray-100' : '',
                  'text-gray-700 block px-4 py-2 text-sm text-center cursor-pointer'
                )}
                onClick={() => setOpenAddManualLoanAccountModal(true)}
              >
                Add manual loan account
              </div>
            )}
          </Menu.Item>
        </div>
      )}
      <div className="py-1 px-1">
        <Menu.Item>
          {({ active }) => (
            <div
              className={classNames(
                active ? 'bg-gray-100' : '',
                'text-gray-700 block px-4 py-2 text-sm text-center cursor-pointer'
              )}
              onClick={() => setOpenManageStatementUploadsModal(true)}
            >
              Manage statements
            </div>
          )}
        </Menu.Item>
      </div>
      <div className="py-1 px-1">
        <Menu.Item>
          {({ active }) => (
            <div
              className={classNames(
                active ? 'bg-gray-100' : '',
                'text-red-500 block px-4 py-2 text-sm text-center cursor-pointer'
              )}
              onClick={() => setOpenDeleteLoanServicerModal(true)}
            >
              Delete
            </div>
          )}
        </Menu.Item>
      </div>
    </>
  );
};

export default LoanServicerSettingsOptions;
