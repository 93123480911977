import { MenuAlt2Icon } from '@heroicons/react/outline';
import { UserDataContext } from 'context/UserContext';
import UserDropdown from 'dashboard/UserDropdown';
import logo from 'images/logo-w-title.png';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

interface DashboardHeaderProps {
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ setSidebarOpen }) => {
  const { currentOrganizationUser } = useContext(UserDataContext);
  return (
    <header className="fixed w-full top-0 z-10 flex-shrink-0 flex h-20 bg-white border-b border-gray-200">
      {/* Home Dropdown */}
      <button
        type="button"
        className="px-4 my-auto flex flex-row items-center text-gray-500 focus:outline-none md:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        {/* <div className="flex-shrink-0 flex items-center px-4"> */}
        <img className="h-10 object-scale-down w-auto" src={logo} alt="unsaddl" />
        {/* </div> */}
        <span className="sr-only">Open sidebar</span>
        {currentOrganizationUser?.active && (
          <MenuAlt2Icon className="md:hidden flex-shrink-0 text-gray-700 h-5 w-5" aria-hidden="true" />
        )}
      </button>
      {/* Home Button */}
      <Link className="px-4 my-auto flex-row items-center text-gray-500 focus:outline-none hidden md:block" to="/home">
        <img className="h-10 w-auto" src={logo} alt="unsaddl" />
      </Link>
      <div className="flex-1 flex justify-between px-1">
        <div className="flex-1 flex"> </div>

        <div className="flex items-center">
          {/* Profile dropdown */}
          <UserDropdown />
        </div>
      </div>
    </header>
  );
};

export default DashboardHeader;
