import ViewPlanRunsTable from 'admin/ContributionPlans/ViewPlan/ViewPlanRunsTable';
import EmptyPlaceholder from 'common/EmptyPlaceholder';
import { ContributionPlan } from 'interfaces/contributionsInterfaces';
import React from 'react';

interface ViewPlanRunsProps {
  contributionPlan: ContributionPlan;
}

const ViewPlanRuns: React.FC<ViewPlanRunsProps> = ({ contributionPlan }) => {
  const contributionPlanRuns = contributionPlan.contributionPlanRuns;

  return (
    <>
      {/* Description list */}
      <div className="mt-6 mx-auto">
        {contributionPlanRuns && contributionPlanRuns.length > 0 ? (
          <ViewPlanRunsTable contributionPlanRuns={contributionPlanRuns} />
        ) : (
          <EmptyPlaceholder
            title={'No plan runs'}
            subtitle={'No runs have been executed for this contribution plan yet.'}
          />
        )}
      </div>
    </>
  );
};

export default ViewPlanRuns;
