/* This example requires Tailwind CSS v2.0+ */
import { CheckCircleIcon, ClockIcon } from '@heroicons/react/outline';
import { ContributionGrantRun } from 'interfaces/contributionsInterfaces';
import { formatMoney } from 'utils/numberFormatter';

interface ContributionGrantRunPaymentColumnProps {
  contributionGrantRun: ContributionGrantRun;
}

const ContributionGrantRunPaymentColumn: React.FC<ContributionGrantRunPaymentColumnProps> = ({
  contributionGrantRun,
}) => {
  const loanPayments = contributionGrantRun.loanPayments;
  const savings529Payments = contributionGrantRun.savings529Payments;

  let sentTotal = 0;
  let electedTotal = 0;
  let grantRunAwaitingFunding = false;

  for (const loanPayment of loanPayments) {
    sentTotal = sentTotal + Number(loanPayment.amountSent);
    electedTotal = electedTotal + Number(loanPayment.amountSubmitted);
    grantRunAwaitingFunding = grantRunAwaitingFunding || loanPayment.status === 'awaiting_funding';
  }
  for (const savings529Payment of savings529Payments) {
    sentTotal = sentTotal + Number(savings529Payment.amountSent);
    electedTotal = electedTotal + Number(savings529Payment.amountSubmitted);
    grantRunAwaitingFunding = grantRunAwaitingFunding || savings529Payment.status === 'awaiting_funding';
  }

  return (
    <div className="flex items-center justify-end">
      <div className="flex-col">
        <div className="flex justify-end">
          {sentTotal === electedTotal ? (
            <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-green-100 text-green-800">
              <CheckCircleIcon
                className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                aria-hidden="true"
              />
              {formatMoney(Number(sentTotal))} / {formatMoney(Number(electedTotal))}
            </span>
          ) : (
            <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-yellow-100 text-yellow-800">
              <ClockIcon className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-yellow-400" aria-hidden="true" />
              {formatMoney(Number(sentTotal))} / {formatMoney(Number(electedTotal))}
            </span>
          )}
        </div>
        {grantRunAwaitingFunding && <div className="text-xs text-gray-500">Awaiting employer funding</div>}
      </div>
    </div>
  );
};

export default ContributionGrantRunPaymentColumn;
