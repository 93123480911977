import { Menu } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import MenuOptions from 'components/MenuOptions';
import { LoanAccount } from 'interfaces/loansInterfaces';
import InactiveLoanAccountMenuOptions from 'participant/Accounts/LoanAccount/InactiveLoanAccounts/InactiveLoanAccountMenuOptions';
import React from 'react';
import { formatMoney } from 'utils/numberFormatter';

interface InactiveLoanAccountRowProps {
  loanAccount: LoanAccount;
  startReactivateAccount: (loanAccount: LoanAccount) => void;
}

const InactiveLoanAccountRow: React.FC<InactiveLoanAccountRowProps> = ({ loanAccount, startReactivateAccount }) => {
  return (
    <tr key={loanAccount.id}>
      <td className="py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0">{loanAccount.accountNumber}</td>
      <td className="py-4 px-3 text-sm text-gray-500 hidden xs:table-cell"> {loanAccount.name}</td>
      <td className="py-4 px-3 text-sm text-gray-500 hidden sm:table-cell">
        {' '}
        {loanAccount.interestRatePercentage ? `${loanAccount.interestRatePercentage}%` : '-'}
      </td>
      <td className="py-4 px-3 text-sm text-gray-500">
        {loanAccount.currentBalance ? formatMoney(Number(loanAccount.currentBalance)) : '-'}
      </td>
      <td className="relative py-4 pl-3 text-right text-sm font-medium">
        <MenuOptions
          menuButton={
            <Menu.Button className="group-hover:visible inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <DotsVerticalIcon className="h-5 w-5 text-gray-500" />
            </Menu.Button>
          }
          menuItems={
            <InactiveLoanAccountMenuOptions loanAccount={loanAccount} startReactivateAccount={startReactivateAccount} />
          }
        />
      </td>
    </tr>
  );
};

export default InactiveLoanAccountRow;
