import ViewGrantBreadcrumb from 'admin/TuitionReimbursement/ViewPlan/ViewGrant/ViewGrantBreadcrumb';
import ViewGrantHeader from 'admin/TuitionReimbursement/ViewPlan/ViewGrant/ViewGrantHeader';
import { apiAdminGetTuitionReimbursementGrant } from 'api/tuitionReimbursementAPI';
import EmptyPlaceholder from 'common/EmptyPlaceholder';
import PageLoad from 'components/Load/PageLoad';
import { TuitionReimbursementGrant } from 'interfaces/tuitionReimbursementInterfaces';
import CreateNewTuitionReimbursementRequestModal from 'participant/TuitionReimbursement/CreateNewTuitionReimbursementRequestModal';
import ParticipantTuitionReimbursementRequests from 'participant/TuitionReimbursement/ParticipantTuitionReimbursementRequests';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AuthenticatedRoute from 'routes/AuthenticatedRoute';

interface ViewGrantRouteParams {
  tuitionReimbursementGrantId: string;
}

const ViewGrant: React.FC = () => {
  let { tuitionReimbursementGrantId } = useParams<ViewGrantRouteParams>();

  const [tuitionReimbursementGrant, setTuitionReimbursementGrant] = useState<TuitionReimbursementGrant>();
  const [isLoadingTuitionReimbursementGrant, setIsLoadingTuitionReimbursementGrant] = useState(true);

  const [openCreateNewRequestModal, setOpenCreateNewRequestModal] = useState(false);

  const tuitionReimbursementRequests = tuitionReimbursementGrant?.tuitionReimbursementRequests;
  const tuitionReimbursementPlan = tuitionReimbursementGrant?.tuitionReimbursementPlan;

  const getTuitionReimbursementGrant = async () => {
    setIsLoadingTuitionReimbursementGrant(true);
    apiAdminGetTuitionReimbursementGrant(tuitionReimbursementGrantId).then(
      (data) => {
        setTuitionReimbursementGrant(data.tuitionReimbursementGrant);
        setIsLoadingTuitionReimbursementGrant(false);
      },
      (error) => {
        setIsLoadingTuitionReimbursementGrant(false);
      }
    );
  };

  useEffect(() => {
    getTuitionReimbursementGrant();
  }, []);

  return (
    <>
      {isLoadingTuitionReimbursementGrant ? (
        <PageLoad />
      ) : (
        <>
          <div className="px-8 mt-8 mx-auto max-w-7xl">
            {tuitionReimbursementGrant ? (
              <>
                <ViewGrantBreadcrumb tuitionReimbursementGrant={tuitionReimbursementGrant} />
                <ViewGrantHeader
                  tuitionReimbursementGrant={tuitionReimbursementGrant}
                  setOpenCreateNewRequestModal={setOpenCreateNewRequestModal}
                />

                <AuthenticatedRoute
                  path={`/tuition-reimbursement/plans/${tuitionReimbursementGrant.tuitionReimbursementPlan?.id}/view/grants/${tuitionReimbursementGrant.id}/requests`}
                  render={() => {
                    return (
                      <ParticipantTuitionReimbursementRequests
                        tuitionReimbursementRequests={tuitionReimbursementRequests}
                        getTuitionReimbursementGrant={getTuitionReimbursementGrant}
                      />
                    );
                  }}
                />

                <CreateNewTuitionReimbursementRequestModal
                  tuitionReimbursementGrantId={tuitionReimbursementGrantId}
                  open={openCreateNewRequestModal}
                  setOpen={setOpenCreateNewRequestModal}
                  onCreateRequestSuccess={getTuitionReimbursementGrant}
                  tuitionReimbursementPlan={tuitionReimbursementPlan}
                  unsaddlManaged={tuitionReimbursementPlan?.unsaddlManaged}
                />
              </>
            ) : (
              <EmptyPlaceholder title={'No grant found'} subtitle={'This grant does not exist.'} />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ViewGrant;
