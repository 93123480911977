import DisablePlanModal from 'admin/ContributionPlans/ListPlans/DisablePlanModal';
import EditPlanDetailsModal from 'admin/ContributionPlans/ListPlans/EditPlanDetailsModal';
import ViewPlanBreadcrumb from 'admin/ContributionPlans/ViewPlan/ViewPlanBreadcrumb';
import ViewPlanHeader from 'admin/ContributionPlans/ViewPlan/ViewPlanHeader';
import ViewPlanRuns from 'admin/ContributionPlans/ViewPlan/ViewPlanRuns';
import ViewPlanSummary from 'admin/ContributionPlans/ViewPlan/ViewPlanSummary';
import { apiGetOrganizationPlan } from 'api/contributionsAPI';
import EmptyPlaceholder from 'common/EmptyPlaceholder';
import PageLoad from 'components/Load/PageLoad';

import { ContributionPlan } from 'interfaces/contributionsInterfaces';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AuthenticatedRoute from 'routes/AuthenticatedRoute';

interface ViewPlanRouteParams {
  contributionPlanId: string;
}

const ViewPlan: React.FC = () => {
  let { contributionPlanId } = useParams<ViewPlanRouteParams>();

  const [openDisablePlanModal, setOpenDisablePlanModal] = useState(false);
  const [openEditPlanDetailsModal, setOpenEditPlanDetailsModal] = useState(false);

  const [isLoadingContributionPlan, setIsLoadingContributionPlan] = useState(true);

  const [organizationPlan, setOrganizationPlan] = useState<ContributionPlan>();

  const getOrganizationPlan = async () => {
    setIsLoadingContributionPlan(true);
    apiGetOrganizationPlan(contributionPlanId).then(
      (data) => {
        const organizationPlan: ContributionPlan = data.organizationPlan;
        setOrganizationPlan(organizationPlan);
        setIsLoadingContributionPlan(false);
      },
      (error) => {
        setIsLoadingContributionPlan(false);
      }
    );
  };

  useEffect(() => {
    getOrganizationPlan();
  }, []);

  return (
    <>
      {isLoadingContributionPlan ? (
        <PageLoad />
      ) : (
        <>
          <div className="px-8 mt-8 mx-auto max-w-7xl">
            {organizationPlan ? (
              <>
                <ViewPlanBreadcrumb contributionPlan={organizationPlan} />
                <ViewPlanHeader
                  contributionPlan={organizationPlan}
                  setOpenDisablePlanModal={setOpenDisablePlanModal}
                  setOpenEditPlanDetailsModal={setOpenEditPlanDetailsModal}
                />

                <AuthenticatedRoute
                  path={`/plans/${organizationPlan.id}/view/summary`}
                  render={() => {
                    return <ViewPlanSummary contributionPlan={organizationPlan} />;
                  }}
                />
                <AuthenticatedRoute
                  path={`/plans/${organizationPlan.id}/view/runs`}
                  render={() => {
                    return <ViewPlanRuns contributionPlan={organizationPlan} />;
                  }}
                />

                <DisablePlanModal
                  contributionPlan={organizationPlan}
                  open={openDisablePlanModal}
                  setOpen={setOpenDisablePlanModal}
                  onDisableSuccess={getOrganizationPlan}
                />
                <EditPlanDetailsModal
                  contributionPlan={organizationPlan}
                  open={openEditPlanDetailsModal}
                  setOpen={setOpenEditPlanDetailsModal}
                  onEditPlanSuccess={getOrganizationPlan}
                />
              </>
            ) : (
              <EmptyPlaceholder title={'No plan found'} subtitle={'This plan does not exist.'} />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ViewPlan;
