interface EmptyPlaceholderProps {
  title: string;
  subtitle: string;
}

const EmptyPlaceholder: React.FC<EmptyPlaceholderProps> = ({ title, subtitle }) => {
  return (
    <div className="w-full py-8 mx-auto border-2 border-gray-300 border-dashed rounded-lg">
      <div>
        <div className="text-center">
          <h2 className="mt-2 text-lg font-medium text-gray-900">{title}</h2>
          <p className="mt-1 text-sm text-gray-500">{subtitle}</p>
        </div>
      </div>
    </div>
  );
};

export default EmptyPlaceholder;
