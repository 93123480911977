import DisabledOrganizationUsersTable from 'admin/ManageOrganizationUsers/DisabledOrganizationUsers/DisabledOrganizationUsersTable/DisabledOrganizationUsersTable';
import ReactivateOrganizationUserModal from 'admin/ManageOrganizationUsers/DisabledOrganizationUsers/ReactivateOrganizationUserModal';
import PageLoad from 'components/Load/PageLoad';
import { OrganizationUser } from 'interfaces/organizationUserInterfaces';
import React, { useState } from 'react';

interface DisabledOrganizationUsersProps {
  role: string;
  isLoadingUsers: boolean;
  updateOrganizationUser: (organizationUser: OrganizationUser) => void;
  searchTerm: string;
  disabledOrganizationUsers: OrganizationUser[];
}

const DisabledOrganizationUsers: React.FC<DisabledOrganizationUsersProps> = ({
  role,
  isLoadingUsers,
  updateOrganizationUser,
  searchTerm,
  disabledOrganizationUsers,
}) => {
  const [openReactivateUserModal, setOpenReactivateUserModal] = useState(false);
  const [toReactivateUser, setToReactivateUser] = useState<OrganizationUser>();
  const startReactivateUser = (disabledOrganizationUser: OrganizationUser) => {
    setToReactivateUser(disabledOrganizationUser);
    setOpenReactivateUserModal(true);
  };

  return (
    <>
      <div className="mx-auto">
        {isLoadingUsers ? (
          <PageLoad />
        ) : (
          <DisabledOrganizationUsersTable
            searchTerm={searchTerm}
            disabledOrganizationUsers={disabledOrganizationUsers}
            startReactivateUser={startReactivateUser}
          />
        )}
      </div>
      <ReactivateOrganizationUserModal
        open={openReactivateUserModal}
        setOpen={setOpenReactivateUserModal}
        disabledOrganizationUser={toReactivateUser}
        updateOrganizationUser={updateOrganizationUser}
      />
    </>
  );
};

export default DisabledOrganizationUsers;
