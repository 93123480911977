import { Menu } from '@headlessui/react';
import { PayrollConnection } from 'interfaces/organizationUserInterfaces';
import React from 'react';
import { classNames } from 'utils/tailwindUtils';

interface PayrollConnectionMenuItemsProps {
  payrollConnection: PayrollConnection;
  refreshPayrollConnections: () => void;
  setOpenDeletePayrollConnectionModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const PayrollConnectionMenuItems: React.FC<PayrollConnectionMenuItemsProps> = ({
  payrollConnection,
  refreshPayrollConnections,
  setOpenDeletePayrollConnectionModal,
}) => {
  return (
    <>
      <div className="py-1 px-1">
        <Menu.Item>
          {({ active }) => (
            <a
              className={classNames(
                active ? 'bg-gray-100' : '',
                'text-red-500 block px-4 py-2 text-sm text-center cursor-pointer'
              )}
              onClick={() => {
                setOpenDeletePayrollConnectionModal(true);
              }}
            >
              Delete
            </a>
          )}
        </Menu.Item>
      </div>
    </>
  );
};

export default PayrollConnectionMenuItems;
