import { ChevronDownIcon, ChevronUpIcon, ClockIcon, CurrencyDollarIcon } from '@heroicons/react/outline';
import EmptyTablePlaceholder from 'common/EmptyTablePlaceholder';
import { ContributionGrant } from 'interfaces/contributionsInterfaces';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { CellProps, Column, useSortBy, useTable } from 'react-table';
import { getContributionPlanScheduleText } from 'utils/contributionUtils';
import { formatMoney } from 'utils/numberFormatter';

interface ViewParticipantPlansTableProps {
  contributionGrants: ContributionGrant[];
}

const ViewParticipantPlansTable: React.FC<ViewParticipantPlansTableProps> = ({ contributionGrants }) => {
  const history = useHistory();

  const contributionGrantsMemo = React.useMemo<ContributionGrant[]>(() => contributionGrants, [contributionGrants]);
  const columns: Column<ContributionGrant>[] = React.useMemo(
    () => [
      {
        id: 'plan',
        Header: 'Plan',

        Cell: (props: CellProps<ContributionGrant>) => {
          const grant = props.row.original;
          const contributionPlan = grant.contributionPlan;
          const planName = grant.contributionPlan?.name;

          return (
            <div className="items-center">
              <div className="text-sm font-medium text-gray-900">{planName}</div>

              <div className="flex mt-1">
                <div>
                  {contributionPlan?.active ? (
                    <span className="inline-flex rounded-md bg-green-100 px-2 text-sm leading-5 text-green-800">
                      Active
                    </span>
                  ) : (
                    <span className="inline-flex rounded-md bg-red-100 px-2 text-sm leading-5 text-red-800">
                      Disabled
                    </span>
                  )}
                </div>

                <div className="ml-1">
                  <span className="inline-flex rounded-md bg-sky-100 px-2 text-sm leading-5 text-sky-800">
                    <ClockIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-sky-400" aria-hidden="true" />
                    {getContributionPlanScheduleText(contributionPlan)}
                  </span>
                </div>
              </div>
            </div>
          );
        },
        className: 'py-4 text-sm font-medium text-gray-900',
        headerClassName: 'py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
      {
        id: 'grant-amount',
        Header: 'Grant Amount',
        Cell: (props: CellProps<ContributionGrant>) => {
          const grant = props.row.original;

          return (
            <div className="flex">
              <span className="inline-flex ml-auto px-2 text-xs leading-5 font-semibold rounded-md bg-purple-100 text-purple-800">
                <CurrencyDollarIcon
                  className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-purple-400"
                  aria-hidden="true"
                />
                {formatMoney(Number(grant.amount))}
              </span>
            </div>
          );
        },
        className: 'py-4 whitespace-nowrap text-sm text-gray-500',
        headerClassName: 'py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
    ],
    []
  ) as any;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: contributionGrantsMemo,
    },
    useSortBy
  );

  return (
    <div>
      <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={`${column.headerClassName}`}
                    >
                      {
                        // Render the header
                        column.render('Header')
                      }
                      <span className="inline-flex ml-1">
                        {column.isSorted ? (
                          <span className="w-3 h-3">
                            {column.isSortedDesc ? <ChevronDownIcon /> : <ChevronUpIcon />}
                          </span>
                        ) : (
                          ''
                        )}
                      </span>
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()} className="divide-y divide-gray-200">
          {
            // Loop over the table rows
            rows.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr
                  {...row.getRowProps()}
                  onClick={() => history.push(`/plans/${row.original.contributionPlanId}/view/summary`)}
                  className="hover:bg-gray-50 cursor-pointer"
                >
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td {...cell.getCellProps()} className={`${cell.column.className}`}>
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>

      {rows.length === 0 && (
        <EmptyTablePlaceholder title="No grants" subtitle="This contribution plan does not have any active grants." />
      )}
    </div>
  );
};

export default ViewParticipantPlansTable;
