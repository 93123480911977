import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import EmptyTablePlaceholder from 'common/EmptyTablePlaceholder';
import Pagination from 'components/Tables/Pagination';
import { OrganizationUser } from 'interfaces/organizationUserInterfaces';
import React, { useEffect } from 'react';
import { Column, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

interface ManageOrganizationUsersTableProps {
  searchTerm: string;
  organizationUsers: OrganizationUser[];
  columns: Column<OrganizationUser>[];
}

const ManageOrganizationUsersTable: React.FC<ManageOrganizationUsersTableProps> = ({
  searchTerm,
  organizationUsers,
  columns,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex },
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: organizationUsers,
      initialState: { pageSize: 10, globalFilter: searchTerm },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setGlobalFilter(searchTerm);
  }, [searchTerm]);

  return (
    <div>
      {page.length > 0 ? (
        <div>
          <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
            <thead>
              {
                // Loop over the header rows
                headerGroups.map((headerGroup) => (
                  // Apply the header row props
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {
                      // Loop over the headers in each row
                      headerGroup.headers.map((column) => (
                        // Apply the header cell props
                        <th
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          className={`${column.headerClassName}`}
                        >
                          {
                            // Render the header
                            column.render('Header')
                          }
                          <span className="inline-flex ml-1">
                            {column.isSorted ? (
                              <span className="w-3 h-3">
                                {column.isSortedDesc ? <ChevronDownIcon /> : <ChevronUpIcon />}
                              </span>
                            ) : (
                              ''
                            )}
                          </span>
                        </th>
                      ))
                    }
                  </tr>
                ))
              }
            </thead>
            <tbody {...getTableBodyProps()} className="divide-y divide-gray-200">
              {
                // Loop over the table rows
                page.map((row) => {
                  // Prepare the row for display
                  prepareRow(row);
                  return (
                    // Apply the row props
                    <tr {...row.getRowProps()}>
                      {
                        // Loop over the rows cells
                        row.cells.map((cell) => {
                          // Apply the cell props
                          return (
                            <td {...cell.getCellProps()} className={`${cell.column.className}`}>
                              {
                                // Render the cell contents
                                cell.render('Cell')
                              }
                            </td>
                          );
                        })
                      }
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
          <Pagination
            pageIndex={pageIndex}
            numPages={pageOptions.length}
            nextPage={nextPage}
            previousPage={previousPage}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            gotoPage={gotoPage}
          />
        </div>
      ) : (
        <div className="mt-4">
          <EmptyTablePlaceholder title="No results" subtitle="Your search does not match any rows." />
        </div>
      )}
    </div>
  );
};

export default ManageOrganizationUsersTable;
