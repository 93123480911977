import { SearchIcon } from '@heroicons/react/outline';
import { Ring } from '@uiball/loaders';
import ViewPlanRunBreadcrumb from 'admin/ContributionPlanRuns/ViewPlanRun/ViewPlanRunBreadcrumb';
import ViewPlanRunGrantsTable from 'admin/ContributionPlanRuns/ViewPlanRun/ViewPlanRunGrantsTable';
import ViewPlanRunHeader from 'admin/ContributionPlanRuns/ViewPlanRun/ViewPlanRunHeader';
import ViewPlanRunSummary from 'admin/ContributionPlanRuns/ViewPlanRun/ViewPlanRunSummary';
import { apiGetContributionPlanRun } from 'api/contributionsAPI';
import ViewGrantRunModal from 'common/Contributions/ViewGrantRunModal';
import EmptyPlaceholder from 'common/EmptyPlaceholder';
import PageLoad from 'components/Load/PageLoad';

import { ContributionGrantRun, ContributionPlanRun } from 'interfaces/contributionsInterfaces';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAsyncDebounce } from 'react-table';

interface ViewPlanRunRouteParams {
  contributionPlanRunId: string;
}

const ViewPlanRun: React.FC = () => {
  let { contributionPlanRunId } = useParams<ViewPlanRunRouteParams>();

  const [isLoadingContributionPlanRun, setIsLoadingContributionPlanRun] = useState(false);
  const [contributionPlanRun, setContributionPlanRun] = useState<ContributionPlanRun>();
  const [viewGrantRun, setViewGrantRun] = useState<ContributionGrantRun>();
  const [openViewGrantRunModal, setOpenViewGrantRunModal] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);

  const contributionPlan = contributionPlanRun?.contributionPlan;

  const startViewGrantRun = (contributionGrantRun: ContributionGrantRun) => {
    setViewGrantRun(contributionGrantRun);
    setOpenViewGrantRunModal(true);
  };

  const getContributionPlanRun = async () => {
    setIsLoadingContributionPlanRun(true);
    apiGetContributionPlanRun(contributionPlanRunId).then(
      (data) => {
        const loadedContributionPlanRun: ContributionPlanRun = data.contributionPlanRun;
        setContributionPlanRun(loadedContributionPlanRun);
        setIsLoadingContributionPlanRun(false);
      },
      (error) => {
        setIsLoadingContributionPlanRun(false);
      }
    );
  };

  const setSearchTermDebounced = useAsyncDebounce((searchText: string | undefined) => {
    setSearchTerm(searchText || '');
    setIsLoadingSearch(false);
  }, 1000);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoadingSearch(true);
    setSearchTermDebounced(event.target.value);
  };

  useEffect(() => {
    getContributionPlanRun();
  }, []);

  return (
    <>
      {isLoadingContributionPlanRun ? (
        <PageLoad />
      ) : (
        <>
          <div className="my-6 px-8 mx-auto max-w-7xl">
            {contributionPlanRun ? (
              <>
                <ViewPlanRunBreadcrumb contributionPlanRun={contributionPlanRun} />
                <ViewPlanRunHeader contributionPlanRun={contributionPlanRun} />
                <ViewPlanRunSummary contributionPlanRun={contributionPlanRun} />

                <div className="flex flex-col mt-4 md:flex-row md:justify-between md:items-center md:space-x-4 space-y-2">
                  <h1 className="text-xl font-bold text-gray-900">Grants</h1>
                  <div className="w-full md:max-w-md">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        {isLoadingSearch ? (
                          <Ring size={15} lineWeight={7} speed={2.0} color="gray" />
                        ) : (
                          <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        )}
                      </div>
                      <input
                        id="search"
                        name="search"
                        className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-sm"
                        placeholder="Search for a user"
                        type="search"
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                </div>
                <ViewPlanRunGrantsTable
                  searchTerm={searchTerm}
                  startViewGrantRun={startViewGrantRun}
                  contributionPlanRun={contributionPlanRun}
                />
                <ViewGrantRunModal
                  recipientOrganizationUser={viewGrantRun?.participantProfile?.organizationUser}
                  contributionPlan={contributionPlan}
                  contributionGrantRun={viewGrantRun}
                  open={openViewGrantRunModal}
                  setOpen={setOpenViewGrantRunModal}
                />
              </>
            ) : (
              <EmptyPlaceholder title={'No plan run found'} subtitle={'This plan run does not exist.'} />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ViewPlanRun;
