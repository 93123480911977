import { InformationCircleIcon } from '@heroicons/react/outline';
import { apiGetParticipantGrants } from 'api/contributionsAPI';
import EmptyPlaceholder from 'common/EmptyPlaceholder';
import EmptyTablePlaceholder from 'common/EmptyTablePlaceholder';
import PageLoad from 'components/Load/PageLoad';
import { UserDataContext } from 'context/UserContext';
import { ContributionGrant } from 'interfaces/contributionsInterfaces';
import ParticipantPlanElement from 'participant/ContributionPlans/ParticipantPlanElement';

import React, { useContext, useEffect, useState } from 'react';

const ParticipantPlansPage: React.FC = () => {
  const { currentOrganizationUser } = useContext(UserDataContext);
  const [participantGrants, setParticipantGrants] = useState<ContributionGrant[]>([]);
  const [isLoadingParticipantGrants, setIsLoadingParticipantGrants] = useState(true);

  const getParticipantGrants = async () => {
    setIsLoadingParticipantGrants(true);
    apiGetParticipantGrants().then(
      (data) => {
        setParticipantGrants(data.participantGrants);
        setIsLoadingParticipantGrants(false);
      },
      (error) => {
        setIsLoadingParticipantGrants(false);
      }
    );
  };

  useEffect(() => {
    getParticipantGrants();
  }, []);

  return (
    <>
      {isLoadingParticipantGrants ? (
        <PageLoad />
      ) : (
        <div className="my-6 px-8 mx-auto max-w-7xl">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Contribution Plans</h1>

            {currentOrganizationUser?.organization?.studentLoanRepaymentEnabled === false ? (
              <div className="mt-4">
                <EmptyTablePlaceholder
                  title={'Student loan and 529 contributions are not enabled for your organization'}
                  subtitle={
                    'Reach out to your organization administrator or Unsaddl support if you believe this is a mistake.'
                  }
                />
              </div>
            ) : (
              <>
                {' '}
                <div className="rounded-md bg-blue-50 p-4 mt-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-blue-800">Disclosure about student loan payments</h3>
                      <div className="mt-2 text-sm text-blue-700">
                        <p>
                          Unsaddl will provide as much information as possible to your loan servicer to specify the
                          exact loans that should receive payments based on your elections. However, your loan servicer
                          has final determination on how payments are distributed. You may see your payments allocated
                          to the highest interest rate loan or split evenly across all loans in your account. Contact
                          your loan servicer to learn more about how they process student loan payments from third
                          parties.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <>
                  {participantGrants.length === 0 ? (
                    <div className="my-6">
                      <EmptyPlaceholder
                        title={'No plans'}
                        subtitle={'Your employer has not enrolled you in any payment plans.'}
                      />
                    </div>
                  ) : (
                    <div className="mt-6 space-y-4">
                      {participantGrants.map((participantGrant) => (
                        <ParticipantPlanElement key={participantGrant.id} participantGrant={participantGrant} />
                      ))}
                    </div>
                  )}
                </>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ParticipantPlansPage;
