import {
  AcademicCapIcon,
  BriefcaseIcon,
  ClipboardIcon,
  CollectionIcon,
  CurrencyDollarIcon,
  HomeIcon,
  IdentificationIcon,
  LibraryIcon,
  ReceiptTaxIcon,
  TrendingUpIcon,
  UsersIcon,
} from '@heroicons/react/outline';
import { RefreshIcon } from '@heroicons/react/solid';
import { UserDataContext } from 'context/UserContext';
import DesktopSidebar from 'dashboard/DesktopSidebar';
import DisabledOrganizationUserBlocker from 'dashboard/DisabledOrganizationUserBlocker';
import MobileSidebar from 'dashboard/MobileSidebar';
import { Role } from 'enums/RolesEnum';
import { useContext } from 'react';
import { Switch } from 'react-router';
import { useLocation } from 'react-router-dom';
import AdminRoutes from 'routes/AdminRoutes';
import AuthenticatedRoute from 'routes/AuthenticatedRoute';
import ParticipantRoutes from 'routes/ParticipantRoutes';

type HeroIcon = (props: React.ComponentProps<'svg'>) => JSX.Element;

export interface NavigationLink {
  name: string;
  href: string;
  icon: HeroIcon;
  current: boolean;
}

interface DashboardBodyProps {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DashboardBody: React.FC<DashboardBodyProps> = ({ sidebarOpen, setSidebarOpen }) => {
  const { currentOrganizationUser } = useContext(UserDataContext);
  const organizationId = currentOrganizationUser?.organizationId;
  const location = useLocation();
  const locationPath = location.pathname;

  const adminNav: NavigationLink[] = [
    { name: 'Home', href: '/home', icon: HomeIcon, current: locationPath.startsWith('/home') },
    {
      name: `Contribution Plans`,
      href: '/plans/list',
      icon: CollectionIcon,
      current: locationPath.startsWith('/plans'),
    },
    {
      name: `Plan Runs`,
      href: '/runs/list/processing',
      icon: RefreshIcon,
      current: locationPath.startsWith('/runs'),
    },
    {
      name: 'Tuition Reimbursement',
      href: '/tuition-reimbursement/list/plans',
      icon: LibraryIcon,
      current: locationPath.startsWith('/tuition-reimbursement'),
    },
    ...(organizationId === '855e8c98-1b7b-488c-ad6c-2a59c3036d28' ||
    organizationId === 'dfc538fe-4108-421f-8d91-dcf5bce9a44d' ||
    organizationId === 'b892b3de-249c-4d27-a72a-bb538fa3e1e3'
      ? [
          {
            name: '401(k) Match',
            href: '/401k-match/list',
            icon: TrendingUpIcon,
            current: locationPath.startsWith('/401k-match'),
          },
        ]
      : []),
    ...(organizationId === '855e8c98-1b7b-488c-ad6c-2a59c3036d28' ||
    organizationId === 'dfc538fe-4108-421f-8d91-dcf5bce9a44d'
      ? [
          {
            name: 'HSA',
            href: '/hsa/list',
            icon: ClipboardIcon,
            current: locationPath.startsWith('/hsa'),
          },
        ]
      : []),

    {
      name: 'Participants',
      href: '/participants/list',
      icon: UsersIcon,
      current: locationPath.startsWith('/participants'),
    },

    { name: 'Admins', href: '/admins/list', icon: BriefcaseIcon, current: locationPath.startsWith('/admins') },
    {
      name: 'Connections',
      href: '/connections',
      icon: IdentificationIcon,
      current: locationPath.startsWith('/connections'),
    },
  ];

  const participantNav: NavigationLink[] = [
    { name: 'Home', href: '/home', icon: HomeIcon, current: locationPath.startsWith('/home') },
    {
      name: 'Connected Accounts',
      href: '/accounts',
      icon: AcademicCapIcon,
      current: locationPath.startsWith('/accounts'),
    },
    {
      name: 'Contribution Plans',
      href: '/plans',
      icon: CollectionIcon,
      current: locationPath.startsWith('/plans'),
    },
    {
      name: 'Tuition Reimbursement',
      href: '/tuition-reimbursement/list/grants',
      icon: LibraryIcon,
      current: locationPath.startsWith('/tuition-reimbursement'),
    },
    {
      name: 'Payments',
      href: '/payments/student-loans',
      icon: CurrencyDollarIcon,
      current: locationPath.startsWith('/payments'),
    },
    {
      name: 'Student Loan Refinancing',
      href: '/student-loan-refinancing',
      icon: ReceiptTaxIcon,
      current: locationPath.startsWith('/student-loan-refinancing'),
    },
    ...(organizationId === '855e8c98-1b7b-488c-ad6c-2a59c3036d28' ||
    organizationId === 'dfc538fe-4108-421f-8d91-dcf5bce9a44d'
      ? [
          {
            name: 'HSA',
            href: '/hsa/contributions',
            icon: ClipboardIcon,
            current: locationPath.startsWith('/hsa'),
          },
        ]
      : []),
    // { name: 'Transactions', href: '#', icon: SwitchVerticalIcon, current: false },
  ];

  const role = currentOrganizationUser?.role;

  const dashboardNav =
    role === Role.Admin || role === Role.SuperAdmin || role === Role.InternalAdmin ? adminNav : participantNav;

  return (
    <>
      {!currentOrganizationUser?.active ? (
        <Switch>
          <AuthenticatedRoute
            path="/"
            render={() => {
              return <DisabledOrganizationUserBlocker />;
            }}
          />
        </Switch>
      ) : (
        <>
          <DesktopSidebar dashboardNav={dashboardNav} />
          <MobileSidebar dashboardNav={dashboardNav} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          {/* Main Column */}
          <div className="md:pl-64 pt-20 flex flex-col flex-1">
            <main>
              <Switch>
                {role === Role.Admin || role === Role.SuperAdmin || role === Role.InternalAdmin ? (
                  <AdminRoutes />
                ) : (
                  <ParticipantRoutes />
                )}
              </Switch>
            </main>
          </div>
        </>
      )}
    </>
  );
};

export default DashboardBody;
