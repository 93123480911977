import { Menu } from '@headlessui/react';
import { Role } from 'enums/RolesEnum';
import { OrganizationUser } from 'interfaces/organizationUserInterfaces';
import React from 'react';
import { Link } from 'react-router-dom';
import { classNames } from 'utils/tailwindUtils';

interface DisabledOrganizationUserMenuOptionsProps {
  disabledOrganizationUser: OrganizationUser;
  startReactivateUser: (disabledOrganizationUser: OrganizationUser) => void;
}

const DisabledOrganizationUserMenuOptions: React.FC<DisabledOrganizationUserMenuOptionsProps> = ({
  disabledOrganizationUser,
  startReactivateUser,
}) => {
  return (
    <>
      {disabledOrganizationUser.role === Role.Participant && (
        <div className="py-1 px-1">
          <Menu.Item>
            {({ active }) => (
              <Link
                className={classNames(
                  active ? 'bg-gray-100' : '',
                  'text-black block px-4 py-2 text-sm text-center cursor-pointer'
                )}
                to={`/participants/${disabledOrganizationUser.id}/view/profile`}
              >
                View profile
              </Link>
            )}
          </Menu.Item>
        </div>
      )}
      <div className="py-1 px-1">
        <Menu.Item>
          {({ active }) => (
            <a
              className={classNames(
                active ? 'bg-gray-100' : '',
                'text-gray-700 block px-4 py-2 text-sm text-center cursor-pointer'
              )}
              onClick={() => {
                startReactivateUser(disabledOrganizationUser);
              }}
            >
              Reactivate
            </a>
          )}
        </Menu.Item>
      </div>
    </>
  );
};

export default DisabledOrganizationUserMenuOptions;
