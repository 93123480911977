import { ChevronRightIcon, HomeIcon } from '@heroicons/react/solid';
import { ContributionPlanRun } from 'interfaces/contributionsInterfaces';
import React from 'react';
import { Link } from 'react-router-dom';
import { abbrevDate } from 'utils/dateUtils';

interface ViewPlanRunBreadcrumbProps {
  contributionPlanRun: ContributionPlanRun;
}

const ViewPlanRunBreadcrumb: React.FC<ViewPlanRunBreadcrumbProps> = ({ contributionPlanRun }) => {
  const contributionPlan = contributionPlanRun.contributionPlan;
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div>
            <Link to="/" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        <li>
          <div className="flex items-center">
            <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
            <Link to="/runs/list/processing" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
              Plan runs
            </Link>
          </div>
        </li>
        <li>
          <div className="flex items-center">
            <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
            <div className="ml-4 text-sm font-medium text-gray-500">
              {contributionPlan?.name}, {abbrevDate(contributionPlanRun.createdAt)}
            </div>
          </div>
        </li>
      </ol>
    </nav>
  );
};

export default ViewPlanRunBreadcrumb;
