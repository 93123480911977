import { Dialog, Transition } from '@headlessui/react';
import { UserAddIcon } from '@heroicons/react/outline';
import { apiCreateOrganizationUserInvite } from 'api/organizationUsersAPI';
import LoadingButton from 'components/Buttons/LoadingButton';
import { Role } from 'enums/RolesEnum';
import { OrganizationUser } from 'interfaces/organizationUserInterfaces';
import { Fragment, useState } from 'react';
import toast from 'react-hot-toast';

interface InviteOrganizationUsersModalProps {
  role: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  addOrganizationUser: (organizationUser: OrganizationUser) => void;
}

const InviteOrganizationUsersModal: React.FC<InviteOrganizationUsersModalProps> = ({
  role,
  open,
  setOpen,
  addOrganizationUser,
}) => {
  const [inviteErrorMessage, setInviteErrorMessage] = useState('');
  const [isSubmittingInvite, setIsSubmittingInvite] = useState(false);

  const submitRole = role === 'participants' ? Role.Participant : Role.Admin;

  const [values, setValues] = useState({
    email: '',
    firstName: '',
    lastName: '',
    userManagementEnabled: false,
    paymentPlanManagementEnabled: false,
    tuitionReimbursementReviewEnabled: false,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    const checked = e.target.checked;
    setValues((prevValues) => ({ ...prevValues, [name]: checked }));
  };

  const handleSubmitInvite = async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmittingInvite(true);

    const adminPermissions = [
      ...(values.userManagementEnabled ? ['user_management'] : []),
      ...(values.paymentPlanManagementEnabled ? ['payment_plan_management'] : []),
      ...(values.tuitionReimbursementReviewEnabled ? ['tuition_reimbursement_review'] : []),
    ];

    apiCreateOrganizationUserInvite(values.firstName, values.lastName, values.email, submitRole, adminPermissions).then(
      (data) => {
        setOpen(false);
        setInviteErrorMessage('');
        toast.success(`Successfully sent invite to ${values.firstName} ${values.lastName} (${values.email})!`);
        setValues({
          email: '',
          firstName: '',
          lastName: '',
          userManagementEnabled: false,
          paymentPlanManagementEnabled: false,
          tuitionReimbursementReviewEnabled: false,
        });
        const newOrganizationUser = data.invitedOrganizationUser;
        addOrganizationUser(newOrganizationUser);
        setIsSubmittingInvite(false);
      },
      (error) => {
        setInviteErrorMessage(error.message);
        setIsSubmittingInvite(false);
      }
    );
  };

  const handleClose = () => {
    setOpen(false);
    setInviteErrorMessage('');
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={handleClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full">
              <form onSubmit={handleSubmitInvite}>
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                      <UserAddIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        {`Invite ${role} to Unsaddl`}
                      </Dialog.Title>

                      <div className="mt-4">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                          Email
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <input
                            type="email"
                            name="email"
                            id="email"
                            value={values.email}
                            onChange={handleChange}
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="you@example.com"
                            required
                          />
                        </div>
                      </div>

                      <div className="mt-4">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                          First Name
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <input
                            type="text"
                            name="firstName"
                            id="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder=""
                            required
                          />
                        </div>
                      </div>

                      <div className="mt-4">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                          Last Name
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <input
                            type="text"
                            name="lastName"
                            id="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder=""
                            required
                          />
                        </div>
                      </div>

                      {submitRole === Role.Admin && (
                        <div className="mt-4">
                          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Admin permissions
                          </label>
                          <div className="mt-4 relative flex items-start">
                            <div className="flex items-center h-5">
                              <input
                                id="userManagementEnabled"
                                name="userManagementEnabled"
                                type="checkbox"
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                checked={values.userManagementEnabled}
                                onChange={handleCheckboxChange}
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label htmlFor="userManagementEnabled" className="font-medium text-gray-700">
                                User management
                              </label>
                            </div>
                          </div>

                          <div className="mt-1 relative flex items-start">
                            <div className="flex items-center h-5">
                              <input
                                id="paymentPlanManagementEnabled"
                                name="paymentPlanManagementEnabled"
                                type="checkbox"
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                checked={values.paymentPlanManagementEnabled}
                                onChange={handleCheckboxChange}
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label htmlFor="paymentPlanManagementEnabled" className="font-medium text-gray-700">
                                Payment plan management
                              </label>
                            </div>
                          </div>

                          <div className="mt-1 relative flex items-start">
                            <div className="flex items-center h-5">
                              <input
                                id="tuitionReimbursementReviewEnabled"
                                name="tuitionReimbursementReviewEnabled"
                                type="checkbox"
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                checked={values.tuitionReimbursementReviewEnabled}
                                onChange={handleCheckboxChange}
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label htmlFor="tuitionReimbursementReviewEnabled" className="font-medium text-gray-700">
                                Tuition reimbursement review
                              </label>
                            </div>
                          </div>
                        </div>
                      )}

                      {inviteErrorMessage !== '' && (
                        <div className="mt-2">
                          <p className="text-sm text-red-500">{inviteErrorMessage}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <LoadingButton
                    type="submit"
                    styles="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                    text="Submit"
                    loadingText="Processing..."
                    isLoading={isSubmittingInvite}
                  />
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default InviteOrganizationUsersModal;
