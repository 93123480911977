import { UserProvider } from 'context/UserContext';
import IdentityGateway from 'identity/IdentityGateway';
import SignInPage from 'identity/SignInPage';
import React from 'react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import AuthenticatedRoute from 'routes/AuthenticatedRoute';
import UnauthenticatedRoute from './routes/UnauthenticatedRoute';

const App: React.FC = () => {
  return (
    <>
      <UserProvider>
        <Router>
          <Switch>
            <UnauthenticatedRoute
              path="/login"
              render={() => {
                return <SignInPage />;
              }}
              exact
            />
            <AuthenticatedRoute
              path="/"
              render={() => {
                return <IdentityGateway />;
              }}
            />
          </Switch>
        </Router>
      </UserProvider>
      <Toaster
        toastOptions={{
          // Define default options
          className: '',
          duration: 4000,
        }}
      />
    </>
  );
};

export default App;
