import { CheckCircleIcon, ChevronDownIcon, ChevronUpIcon, ClockIcon, XIcon } from '@heroicons/react/outline';
import EmptyTablePlaceholder from 'common/EmptyTablePlaceholder';
import Pagination from 'components/Tables/Pagination';
import { TuitionReimbursementRequest } from 'interfaces/tuitionReimbursementInterfaces';
import React, { useEffect } from 'react';
import Highlighter from 'react-highlight-words';
import { CellProps, Column, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { abbrevDate } from 'utils/dateUtils';

interface ListRequestsTableProps {
  searchTerm: string;
  tuitionReimbursementRequests: TuitionReimbursementRequest[];
  startRequestSlideover: (tuitionReimbursementRequestId: string) => void;
}

const ListRequestsTable: React.FC<ListRequestsTableProps> = ({
  searchTerm,
  tuitionReimbursementRequests,
  startRequestSlideover,
}) => {
  const tuitionReimbursementRequestsMemo = React.useMemo<TuitionReimbursementRequest[]>(
    () => tuitionReimbursementRequests,
    [tuitionReimbursementRequests]
  );
  const columns: Column<TuitionReimbursementRequest>[] = React.useMemo(
    () => [
      {
        id: 'created-at',
        Header: 'Created at',
        accessor: (tuitionReimbursementRequest: TuitionReimbursementRequest) => {
          return tuitionReimbursementRequest.createdAt ? new Date(tuitionReimbursementRequest.createdAt) : new Date();
        },
        Cell: (props: CellProps<TuitionReimbursementRequest>) => {
          const tuitionReimbursementRequest = props.row.original;
          const createdAt = abbrevDate(tuitionReimbursementRequest.createdAt);

          return (
            <Highlighter
              className="block font-medium text-gray-900 break-all"
              highlightClassName="bg-yellow-200"
              searchWords={[props.state.globalFilter]}
              autoEscape={true}
              textToHighlight={createdAt}
            />
          );
        },
        className: 'py-4 text-sm font-medium text-gray-900',
        headerClassName: 'py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
      {
        id: 'participant',
        Header: 'Participant',
        accessor: (tuitionReimbursementRequest: TuitionReimbursementRequest) => {
          let recipient = tuitionReimbursementRequest.participantProfile?.organizationUser;
          return recipient?.firstName + ' ' + recipient?.lastName + ' ' + recipient?.email;
        },
        Cell: (props: CellProps<TuitionReimbursementRequest>) => {
          const tuitionReimbursementRequest = props.row.original;
          const participant = tuitionReimbursementRequest.participantProfile?.organizationUser;
          let firstName = participant?.firstName;
          let lastName = participant?.lastName;
          let email = participant?.email;

          return (
            <div className="flex items-center">
              <div>
                <Highlighter
                  className="block font-medium text-gray-900 break-all"
                  highlightClassName="bg-yellow-200"
                  searchWords={[props.state.globalFilter]}
                  autoEscape={true}
                  textToHighlight={`${firstName} ${lastName}`}
                />
                <Highlighter
                  className="block text-gray-500 break-all"
                  highlightClassName="bg-yellow-200"
                  searchWords={[props.state.globalFilter]}
                  autoEscape={true}
                  textToHighlight={`${email}`}
                />
              </div>
            </div>
          );
        },
        className: 'py-4 text-sm font-medium text-gray-900',
        headerClassName: 'py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
      {
        id: 'course-details',
        Header: 'Course details',
        accessor: (tuitionReimbursementRequest: TuitionReimbursementRequest) => {
          return tuitionReimbursementRequest?.courseName + ' ' + tuitionReimbursementRequest?.institutionName;
        },
        Cell: (props: CellProps<TuitionReimbursementRequest>) => {
          const tuitionReimbursementRequest = props.row.original;

          return (
            <div className="flex items-center">
              <div>
                <Highlighter
                  className="block font-medium text-gray-900 break-all"
                  highlightClassName="bg-yellow-200"
                  searchWords={[props.state.globalFilter]}
                  autoEscape={true}
                  textToHighlight={tuitionReimbursementRequest.courseName}
                />
                <Highlighter
                  className="block text-gray-500 break-all"
                  highlightClassName="bg-yellow-200"
                  searchWords={[props.state.globalFilter]}
                  autoEscape={true}
                  textToHighlight={tuitionReimbursementRequest.institutionName}
                />
              </div>
            </div>
          );
        },
        className: 'py-4 text-sm font-medium text-gray-900',
        headerClassName: 'py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
      {
        id: 'status',
        Header: 'Status',
        Cell: (props: CellProps<TuitionReimbursementRequest>) => {
          const tuitionReimbursementRequest = props.row.original;
          const reviewStatus = tuitionReimbursementRequest.reviewStatus;
          return (
            <>
              {reviewStatus === 'submitted' ? (
                <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-yellow-100 text-yellow-800">
                  <ClockIcon
                    className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                  Submitted for review...
                </span>
              ) : reviewStatus === 'course_approved' ? (
                <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-yellow-100 text-yellow-800">
                  <ClockIcon
                    className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                  Awaiting course completion...
                </span>
              ) : reviewStatus === 'course_denied' ? (
                <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-red-100 text-red-800">
                  <XIcon className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-red-400" aria-hidden="true" />
                  Course denied
                </span>
              ) : reviewStatus === 'course_completed' ? (
                <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-yellow-100 text-yellow-800">
                  <ClockIcon
                    className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                  Course completion awaiting review...
                </span>
              ) : reviewStatus === 'completion_approved' ? (
                <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-yellow-100 text-yellow-800">
                  <ClockIcon
                    className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                  Payment processing
                </span>
              ) : reviewStatus === 'completion_denied' ? (
                <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-red-100 text-red-800">
                  <XIcon className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-red-400" aria-hidden="true" />
                  Completion denied
                </span>
              ) : (
                <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-green-100 text-green-800">
                  <CheckCircleIcon
                    className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                    aria-hidden="true"
                  />
                  Paid
                </span>
              )}
            </>
          );
        },
        className: 'py-4 whitespace-nowrap text-sm text-gray-500',
        headerClassName: 'py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
    ],
    []
  ) as any;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex },
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: tuitionReimbursementRequestsMemo,
      initialState: { pageSize: 10, globalFilter: searchTerm },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setGlobalFilter(searchTerm);
  }, [searchTerm]);

  return (
    <div>
      <>
        <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        className={`${column.headerClassName}`}
                      >
                        {
                          // Render the header
                          column.render('Header')
                        }
                        <span className="inline-flex ml-1">
                          {column.isSorted ? (
                            <span className="w-3 h-3">
                              {column.isSortedDesc ? <ChevronDownIcon /> : <ChevronUpIcon />}
                            </span>
                          ) : (
                            ''
                          )}
                        </span>
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          <tbody {...getTableBodyProps()} className="divide-y divide-gray-200">
            {
              // Loop over the table rows
              page.map((row) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr
                    {...row.getRowProps()}
                    onClick={() => startRequestSlideover(row.original.id)}
                    className="hover:bg-gray-50 cursor-pointer"
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td {...cell.getCellProps()} className={`${cell.column.className}`}>
                            {
                              // Render the cell contents
                              cell.render('Cell')
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>

        {page.length === 0 && (
          <EmptyTablePlaceholder title="No requests" subtitle="No tuition reimbursement requests" />
        )}

        <Pagination
          pageIndex={pageIndex}
          numPages={pageOptions.length}
          nextPage={nextPage}
          previousPage={previousPage}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          gotoPage={gotoPage}
        />
      </>
    </div>
  );
};

export default ListRequestsTable;
