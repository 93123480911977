/* This example requires Tailwind CSS v2.0+ */
import { CurrencyDollarIcon, ReceiptTaxIcon } from '@heroicons/react/outline';
import { ContributionGrantRun } from 'interfaces/contributionsInterfaces';
import { formatMoney } from 'utils/numberFormatter';

interface ContributionGrantRunGrantedColumnProps {
  contributionGrantRun: ContributionGrantRun;
}

const ContributionGrantRunGrantedColumn: React.FC<ContributionGrantRunGrantedColumnProps> = ({
  contributionGrantRun,
}) => {
  return (
    <div className="flex-col space-y-1">
      <div className="flex justify-end">
        <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-purple-100 text-purple-800">
          <CurrencyDollarIcon
            className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-purple-400"
            aria-hidden="true"
          />
          {formatMoney(Number(contributionGrantRun.amountGranted))}
        </span>
      </div>
      {contributionGrantRun.employeeMatchRequired && (
        <div className="flex justify-end">
          <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-sky-100 text-sky-800">
            <ReceiptTaxIcon className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-sky-400" aria-hidden="true" />
            {contributionGrantRun.matchPercentage}% match (
            {formatMoney(Number(contributionGrantRun.matchableEmployeeContribution))} eligible)
          </span>
        </div>
      )}
    </div>
  );
};

export default ContributionGrantRunGrantedColumn;
