import { Menu } from '@headlessui/react';
import { LoanAccount } from 'interfaces/loansInterfaces';
import React from 'react';
import { classNames } from 'utils/tailwindUtils';

interface InactiveLoanAccountMenuOptionsProps {
  loanAccount: LoanAccount;
  startReactivateAccount: (loanAccount: LoanAccount) => void;
}

const InactiveLoanAccountMenuOptions: React.FC<InactiveLoanAccountMenuOptionsProps> = ({
  loanAccount,
  startReactivateAccount,
}) => {
  return (
    <>
      <div className="py-1 px-1">
        <Menu.Item>
          {({ active }) => (
            <a
              className={classNames(
                active ? 'bg-gray-100' : '',
                'text-indigo-500 block px-4 py-2 text-sm text-center cursor-pointer'
              )}
              onClick={() => {
                startReactivateAccount(loanAccount);
              }}
            >
              Reactivate
            </a>
          )}
        </Menu.Item>
      </div>
    </>
  );
};

export default InactiveLoanAccountMenuOptions;
