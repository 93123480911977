import { NavigationLink } from 'dashboard/DashboardBody';
import logo from 'images/logo-w-title.png';
import { Link } from 'react-router-dom';
import { classNames } from 'utils/tailwindUtils';

interface DesktopSidebarProps {
  dashboardNav: NavigationLink[];
}

const DesktopSidebar: React.FC<DesktopSidebarProps> = ({ dashboardNav }) => {
  return (
    <>
      <div className="hidden md:flex md:flex-col md:w-64 md:fixed md:inset-y-0 md:border-r md:border-gray-200 bg-gray-50">
        <div className="flex items-center flex-shrink-0 px-6">
          <img className="h-12 w-auto" src={logo} alt="unsaddl" />
        </div>
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="mt-6 h-0 flex-1 flex flex-col overflow-y-auto">
          {/* Navigation */}
          <nav className="mt-2">
            <div className="space-y-1">
              {dashboardNav.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    item.current
                      ? 'bg-indigo-50 border-indigo-600 text-indigo-600'
                      : 'border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                    'group flex items-center px-3 py-2 text-sm font-medium border-l-4'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  <item.icon
                    className={classNames(
                      item.current ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500',
                      'mr-3 flex-shrink-0 h-6 w-6'
                    )}
                    aria-hidden="true"
                  />
                  {item.name}
                </Link>
              ))}
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default DesktopSidebar;
