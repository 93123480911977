import { Dialog, Transition } from '@headlessui/react';
import {
  CheckCircleIcon,
  ClockIcon,
  CurrencyDollarIcon,
  MailIcon,
  ReceiptRefundIcon,
  ReceiptTaxIcon,
  XIcon,
} from '@heroicons/react/outline';
import { UserDataContext } from 'context/UserContext';
import { ContributionGrantRun, ContributionPlan } from 'interfaces/contributionsInterfaces';
import { OrganizationUser } from 'interfaces/organizationUserInterfaces';
import React, { Fragment, useContext } from 'react';
import { abbrevDate } from 'utils/dateUtils';
import { formatMoney } from 'utils/numberFormatter';

interface ViewGrantRunModalProps {
  recipientOrganizationUser?: OrganizationUser;
  contributionPlan?: ContributionPlan;
  contributionGrantRun?: ContributionGrantRun;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ViewGrantRunModal: React.FC<ViewGrantRunModalProps> = ({
  recipientOrganizationUser,
  contributionPlan,
  contributionGrantRun,
  open,
  setOpen,
}) => {
  const { currentOrganizationUser } = useContext(UserDataContext);

  const grantRunRecipientName = `${recipientOrganizationUser?.firstName} ${recipientOrganizationUser?.lastName}`;
  const grantRunRecipientEmail = recipientOrganizationUser?.email;
  const loanPayments = contributionGrantRun?.loanPayments;
  const savings529Payments = contributionGrantRun?.savings529Payments;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div className="absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="px-4">
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      {currentOrganizationUser?.id === recipientOrganizationUser?.id ? (
                        <h1 className="text-xl font-semibold text-gray-900">{contributionPlan?.name}</h1>
                      ) : (
                        <h1 className="text-xl font-semibold text-gray-900">
                          {grantRunRecipientName} ({grantRunRecipientEmail})
                        </h1>
                      )}

                      <div className="flex py-2">
                        <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-purple-100 text-purple-800">
                          <CurrencyDollarIcon
                            className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-purple-400"
                            aria-hidden="true"
                          />
                          {formatMoney(Number(contributionGrantRun?.amountGranted))} maximum granted
                        </span>
                        {contributionGrantRun?.employeeMatchRequired && (
                          <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-sky-100 text-sky-800">
                            <ReceiptTaxIcon
                              className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-sky-400"
                              aria-hidden="true"
                            />
                            {contributionGrantRun.matchPercentage}% match (
                            {formatMoney(Number(contributionGrantRun.matchableEmployeeContribution))} eligible)
                          </span>
                        )}
                      </div>

                      <p className="mt-2 text-sm text-gray-700">
                        Contributions from {contributionPlan?.name}{' '}
                        {contributionGrantRun?.createdAt && `executed on ${abbrevDate(contributionGrantRun.createdAt)}`}
                        .
                      </p>
                    </div>
                  </div>
                  {loanPayments && loanPayments?.length > 0 && (
                    <div className="flex flex-col">
                      <div className="mt-4 font-semibold">Student Loan Contributions</div>
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th scope="col" className="py-2 w-1/3 text-left text-sm font-semibold text-gray-900">
                              <div>Loan servicer</div>
                            </th>
                            <th scope="col" className="py-2 px-1 text-left text-sm font-semibold text-gray-900">
                              Account #
                            </th>
                            <th scope="col" className="py-2 text-right text-sm font-semibold text-gray-900">
                              Amount submitted
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {loanPayments &&
                            loanPayments.map((loanPayment) => (
                              <React.Fragment key={loanPayment.id}>
                                <tr key={loanPayment.id} className="bg-gray-50">
                                  <td className="py-4 text-sm">
                                    <div className="font-medium text-gray-900">{loanPayment.servicerName}</div>
                                  </td>
                                  <td className="py-4 px-1 text-left text-sm text-gray-500">
                                    {loanPayment.accountNumber}
                                  </td>
                                  {/* <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
                                  {formatMoney(Number(loanPayment.amountSent))}
                                </td> */}
                                  <td className="py-4 text-right text-sm text-gray-500">
                                    {formatMoney(Number(loanPayment.amountSubmitted))}{' '}
                                    {loanPayment.percentage && `(${loanPayment.percentage}%)`}
                                  </td>
                                </tr>

                                <tr key={loanPayment.id + 'transactionHeader'}>
                                  <td className="text-sm text-gray-500 py-2">
                                    <div className="font-medium">Transactions</div>
                                  </td>
                                </tr>
                                {loanPayment.treasuryPrimeBillPayments.length === 0 && (
                                  <tr key={'no-transactions'}>
                                    <td className="py-4 text-sm text-gray-500">
                                      <div className="font-medium">No payments sent yet</div>
                                    </td>
                                  </tr>
                                )}
                                {loanPayment.treasuryPrimeBillPayments.map((billPayment) => (
                                  <tr key={billPayment.id}>
                                    <td className="py-4 text-sm text-gray-500">
                                      <div className="font-medium">{billPayment.id}</div>
                                    </td>
                                    <td className="py-4 px-1 text-left text-sm text-gray-500">
                                      <span className="inline-flex text-xs leading-5 font-semibold ">
                                        <MailIcon
                                          className="flex-shrink-0 mr-1 h-5 w-5 text-sky-400"
                                          aria-hidden="true"
                                        />
                                        Deliver by
                                      </span>
                                      {billPayment.deliverByDate && (
                                        <div className="font-medium">{abbrevDate(billPayment.deliverByDate)}</div>
                                      )}
                                    </td>

                                    <td className="py-4 text-right text-sm text-gray-500">
                                      {billPayment.returned === true ? (
                                        <span className="inline-flex px-1 text-xs leading-5 font-semibold rounded-md bg-red-100 text-red-800">
                                          <XIcon
                                            className="hidden md:flex flex-shrink-0 mr-1 h-5 w-5 text-red-400"
                                            aria-hidden="true"
                                          />
                                          Error
                                        </span>
                                      ) : (
                                        {
                                          pending: (
                                            <span className="inline-flex px-1 text-xs leading-5 font-semibold rounded-md bg-yellow-100 text-yellow-800">
                                              <ClockIcon
                                                className="hidden md:flex flex-shrink-0 mr-1 h-5 w-5 text-yellow-400"
                                                aria-hidden="true"
                                              />
                                              Pending
                                            </span>
                                          ),
                                          canceled: (
                                            <span className="inline-flex px-1 text-xs leading-5 font-semibold rounded-md bg-red-100 text-red-800">
                                              <XIcon
                                                className="hidden md:flex flex-shrink-0 mr-1 h-5 w-5 text-red-400"
                                                aria-hidden="true"
                                              />
                                              Canceled
                                            </span>
                                          ),
                                          processing: (
                                            <span className="inline-flex px-1 text-xs leading-5 font-semibold rounded-md bg-yellow-100 text-yellow-800">
                                              <ClockIcon
                                                className="hidden md:flex flex-shrink-0 mr-1 h-5 w-5 text-yellow-400"
                                                aria-hidden="true"
                                              />
                                              Processing
                                            </span>
                                          ),
                                          sent: (
                                            <span className="inline-flex px-1 text-xs leading-5 font-semibold rounded-md bg-green-100 text-green-800">
                                              <CheckCircleIcon
                                                className="hidden md:flex flex-shrink-0 mr-1 h-5 w-5 text-green-400"
                                                aria-hidden="true"
                                              />
                                              Sent
                                            </span>
                                          ),
                                          error: (
                                            <span className="inline-flex px-1 text-xs leading-5 font-semibold rounded-md bg-red-100 text-red-800">
                                              <XIcon
                                                className="hidden md:flex flex-shrink-0 mr-1 h-5 w-5 text-red-400"
                                                aria-hidden="true"
                                              />
                                              Error
                                            </span>
                                          ),
                                        }[billPayment.status]
                                      )}

                                      <div className="font-semibold">{formatMoney(Number(billPayment.amount))}</div>
                                    </td>
                                  </tr>
                                ))}
                                <tr key={loanPayment.id + 'border'} className="border-b border-gray-200" />
                              </React.Fragment>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}

                  {savings529Payments && savings529Payments.length > 0 && (
                    <div className="flex flex-col">
                      <div className="mt-4 font-semibold">529 Contributions</div>
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th scope="col" className="py-2 w-1/3 text-left text-sm font-semibold text-gray-900">
                              <div>Institution</div>
                            </th>
                            <th scope="col" className="py-2 px-1 text-left text-sm font-semibold text-gray-900">
                              Payment details
                            </th>
                            <th scope="col" className="py-2 text-right text-sm font-semibold text-gray-900">
                              Amount submitted
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {savings529Payments &&
                            savings529Payments.map((savings529Payment) => (
                              <React.Fragment key={savings529Payment.id}>
                                <tr key={savings529Payment.id} className="bg-gray-50">
                                  <td className="py-4 text-sm">
                                    <div className="font-medium text-gray-900">{savings529Payment.institutionName}</div>
                                    {savings529Payment.contributionType === 'check' && (
                                      <div className="text-sm text-gray-500">
                                        <div>{savings529Payment.institutionAddressStreet}</div>
                                        {savings529Payment.institutionAddressStreet2 && (
                                          <div>{savings529Payment.institutionAddressStreet2}</div>
                                        )}
                                        <div>
                                          {savings529Payment.institutionAddressCity},{' '}
                                          {savings529Payment.institutionAddressRegion}{' '}
                                          {savings529Payment.institutionAddressPostalCode}
                                        </div>
                                      </div>
                                    )}
                                  </td>
                                  <td className="py-4 px-1 text-left text-sm text-gray-500">
                                    {savings529Payment.contributionType === 'ach' ? (
                                      <>
                                        <div>Routing #: {savings529Payment.routingNumber}</div>
                                        <div>Account #: {savings529Payment.accountNumber}</div>
                                      </>
                                    ) : (
                                      <>
                                        <div>Account #: {savings529Payment.accountNumber}</div>
                                        <div>{savings529Payment.additionalInstructions}</div>
                                      </>
                                    )}
                                  </td>
                                  {/* <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
                                  {formatMoney(Number(loanPayment.amountSent))}
                                </td> */}
                                  <td className="py-4 text-right text-sm text-gray-500">
                                    {formatMoney(Number(savings529Payment.amountSubmitted))}{' '}
                                    {savings529Payment.percentage && `(${savings529Payment.percentage}%)`}
                                  </td>
                                </tr>

                                <tr key={savings529Payment.id + 'transactionHeader'}>
                                  <td className="text-sm text-gray-500 py-2">
                                    <div className="font-medium">Transactions</div>
                                  </td>
                                </tr>
                                {savings529Payment.treasuryPrimeBillPayments.length === 0 &&
                                  savings529Payment.treasuryPrimeAchs.length === 0 && (
                                    <tr key={'no-transactions'}>
                                      <td className="py-4 text-sm text-gray-500">
                                        <div className="font-medium">No payments sent yet</div>
                                      </td>
                                    </tr>
                                  )}
                                {savings529Payment.contributionType === 'check'
                                  ? savings529Payment.treasuryPrimeBillPayments.map((billPayment) => (
                                      <tr key={billPayment.id}>
                                        <td className="py-4 text-sm text-gray-500">
                                          <div className="font-medium">{billPayment.id}</div>
                                        </td>
                                        <td className="py-4 px-1 text-left text-sm text-gray-500">
                                          <span className="inline-flex text-xs leading-5 font-semibold ">
                                            <MailIcon
                                              className="flex-shrink-0 mr-1 h-5 w-5 text-sky-400"
                                              aria-hidden="true"
                                            />
                                            Deliver by
                                          </span>
                                          {billPayment.deliverByDate && (
                                            <div className="font-medium">{abbrevDate(billPayment.deliverByDate)}</div>
                                          )}
                                        </td>

                                        <td className="py-4 text-right text-sm text-gray-500">
                                          {billPayment.returned === true ? (
                                            <span className="inline-flex px-1 text-xs leading-5 font-semibold rounded-md bg-red-100 text-red-800">
                                              <XIcon
                                                className="hidden md:flex flex-shrink-0 mr-1 h-5 w-5 text-red-400"
                                                aria-hidden="true"
                                              />
                                              Error
                                            </span>
                                          ) : (
                                            {
                                              pending: (
                                                <span className="inline-flex px-1 text-xs leading-5 font-semibold rounded-md bg-yellow-100 text-yellow-800">
                                                  <ClockIcon
                                                    className="hidden md:flex flex-shrink-0 mr-1 h-5 w-5 text-yellow-400"
                                                    aria-hidden="true"
                                                  />
                                                  Pending
                                                </span>
                                              ),
                                              canceled: (
                                                <span className="inline-flex px-1 text-xs leading-5 font-semibold rounded-md bg-red-100 text-red-800">
                                                  <XIcon
                                                    className="hidden md:flex flex-shrink-0 mr-1 h-5 w-5 text-red-400"
                                                    aria-hidden="true"
                                                  />
                                                  Canceled
                                                </span>
                                              ),
                                              processing: (
                                                <span className="inline-flex px-1 text-xs leading-5 font-semibold rounded-md bg-yellow-100 text-yellow-800">
                                                  <ClockIcon
                                                    className="hidden md:flex flex-shrink-0 mr-1 h-5 w-5 text-yellow-400"
                                                    aria-hidden="true"
                                                  />
                                                  Processing
                                                </span>
                                              ),
                                              sent: (
                                                <span className="inline-flex px-1 text-xs leading-5 font-semibold rounded-md bg-green-100 text-green-800">
                                                  <CheckCircleIcon
                                                    className="hidden md:flex flex-shrink-0 mr-1 h-5 w-5 text-green-400"
                                                    aria-hidden="true"
                                                  />
                                                  Sent
                                                </span>
                                              ),
                                              error: (
                                                <span className="inline-flex px-1 text-xs leading-5 font-semibold rounded-md bg-red-100 text-red-800">
                                                  <XIcon
                                                    className="hidden md:flex flex-shrink-0 mr-1 h-5 w-5 text-red-400"
                                                    aria-hidden="true"
                                                  />
                                                  Error
                                                </span>
                                              ),
                                            }[billPayment.status]
                                          )}

                                          <div className="font-semibold">{formatMoney(Number(billPayment.amount))}</div>
                                        </td>
                                      </tr>
                                    ))
                                  : savings529Payment.treasuryPrimeAchs.map((treasuryPrimeAch) => (
                                      <tr key={treasuryPrimeAch.id}>
                                        <td className="py-4 text-sm text-gray-500">
                                          <div className="font-medium">{treasuryPrimeAch.id}</div>
                                        </td>
                                        <td className="py-4 px-1 text-left text-sm text-gray-500">
                                          <span className="inline-flex text-xs leading-5 font-semibold ">
                                            <MailIcon
                                              className="flex-shrink-0 mr-1 h-5 w-5 text-sky-400"
                                              aria-hidden="true"
                                            />
                                            Effective date
                                          </span>
                                          {treasuryPrimeAch.effectiveDate && (
                                            <div className="font-medium">
                                              {abbrevDate(treasuryPrimeAch.effectiveDate)}
                                            </div>
                                          )}
                                        </td>

                                        <td className="py-4 text-right text-sm text-gray-500">
                                          {
                                            {
                                              pending: (
                                                <span className="inline-flex px-1 text-xs leading-5 font-semibold rounded-md bg-yellow-100 text-yellow-800">
                                                  <ClockIcon
                                                    className="hidden md:flex flex-shrink-0 mr-1 h-5 w-5 text-yellow-400"
                                                    aria-hidden="true"
                                                  />
                                                  Pending
                                                </span>
                                              ),
                                              canceled: (
                                                <span className="inline-flex px-1 text-xs leading-5 font-semibold rounded-md bg-red-100 text-red-800">
                                                  <XIcon
                                                    className="hidden md:flex flex-shrink-0 mr-1 h-5 w-5 text-red-400"
                                                    aria-hidden="true"
                                                  />
                                                  Canceled
                                                </span>
                                              ),
                                              processing: (
                                                <span className="inline-flex px-1 text-xs leading-5 font-semibold rounded-md bg-yellow-100 text-yellow-800">
                                                  <ClockIcon
                                                    className="hidden md:flex flex-shrink-0 mr-1 h-5 w-5 text-yellow-400"
                                                    aria-hidden="true"
                                                  />
                                                  Processing
                                                </span>
                                              ),
                                              sent: (
                                                <span className="inline-flex px-1 text-xs leading-5 font-semibold rounded-md bg-green-100 text-green-800">
                                                  <CheckCircleIcon
                                                    className="hidden md:flex flex-shrink-0 mr-1 h-5 w-5 text-green-400"
                                                    aria-hidden="true"
                                                  />
                                                  Sent
                                                </span>
                                              ),
                                              error: (
                                                <span className="inline-flex px-1 text-xs leading-5 font-semibold rounded-md bg-red-100 text-red-800">
                                                  <XIcon
                                                    className="hidden md:flex flex-shrink-0 mr-1 h-5 w-5 text-red-400"
                                                    aria-hidden="true"
                                                  />
                                                  Error
                                                </span>
                                              ),
                                              returned: (
                                                <span className="inline-flex px-1 text-xs leading-5 font-semibold rounded-md bg-red-100 text-red-800">
                                                  <ReceiptRefundIcon
                                                    className="hidden md:flex flex-shrink-0 mr-1 h-5 w-5 text-red-400"
                                                    aria-hidden="true"
                                                  />
                                                  Returned
                                                </span>
                                              ),
                                            }[treasuryPrimeAch.status]
                                          }
                                          <div className="font-semibold">
                                            {formatMoney(Number(treasuryPrimeAch.amount))}
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                <tr key={savings529Payment.id + 'border'} className="border-b border-gray-200" />
                              </React.Fragment>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ViewGrantRunModal;
