import StudentLoanPaymentsTab from 'participant/Payments/StudentLoanPaymentsTab';
import TuitionReimbursementPaymentsTab from 'participant/Payments/TuitionReimbursementPaymentsTab';

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import AuthenticatedRoute from 'routes/AuthenticatedRoute';
import { classNames } from 'utils/tailwindUtils';

interface PaymentsTab {
  name: string;
  href: string;
  current: boolean;
}

const PaymentsPage: React.FC = () => {
  const location = useLocation();
  const locationPath = location.pathname;

  const tabs: PaymentsTab[] = [
    {
      name: 'Student loans / 529',
      href: `/payments/student-loans`,
      current: locationPath === `/payments/student-loans`,
    },
    {
      name: 'Tuition reimbursement',
      href: `/payments/tuition-reimbursement`,
      current: locationPath === `/payments/tuition-reimbursement`,
    },
  ];

  return (
    <div className="my-6 px-8 mx-auto max-w-7xl">
      <h1 className="text-2xl font-bold text-gray-900">Payments</h1>

      <div className="mt-6 sm:mt-2 2xl:mt-5 border-b border-gray-200 overflow-x-auto scrollbar-hide">
        <nav className="flex space-x-8" aria-label="Tabs">
          {tabs.map((tab) => (
            <Link
              key={tab.name}
              to={tab.href}
              className={classNames(
                tab.current
                  ? 'border-purple-500 text-gray-900'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              {tab.name}
            </Link>
          ))}
        </nav>
      </div>

      <AuthenticatedRoute
        path={`/payments/student-loans`}
        render={() => {
          return <StudentLoanPaymentsTab />;
        }}
      />
      <AuthenticatedRoute
        path={`/payments/tuition-reimbursement`}
        render={() => {
          return <TuitionReimbursementPaymentsTab />;
        }}
      />
    </div>
  );
};

export default PaymentsPage;
