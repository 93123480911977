import api from 'config/axiosConfig';
import { PlaidLinkOnSuccessMetadata } from 'react-plaid-link';

export const apiGetPlaidLinkToken = () =>
  api
    .post(`/loans/plaid-link-token`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiGetPlaidLinkUpdateToken = (plaidItemId: string) =>
  api
    .post(`/loans/plaid-link-token/${plaidItemId}`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiDeleteLoanServicer = (loanServicerId: string) =>
  api
    .delete(`/loans/servicers/${loanServicerId}`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiAddManualLoanServicer = (merchantId: string) =>
  api
    .post(`/loans/servicers/manual`, { merchantId })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });
export const apiAddManualLoanAccount = (loanServicerId: string, loanName: string, accountNumber: string) =>
  api
    .post(`/loans/accounts/manual`, { loanServicerId, loanName, accountNumber })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiAddPlaidLoanServicer = (public_token: string, metadata: PlaidLinkOnSuccessMetadata) =>
  api
    .post(`/loans/servicers/plaid-item`, { public_token, metadata })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiFixPlaidLoanServicer = (public_token: string, metadata: PlaidLinkOnSuccessMetadata) =>
  api
    .post(`/loans/servicers/plaid-item/fix`, { public_token, metadata })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiLoanServicerPlaidRefresh = (loanServicerId: string | undefined) =>
  api
    .get(`/loans/servicers/${loanServicerId}/plaid-refresh`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiUpdateLoanAccountActiveStatus = (loanServicerId: string | undefined, active: boolean) =>
  api
    .post(`/loans/accounts/${loanServicerId}`, { active })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiUploadLoanServicerStatement = (loanServicerId: string | undefined, formData: FormData) =>
  api
    .post(`/loans/servicers/${loanServicerId}/statements`, formData, {})
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiGetLoanServicerStatements = (loanServicerId: string | undefined) =>
  api
    .get(`/loans/servicers/${loanServicerId}/statements`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiGetLoanServicerStatementLink = (loanServicerId: string, statementUploadId: string) =>
  api
    .get(`/loans/servicers/${loanServicerId}/statements/${statementUploadId}/link`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiDeleteLoanServicerStatement = (loanServicerId: string, statementUploadId: string) =>
  api
    .delete(`/loans/servicers/${loanServicerId}/statements/${statementUploadId}`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });
