import { Dialog, Transition } from '@headlessui/react';
import { LibraryIcon } from '@heroicons/react/outline';
import { apiCreateSavings529Account } from 'api/savings529API';
import LoadingButton from 'components/Buttons/LoadingButton';
import { Fragment, useState } from 'react';
import toast from 'react-hot-toast';
import { classNames } from 'utils/tailwindUtils';

interface Add529AccountModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refreshAccountData: () => void;
}

const Add529AccountModal: React.FC<Add529AccountModalProps> = ({ open, setOpen, refreshAccountData }) => {
  const [error, setError] = useState('');

  const [isSubmittingNew529Account, setIsSubmittingNew529Account] = useState(false);

  const [values, setValues] = useState({
    contributionType: 'ach',
    name: '',
    institutionName: '',
    routingNumber: '',
    accountNumber: '',
    confirmAccountNumber: '',
    additionalInstructions: '',
    institutionAddressCity: '',
    institutionAddressPostalCode: '',
    institutionAddressRegion: 'AL',
    institutionAddressStreet: '',
    institutionAddressStreet2: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleClose = () => {
    setOpen(false);
    setError('');
  };

  const handleSubmitNew529Account = async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsSubmittingNew529Account(true);
    apiCreateSavings529Account(
      values.contributionType,
      values.name,
      values.institutionName,
      values.routingNumber,
      values.accountNumber,
      values.additionalInstructions,
      values.institutionAddressCity,
      values.institutionAddressPostalCode,
      values.institutionAddressRegion,
      values.institutionAddressStreet,
      values.institutionAddressStreet2
    ).then(
      (data) => {
        setOpen(false);
        toast.success(`Successfully added new 529 account!`);
        setIsSubmittingNew529Account(false);
        refreshAccountData();
      },
      (error) => {
        setError(error.message);
        setIsSubmittingNew529Account(false);
      }
    );
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={handleClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full">
              <form onSubmit={handleSubmitNew529Account}>
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                      <LibraryIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Add 529 Savings Account
                      </Dialog.Title>

                      <div className="mt-4">
                        <label htmlFor="contribution-type" className="block text-sm font-medium text-gray-700">
                          Contribution type
                        </label>
                        <select
                          id="contributionType"
                          name="contributionType"
                          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          value={values.contributionType}
                          onChange={handleChange}
                        >
                          <option value="ach">ACH</option>
                          <option value="check">Mail-in check</option>
                        </select>
                      </div>

                      <div className="mt-4">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                          Name *
                        </label>
                        <div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            onChange={handleChange}
                            value={values.name}
                            className="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                            required
                          />
                        </div>
                      </div>

                      <div className="mt-4">
                        <label htmlFor="institutionName" className="block text-sm font-medium text-gray-700">
                          Institution name *
                        </label>
                        <div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
                          <input
                            type="text"
                            name="institutionName"
                            id="institutionName"
                            onChange={handleChange}
                            value={values.institutionName}
                            className="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                            required
                          />
                        </div>
                      </div>

                      {values.contributionType === 'ach' ? (
                        <>
                          <div className="mt-4">
                            <label htmlFor="routingNumber" className="block text-sm font-medium text-gray-700">
                              Routing number *
                            </label>
                            <div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
                              <input
                                type="text"
                                name="routingNumber"
                                id="routingNumber"
                                onChange={handleChange}
                                value={values.routingNumber}
                                minLength={9}
                                maxLength={9}
                                className="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                                required
                              />
                            </div>
                          </div>
                          <div className="mt-4">
                            <label htmlFor="accountNumber" className="block text-sm font-medium text-gray-700">
                              Account number *
                            </label>
                            <div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
                              <input
                                type="text"
                                name="accountNumber"
                                id="accountNumber"
                                onChange={handleChange}
                                value={values.accountNumber}
                                className="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                                required
                              />
                            </div>
                          </div>
                          <div className="mt-4">
                            <label htmlFor="confirmAccountNumber" className="block text-sm font-medium text-gray-700">
                              Confirm account number *
                            </label>
                            <div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
                              <input
                                type="text"
                                name="confirmAccountNumber"
                                id="confirmAccountNumber"
                                onChange={handleChange}
                                value={values.confirmAccountNumber}
                                className="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                                required
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="mt-4">
                            <label
                              htmlFor="institutionAddressStreet"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Street Address *
                            </label>
                            <div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
                              <input
                                type="text"
                                name="institutionAddressStreet"
                                id="institutionAddressStreet"
                                onChange={handleChange}
                                value={values.institutionAddressStreet}
                                className="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                                required
                              />
                            </div>
                          </div>
                          <div className="mt-4">
                            <label
                              htmlFor="institutionAddressStreet2"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Floor / Suite / Office #
                            </label>
                            <div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
                              <input
                                type="text"
                                name="institutionAddressStreet2"
                                id="institutionAddressStreet2"
                                onChange={handleChange}
                                value={values.institutionAddressStreet2}
                                className="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                              />
                            </div>
                          </div>
                          <div className="mt-4">
                            <label htmlFor="institutionAddressCity" className="block text-sm font-medium text-gray-700">
                              City *
                            </label>
                            <div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
                              <input
                                type="text"
                                name="institutionAddressCity"
                                id="institutionAddressCity"
                                onChange={handleChange}
                                value={values.institutionAddressCity}
                                className="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                                required
                              />
                            </div>
                          </div>
                          <div className="mt-4">
                            <label
                              htmlFor="institutionAddressRegion"
                              className="block text-sm font-medium text-gray-700"
                            >
                              State *
                            </label>
                            <select
                              id="institutionAddressRegion"
                              name="institutionAddressRegion"
                              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              value={values.institutionAddressRegion}
                              onChange={handleChange}
                            >
                              <option value="AL">Alabama</option>
                              <option value="AK">Alaska</option>
                              <option value="AZ">Arizona</option>
                              <option value="AR">Arkansas</option>
                              <option value="CA">California</option>
                              <option value="CO">Colorado</option>
                              <option value="CT">Connecticut</option>
                              <option value="DE">Delaware</option>
                              <option value="DC">District Of Columbia</option>
                              <option value="FL">Florida</option>
                              <option value="GA">Georgia</option>
                              <option value="HI">Hawaii</option>
                              <option value="ID">Idaho</option>
                              <option value="IL">Illinois</option>
                              <option value="IN">Indiana</option>
                              <option value="IA">Iowa</option>
                              <option value="KS">Kansas</option>
                              <option value="KY">Kentucky</option>
                              <option value="LA">Louisiana</option>
                              <option value="ME">Maine</option>
                              <option value="MD">Maryland</option>
                              <option value="MA">Massachusetts</option>
                              <option value="MI">Michigan</option>
                              <option value="MN">Minnesota</option>
                              <option value="MS">Mississippi</option>
                              <option value="MO">Missouri</option>
                              <option value="MT">Montana</option>
                              <option value="NE">Nebraska</option>
                              <option value="NV">Nevada</option>
                              <option value="NH">New Hampshire</option>
                              <option value="NJ">New Jersey</option>
                              <option value="NM">New Mexico</option>
                              <option value="NY">New York</option>
                              <option value="NC">North Carolina</option>
                              <option value="ND">North Dakota</option>
                              <option value="OH">Ohio</option>
                              <option value="OK">Oklahoma</option>
                              <option value="OR">Oregon</option>
                              <option value="PA">Pennsylvania</option>
                              <option value="RI">Rhode Island</option>
                              <option value="SC">South Carolina</option>
                              <option value="SD">South Dakota</option>
                              <option value="TN">Tennessee</option>
                              <option value="TX">Texas</option>
                              <option value="UT">Utah</option>
                              <option value="VT">Vermont</option>
                              <option value="VA">Virginia</option>
                              <option value="WA">Washington</option>
                              <option value="WV">West Virginia</option>
                              <option value="WI">Wisconsin</option>
                              <option value="WY">Wyoming</option>
                            </select>
                          </div>
                          <div className="mt-4">
                            <label
                              htmlFor="institutionAddressPostalCode"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Zip code *
                            </label>
                            <div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
                              <input
                                type="text"
                                name="institutionAddressPostalCode"
                                id="institutionAddressPostalCode"
                                onChange={handleChange}
                                value={values.institutionAddressPostalCode}
                                className="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                                required
                              />
                            </div>
                          </div>
                          <div className="mt-4">
                            <label htmlFor="accountNumber" className="block text-sm font-medium text-gray-700">
                              Account number *
                            </label>
                            <div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
                              <input
                                type="text"
                                name="accountNumber"
                                id="accountNumber"
                                onChange={handleChange}
                                value={values.accountNumber}
                                className="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                                required
                              />
                            </div>
                          </div>
                          <div className="mt-4">
                            <label htmlFor="confirmAccountNumber" className="block text-sm font-medium text-gray-700">
                              Confirm account number *
                            </label>
                            <div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
                              <input
                                type="text"
                                name="confirmAccountNumber"
                                id="confirmAccountNumber"
                                onChange={handleChange}
                                value={values.confirmAccountNumber}
                                className="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                                required
                              />
                            </div>
                          </div>
                          <div className="mt-4">
                            <label htmlFor="additionalInstructions" className="block text-sm font-medium text-gray-700">
                              Please include any additional instructions for third-party payments to your 529 account.
                              Your payment may be delayed if instructions are not provided.
                            </label>
                            <div className="mt-1 border-gray-300 focus-within:border-indigo-600">
                              <textarea
                                id="additionalInstructions"
                                name="additionalInstructions"
                                rows={3}
                                onChange={handleChange}
                                className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
                                value={values.additionalInstructions}
                                placeholder="Add instructions here"
                              />
                            </div>
                          </div>
                        </>
                      )}

                      {error !== '' && (
                        <div className="mt-2">
                          <p className="text-sm text-red-500">{error}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <LoadingButton
                    type="submit"
                    styles={classNames(
                      values.accountNumber === '' || values.accountNumber !== values.confirmAccountNumber
                        ? 'opacity-70'
                        : 'hover:bg-green-700',
                      'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm'
                    )}
                    text="Submit"
                    loadingText="Processing..."
                    disabled={values.accountNumber === '' || values.accountNumber !== values.confirmAccountNumber}
                    isLoading={isSubmittingNew529Account}
                  />
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Add529AccountModal;
