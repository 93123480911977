import { Menu } from '@headlessui/react';
import { LoanAccount } from 'interfaces/loansInterfaces';
import React from 'react';
import { classNames } from 'utils/tailwindUtils';

interface ActiveLoanAccountMenuOptionsProps {
  loanAccount: LoanAccount;
  startDeactivateAccount: (loanAccount: LoanAccount) => void;
}

const ActiveLoanAccountMenuOptions: React.FC<ActiveLoanAccountMenuOptionsProps> = ({
  loanAccount,
  startDeactivateAccount,
}) => {
  return (
    <>
      <div className="py-1 px-1">
        <Menu.Item>
          {({ active }) => (
            <a
              className={classNames(
                active ? 'bg-gray-100' : '',
                'text-red-500 block px-4 py-2 text-sm text-center cursor-pointer'
              )}
              onClick={() => {
                startDeactivateAccount(loanAccount);
              }}
            >
              Deactivate
            </a>
          )}
        </Menu.Item>
      </div>
    </>
  );
};

export default ActiveLoanAccountMenuOptions;
