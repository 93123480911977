import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import ContributionGrantRunGrantedColumn from 'common/Contributions/ContributionGrantRunGrantedColumn';
import ContributionGrantRunPaymentColumn from 'common/Contributions/ContributionGrantRunPaymentColumn';
import EmptyTablePlaceholder from 'common/EmptyTablePlaceholder';
import Pagination from 'components/Tables/Pagination';
import { ContributionGrantRun, ContributionPlanRun } from 'interfaces/contributionsInterfaces';
import React, { useEffect } from 'react';
import Highlighter from 'react-highlight-words';
import { CellProps, Column, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

interface ViewPlanRunGrantsTableProps {
  searchTerm: string;
  contributionPlanRun: ContributionPlanRun;
  startViewGrantRun: (contributionGrantRun: ContributionGrantRun) => void;
}

const ViewPlanRunGrantsTable: React.FC<ViewPlanRunGrantsTableProps> = ({
  searchTerm,
  contributionPlanRun,
  startViewGrantRun,
}) => {
  const contributionGrantRunsMemo = React.useMemo<ContributionGrantRun[]>(
    () => contributionPlanRun.contributionGrantRuns,
    [contributionPlanRun.contributionGrantRuns]
  );
  const columns: Column<ContributionGrantRun>[] = React.useMemo(
    () => [
      {
        id: 'recipient',
        Header: 'Recipient',
        accessor: (contributionGrantRun: ContributionGrantRun) => {
          const granteeOrganizationUser = contributionGrantRun.participantProfile?.organizationUser;

          return (
            granteeOrganizationUser?.firstName +
            ' ' +
            granteeOrganizationUser?.lastName +
            ' ' +
            granteeOrganizationUser?.email
          );
        },
        Cell: (props: CellProps<ContributionGrantRun>) => {
          const contributionGrantRun = props.row.original;
          const granteeOrganizationUser = contributionGrantRun.participantProfile?.organizationUser;
          const firstName = granteeOrganizationUser?.firstName;
          const lastName = granteeOrganizationUser?.lastName;
          const email = granteeOrganizationUser?.email;

          return (
            <div className="flex items-center">
              <div>
                <Highlighter
                  className="block font-medium text-gray-900 break-all"
                  highlightClassName="bg-yellow-200"
                  searchWords={[props.state.globalFilter]}
                  autoEscape={true}
                  textToHighlight={`${firstName} ${lastName}`}
                />
                <Highlighter
                  className="block text-gray-500 break-all"
                  highlightClassName="bg-yellow-200"
                  searchWords={[props.state.globalFilter]}
                  autoEscape={true}
                  textToHighlight={`${email}`}
                />
              </div>
            </div>
          );
        },
        className: 'py-4 text-sm font-medium text-gray-900',
        headerClassName: 'py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
      {
        id: 'max-amount-granted',
        Header: 'Max amount granted',
        accessor: (contributionGrantRun: ContributionGrantRun) => {
          return Number(contributionGrantRun.amountGranted);
        },
        Cell: (props: CellProps<ContributionGrantRun>) => {
          const contributionGrantRun = props.row.original;
          return <ContributionGrantRunGrantedColumn contributionGrantRun={contributionGrantRun} />;
        },
        className: 'hidden sm:table-cell py-4 whitespace-nowrap text-sm text-gray-500',
        headerClassName:
          'hidden sm:table-cell py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
      {
        id: 'payment',
        Header: 'Payment sent / submitted',
        Cell: (props: CellProps<ContributionGrantRun>) => {
          const contributionGrantRun = props.row.original;
          return <ContributionGrantRunPaymentColumn contributionGrantRun={contributionGrantRun} />;
        },
        className: 'py-4 whitespace-nowrap text-sm text-gray-500',
        headerClassName: 'py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
    ],
    []
  ) as any;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex },
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: contributionGrantRunsMemo,
      initialState: { pageSize: 10, globalFilter: searchTerm },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setGlobalFilter(searchTerm);
  }, [searchTerm]);

  return (
    <div className="mt-4">
      <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={`${column.headerClassName}`}
                    >
                      {
                        // Render the header
                        column.render('Header')
                      }
                      <span className="inline-flex ml-1">
                        {column.isSorted ? (
                          <span className="w-3 h-3">
                            {column.isSortedDesc ? <ChevronDownIcon /> : <ChevronUpIcon />}
                          </span>
                        ) : (
                          ''
                        )}
                      </span>
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()} className="divide-y divide-gray-200">
          {
            // Loop over the table rows
            page.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr
                  {...row.getRowProps()}
                  onClick={() => startViewGrantRun(row.original)}
                  className="hover:bg-gray-50 cursor-pointer"
                >
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td {...cell.getCellProps()} className={`${cell.column.className}`}>
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>

      {page.length === 0 && (
        <EmptyTablePlaceholder
          title="No grants"
          subtitle="This contribution plan run does not have any active grants."
        />
      )}

      <Pagination
        pageIndex={pageIndex}
        numPages={pageOptions.length}
        nextPage={nextPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
      />
    </div>
  );
};

export default ViewPlanRunGrantsTable;
