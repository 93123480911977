import CourseReviewActions from 'admin/TuitionReimbursement/ListRequests/OrganizationRequestActions/CourseReviewActions';
import { TuitionReimbursementRequest } from 'interfaces/tuitionReimbursementInterfaces';

interface OrganizationRequestActionsProps {
  tuitionReimbursementRequest?: TuitionReimbursementRequest;
}

const OrganizationRequestActions: React.FC<OrganizationRequestActionsProps> = ({ tuitionReimbursementRequest }) => {
  const reviewStatus = tuitionReimbursementRequest?.reviewStatus;
  return (
    <div className="py-2">
      {reviewStatus === 'submitted' ? (
        <CourseReviewActions tuitionReimbursementRequest={tuitionReimbursementRequest} />
      ) : reviewStatus === 'course_approved' ? (
        <>
          <h2 className="text-lg font-medium text-gray-900">Awaiting course completion</h2>
        </>
      ) : reviewStatus === 'course_denied' ? (
        <>
          <h2 className="text-lg font-medium text-gray-900">Course denied</h2>
        </>
      ) : reviewStatus === 'course_completed' ? (
        <CourseReviewActions tuitionReimbursementRequest={tuitionReimbursementRequest} />
      ) : reviewStatus === 'completion_approved' ? (
        <>
          <h2 className="text-lg font-medium text-gray-900">Payment pending</h2>
        </>
      ) : reviewStatus === 'completion_denied' ? (
        <>
          <h2 className="text-lg font-medium text-gray-900">Course completion denied</h2>
        </>
      ) : (
        <>
          <h2 className="text-lg font-medium text-gray-900">Paid</h2>
        </>
      )}
    </div>
  );
};

export default OrganizationRequestActions;
