import { Dialog, Transition } from '@headlessui/react';
import {
  CheckCircleIcon,
  ClockIcon,
  CurrencyDollarIcon,
  DownloadIcon,
  TrashIcon,
  UploadIcon,
  XCircleIcon,
  XIcon,
} from '@heroicons/react/outline';
import { ExclamationIcon } from '@heroicons/react/solid';
import { Ring } from '@uiball/loaders';
import {
  apiGetLoanServicerStatementLink,
  apiGetLoanServicerStatements,
  apiUploadLoanServicerStatement,
} from 'api/loansAPI';
import DeniedStatementUploadMemoModal from 'common/Loans/DeniedStatementUploadMemoModal';
import { LoanServicer, LoanServicerStatementUpload } from 'interfaces/loansInterfaces';
import DeleteStatementModal from 'participant/Accounts/LoanServicer/modals/DeleteStatementModal';
import React, { Fragment, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { formatMoney } from 'utils/numberFormatter';

interface ManageStatementUploadsModalProps {
  loanServicer?: LoanServicer;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ManageStatementUploadsModal: React.FC<ManageStatementUploadsModalProps> = ({ loanServicer, open, setOpen }) => {
  const [isLoadingStatementUploads, setIsLoadingStatementUploads] = useState(false);
  const [statementUploads, setStatementUploads] = useState<LoanServicerStatementUpload[]>([]);
  const [isUploadingStatement, setIsUploadingStatement] = useState(false);
  const [deniedStatementUpload, setDeniedStatementUpload] = useState<LoanServicerStatementUpload>();
  const [openDeniedStatementUploadModal, setOpenDeniedStatementUploadModal] = useState(false);
  const [openDeleteStatementModal, setOpenDeleteStatementModal] = useState(false);
  const [toDeleteStatement, setToDeleteStatement] = useState<LoanServicerStatementUpload>();
  const loanServicerName = loanServicer?.name;

  let matchableEmployeeContributionAmount = 0;
  if (statementUploads) {
    for (const statementUpload of statementUploads) {
      matchableEmployeeContributionAmount =
        matchableEmployeeContributionAmount + Number(statementUpload.matchableEmployeeContribution);
    }
  }

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleFileUploadClick = () => {
    if (hiddenFileInput.current != null) {
      hiddenFileInput.current.click();
    }
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsUploadingStatement(true);
    const files = e.target.files;
    const formData = new FormData();
    if (files) {
      formData.append('file', files[0]);
      apiUploadLoanServicerStatement(loanServicer?.id, formData).then(
        (data) => {
          setIsUploadingStatement(false);
          toast.success('Successfully uploaded loan statement');
          getStatementUploads();
        },
        (error) => {
          setIsUploadingStatement(false);
          toast.error(error.message);
        }
      );
    }
  };

  const getStatementUploads = async () => {
    setIsLoadingStatementUploads(true);
    apiGetLoanServicerStatements(loanServicer?.id).then(
      (data) => {
        setStatementUploads(data.statementUploads);
        setIsLoadingStatementUploads(false);
      },
      (error) => {
        setIsLoadingStatementUploads(false);
      }
    );
  };

  const handleStatementDownload = async (loanServicerId: string, statementUploadId: string) => {
    apiGetLoanServicerStatementLink(loanServicerId, statementUploadId).then(
      (data) => {
        window.open(data.statementUploadUrl);
      },
      (error) => {
        toast.error('Failed to download statement');
      }
    );
  };

  const startDeleteStatement = (loanServicerStatement: LoanServicerStatementUpload) => {
    setToDeleteStatement(loanServicerStatement);
    setOpenDeleteStatementModal(true);
  };

  useEffect(() => {
    if (open) {
      getStatementUploads();
    }
  }, [open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div className="absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                {isLoadingStatementUploads ? (
                  <div className="flex justify-center my-12">
                    <Ring size={40} lineWeight={5} speed={2} color="gray" />
                  </div>
                ) : (
                  <div className="px-4 sm:px-6">
                    <div className="sm:flex sm:items-center">
                      <div className="sm:flex-auto">
                        <h1 className="text-xl font-semibold text-gray-900">{loanServicerName} Uploaded Statements</h1>
                        <div className="flex py-2">
                          <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-purple-100 text-purple-800">
                            <CurrencyDollarIcon
                              className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-purple-400"
                              aria-hidden="true"
                            />
                            {formatMoney(Number(matchableEmployeeContributionAmount))} matchable employee contribution
                            amount
                          </span>
                        </div>

                        <div className="rounded-md border bg-yellow-50 p-4 my-2">
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                            </div>
                            <div className="ml-3">
                              <h3 className="text-sm font-medium text-yellow-800">Match verification</h3>
                              <div className="mt-2 text-sm text-yellow-700">
                                <p>
                                  Please upload your most recent loan statements that include direct payments that you
                                  would like to be matched by your employer. Once your statements are uploaded, the
                                  Unsaddl team will verify your payments that are eligible to be matched.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="rounded-md border bg-yellow-50 p-4 my-2">
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                            </div>
                            <div className="ml-3">
                              <h3 className="text-sm font-medium text-yellow-800">Manual loan verification</h3>
                              <div className="mt-2 text-sm text-yellow-700">
                                <p>
                                  Please upload a document that verifies the account numbers you have entered. Payments
                                  will not be disbursed towards unverified loan accounts.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="py-2">
                      <button
                        onClick={handleFileUploadClick}
                        className="inline-flex items-center justify-center rounded-md border bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 sm:w-auto"
                      >
                        {isUploadingStatement ? (
                          <>
                            <div className="mr-1.5">
                              <Ring size={20} lineWeight={5} speed={2} color="gray" />
                            </div>
                            Uploading...
                          </>
                        ) : (
                          <>
                            <UploadIcon className="flex flex-shrink-0 mr-1.5 h-5 w-5" aria-hidden="true" />
                            Upload documents
                          </>
                        )}
                      </button>
                      <input type="file" ref={hiddenFileInput} hidden onChange={handleFileUpload} />
                    </div>
                    {statementUploads && statementUploads.length > 0 && (
                      <div className="flex flex-col">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead>
                            <tr>
                              <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                                File name
                              </th>

                              <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                                Amount submitted
                              </th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {statementUploads.map((statementUpload) => (
                              <React.Fragment key={statementUpload.id}>
                                <tr key={statementUpload.id}>
                                  <td className="py-4 text-left text-sm text-gray-500">
                                    {statementUpload.gcsFileUpload.fileName}
                                  </td>

                                  <td className="py-4 text-left text-sm text-gray-500">
                                    {statementUpload.statementUploadReviewStatus === 'manual_verified' ? (
                                      <span className="inline-flex px-2 py-1 text-xs leading-5 font-semibold rounded-md bg-green-100 text-green-800">
                                        <CheckCircleIcon
                                          className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                          aria-hidden="true"
                                        />
                                        Manual accounts verified
                                      </span>
                                    ) : statementUpload.statementUploadReviewStatus === 'needs_review' ? (
                                      <span className="inline-flex px-2 py-1 text-xs leading-5 font-semibold rounded-md bg-yellow-100 text-yellow-800">
                                        <ClockIcon
                                          className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-yellow-400"
                                          aria-hidden="true"
                                        />
                                        Document under review
                                      </span>
                                    ) : statementUpload.statementUploadReviewStatus === 'denied' ? (
                                      <span
                                        className="inline-flex cursor-pointer px-2 py-1 text-xs leading-5 font-semibold rounded-md bg-red-100 hover:bg-red-200 text-red-800"
                                        onClick={() => {
                                          setDeniedStatementUpload(statementUpload);
                                          setOpenDeniedStatementUploadModal(true);
                                        }}
                                      >
                                        <XCircleIcon
                                          className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-red-400"
                                          aria-hidden="true"
                                        />
                                        Denied
                                      </span>
                                    ) : statementUpload.statementUploadReviewStatus === 'approved' ? (
                                      <span className="inline-flex px-2 py-1 text-xs leading-5 font-semibold rounded-md bg-green-100 text-green-800">
                                        <CheckCircleIcon
                                          className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                          aria-hidden="true"
                                        />
                                        {formatMoney(Number(statementUpload.matchableEmployeeContribution))} match
                                        eligible
                                      </span>
                                    ) : (
                                      <span className="inline-flex px-2 py-1 text-xs leading-5 font-semibold rounded-md bg-yellow-100 text-yellow-800">
                                        <ClockIcon
                                          className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-yellow-400"
                                          aria-hidden="true"
                                        />
                                        Statement verification expired
                                      </span>
                                    )}
                                  </td>

                                  <td>
                                    <div className="ml-4 flex-shrink-0 flex space-x-1">
                                      <DownloadIcon
                                        className="flex flex-shrink-0 h-5 w-5 cursor-pointer text-gray-500 hover:text-gray-900"
                                        onClick={() => {
                                          handleStatementDownload(loanServicer!.id, statementUpload.id);
                                        }}
                                        aria-hidden="true"
                                      />

                                      <TrashIcon
                                        className="flex flex-shrink-0 h-5 w-5 cursor-pointer text-red-600 hover:text-red-800"
                                        onClick={() => {
                                          startDeleteStatement(statementUpload);
                                        }}
                                        aria-hidden="true"
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                )}
                <DeniedStatementUploadMemoModal
                  deniedStatementUpload={deniedStatementUpload}
                  open={openDeniedStatementUploadModal}
                  setOpen={setOpenDeniedStatementUploadModal}
                />
                <DeleteStatementModal
                  loanServicer={loanServicer}
                  statementUpload={toDeleteStatement}
                  open={openDeleteStatementModal}
                  setOpen={setOpenDeleteStatementModal}
                  onStatementDelete={getStatementUploads}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ManageStatementUploadsModal;
