import EditAdminModal from 'admin/ManageOrganizationUsers/ViewUser/ViewAdmin/EditAdminModal';
import ViewAdminBreadcrumb from 'admin/ManageOrganizationUsers/ViewUser/ViewAdmin/ViewAdminBreadcrumb';
import ViewAdminHeader from 'admin/ManageOrganizationUsers/ViewUser/ViewAdmin/ViewAdminHeader';
import ViewAdminProfile from 'admin/ManageOrganizationUsers/ViewUser/ViewAdmin/ViewAdminProfile';

import { apiGetAdmin } from 'api/organizationUsersAPI';
import EmptyPlaceholder from 'common/EmptyPlaceholder';
import PageLoad from 'components/Load/PageLoad';
import { OrganizationUser } from 'interfaces/organizationUserInterfaces';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AuthenticatedRoute from 'routes/AuthenticatedRoute';

interface ViewAdminRouteParams {
  organizationUserId: string;
}

const ViewAdmin: React.FC = () => {
  const { organizationUserId } = useParams<ViewAdminRouteParams>();
  const [isLoadingAdmin, setIsLoadingAdmin] = useState(true);
  const [admin, setAdmin] = useState<OrganizationUser>();
  const [openEditAdminModal, setOpenEditAdminModal] = useState(false);

  const getAdmin = async () => {
    setIsLoadingAdmin(true);
    apiGetAdmin(organizationUserId).then(
      (data) => {
        const loadedAdmin: OrganizationUser = data.getAdmin;
        setAdmin(loadedAdmin);
        setIsLoadingAdmin(false);
      },
      (error) => {
        setIsLoadingAdmin(false);
      }
    );
  };

  useEffect(() => {
    getAdmin();
  }, []);
  return (
    <>
      {isLoadingAdmin ? (
        <PageLoad />
      ) : (
        <>
          {admin ? (
            <>
              <main className="flex-1 relative z-0 focus:outline-none xl:order-last">
                <ViewAdminBreadcrumb admin={admin} />
                <ViewAdminHeader admin={admin} setOpenEditAdminModal={setOpenEditAdminModal} />
                <AuthenticatedRoute
                  path={`/admins/${admin?.id}/view/profile`}
                  render={() => {
                    return <ViewAdminProfile admin={admin} />;
                  }}
                />

                <EditAdminModal
                  open={openEditAdminModal}
                  setOpen={setOpenEditAdminModal}
                  admin={admin}
                  refreshAdmin={getAdmin}
                />
              </main>
            </>
          ) : (
            <EmptyPlaceholder title={'Admin not found'} subtitle={'This admin does not exist.'} />
          )}
        </>
      )}
    </>
  );
};

export default ViewAdmin;
