import { UserDataContext } from 'context/UserContext';
import AccountsPage from 'participant/Accounts/AccountsPage';
import ParticipantPlansPage from 'participant/ContributionPlans/ParticipantPlansPage';
import HSAPage from 'participant/HSA/HSAPage';
import LoanRefinancingPage from 'participant/LoanRefinancing/LoanRefinancingPage';

import ParticipantHomePage from 'participant/ParticipantHome/ParticipantHomePage';
import PaymentsPage from 'participant/Payments/PaymentsPage';
import ParticipantTuitionReimbursementPage from 'participant/TuitionReimbursement/ParticipantTuitionReimbursementPage';
import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import AuthenticatedRoute from 'routes/AuthenticatedRoute';

const ParticipantRoutes: React.FC = () => {
  const { currentOrganizationUser } = useContext(UserDataContext);
  const organizationId = currentOrganizationUser?.organizationId;

  return (
    <>
      <Route
        exact
        path="/"
        render={() => {
          return <Redirect to="/home" />;
        }}
      />
      <AuthenticatedRoute
        path="/home"
        render={() => {
          return <ParticipantHomePage />;
        }}
      />
      <AuthenticatedRoute
        path="/accounts"
        render={() => {
          return <AccountsPage />;
        }}
      />
      <AuthenticatedRoute
        path="/plans"
        render={() => {
          return <ParticipantPlansPage />;
        }}
      />
      <AuthenticatedRoute
        path="/payments"
        render={() => {
          return <PaymentsPage />;
        }}
      />
      <AuthenticatedRoute
        path="/tuition-reimbursement"
        render={() => {
          return <ParticipantTuitionReimbursementPage />;
        }}
      />
      <AuthenticatedRoute
        path="/student-loan-refinancing"
        render={() => {
          return <LoanRefinancingPage />;
        }}
      />
      {(organizationId === '855e8c98-1b7b-488c-ad6c-2a59c3036d28' ||
        organizationId === 'dfc538fe-4108-421f-8d91-dcf5bce9a44d') && (
        <AuthenticatedRoute
          path="/hsa"
          render={() => {
            return <HSAPage />;
          }}
        />
      )}
    </>
  );
};

export default ParticipantRoutes;
