import { Dialog, Transition } from '@headlessui/react';
import { DownloadIcon, TrashIcon, UploadIcon, XIcon } from '@heroicons/react/outline';
import { ExclamationIcon } from '@heroicons/react/solid';
import { Ring } from '@uiball/loaders';
import {
  apiGetSavings529AccountDocumentLink,
  apiGetSavings529AccountDocuments,
  apiUploadSavings529AccountDocument,
} from 'api/savings529API';
import { Savings529Account, Savings529AccountDocumentUpload } from 'interfaces/savings529Interfaces';
import DeleteSavings529AccountDocumentUploadModal from 'participant/Accounts/Savings529Account/modals/DeleteSavings529AccountDocumentUploadModal';
import React, { Fragment, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

interface Manage529AccountDocumentUploadsModalProps {
  savings529Account?: Savings529Account;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Manage529AccountDocumentUploadsModal: React.FC<Manage529AccountDocumentUploadsModalProps> = ({
  savings529Account,
  open,
  setOpen,
}) => {
  const [isLoadingDocumentUploads, setIsLoadingDocumentUploads] = useState(false);
  const [documentUploads, setDocumentUploads] = useState<Savings529AccountDocumentUpload[]>([]);
  const [isUploadingDocument, setIsUploadingDocument] = useState(false);
  const [openDeleteDocumentModal, setOpenDeleteDocumentModal] = useState(false);
  const [toDeleteDocument, setToDeleteDocument] = useState<Savings529AccountDocumentUpload>();
  const accountName = savings529Account?.name;

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleFileUploadClick = () => {
    if (hiddenFileInput.current != null) {
      hiddenFileInput.current.click();
    }
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsUploadingDocument(true);
    const files = e.target.files;
    const formData = new FormData();
    if (files) {
      formData.append('file', files[0]);
      apiUploadSavings529AccountDocument(savings529Account?.id, formData).then(
        (data) => {
          setIsUploadingDocument(false);
          toast.success('Successfully uploaded 529 account document');
          getAccountDocuments();
        },
        (error) => {
          setIsUploadingDocument(false);
          toast.error(error.message);
        }
      );
    }
  };

  const getAccountDocuments = async () => {
    setIsLoadingDocumentUploads(true);
    apiGetSavings529AccountDocuments(savings529Account?.id).then(
      (data) => {
        setDocumentUploads(data.documentUploads);
        setIsLoadingDocumentUploads(false);
      },
      (error) => {
        setIsLoadingDocumentUploads(false);
      }
    );
  };

  const handleDocumentDownload = async (savings529AccountId: string, documentUploadId: string) => {
    apiGetSavings529AccountDocumentLink(savings529AccountId, documentUploadId).then(
      (data) => {
        window.open(data.documentUploadUrl);
      },
      (error) => {
        toast.error('Failed to download statement');
      }
    );
  };

  const startDeleteDocument = (savings529AccountDocumentUpload: Savings529AccountDocumentUpload) => {
    setToDeleteDocument(savings529AccountDocumentUpload);
    setOpenDeleteDocumentModal(true);
  };

  useEffect(() => {
    if (open) {
      getAccountDocuments();
    }
  }, [open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div className="absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                {isLoadingDocumentUploads ? (
                  <div className="flex justify-center my-12">
                    <Ring size={40} lineWeight={5} speed={2} color="gray" />
                  </div>
                ) : (
                  <div className="px-4 sm:px-6">
                    <div className="sm:flex sm:items-center">
                      <div className="sm:flex-auto">
                        <h1 className="text-xl font-semibold text-gray-900">{accountName} Uploaded Documents</h1>

                        <div className="rounded-md border bg-yellow-50 p-4 my-2">
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                            </div>
                            <div className="ml-3">
                              <h3 className="text-sm font-medium text-yellow-800">Account verification</h3>
                              <div className="mt-2 text-sm text-yellow-700">
                                <p>
                                  Please upload documents that verify ownership of your 529 account. Once your documents
                                  are uploaded, the Unsaddl team will verify your accounts that are eligible to receive
                                  contributions.
                                </p>
                              </div>
                              <div className="py-2">
                                <button
                                  onClick={handleFileUploadClick}
                                  className="inline-flex items-center justify-center rounded-md border bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 sm:w-auto"
                                >
                                  {isUploadingDocument ? (
                                    <>
                                      <div className="mr-1.5">
                                        <Ring size={20} lineWeight={5} speed={2} color="gray" />
                                      </div>
                                      Uploading...
                                    </>
                                  ) : (
                                    <>
                                      <UploadIcon className="flex flex-shrink-0 mr-1.5 h-5 w-5" aria-hidden="true" />
                                      Upload documents
                                    </>
                                  )}
                                </button>
                                <input type="file" ref={hiddenFileInput} hidden onChange={handleFileUpload} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {documentUploads && documentUploads.length > 0 && (
                      <div className="flex flex-col">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead>
                            <tr>
                              <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                                File name
                              </th>

                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {documentUploads.map((documentUpload) => (
                              <React.Fragment key={documentUpload.id}>
                                <tr key={documentUpload.id}>
                                  <td className="py-4 text-left text-sm text-gray-500">
                                    {documentUpload.gcsFileUpload?.fileName}
                                  </td>

                                  <td>
                                    <div className="ml-4 flex-shrink-0 flex space-x-1">
                                      <DownloadIcon
                                        className="flex flex-shrink-0 h-5 w-5 cursor-pointer text-gray-500 hover:text-gray-900"
                                        onClick={() => {
                                          handleDocumentDownload(savings529Account!.id, documentUpload.id);
                                        }}
                                        aria-hidden="true"
                                      />

                                      <TrashIcon
                                        className="flex flex-shrink-0 h-5 w-5 cursor-pointer text-red-600 hover:text-red-800"
                                        onClick={() => {
                                          startDeleteDocument(documentUpload);
                                        }}
                                        aria-hidden="true"
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                )}

                <DeleteSavings529AccountDocumentUploadModal
                  savings529Account={savings529Account}
                  documentUpload={toDeleteDocument}
                  open={openDeleteDocumentModal}
                  setOpen={setOpenDeleteDocumentModal}
                  onDocumentDelete={getAccountDocuments}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Manage529AccountDocumentUploadsModal;
