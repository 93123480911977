import InvitedUsersTable from 'admin/ManageOrganizationUsers/InvitedUsers/InvitedUsersTable/InvitedUsersTable';
import RevokeInvitedUserModal from 'admin/ManageOrganizationUsers/InvitedUsers/RevokeInvitedUsersModal';
import PageLoad from 'components/Load/PageLoad';
import { OrganizationUser } from 'interfaces/organizationUserInterfaces';
import React, { useState } from 'react';

interface InvitedUsersProps {
  searchTerm: string;
  role: string;
  isLoadingUsers: boolean;
  invitedUsers: OrganizationUser[];
  removeOrganizationUser: (organizationUserId: string) => void;
}

const InvitedUsers: React.FC<InvitedUsersProps> = ({
  searchTerm,
  role,
  isLoadingUsers,
  invitedUsers,
  removeOrganizationUser,
}) => {
  const [openRevokeInvitedUserModal, setOpenRevokeInvitedUserModal] = useState(false);
  const [toRevokeInvitedUser, setToRevokeInvitedUser] = useState<OrganizationUser>();

  const startRevokeInvitedUser = (invitedUser: OrganizationUser) => {
    setToRevokeInvitedUser(invitedUser);
    setOpenRevokeInvitedUserModal(true);
  };

  return (
    <>
      <div className="mx-auto">
        {isLoadingUsers ? (
          <PageLoad />
        ) : (
          <InvitedUsersTable
            searchTerm={searchTerm}
            invitedUsers={invitedUsers}
            startRevokeInvitedUser={startRevokeInvitedUser}
          />
        )}
      </div>
      <RevokeInvitedUserModal
        open={openRevokeInvitedUserModal}
        setOpen={setOpenRevokeInvitedUserModal}
        invitedUser={toRevokeInvitedUser}
        removeOrganizationUser={removeOrganizationUser}
        role={role}
      />
    </>
  );
};

export default InvitedUsers;
