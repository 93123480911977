import { PlusIcon } from '@heroicons/react/solid';
import { TuitionReimbursementGrant } from 'interfaces/tuitionReimbursementInterfaces';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { classNames } from 'utils/tailwindUtils';

interface ViewGrantHeaderProps {
  tuitionReimbursementGrant: TuitionReimbursementGrant;
  setOpenCreateNewRequestModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ViewGrantTabs {
  name: string;
  href: string;

  current: boolean;
}

const ViewGrantHeader: React.FC<ViewGrantHeaderProps> = ({
  tuitionReimbursementGrant,
  setOpenCreateNewRequestModal,
}) => {
  const location = useLocation();
  const locationPath = location.pathname;
  const tabs: ViewGrantTabs[] = [
    {
      name: 'Requests',
      href: `/tuition-reimbursement/plans/${tuitionReimbursementGrant.tuitionReimbursementPlan?.id}/view/grants/${tuitionReimbursementGrant.id}/requests`,
      current:
        locationPath ===
        `/tuition-reimbursement/plans/${tuitionReimbursementGrant.tuitionReimbursementPlan?.id}/view/grants/${tuitionReimbursementGrant.id}/requests`,
    },
  ];

  return (
    <article>
      <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-900 ">
        "{tuitionReimbursementGrant.tuitionReimbursementPlan?.name}" Plan
      </h1>
      <div className="mt-1 text-gray-500">{tuitionReimbursementGrant.tuitionReimbursementPlan?.description}</div>
      <div className="lg:flex lg:items-center lg:justify-between">
        <div className="flex flex-col sm:flex-row space-y-1">
          <button
            onClick={() => {
              setOpenCreateNewRequestModal(true);
            }}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <PlusIcon className="-ml-1 mr-2 h-5 w-" aria-hidden="true" />
            Create new request
          </button>
        </div>
      </div>
      {/* Tabs */}
      <div className="border-b border-gray-200 overflow-x-auto scrollbar-hide">
        <nav className="flex space-x-8" aria-label="Tabs">
          {tabs.map((tab) => (
            <Link
              key={tab.name}
              to={tab.href}
              className={classNames(
                tab.current
                  ? 'border-purple-500 text-gray-900'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              {tab.name}
            </Link>
          ))}
        </nav>
      </div>
    </article>
  );
};

export default ViewGrantHeader;
