import { Menu } from '@headlessui/react';
import { DotsVerticalIcon, XCircleIcon } from '@heroicons/react/outline';
import { CheckCircleIcon, UserIcon } from '@heroicons/react/solid';
import DeletePayrollConnectionModal from 'admin/PayrollConnections/DeletePayrollConnectionModal';
import PayrollConnectionMenuItems from 'admin/PayrollConnections/PayrollConnectionMenuItems';
import MenuOptions from 'components/MenuOptions';
import { PayrollConnection } from 'interfaces/organizationUserInterfaces';
import React, { useState } from 'react';
import { abbrevDate } from 'utils/dateUtils';

interface PayrollConnectionElementProps {
  payrollConnection: PayrollConnection;
  getPayrollConnections: () => void;
}

const PayrollConnectionElement: React.FC<PayrollConnectionElementProps> = ({
  payrollConnection,
  getPayrollConnections,
}) => {
  const [openDeletePayrollConnectionModal, setOpenDeletePayrollConnectionModal] = useState(false);

  return (
    <div className="bg-white shadow rounded-sm ring-1 ring-black ring-opacity-5 mt-4">
      <div className="block">
        <div className="flex items-center px-4 py-4">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0">
              <img
                className="h-12 w-12 rounded-full border"
                src={payrollConnection.finchPayrollProvider?.icon}
                alt=""
              />
            </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <p className="text-sm font-medium text-indigo-600 truncate">
                  {payrollConnection.finchPayrollProvider?.displayName}
                </p>
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  <UserIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  <span className="truncate">{payrollConnection.finchUsername}</span>
                </p>
              </div>
              <div className="hidden md:block">
                <div>
                  <p className="text-sm text-gray-900">
                    {payrollConnection.lastRefresh
                      ? `Last refreshed: ${abbrevDate(payrollConnection.lastRefresh)}`
                      : 'Awaiting connection...'}
                  </p>
                  {payrollConnection.needsReauthentication ? (
                    <p className="mt-2 flex items-center text-sm text-gray-500">
                      <XCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400" aria-hidden="true" />
                      Needs reauthentication
                    </p>
                  ) : (
                    <p className="mt-2 flex items-center text-sm text-gray-500">
                      <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" aria-hidden="true" />
                      Active
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            <MenuOptions
              menuButton={
                <Menu.Button className="group-hover:visible inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <DotsVerticalIcon className="h-5 w-5 text-gray-500" />
                </Menu.Button>
              }
              menuItems={
                <PayrollConnectionMenuItems
                  payrollConnection={payrollConnection}
                  refreshPayrollConnections={getPayrollConnections}
                  setOpenDeletePayrollConnectionModal={setOpenDeletePayrollConnectionModal}
                />
              }
            />
          </div>
        </div>
        <DeletePayrollConnectionModal
          payrollConnection={payrollConnection}
          getPayrollConnections={getPayrollConnections}
          open={openDeletePayrollConnectionModal}
          setOpen={setOpenDeletePayrollConnectionModal}
        />
      </div>
    </div>
  );
};

export default PayrollConnectionElement;
