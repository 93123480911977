import { ChevronRightIcon, HomeIcon } from '@heroicons/react/solid';
import { TuitionReimbursementPlan } from 'interfaces/tuitionReimbursementInterfaces';

import React from 'react';
import { Link } from 'react-router-dom';

interface ViewPlanBreadcrumbProps {
  tuitionReimbursementPlan: TuitionReimbursementPlan;
}

const ViewPlanBreadcrumb: React.FC<ViewPlanBreadcrumbProps> = ({ tuitionReimbursementPlan }) => {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div>
            <Link to="/" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        <li>
          <div className="flex items-center">
            <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
            <Link
              to="/tuition-reimbursement/list/plans"
              className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
            >
              Plans
            </Link>
          </div>
        </li>
        <li>
          <div className="flex items-center">
            <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
            <div className="ml-4 text-sm font-medium text-gray-500">{tuitionReimbursementPlan?.name}</div>
          </div>
        </li>
      </ol>
    </nav>
  );
};

export default ViewPlanBreadcrumb;
