import { DuplicateIcon } from '@heroicons/react/outline';
import { LoanServicer } from 'interfaces/loansInterfaces';
import ElectContributionsAccount from 'participant/ContributionPlans/ElectContributions/ElectContributionsAccount';
import React from 'react';
import { formatMoney } from 'utils/numberFormatter';

interface ElectContributionsServicerProps {
  loanServicer: LoanServicer;
  contributionElectionAmountsByLoan: { [key: string]: number };
  setContributionElectionAmountForLoan: (loanAccountId: string, amount: number) => void;
  contributionElectionPercentagesByLoan: { [key: string]: number };
  setContributionElectionPercentageForLoan: (loanAccountId: string, amount: number) => void;
  isEditingContributions: boolean;
  electionType: string;
}

const ElectContributionsServicer: React.FC<ElectContributionsServicerProps> = ({
  loanServicer,
  contributionElectionAmountsByLoan,
  setContributionElectionAmountForLoan,
  contributionElectionPercentagesByLoan,
  setContributionElectionPercentageForLoan,
  isEditingContributions,
  electionType,
}) => {
  const loanAccounts = loanServicer.loanAccounts;
  const activeLoanAccounts = loanAccounts.filter((loanAccount) => loanAccount.active);
  const approvedStatementUploadMatchSum = loanServicer.statementUploads.reduce(
    (loanServicerMatchableEmployeeContribution, statementUpload) =>
      loanServicerMatchableEmployeeContribution +
      (statementUpload.statementUploadReviewStatus === 'approved' && statementUpload.matchableEmployeeContribution
        ? Number(statementUpload.matchableEmployeeContribution)
        : 0),
    0
  );

  return (
    <div className="px-6 py-4 shadow-md ring-1 ring-black ring-opacity-10 rounded-lg bg-white">
      <h1 className="text-lg font-bold text-gray-900">{loanServicer.name}</h1>
      {approvedStatementUploadMatchSum > 0 && (
        <div className="mt-1 ">
          <div className="inline-flex rounded-md bg-green-100 px-2 py-1 text-sm leading-5 text-green-800">
            <DuplicateIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" aria-hidden="true" />
            Qualifying payments for employer match: {formatMoney(Number(approvedStatementUploadMatchSum))}
          </div>
        </div>
      )}

      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th scope="col" className="py-3.5 pr-2 text-left text-sm font-semibold text-gray-500">
              Account #
            </th>
            <th scope="col" className="py-3.5 px-2 text-left text-sm font-semibold text-gray-500 hidden xs:table-cell">
              Name
            </th>
            <th scope="col" className="py-3.5 px-2 text-left text-sm font-semibold text-gray-500 hidden sm:table-cell">
              Interest rate
            </th>
            <th scope="col" className="py-3.5 px-2 text-left text-sm font-semibold text-gray-500 hidden sm:table-cell">
              Current balance
            </th>
            <th scope="col" className="py-3.5 pl-2 text-left text-sm font-semibold text-gray-500">
              Your elections
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {activeLoanAccounts.map((activeLoanAccount) => (
            <ElectContributionsAccount
              key={activeLoanAccount.id}
              loanAccount={activeLoanAccount}
              contributionElectionAmountsByLoan={contributionElectionAmountsByLoan}
              setContributionElectionAmountForLoan={setContributionElectionAmountForLoan}
              contributionElectionPercentagesByLoan={contributionElectionPercentagesByLoan}
              setContributionElectionPercentageForLoan={setContributionElectionPercentageForLoan}
              isEditingContributions={isEditingContributions}
              electionType={electionType}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ElectContributionsServicer;
