import ManageOrganizationUsersPage from 'admin/ManageOrganizationUsers/ManageOrganizationUsersPage';
import ViewParticipant from 'admin/ManageOrganizationUsers/ViewUser/ViewParticipant/ViewParticipant';
import EmptyPlaceholder from 'common/EmptyPlaceholder';
import { UserDataContext } from 'context/UserContext';
import React, { useContext } from 'react';
import AuthenticatedRoute from 'routes/AuthenticatedRoute';

const ParticipantsPage: React.FC = () => {
  const { currentOrganizationUser } = useContext(UserDataContext);

  return currentOrganizationUser?.adminProfile?.adminPermissions &&
    currentOrganizationUser?.adminProfile?.adminPermissions.includes('user_management') ? (
    <>
      <AuthenticatedRoute
        path={`/participants/list`}
        render={() => {
          return (
            <div className="my-6 px-8 mx-auto max-w-7xl">
              <h1 className="text-2xl font-bold text-gray-900 capitalize">Participants</h1>
              <ManageOrganizationUsersPage role="participants" />
            </div>
          );
        }}
      />
      <AuthenticatedRoute
        path={`/participants/:organizationUserId/view`}
        render={() => {
          return (
            <div className="my-6 px-8 mx-auto max-w-7xl">
              <ViewParticipant />
            </div>
          );
        }}
      />
    </>
  ) : (
    <div className="my-6 px-8 mx-auto max-w-7xl">
      <EmptyPlaceholder
        title="Access denied"
        subtitle="Your organization super admin has not given you permission to access this page"
      />
    </div>
  );
};

export default ParticipantsPage;
