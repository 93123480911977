/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon, ClockIcon, MinusSmIcon, XIcon } from '@heroicons/react/solid';
import { TuitionReimbursementRequest } from 'interfaces/tuitionReimbursementInterfaces';
import { classNames } from 'utils/tailwindUtils';

interface TuitionReimbursementTimelineProps {
  tuitionReimbursementRequest?: TuitionReimbursementRequest;
}

const TuitionReimbursementTimeline: React.FC<TuitionReimbursementTimelineProps> = ({ tuitionReimbursementRequest }) => {
  const reviewStatus = tuitionReimbursementRequest?.reviewStatus;

  const timeline = [
    {
      id: 1,
      content: 'Submitted',
      target: '',
      href: '#',
      icon: CheckIcon,
      iconBackground: 'bg-green-500',
    },
    {
      id: 2,
      content: 'Company approval',
      target: '',
      href: '#',

      icon: reviewStatus === 'submitted' ? ClockIcon : reviewStatus === 'course_denied' ? XIcon : CheckIcon,
      iconBackground:
        reviewStatus === 'submitted'
          ? 'bg-yellow-500'
          : reviewStatus === 'course_denied'
          ? 'bg-red-500'
          : 'bg-green-500',
    },
    {
      id: 3,
      content: 'Course completion',
      target: '',
      href: '#',
      icon:
        reviewStatus === 'course_approved'
          ? ClockIcon
          : reviewStatus === 'course_denied' || reviewStatus === 'submitted'
          ? MinusSmIcon
          : CheckIcon,
      iconBackground:
        reviewStatus === 'course_approved'
          ? 'bg-yellow-500'
          : reviewStatus === 'course_denied' || reviewStatus === 'submitted'
          ? 'bg-gray-500'
          : 'bg-green-500',
    },
    {
      id: 4,
      content: 'Completion review',
      target: '',
      href: '#',
      icon:
        reviewStatus === 'completion_denied'
          ? XIcon
          : reviewStatus === 'course_completed'
          ? ClockIcon
          : reviewStatus === 'completion_approved' || reviewStatus === 'paid'
          ? CheckIcon
          : MinusSmIcon,
      iconBackground:
        reviewStatus === 'completion_denied'
          ? 'bg-red-500'
          : reviewStatus === 'course_completed'
          ? 'bg-yellow-500'
          : reviewStatus === 'completion_approved' || reviewStatus === 'paid'
          ? 'bg-green-500'
          : 'bg-gray-500',
    },
    {
      id: 5,
      content: 'Payment',
      target: '',
      href: '#',

      icon: reviewStatus === 'paid' ? CheckIcon : reviewStatus === 'completion_approved' ? ClockIcon : MinusSmIcon,
      iconBackground:
        reviewStatus === 'paid'
          ? 'bg-green-500'
          : reviewStatus === 'completion_approved'
          ? 'bg-yellow-500'
          : 'bg-gray-500',
    },
  ];

  return (
    <div className="flow-root">
      <ul className="-mb-8">
        {timeline.map((event, eventIdx) => (
          <li key={event.id}>
            <div className="relative pb-8">
              {eventIdx !== timeline.length - 1 ? (
                <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  <span
                    className={classNames(
                      event.iconBackground,
                      'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                    )}
                  >
                    <event.icon className="h-5 w-5 text-white" aria-hidden="true" />
                  </span>
                </div>
                <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                  <div>
                    <p className="text-sm text-gray-500">
                      {event.content}{' '}
                      <a href={event.href} className="font-medium text-gray-900">
                        {event.target}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TuitionReimbursementTimeline;
