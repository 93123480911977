/* This example requires Tailwind CSS v2.0+ */
import { CheckCircleIcon, ClockIcon } from '@heroicons/react/outline';
import { TuitionReimbursementPayment } from 'interfaces/tuitionReimbursementInterfaces';
import { formatMoney } from 'utils/numberFormatter';

interface TuitionReimbursementPaymentColumnProps {
  tuitionReimbursementPayment: TuitionReimbursementPayment;
}

const TuitionReimbursementPaymentColumn: React.FC<TuitionReimbursementPaymentColumnProps> = ({
  tuitionReimbursementPayment,
}) => {
  let sentTotal = tuitionReimbursementPayment.amountSent;
  let submittedTotal = tuitionReimbursementPayment.amountSubmitted;
  let tuitionReimbursementPaymentAwaitingFunding = tuitionReimbursementPayment.fundStatus === 'awaiting_funding';

  return (
    <div className="flex items-center justify-end">
      <div className="flex-col">
        <div className="flex justify-end">
          {sentTotal === submittedTotal ? (
            <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-green-100 text-green-800">
              <CheckCircleIcon
                className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                aria-hidden="true"
              />
              {formatMoney(Number(sentTotal))} / {formatMoney(Number(submittedTotal))}
            </span>
          ) : (
            <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-yellow-100 text-yellow-800">
              <ClockIcon className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-yellow-400" aria-hidden="true" />
              {formatMoney(Number(sentTotal))} / {formatMoney(Number(submittedTotal))}
            </span>
          )}
        </div>
        {tuitionReimbursementPaymentAwaitingFunding && (
          <div className="text-xs text-gray-500">Awaiting employer funding</div>
        )}
      </div>
    </div>
  );
};

export default TuitionReimbursementPaymentColumn;
