import { Menu } from '@headlessui/react';
import { ExternalBankAccount } from 'interfaces/externalBankAccountInterfaces';
import React from 'react';
import { classNames } from 'utils/tailwindUtils';

interface ExternalBankAccountSettingsOptionsProps {
  externalBankAccount: ExternalBankAccount;
  setOpenDeleteExternalBankAccountModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExternalBankAccountSettingsOptions: React.FC<ExternalBankAccountSettingsOptionsProps> = ({
  externalBankAccount,
  setOpenDeleteExternalBankAccountModal,
}) => {
  return (
    <>
      <div className="py-1 px-1">
        <Menu.Item>
          {({ active }) => (
            <div
              className={classNames(
                active ? 'bg-gray-100' : '',
                'text-red-500 block px-4 py-2 text-sm text-center cursor-pointer'
              )}
              onClick={() => setOpenDeleteExternalBankAccountModal(true)}
            >
              Delete
            </div>
          )}
        </Menu.Item>
      </div>
    </>
  );
};

export default ExternalBankAccountSettingsOptions;
