import { Menu, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, LogoutIcon } from '@heroicons/react/outline';
import { logOut } from 'config/firebaseConfig';
import { UserDataContext } from 'context/UserContext';
import { OrganizationUser } from 'interfaces/organizationUserInterfaces';
import { Fragment, useContext } from 'react';
import { getRoleText } from 'utils/roleUtils';
import { classNames } from 'utils/tailwindUtils';

const UserDropdown: React.FC = () => {
  const { currentOrganizationUser, orgUsersById, switchCurrentOrganizationUser } = useContext(UserDataContext);
  const firstName = currentOrganizationUser?.firstName;
  const lastName = currentOrganizationUser?.lastName;
  const organizationUsers: OrganizationUser[] = orgUsersById ? Object.values(orgUsersById) : [];
  const activeRoleText = getRoleText(currentOrganizationUser?.role);

  return (
    <>
      {/* User account dropdown */}
      <Menu as="div" className="ml-3 relative inline-block text-left">
        <div>
          <Menu.Button className="group w-full rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-200">
            <span className="flex w-full justify-between items-center">
              <span className="flex min-w-0 items-center justify-between space-x-3">
                <span className="inline-flex items-center justify-center h-12 w-12 rounded-full bg-indigo-500">
                  <span className="text-xl font-medium leading-none text-white">
                    {firstName?.charAt(0).toUpperCase()}
                    {lastName?.charAt(0).toUpperCase()}
                  </span>
                </span>

                <span className="flex-1 flex flex-col min-w-0">
                  <span className="text-gray-900 text-sm font-medium truncate">{firstName + ' ' + lastName}</span>
                  <span className="text-gray-500 text-sm truncate">{activeRoleText}</span>
                  <span className="text-gray-500 text-xs truncate">{currentOrganizationUser?.organization?.name}</span>
                </span>
              </span>
              <span className="relative inline-block ml-2">
                <ChevronDownIcon
                  className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              </span>
            </span>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
            {/* <div className="py-1"> */}
            {/* <Menu.Item>
                {({ active }) => (
                  <Link
                    to="#"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm'
                    )}
                  >
                    Settings
                  </Link>
                )}
              </Menu.Item> */}
            {/* <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm'
                    )}
                  >
                    Notifications
                  </a>
                )}
              </Menu.Item> */}
            {/* </div> */}

            <div className="py-1 px-3 text-sm">
              <div className="py-2 px-2 border-b text-gray-500">Accounts</div>
              <div className="mt-1">
                {organizationUsers.map((organizationUser) => (
                  <Menu.Item key={organizationUser.id}>
                    {({ active }) => (
                      <a
                        href="/home"
                        onClick={() => {
                          switchCurrentOrganizationUser(organizationUser.id);
                        }}
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          !organizationUser.active ? 'opacity-50' : '',
                          'px-2 py-2 cursor-pointer rounded-md flex items-center'
                        )}
                      >
                        <div>
                          <div className="font-medium">{getRoleText(organizationUser.role)}</div>
                          <div>{!organizationUser.active && '(Disabled)'}</div>
                          <div className="text-xs">{organizationUser.organization?.name}</div>
                        </div>
                        {currentOrganizationUser?.id === organizationUser.id && (
                          <CheckIcon className="ml-auto flex-shrink-0 h-4 w-4" />
                        )}
                      </a>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </div>

            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={logOut}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-5 py-2 text-sm cursor-pointer flex items-center'
                    )}
                  >
                    Sign out
                    <LogoutIcon className="ml-auto flex-shrink-0 h-4 w-4" />
                  </a>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default UserDropdown;
