import HSAChangePortfolio from 'participant/HSA/HSAChangePortfolio';
import HSAInvestments from 'participant/HSA/HSAInvestments';
import React, { useEffect, useState } from 'react';
import AuthenticatedRoute from 'routes/AuthenticatedRoute';

const HSAInvestmentsPage: React.FC = () => {
  const [hsaInvestments, setHsaInvestments] = useState<{
    [fundName: string]: number;
  }>({
    vtsax: 80,
    vfiax: 20,
    vigax: 0,
    vlcax: 0,
    vviax: 0,
    vdadx: 0,
    vimax: 0,
  });

  const addHsaInvestment = (fundName: string, allocation: number) => {
    setHsaInvestments((prevValues) => ({ ...prevValues, [fundName]: allocation }));
  };

  useEffect(() => {
    if (localStorage.getItem('hsaInvestments')) {
      const localHsaInvestments = JSON.parse(localStorage.getItem('hsaInvestments')!);
      if (localHsaInvestments) {
        setHsaInvestments(localHsaInvestments);
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('hsaInvestments', JSON.stringify(hsaInvestments));
  }, [hsaInvestments]);

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   setValues((prevValues) => ({ ...prevValues, [name]: value }));
  // };

  // const handleElectionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   setFundElections((prevValues) => ({ ...prevValues, [name]: Number(value) }));
  // };

  return (
    <>
      <AuthenticatedRoute
        path={`/hsa/investments/change-portfolio`}
        exact
        render={() => {
          return <HSAChangePortfolio hsaInvestments={hsaInvestments} addHsaInvestment={addHsaInvestment} />;
        }}
      />
      <AuthenticatedRoute
        path={`/hsa/investments`}
        exact
        render={() => {
          return <HSAInvestments hsaInvestments={hsaInvestments} />;
        }}
      />
    </>
  );
};

export default HSAInvestmentsPage;
