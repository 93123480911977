import { CashIcon } from '@heroicons/react/outline';
import HSAReimbursementModal from 'participant/HSA/HSAReimbursementModal';
import React, { useEffect, useState } from 'react';
import Cards from 'react-credit-cards';
import { abbrevDate } from 'utils/dateUtils';
import { formatMoney } from 'utils/numberFormatter';
import { classNames } from 'utils/tailwindUtils';

const HSAExpenses: React.FC = () => {
  const statusStyles: Record<string, string> = {
    complete: 'bg-green-100 text-green-800',
    processing: 'bg-yellow-100 text-yellow-800',
    awaiting_funding: 'bg-gray-100 text-gray-800',
  };
  const [openHSAReimbursementModal, setOpenHSAReimbursementModal] = useState(false);

  const [hsaExpenses, setHsaExpenses] = useState([
    {
      id: 'test3',
      description: 'Costco Pharmacy Reimbursement',
      amountSubmitted: 20.78,
      status: 'processing',
      createdAt: '10-01-2023',
    },
    {
      id: 'test2',
      description: 'CVS',
      amountSubmitted: 117.16,
      status: 'complete',
      createdAt: '09-01-2023',
    },
    {
      id: 'test1',
      description: 'Brigham MGH Health',
      amountSubmitted: 270,
      status: 'complete',
      createdAt: '08-22-2023',
    },
  ]);

  const addNewExpense = (id: string, description: string, amountSubmitted: number, createdAt: string) => {
    setHsaExpenses((prevValues) => [
      { id, description, amountSubmitted, createdAt, status: 'processing' },
      ...prevValues,
    ]);
  };

  useEffect(() => {
    if (localStorage.getItem('hsaExpenses')) {
      const localHsaExpensesValues = JSON.parse(localStorage.getItem('hsaExpenses')!);
      if (localHsaExpensesValues) {
        setHsaExpenses(localHsaExpensesValues);
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('hsaExpenses', JSON.stringify(hsaExpenses));
  }, [hsaExpenses]);

  return (
    <>
      <div className="px-8 py-4 ring-1 ring-black ring-opacity-10 rounded mt-4">
        <div>
          <div className="flex flex-row items-center">
            <div className="flex-auto">
              <h1 className="text-md text-gray-900">Current balance</h1>
              <div className="flex-auto">
                <h1 className="text-3xl font-bold text-gray-900">{formatMoney(509.31)}</h1>
              </div>
            </div>

            <button
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-full sm:w-auto"
              onClick={() => {
                setOpenHSAReimbursementModal(true);
              }}
            >
              Submit reimbursement
            </button>
          </div>

          <div className="mt-4 flex flex-row items-start">
            <Cards cvc="166" expiry="10/26" name="Steve Troy" number="4222434233497019" preview={true} />
          </div>
        </div>
      </div>

      <div>
        <div className="max-w-7xl mx-auto ">
          <div className="flex flex-col mt-2">
            <div className="align-middle min-w-full overflow-x-auto overflow-hidden sm:rounded-lg shadow-md ring-1 ring-black ring-opacity-10">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Expense
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Amount
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {hsaExpenses.map((hsaExpense) => (
                    <tr key={hsaExpense.id} className="bg-white">
                      <td className="max-w-0 w-full px-6 py-4  text-sm text-gray-900">
                        <div className="flex">
                          <div className="group inline-flex space-x-2 text-sm">
                            <CashIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                            <p className="text-gray-900">{hsaExpense.description}</p>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                        <span className="text-gray-900 font-medium">
                          {formatMoney(Number(hsaExpense.amountSubmitted))}
                        </span>
                      </td>
                      <td className=" px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <span
                          className={classNames(
                            statusStyles[hsaExpense.status],
                            'inline-flex  px-2.5 py-0.5 rounded-md text-xs font-medium capitalize'
                          )}
                        >
                          {hsaExpense.status.replace('_', ' ')}
                        </span>
                      </td>
                      <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                        <time dateTime={hsaExpense.createdAt}>{abbrevDate(hsaExpense.createdAt)}</time>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* Pagination */}
              {/* <nav
                  className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                  aria-label="Pagination"
                >
                  <div className="hidden sm:block">
                    <p className="text-sm text-gray-700">
                      Showing <span className="font-medium">1</span> to <span className="font-medium">10</span> of{' '}
                      <span className="font-medium">20</span> results
                    </p>
                  </div>
                  <div className="flex-1 flex justify-between sm:justify-end">
                    <a
                      href="#"
                      className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Previous
                    </a>
                    <a
                      href="#"
                      className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Next
                    </a>
                  </div>
                </nav> */}
            </div>
          </div>
        </div>
      </div>
      <HSAReimbursementModal
        open={openHSAReimbursementModal}
        setOpen={setOpenHSAReimbursementModal}
        addNewExpense={addNewExpense}
      />
    </>
  );
};

export default HSAExpenses;
