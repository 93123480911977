import { SearchIcon } from '@heroicons/react/outline';
import { Ring } from '@uiball/loaders';
import ViewGrantsTable from 'admin/TuitionReimbursement/ViewPlan/ViewGrantsTable';
import { TuitionReimbursementPlan } from 'interfaces/tuitionReimbursementInterfaces';

import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
interface ViewPlanSummaryProps {
  tuitionReimbursementPlan: TuitionReimbursementPlan;
}

const ViewPlanSummary: React.FC<ViewPlanSummaryProps> = ({ tuitionReimbursementPlan }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);

  const tuitionReimbursementGrants = tuitionReimbursementPlan?.tuitionReimbursementGrants
    ? tuitionReimbursementPlan.tuitionReimbursementGrants
    : [];

  // const maxGrantedAmount = tuitionreimbursementGrants
  //   ? tuitionreimbursementGrants.map((grant) => grant.amount).reduce((acc, curr) => Number(acc) + Number(curr), 0)
  //   : 0;
  // const numFullyElectedParticipants = tuitionreimbursementGrants.reduce(
  //   (fullyElectedParticipantsCounter, tuitionreimbursementGrant) =>
  //     Number(tuitionreimbursementGrant.totalElectionPercentage) === 100
  //       ? fullyElectedParticipantsCounter + 1
  //       : fullyElectedParticipantsCounter,
  //   0
  // );

  const cards = [
    // {
    //   name: 'Elected/eligible participants',
    //   href: '#',
    //   icon: UsersIcon,
    //   amount: `${numFullyElectedParticipants} / ${tuitionreimbursementGrants.length}`,
    //   logoBgColor: 'bg-purple-100',
    //   logoTextColor: 'text-purple-600',
    // },
    // {
    //   name: 'Max amount granted',
    //   href: '#',
    //   icon: CashIcon,
    //   amount: formatMoney(Number(maxGrantedAmount)),
    //   logoBgColor: 'bg-green-100',
    //   logoTextColor: 'text-green-600',
    // },
    // {
    //   name: 'Next election deadline',
    //   href: '#',
    //   icon: CalendarIcon,
    //   amount: tuitionReimbursementPlan?.nextElectionDeadline
    //     ? abbrevDate(tuitionReimbursementPlan.nextElectionDeadline)
    //     : 'Pending...',
    //   logoBgColor: 'bg-sky-100',
    //   logoTextColor: 'text-sky-600',
    // },
    // More items...
  ];

  const setSearchTermDebounced = useAsyncDebounce((searchText: string | undefined) => {
    setSearchTerm(searchText || '');
    setIsLoadingSearch(false);
  }, 1000);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoadingSearch(true);
    setSearchTermDebounced(event.target.value);
  };

  return (
    <>
      <div className="flex flex-col mt-6 md:flex-row md:justify-between md:items-center md:space-x-4 space-y-2">
        <h1 className="text-xl font-bold text-gray-900">All grants</h1>
        <div className="w-full md:max-w-md">
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              {isLoadingSearch ? (
                <Ring size={15} lineWeight={7} speed={2.0} color="gray" />
              ) : (
                <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              )}
            </div>
            <input
              id="search"
              name="search"
              className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-sm"
              placeholder="Search for a user"
              type="search"
              onChange={handleSearch}
            />
          </div>
        </div>
      </div>

      <div className="mt-2 space-y-8 divide-y divide-gray-200">
        <ViewGrantsTable
          tuitionReimbursementPlan={tuitionReimbursementPlan}
          tuitionReimbursementGrants={tuitionReimbursementGrants}
          searchTerm={searchTerm}
        />
      </div>
    </>
  );
};

export default ViewPlanSummary;
