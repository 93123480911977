import { LoanAccount } from 'interfaces/loansInterfaces';
import InactiveLoanAccountRow from 'participant/Accounts/LoanAccount/InactiveLoanAccounts/InactiveLoanAccountRow';
import React from 'react';

interface InactiveLoanAccountsTableProps {
  loanAccounts: LoanAccount[];
  startReactivateAccount: (loanAccount: LoanAccount) => void;
}

const InactiveLoanAccountsTable: React.FC<InactiveLoanAccountsTableProps> = ({
  loanAccounts,
  startReactivateAccount,
}) => {
  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-500">
            Account #
          </th>
          <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-500 hidden xs:table-cell">
            Name
          </th>
          <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-500 hidden sm:table-cell">
            Interest rate
          </th>
          <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-500">
            Current balance
          </th>

          <th scope="col" className="relative pl-3 py-3.5">
            <span className="sr-only">Options</span>
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {loanAccounts.map((loanAccount) => (
          <InactiveLoanAccountRow
            loanAccount={loanAccount}
            startReactivateAccount={startReactivateAccount}
            key={loanAccount.id}
          />
        ))}
      </tbody>
    </table>
  );
};

export default InactiveLoanAccountsTable;
