import api from 'config/axiosConfig';

export const apiGetContributionAccounts = () =>
  api
    .get(`/contributions/accounts`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiGetParticipantGrants = () =>
  api
    .get(`/contributions/grants/participant`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiGetParticipantGrant = (contributionGrantId: string) =>
  api
    .get(`/contributions/grants/${contributionGrantId}`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiSetParticipantElectionsByGrant = (
  contributionGrantId: string,
  electionType: string,
  loanElectionAmounts: string,
  loanElectionPercentages: string,
  savings529ElectionAmounts: string,
  savings529ElectionPercentages: string
) =>
  api
    .post(`/contributions/grants/${contributionGrantId}/elections`, {
      electionType,
      loanElectionAmounts,
      loanElectionPercentages,
      savings529ElectionAmounts,
      savings529ElectionPercentages,
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiGetOrganizationPlans = () =>
  api
    .get(`/contributions/plans/organization`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiSetGrants = (contributionPlanId: string, grants: string) =>
  api
    .post(`/contributions/plans/${contributionPlanId}/grants`, { grants })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiGetOrganizationPlan = (contributionPlanId: string) =>
  api
    .get(`/contributions/plans/${contributionPlanId}`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiCreateNewPlan = (
  name: string,
  description: string,
  defaultContributionAmount: number,
  recurring: boolean,
  schedule: string,
  eligibilityTenureDays: number,
  electionDeadlineDay: number,
  electionDeadlineDate: string,
  contributeStudentLoansEnabled: boolean,
  contribute529Enabled: boolean,
  employeeMatchRequired: boolean,
  matchPercentage: number
) =>
  api
    .post(`/contributions/plans`, {
      name,
      description,
      defaultContributionAmount,
      recurring,
      schedule,
      eligibilityTenureDays,
      electionDeadlineDay,
      electionDeadlineDate,
      contributeStudentLoansEnabled,
      contribute529Enabled,
      employeeMatchRequired,
      matchPercentage,
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiEditPlanDetails = (
  contributionPlanId: string,
  description?: string,
  defaultContributionAmount?: number,
  eligibilityTenureDays?: number
) =>
  api
    .post(`/contributions/plans/${contributionPlanId}/details`, {
      description,
      defaultContributionAmount,
      eligibilityTenureDays,
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiDisablePlan = (contributionPlanId: string) =>
  api
    .post(`/contributions/plans/${contributionPlanId}/disable`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiGetOrganizationPlanRuns = () =>
  api
    .get(`/contributions/runs`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiGetContributionPlanRun = (contributionPlanRunId: string) =>
  api
    .get(`/contributions/runs/${contributionPlanRunId}`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiGetContributionGrantRun = (contributionGrantRunId: string) =>
  api
    .get(`/contributions/runs/grants/${contributionGrantRunId}`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiGetParticipantGrantRuns = () =>
  api
    .get(`/contributions/runs/grants/participant`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });
