import EditGrants from 'admin/ContributionPlans/EditGrants/EditGrants';
import ListPlans from 'admin/ContributionPlans/ListPlans/ListPlans';
import ViewPlan from 'admin/ContributionPlans/ViewPlan/ViewPlan';
import EmptyPlaceholder from 'common/EmptyPlaceholder';
import { UserDataContext } from 'context/UserContext';

import React, { useContext } from 'react';
import AuthenticatedRoute from 'routes/AuthenticatedRoute';

const OrganizationPlansPage: React.FC = () => {
  const { currentOrganizationUser } = useContext(UserDataContext);

  return currentOrganizationUser?.adminProfile?.adminPermissions &&
    currentOrganizationUser?.adminProfile?.adminPermissions.includes('payment_plan_management') ? (
    <>
      <AuthenticatedRoute
        path={`/plans/list`}
        render={() => {
          return <ListPlans />;
        }}
      />
      <AuthenticatedRoute
        path={`/plans/:contributionPlanId/edit`}
        render={() => {
          return <EditGrants />;
        }}
      />
      <AuthenticatedRoute
        path={`/plans/:contributionPlanId/view`}
        render={() => {
          return <ViewPlan />;
        }}
      />
    </>
  ) : (
    <div className="my-6 px-8 mx-auto max-w-7xl">
      <EmptyPlaceholder
        title="Access denied"
        subtitle="Your organization super admin has not given you permission to access this page"
      />
    </div>
  );
};

export default OrganizationPlansPage;
