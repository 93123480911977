import { apiGetParticipantGrantRuns } from 'api/contributionsAPI';
import PaymentsTable from 'common/Contributions/PaymentsTable';
import ViewGrantRunModal from 'common/Contributions/ViewGrantRunModal';
import PageLoad from 'components/Load/PageLoad';
import { UserDataContext } from 'context/UserContext';
import { ContributionGrantRun } from 'interfaces/contributionsInterfaces';

import React, { useContext, useEffect, useState } from 'react';

const StudentLoanPaymentsTab: React.FC = () => {
  const { currentOrganizationUser } = useContext(UserDataContext);

  const [participantGrantRuns, setParticipantGrantRuns] = useState<ContributionGrantRun[]>([]);
  const [isLoadingGrantRuns, setIsLoadingGrantRuns] = useState(true);
  const [viewParticipantGrantRun, setViewParticipantGrantRun] = useState<ContributionGrantRun>();
  const [openViewParticipantGrantRunModa, setOpenViewParticipantGrantRunModal] = useState(false);

  const contributionPlan = viewParticipantGrantRun?.contributionPlanRun?.contributionPlan;

  const startViewGrantRun = (participantGrantRun: ContributionGrantRun) => {
    setViewParticipantGrantRun(participantGrantRun);
    setOpenViewParticipantGrantRunModal(true);
  };

  const getParticipantGrantRuns = async () => {
    setIsLoadingGrantRuns(true);
    apiGetParticipantGrantRuns().then(
      (data) => {
        setParticipantGrantRuns(data.contributionGrantRuns);
        setIsLoadingGrantRuns(false);
      },
      (error) => {
        setIsLoadingGrantRuns(false);
      }
    );
  };

  useEffect(() => {
    getParticipantGrantRuns();
  }, []);

  return (
    <>
      {isLoadingGrantRuns ? (
        <PageLoad />
      ) : (
        <div>
          <PaymentsTable contributionGrantRuns={participantGrantRuns} startViewGrantRun={startViewGrantRun} />
          <ViewGrantRunModal
            recipientOrganizationUser={currentOrganizationUser}
            contributionPlan={contributionPlan}
            contributionGrantRun={viewParticipantGrantRun}
            open={openViewParticipantGrantRunModa}
            setOpen={setOpenViewParticipantGrantRunModal}
          />
        </div>
      )}
    </>
  );
};

export default StudentLoanPaymentsTab;
