import { CalendarIcon, CashIcon, SearchIcon, UsersIcon } from '@heroicons/react/outline';
import { Ring } from '@uiball/loaders';
import ViewGrantsTable from 'admin/ContributionPlans/ViewPlan/ViewGrantsTable';

import { ContributionPlan } from 'interfaces/contributionsInterfaces';
import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import { abbrevDate } from 'utils/dateUtils';
import { formatMoney } from 'utils/numberFormatter';
import { classNames } from 'utils/tailwindUtils';
interface ViewPlanSummaryProps {
  contributionPlan: ContributionPlan;
}

const ViewPlanSummary: React.FC<ViewPlanSummaryProps> = ({ contributionPlan }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);

  const contributionGrants = contributionPlan?.contributionGrants ? contributionPlan.contributionGrants : [];

  const maxGrantedAmount = contributionGrants
    ? contributionGrants.map((grant) => grant.amount).reduce((acc, curr) => Number(acc) + Number(curr), 0)
    : 0;
  const numFullyElectedParticipants = contributionGrants.reduce(
    (fullyElectedParticipantsCounter, contributionGrant) =>
      Number(contributionGrant.totalElectionPercentage) === 100
        ? fullyElectedParticipantsCounter + 1
        : fullyElectedParticipantsCounter,
    0
  );

  const cards = [
    {
      name: 'Elected/eligible participants',
      href: '#',
      icon: UsersIcon,
      amount: `${numFullyElectedParticipants} / ${contributionGrants.length}`,
      logoBgColor: 'bg-purple-100',
      logoTextColor: 'text-purple-600',
    },
    {
      name: 'Max amount granted',
      href: '#',
      icon: CashIcon,
      amount: formatMoney(Number(maxGrantedAmount)),
      logoBgColor: 'bg-green-100',
      logoTextColor: 'text-green-600',
    },
    {
      name: 'Next election deadline',
      href: '#',
      icon: CalendarIcon,
      amount: contributionPlan?.nextElectionDeadline ? abbrevDate(contributionPlan.nextElectionDeadline) : 'Pending...',
      logoBgColor: 'bg-sky-100',
      logoTextColor: 'text-sky-600',
    },
    // More items...
  ];

  const setSearchTermDebounced = useAsyncDebounce((searchText: string | undefined) => {
    setSearchTerm(searchText || '');
    setIsLoadingSearch(false);
  }, 1000);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoadingSearch(true);
    setSearchTermDebounced(event.target.value);
  };

  return (
    <>
      <div className="mt-4 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {/* Card */}
        {cards.map((card) => (
          <div
            key={card.name}
            className="bg-white ring-1 ring-black ring-opacity-5 overflow-hidden shadow-md rounded-md"
          >
            <div className="p-5">
              <div className="flex items-center">
                <div
                  className={classNames(
                    'flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full',
                    card.logoBgColor
                  )}
                >
                  <card.icon className={classNames('h-6 w-6', card.logoTextColor)} aria-hidden="true" />
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dl>
                    <dt className="text-sm font-medium text-gray-500 truncate">{card.name}</dt>
                    <dd>
                      <div className="text-lg font-medium text-gray-900">{card.amount}</div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex flex-col mt-6 md:flex-row md:justify-between md:items-center md:space-x-4 space-y-2">
        <h1 className="text-xl font-bold text-gray-900">All grants</h1>
        <div className="w-full md:max-w-md">
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              {isLoadingSearch ? (
                <Ring size={15} lineWeight={7} speed={2.0} color="gray" />
              ) : (
                <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              )}
            </div>
            <input
              id="search"
              name="search"
              className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-sm"
              placeholder="Search for a user"
              type="search"
              onChange={handleSearch}
            />
          </div>
        </div>
      </div>

      <div className="mt-2 space-y-8 divide-y divide-gray-200">
        <ViewGrantsTable
          contributionPlan={contributionPlan}
          contributionGrants={contributionGrants}
          searchTerm={searchTerm}
        />
      </div>
    </>
  );
};

export default ViewPlanSummary;
