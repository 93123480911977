import AdminHomePage from 'admin/AdminHome/AdminHomePage';
import AdminsPage from 'admin/Admins/AdminsPage';
import AdminSettingsPage from 'admin/AdminSettings/AdminSettingsPage';
import OrganizationTuitionReimbursementPage from 'admin/TuitionReimbursement/OrganizationTuitionReimbursementPage';

import ContributionPlanRunsPage from 'admin/ContributionPlanRuns/ContributionPlanRunsPage';
import OrganizationPlansPage from 'admin/ContributionPlans/OrganizationPlansPage';
import Match401kPage from 'admin/Match401k/Match401kPage';
import ParticipantsPage from 'admin/Participants/ParticipantsPage';
import PayrollConnectionsPage from 'admin/PayrollConnections/PayrollConnectionsPage';
import { UserDataContext } from 'context/UserContext';

import HSAPage from 'admin/HSA/HSAPage';
import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import AuthenticatedRoute from 'routes/AuthenticatedRoute';

const AdminRoutes: React.FC = () => {
  const { currentOrganizationUser } = useContext(UserDataContext);
  const organizationId = currentOrganizationUser?.organizationId;

  return (
    <>
      <AuthenticatedRoute
        path="/home"
        render={() => {
          return <AdminHomePage />;
        }}
      />
      <AuthenticatedRoute
        path="/participants"
        render={() => {
          return <ParticipantsPage />;
        }}
      />
      <AuthenticatedRoute
        path="/admins"
        render={() => {
          return <AdminsPage />;
        }}
      />
      <AuthenticatedRoute
        path="/settings"
        render={() => {
          return <AdminSettingsPage />;
        }}
      />
      <AuthenticatedRoute
        path="/plans"
        render={() => {
          return <OrganizationPlansPage />;
        }}
      />
      <AuthenticatedRoute
        path="/runs"
        render={() => {
          return <ContributionPlanRunsPage />;
        }}
      />
      <AuthenticatedRoute
        path="/tuition-reimbursement"
        render={() => {
          return <OrganizationTuitionReimbursementPage />;
        }}
      />
      {(organizationId === '855e8c98-1b7b-488c-ad6c-2a59c3036d28' ||
        organizationId === 'dfc538fe-4108-421f-8d91-dcf5bce9a44d' ||
        organizationId === 'b892b3de-249c-4d27-a72a-bb538fa3e1e3') && (
        <AuthenticatedRoute
          path="/401k-match"
          render={() => {
            return <Match401kPage />;
          }}
        />
      )}
      {(organizationId === '855e8c98-1b7b-488c-ad6c-2a59c3036d28' ||
        organizationId === 'dfc538fe-4108-421f-8d91-dcf5bce9a44d') && (
        <AuthenticatedRoute
          path="/hsa"
          render={() => {
            return <HSAPage />;
          }}
        />
      )}
      <AuthenticatedRoute
        path="/connections"
        render={() => {
          return <PayrollConnectionsPage />;
        }}
      />
      <AuthenticatedRoute
        exact
        path="/"
        render={() => {
          return <Redirect to="/home" />;
        }}
      />
    </>
  );
};

export default AdminRoutes;
