import { LoanAccount } from 'interfaces/loansInterfaces';
import React from 'react';
import { formatMoney } from 'utils/numberFormatter';
import { classNames } from 'utils/tailwindUtils';

interface ElectContributionsAccountProps {
  loanAccount: LoanAccount;
  contributionElectionAmountsByLoan: { [key: string]: number };
  setContributionElectionAmountForLoan: (loanAccountId: string, amount: number) => void;
  contributionElectionPercentagesByLoan: { [key: string]: number };
  setContributionElectionPercentageForLoan: (loanAccountId: string, amount: number) => void;
  isEditingContributions: boolean;
  electionType: string;
}

const ElectContributionsAccount: React.FC<ElectContributionsAccountProps> = ({
  loanAccount,
  contributionElectionAmountsByLoan,
  setContributionElectionAmountForLoan,
  contributionElectionPercentagesByLoan,
  setContributionElectionPercentageForLoan,
  isEditingContributions,
  electionType,
}) => {
  const handleChangeAmountInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContributionElectionAmountForLoan(loanAccount.id, Number(e.target.value));
  };

  const handleChangePercentageInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContributionElectionPercentageForLoan(loanAccount.id, Number(e.target.value));
  };

  const electionAmount = contributionElectionAmountsByLoan[loanAccount.id];
  const formattedElectionAmount = Number(electionAmount).toFixed(2);
  const percentage = contributionElectionPercentagesByLoan[loanAccount.id];

  return (
    <tr key={loanAccount.id}>
      <td className="pr-2 py-4 text-sm font-medium text-gray-900">{loanAccount.accountNumber}</td>
      <td className="py-4 px-2 text-sm text-gray-500 hidden xs:table-cell"> {loanAccount.name}</td>
      <td className="py-4 px-2 text-sm text-gray-500 hidden sm:table-cell">
        {' '}
        {loanAccount.interestRatePercentage ? `${loanAccount.interestRatePercentage}%` : '-'}
      </td>
      <td className="py-4 px-2 text-sm text-gray-500 hidden sm:table-cell">
        {loanAccount.currentBalance ? formatMoney(Number(loanAccount.currentBalance)) : '-'}
      </td>

      <td className="relative py-4 pl-2 text-right text-sm font-medium">
        <div className="relative">
          {electionType === 'amount' ? (
            <>
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500 text-sm">$</span>
              </div>
              <div>
                {' '}
                <input
                  type="number"
                  step=".01"
                  min="0.00"
                  max="10000.00"
                  name="amount"
                  id="amount"
                  onChange={handleChangeAmountInput}
                  className={classNames(
                    isEditingContributions
                      ? 'border-indigo-500 text-black shadow-lg'
                      : 'text-gray-500 border-transparent',
                    'block w-full pl-7 pr-12 text-sm rounded-md'
                  )}
                  value={isEditingContributions ? electionAmount : formattedElectionAmount}
                  aria-describedby="price-currency"
                  disabled={!isEditingContributions}
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 text-sm" id="price-currency">
                    USD
                  </span>
                </div>{' '}
              </div>
            </>
          ) : (
            <>
              <div>
                <input
                  type="number"
                  step="1"
                  min="0"
                  max="100"
                  name="percentage"
                  id="percentage"
                  onChange={handleChangePercentageInput}
                  className={classNames(
                    isEditingContributions
                      ? 'border-indigo-500 text-black shadow-lg'
                      : 'text-gray-500 border-transparent',
                    'block w-full text-sm rounded-md'
                  )}
                  value={percentage}
                  aria-describedby="price-currency"
                  disabled={!isEditingContributions}
                />{' '}
              </div>
              <div className="absolute inset-y-0 right-0 pr-8 flex items-center pointer-events-none">
                <span className="text-gray-500 text-sm">%</span>
              </div>
            </>
          )}
        </div>
      </td>
    </tr>
  );
};

export default ElectContributionsAccount;
