import { CashIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { abbrevDate } from 'utils/dateUtils';
import { formatMoney } from 'utils/numberFormatter';
import { classNames } from 'utils/tailwindUtils';

const HSAContributions: React.FC = () => {
  const statusStyles: Record<string, string> = {
    complete: 'bg-green-100 text-green-800',
    processing: 'bg-yellow-100 text-yellow-800',
    awaiting_funding: 'bg-gray-100 text-gray-800',
  };

  const [values, setValues] = useState({
    contributionPerPayPeriod: 300,
    cashPercentage: 50,
    investmentPercentage: 50,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  useEffect(() => {
    if (localStorage.getItem('hsaContributionsValues')) {
      const localHsaContributionValues = JSON.parse(localStorage.getItem('hsaContributionsValues')!);
      if (localHsaContributionValues) {
        setValues(localHsaContributionValues);
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('hsaContributionsValues', JSON.stringify(values));
  }, [values]);

  let hsaPayments = [
    {
      id: 'test2',
      description: 'September contribution',
      amountSubmitted: 300,
      status: 'complete',
      createdAt: '09-01-2023',
    },
    {
      id: 'test1',
      description: 'August contribution',
      amountSubmitted: 300,
      status: 'complete',
      createdAt: '08-01-2023',
    },
  ];

  return (
    <>
      <div className="px-8 py-4 ring-1 ring-black ring-opacity-10 rounded mt-4">
        <div>
          <div className="sm:items-center">
            <div className="flex-auto">
              <h1 className="text-xl font-bold text-gray-900">Change contribution</h1>
            </div>
          </div>
          <div className="mt-4">
            <label htmlFor="price" className="block text-sm font-medium text-gray-700">
              Contribution per pay period
            </label>
            <div className="mt-1 relative rounded-md shadow-sm max-w-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500 sm:text-sm">$</span>
              </div>
              <input
                type="number"
                step=".01"
                min="0.00"
                max="10000.00"
                name="contributionPerPayPeriod"
                id="contributionPerPayPeriod"
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                value={values.contributionPerPayPeriod}
                onChange={handleChange}
                required
                aria-describedby="price-currency"
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span className="text-gray-500 sm:text-sm" id="price-currency">
                  USD
                </span>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <div>
              <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                Cash (%)
              </label>
              <div className="mt-1 relative rounded-md shadow-sm max-w-xs">
                <input
                  type="number"
                  step="1"
                  min="0.00"
                  max="100"
                  name="cashPercentage"
                  id="cashPercentage"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                  placeholder="50"
                  value={values.cashPercentage}
                  onChange={handleChange}
                  required
                  aria-describedby="price-currency"
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm" id="price-currency">
                    %
                  </span>
                </div>
              </div>
            </div>
            <div>
              <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                Investments (%)
              </label>
              <div className="mt-1 relative rounded-md shadow-sm max-w-xs">
                <input
                  type="number"
                  step="1"
                  min="0.00"
                  max="100"
                  name="investmentPercentage"
                  id="investmentPercentage"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full  pr-12 sm:text-sm border-gray-300 rounded-md"
                  placeholder="50"
                  value={values.investmentPercentage}
                  onChange={handleChange}
                  required
                  aria-describedby="price-currency"
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm" id="price-currency">
                    %
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => {
                toast.success(`Successfully submitted contribution changes!`);
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>

      <div>
        <div className="max-w-7xl mx-auto mt-4">
          <div className="flex flex-col mt-2">
            <div className="align-middle min-w-full overflow-x-auto overflow-hidden sm:rounded-lg shadow-md ring-1 ring-black ring-opacity-10">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Contribution
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Amount
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {hsaPayments.map((hsaPayment) => (
                    <tr key={hsaPayment.id} className="bg-white">
                      <td className="max-w-0 w-full px-6 py-4  text-sm text-gray-900">
                        <div className="flex">
                          <div className="group inline-flex space-x-2 text-sm">
                            <CashIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                            <p className="text-gray-900">{hsaPayment.description}</p>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                        <span className="text-gray-900 font-medium">
                          {formatMoney(Number(hsaPayment.amountSubmitted))}
                        </span>
                      </td>
                      <td className=" px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <span
                          className={classNames(
                            statusStyles[hsaPayment.status],
                            'inline-flex  px-2.5 py-0.5 rounded-md text-xs font-medium capitalize'
                          )}
                        >
                          {hsaPayment.status.replace('_', ' ')}
                        </span>
                      </td>
                      <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                        <time dateTime={hsaPayment.createdAt}>{abbrevDate(hsaPayment.createdAt)}</time>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* Pagination */}
              {/* <nav
                  className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                  aria-label="Pagination"
                >
                  <div className="hidden sm:block">
                    <p className="text-sm text-gray-700">
                      Showing <span className="font-medium">1</span> to <span className="font-medium">10</span> of{' '}
                      <span className="font-medium">20</span> results
                    </p>
                  </div>
                  <div className="flex-1 flex justify-between sm:justify-end">
                    <a
                      href="#"
                      className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Previous
                    </a>
                    <a
                      href="#"
                      className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Next
                    </a>
                  </div>
                </nav> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HSAContributions;
