import { InformationCircleIcon } from '@heroicons/react/outline';
import { apiGetParticipantTuitionReimbursementGrant } from 'api/tuitionReimbursementAPI';
import EmptyPlaceholder from 'common/EmptyPlaceholder';
import PageLoad from 'components/Load/PageLoad';
import { TuitionReimbursementGrant } from 'interfaces/tuitionReimbursementInterfaces';
import CreateNewTuitionReimbursementRequestModal from 'participant/TuitionReimbursement/CreateNewTuitionReimbursementRequestModal';
import ViewGrantBreadcrumb from 'participant/TuitionReimbursement/ParticipantTuitionReimbursementGrant/ViewGrantBreadcrumb';
import ViewGrantHeader from 'participant/TuitionReimbursement/ParticipantTuitionReimbursementGrant/ViewGrantHeader';
import ParticipantTuitionReimbursementRequests from 'participant/TuitionReimbursement/ParticipantTuitionReimbursementRequests';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AuthenticatedRoute from 'routes/AuthenticatedRoute';

interface ParticipantTuitionReimbursementGrantRouteParams {
  tuitionReimbursementGrantId: string;
}

const ParticipantTuitionReimbursementGrant: React.FC = () => {
  let { tuitionReimbursementGrantId } = useParams<ParticipantTuitionReimbursementGrantRouteParams>();

  const [tuitionReimbursementGrant, setTuitionReimbursementGrant] = useState<TuitionReimbursementGrant>();
  const [isLoadingTuitionReimbursementGrant, setIsLoadingTuitionReimbursementGrant] = useState(true);

  const [openCreateNewRequestModal, setOpenCreateNewRequestModal] = useState(false);

  const tuitionReimbursementRequests = tuitionReimbursementGrant?.tuitionReimbursementRequests;
  const tuitionReimbursementPlan = tuitionReimbursementGrant?.tuitionReimbursementPlan;

  const getTuitionReimbursementGrant = async () => {
    setIsLoadingTuitionReimbursementGrant(true);
    apiGetParticipantTuitionReimbursementGrant(tuitionReimbursementGrantId).then(
      (data) => {
        setTuitionReimbursementGrant(data.participantTuitionReimbursementGrant);
        setIsLoadingTuitionReimbursementGrant(false);
      },
      (error) => {
        setIsLoadingTuitionReimbursementGrant(false);
      }
    );
  };

  useEffect(() => {
    getTuitionReimbursementGrant();
  }, []);

  return (
    <>
      {isLoadingTuitionReimbursementGrant ? (
        <PageLoad />
      ) : (
        <>
          <div className="px-8 mt-8 mx-auto max-w-7xl">
            {tuitionReimbursementGrant ? (
              <>
                <ViewGrantBreadcrumb tuitionReimbursementGrant={tuitionReimbursementGrant} />
                <ViewGrantHeader
                  tuitionReimbursementGrant={tuitionReimbursementGrant}
                  setOpenCreateNewRequestModal={setOpenCreateNewRequestModal}
                />

                <div className="rounded-md bg-blue-50 p-4 mt-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-blue-800">Tuition reimbursement plan instructions</h3>
                      <div className="mt-2 text-sm text-blue-700">
                        <p>{tuitionReimbursementPlan?.instructions}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <AuthenticatedRoute
                  path={`/tuition-reimbursement/grants/${tuitionReimbursementGrantId}/requests`}
                  render={() => {
                    return (
                      <ParticipantTuitionReimbursementRequests
                        tuitionReimbursementRequests={tuitionReimbursementRequests}
                        getTuitionReimbursementGrant={getTuitionReimbursementGrant}
                      />
                    );
                  }}
                />

                <CreateNewTuitionReimbursementRequestModal
                  tuitionReimbursementGrantId={tuitionReimbursementGrantId}
                  open={openCreateNewRequestModal}
                  setOpen={setOpenCreateNewRequestModal}
                  onCreateRequestSuccess={getTuitionReimbursementGrant}
                  tuitionReimbursementPlan={tuitionReimbursementPlan}
                  unsaddlManaged={tuitionReimbursementPlan?.unsaddlManaged}
                />
              </>
            ) : (
              <EmptyPlaceholder title={'No grant found'} subtitle={'This grant does not exist.'} />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ParticipantTuitionReimbursementGrant;
