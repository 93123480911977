import { Match401kPlanRun } from 'interfaces/match401kInterfaces';
import React from 'react';
import { abbrevDate } from 'utils/dateUtils';

interface ViewMatch401kPlanRunHeaderProps {
  match401kPlanRun: Match401kPlanRun;
}

const ViewMatch401kPlanRunHeader: React.FC<ViewMatch401kPlanRunHeaderProps> = ({ match401kPlanRun }) => {
  return (
    <article>
      <div className="lg:flex lg:items-center lg:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="mt-2 text-2xl font-bold text-gray-900">
            {match401kPlanRun?.name} | {abbrevDate(match401kPlanRun.createdAt)}
          </h2>
        </div>
      </div>
    </article>
  );
};

export default ViewMatch401kPlanRunHeader;
