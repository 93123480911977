/* This example requires Tailwind CSS v2.0+ */
import { Switch } from '@headlessui/react';
import { classNames } from 'utils/tailwindUtils';

interface SettingSwitchProps {
  switchTitle: string;
  switchSubtitle?: string;
  featureEnabled: boolean;
  setFeatureEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const SettingSwitch: React.FC<SettingSwitchProps> = ({
  switchTitle,
  switchSubtitle,
  featureEnabled,
  setFeatureEnabled,
}) => {
  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={featureEnabled}
        onChange={setFeatureEnabled}
        className={classNames(
          featureEnabled ? 'bg-indigo-600' : 'bg-gray-200',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            featureEnabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-3">
        <span className="text-sm font-medium text-gray-900">{switchTitle} </span>
        {switchSubtitle && <span className="text-sm text-gray-500">{switchSubtitle}</span>}
      </Switch.Label>
    </Switch.Group>
  );
};

export default SettingSwitch;
