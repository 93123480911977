import ListMatch401kPlanRuns from 'admin/Match401k/ListMatch401kPlanRuns';
import ViewMatch401kPlanRun from 'admin/Match401k/ViewMatch401kPlanRun/ViewMatch401kPlanRun';
import EmptyPlaceholder from 'common/EmptyPlaceholder';
import { UserDataContext } from 'context/UserContext';
import React, { useContext } from 'react';
import AuthenticatedRoute from 'routes/AuthenticatedRoute';

const Match401kPage: React.FC = () => {
  const { currentOrganizationUser } = useContext(UserDataContext);

  return currentOrganizationUser?.adminProfile?.adminPermissions.includes('payment_plan_management') ? (
    <>
      <AuthenticatedRoute
        path={`/401k-match/list`}
        render={() => {
          return <ListMatch401kPlanRuns />;
        }}
      />
      <AuthenticatedRoute
        path={`/401k-match/:match401kPlanRunId/view`}
        render={() => {
          return <ViewMatch401kPlanRun />;
        }}
      />
    </>
  ) : (
    <div className="my-6 px-8 mx-auto max-w-7xl">
      <EmptyPlaceholder
        title="Access denied"
        subtitle="Your organization super admin has not given you permission to access this page"
      />
    </div>
  );
};

export default Match401kPage;
