import DisablePlanModal from 'admin/TuitionReimbursement/ListPlans/DisablePlanModal';
import EditPlanDetailsModal from 'admin/TuitionReimbursement/ListPlans/EditPlanDetailsModal';

import ViewPlanBreadcrumb from 'admin/TuitionReimbursement/ViewPlan/ViewPlanBreadcrumb';
import ViewPlanHeader from 'admin/TuitionReimbursement/ViewPlan/ViewPlanHeader';
import ViewPlanSummary from 'admin/TuitionReimbursement/ViewPlan/ViewPlanSummary';
import { apiGetOrganizationTuitionReimbursementPlan } from 'api/tuitionReimbursementAPI';
import EmptyPlaceholder from 'common/EmptyPlaceholder';
import PageLoad from 'components/Load/PageLoad';
import { TuitionReimbursementPlan } from 'interfaces/tuitionReimbursementInterfaces';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AuthenticatedRoute from 'routes/AuthenticatedRoute';

interface ViewPlanRouteParams {
  tuitionReimbursementPlanId: string;
}

const ViewPlan: React.FC = () => {
  let { tuitionReimbursementPlanId } = useParams<ViewPlanRouteParams>();

  const [openDisablePlanModal, setOpenDisablePlanModal] = useState(false);
  const [openEditPlanDetailsModal, setOpenEditPlanDetailsModal] = useState(false);

  const [isLoadingTuitionReimbursementPlan, setIsLoadingTuitionReimbursementPlan] = useState(true);

  const [tuitionReimbursementPlan, setTuitionReimbursementPlan] = useState<TuitionReimbursementPlan>();

  const getTuitionReimbursementPlan = async () => {
    setIsLoadingTuitionReimbursementPlan(true);
    apiGetOrganizationTuitionReimbursementPlan(tuitionReimbursementPlanId).then(
      (data) => {
        const tuitionReimbursementPlan: TuitionReimbursementPlan = data.tuitionReimbursementPlan;
        setTuitionReimbursementPlan(tuitionReimbursementPlan);
        setIsLoadingTuitionReimbursementPlan(false);
      },
      (error) => {
        setIsLoadingTuitionReimbursementPlan(false);
      }
    );
  };

  useEffect(() => {
    getTuitionReimbursementPlan();
  }, []);

  return (
    <>
      {isLoadingTuitionReimbursementPlan ? (
        <PageLoad />
      ) : (
        <>
          <div className="mx-auto max-w-7xl">
            {tuitionReimbursementPlan ? (
              <>
                <ViewPlanBreadcrumb tuitionReimbursementPlan={tuitionReimbursementPlan} />
                <ViewPlanHeader
                  tuitionReimbursementPlan={tuitionReimbursementPlan}
                  setOpenDisablePlanModal={setOpenDisablePlanModal}
                  setOpenEditPlanDetailsModal={setOpenEditPlanDetailsModal}
                />

                <AuthenticatedRoute
                  path={`/tuition-reimbursement/plans/${tuitionReimbursementPlan.id}/view`}
                  render={() => {
                    return <ViewPlanSummary tuitionReimbursementPlan={tuitionReimbursementPlan} />;
                  }}
                />
                {/* <AuthenticatedRoute
                  path={`/plans/${tuitionReimbursementPlan.id}/view/runs`}
                  render={() => {
                    return <ViewPlanRuns tuitionreimbursementPlan={tuitionReimbursementPlan} />;
                  }}
                /> */}

                <DisablePlanModal
                  tuitionReimbursementPlan={tuitionReimbursementPlan}
                  open={openDisablePlanModal}
                  setOpen={setOpenDisablePlanModal}
                  onDisableSuccess={getTuitionReimbursementPlan}
                />
                <EditPlanDetailsModal
                  tuitionReimbursementPlan={tuitionReimbursementPlan}
                  open={openEditPlanDetailsModal}
                  setOpen={setOpenEditPlanDetailsModal}
                  onEditPlanSuccess={getTuitionReimbursementPlan}
                />
              </>
            ) : (
              <EmptyPlaceholder title={'No plan found'} subtitle={'This plan does not exist.'} />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ViewPlan;
