import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ClockIcon,
  DownloadIcon,
  XCircleIcon,
} from '@heroicons/react/outline';
import { apiGetLoanServicerStatementLink } from 'api/loansAPI';

import EmptyTablePlaceholder from 'common/EmptyTablePlaceholder';
import DeniedStatementUploadMemoModal from 'common/Loans/DeniedStatementUploadMemoModal';
import { LoanServicerStatementUpload } from 'interfaces/loansInterfaces';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { CellProps, Column, useSortBy, useTable } from 'react-table';
import { abbrevDate } from 'utils/dateUtils';
import { formatMoney } from 'utils/numberFormatter';

interface ViewParticipantStatementUploadsTableProps {
  statementUploads: LoanServicerStatementUpload[];
}

const ViewParticipantStatementUploadsTable: React.FC<ViewParticipantStatementUploadsTableProps> = ({
  statementUploads,
}) => {
  const [deniedStatementUpload, setDeniedStatementUpload] = useState<LoanServicerStatementUpload>();
  const [openDeniedStatementUploadModal, setOpenDeniedStatementUploadModal] = useState(false);

  const handleStatementDownload = async (loanServicerId: string, statementUploadId: string) => {
    apiGetLoanServicerStatementLink(loanServicerId, statementUploadId).then(
      (data) => {
        window.open(data.statementUploadUrl);
      },
      (error) => {
        toast.error('Failed to download statement');
      }
    );
  };

  const statementUploadsMemo = React.useMemo<LoanServicerStatementUpload[]>(() => statementUploads, [statementUploads]);
  const columns: Column<LoanServicerStatementUpload>[] = React.useMemo(
    () => [
      {
        id: 'file-name',
        Header: 'File name',

        Cell: (props: CellProps<LoanServicerStatementUpload>) => {
          const statementUpload = props.row.original;
          const fileName = statementUpload.gcsFileUpload.fileName;
          const loanServicerName = statementUpload.loanServicerName;

          return (
            <div className="text-sm">
              <div className="font-medium text-gray-900 break-all">{fileName}</div>
              <div className="text-gray-500 break-all">{loanServicerName}</div>
            </div>
          );
        },
        className: 'py-4 text-sm font-medium text-gray-900',
        headerClassName: 'py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
      {
        id: 'upload-date',
        Header: 'Upload date',
        accessor: (statementUpload: LoanServicerStatementUpload) => {
          return new Date(statementUpload.createdAt);
        },
        Cell: (props: CellProps<LoanServicerStatementUpload>) => {
          const statementUpload = props.row.original;
          const uploadedAt = abbrevDate(statementUpload.createdAt);

          return uploadedAt;
        },
        className: 'hidden sm:table-cell py-4 text-sm text-gray-500',
        headerClassName:
          'hidden sm:table-cell py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
      {
        id: 'status',
        Header: 'Status',
        Cell: (props: CellProps<LoanServicerStatementUpload>) => {
          const statementUpload = props.row.original;

          return (
            <>
              {statementUpload.statementUploadReviewStatus === 'manual_verified' ? (
                <span className="inline-flex px-2 py-1 text-xs leading-5 font-semibold rounded-md bg-green-100 text-green-800">
                  <CheckCircleIcon
                    className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                    aria-hidden="true"
                  />
                  Manual accounts verified
                </span>
              ) : statementUpload.statementUploadReviewStatus === 'needs_review' ? (
                <span className="inline-flex px-2 py-1 text-xs leading-5 font-semibold rounded-md bg-yellow-100 text-yellow-800">
                  <ClockIcon
                    className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                  Document under review
                </span>
              ) : statementUpload.statementUploadReviewStatus === 'denied' ? (
                <div className="space-y-1">
                  <div
                    className="inline-flex cursor-pointer px-2 py-1 text-xs leading-5 font-semibold rounded-md bg-red-100 hover:bg-red-200 text-red-800"
                    onClick={() => {
                      setDeniedStatementUpload(statementUpload);
                      setOpenDeniedStatementUploadModal(true);
                    }}
                  >
                    <XCircleIcon
                      className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-red-400"
                      aria-hidden="true"
                    />
                    Denied
                  </div>
                  <div className="text-gray-500 text-xs break-all">
                    Reviewed: {abbrevDate(statementUpload.updatedAt)}
                  </div>
                </div>
              ) : statementUpload.statementUploadReviewStatus === 'approved' ? (
                <div className="space-y-1">
                  <div className="inline-flex px-2 py-1 text-xs leading-5 font-semibold rounded-md bg-green-100 text-green-800">
                    <CheckCircleIcon
                      className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                      aria-hidden="true"
                    />
                    {formatMoney(Number(statementUpload.matchableEmployeeContribution))} match eligible
                  </div>
                  <div className="text-gray-500 text-xs break-all">
                    Reviewed: {abbrevDate(statementUpload.updatedAt)}
                  </div>
                </div>
              ) : (
                <span className="inline-flex px-2 py-1 text-xs leading-5 font-semibold rounded-md bg-yellow-100 text-yellow-800">
                  <ClockIcon
                    className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                  Statement verification expired
                </span>
              )}
            </>
          );
        },
        className: 'py-4 whitespace-nowrap text-sm text-gray-500',
        headerClassName: 'py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
      {
        id: 'options',
        Cell: (props: CellProps<LoanServicerStatementUpload>) => {
          const statementUpload = props.row.original;

          return (
            <div className="flex-shrink-0 flex">
              <DownloadIcon
                className="flex flex-shrink-0 h-5 w-5 cursor-pointer text-gray-500 hover:text-gray-900"
                onClick={() => {
                  handleStatementDownload(statementUpload.loanServicerId, statementUpload.id);
                }}
                aria-hidden="true"
              />
            </div>
          );
        },
        className: 'py-4 text-right',
      },
    ],
    []
  ) as any;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: statementUploadsMemo,
      initialState: {
        sortBy: [
          {
            id: 'upload-date',
            desc: true,
          },
        ],
      },
    },
    useSortBy
  );

  return (
    <div>
      <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={`${column.headerClassName}`}
                    >
                      {
                        // Render the header
                        column.render('Header')
                      }
                      <span className="inline-flex ml-1">
                        {column.isSorted ? (
                          <span className="w-3 h-3">
                            {column.isSortedDesc ? <ChevronDownIcon /> : <ChevronUpIcon />}
                          </span>
                        ) : (
                          ''
                        )}
                      </span>
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()} className="divide-y divide-gray-200">
          {
            // Loop over the table rows
            rows.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr {...row.getRowProps()}>
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td {...cell.getCellProps()} className={`${cell.column.className}`}>
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>

      {rows.length === 0 && (
        <EmptyTablePlaceholder
          title="No statement uploads"
          subtitle="This participant has not uploaded any loan statements"
        />
      )}
      <DeniedStatementUploadMemoModal
        deniedStatementUpload={deniedStatementUpload}
        open={openDeniedStatementUploadModal}
        setOpen={setOpenDeniedStatementUploadModal}
      />
    </div>
  );
};

export default ViewParticipantStatementUploadsTable;
