import { Menu } from '@headlessui/react';
import MenuOptions from 'components/MenuOptions';
import { ExternalBankAccount } from 'interfaces/externalBankAccountInterfaces';
import ExternalBankAccountSettingsOptions from 'participant/Accounts/ExternalBankAccount/ExternalBankAccountSettingsOptions';
import DeleteExternalBankAccountModal from 'participant/Accounts/ExternalBankAccount/modals/DeleteExternalBankAccountModal';
import React, { useState } from 'react';

interface ExternalBankAccountElementProps {
  externalBankAccount: ExternalBankAccount;
  refreshAccountData: () => void;
}

const ExternalBankAccountElement: React.FC<ExternalBankAccountElementProps> = ({
  externalBankAccount,
  refreshAccountData,
}) => {
  const [openDeleteExternalBankAccountModal, setOpenDeleteExternalBankAccountModal] = useState(false);

  return (
    <>
      <div className="px-8 py-4 ring-1 ring-black ring-opacity-10 rounded">
        <div>
          <div className="sm:items-center flex flex-col sm:flex-row">
            <div className="flex-auto">
              <h1 className="text-xl font-bold text-gray-900">
                {externalBankAccount.name}
                {externalBankAccount.accountType &&
                  ` (${externalBankAccount.accountType.charAt(0).toUpperCase()}${externalBankAccount.accountType.slice(
                    1
                  )})`}
              </h1>
            </div>

            <MenuOptions
              menuButton={
                <Menu.Button className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-full sm:w-auto my-2 sm:my-0">
                  Manage account
                </Menu.Button>
              }
              menuItems={
                <ExternalBankAccountSettingsOptions
                  externalBankAccount={externalBankAccount}
                  setOpenDeleteExternalBankAccountModal={setOpenDeleteExternalBankAccountModal}
                />
              }
            />
          </div>
        </div>
        {/* active loan table */}
        <div className="mt-4 flex flex-col">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full table-auto divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-500">
                    Institution name
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 px-3 text-right text-sm font-semibold text-gray-500 hidden sm:table-cell"
                  >
                    Routing #
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 px-3 text-right text-sm font-semibold text-gray-500 hidden xs:table-cell"
                  >
                    Account #
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                <tr key={externalBankAccount.id}>
                  <td className="py-4 pr-3 text-sm font-medium text-gray-900">{externalBankAccount.institutionName}</td>
                  <td className="py-4 px-3 text-sm text-right text-gray-500 hidden sm:table-cell">
                    {externalBankAccount.routingNumber}
                  </td>
                  <td className="py-4 px-3 text-sm text-right text-gray-500 hidden xs:table-cell">
                    {externalBankAccount.accountNumber}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <DeleteExternalBankAccountModal
        externalbankAccount={externalBankAccount}
        open={openDeleteExternalBankAccountModal}
        setOpen={setOpenDeleteExternalBankAccountModal}
        refreshAccountData={refreshAccountData}
      />
    </>
  );
};

export default ExternalBankAccountElement;
