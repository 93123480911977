import { ClockIcon, DuplicateIcon } from '@heroicons/react/outline';
import {
  CheckCircleIcon,
  CurrencyDollarIcon,
  PencilIcon,
  UserGroupIcon,
  XCircleIcon,
  XIcon,
} from '@heroicons/react/solid';
import { ContributionPlan } from 'interfaces/contributionsInterfaces';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getContributionPlanScheduleText } from 'utils/contributionUtils';
import { classNames } from 'utils/tailwindUtils';

interface ViewPlanHeaderProps {
  contributionPlan: ContributionPlan;
  setOpenDisablePlanModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenEditPlanDetailsModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ViewPlanTabs {
  name: string;
  href: string;

  current: boolean;
}

const ViewPlanHeader: React.FC<ViewPlanHeaderProps> = ({
  contributionPlan,
  setOpenDisablePlanModal,
  setOpenEditPlanDetailsModal,
}) => {
  const location = useLocation();
  const locationPath = location.pathname;
  const tabs: ViewPlanTabs[] = [
    {
      name: 'Summary',
      href: `/plans/${contributionPlan.id}/view/summary`,
      current: locationPath === `/plans/${contributionPlan.id}/view/summary`,
    },
    {
      name: 'Plan runs',
      href: `/plans/${contributionPlan.id}/view/runs`,
      current: locationPath === `/plans/${contributionPlan.id}/view/runs`,
    },
  ];

  return (
    <article>
      <div className="lg:flex lg:items-center lg:justify-between">
        <div className="flex-1 min-w-0">
          <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-900 ">"{contributionPlan.name}" Plan</h1>
          <div className="mt-1 text-gray-500">{contributionPlan.description}</div>
          <div className="mt-1 flex flex-col">
            <div className="mt-2 flex items-center text-sm text-gray-500">
              {contributionPlan.active ? (
                <>
                  <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Active
                </>
              ) : (
                <>
                  <XCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Disabled
                </>
              )}
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <ClockIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              {getContributionPlanScheduleText(contributionPlan)}
            </div>
            {!!contributionPlan.employeeMatchRequired && (
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <DuplicateIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                Employee contribution matched: {contributionPlan.matchPercentage}%
              </div>
            )}
            {!!contributionPlan.defaultAmount && (
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <CurrencyDollarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />$
                {contributionPlan.defaultAmount} default contribution
              </div>
            )}
            {!!contributionPlan.eligibilityTenureDays && (
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <UserGroupIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                {contributionPlan.eligibilityTenureDays} day eligibility tenure
              </div>
            )}
          </div>
        </div>
        <div className="mt-5 flex lg:mt-0 lg:ml-4 flex-col sm:flex-row space-y-1 sm:space-y-0 sm:space-x-2">
          <Link
            to={`/plans/${contributionPlan.id}/edit`}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <CurrencyDollarIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" aria-hidden="true" />
            Edit grants
          </Link>

          <button
            onClick={() => {
              setOpenEditPlanDetailsModal(true);
            }}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <PencilIcon className="-ml-1 mr-2 h-5 w-" aria-hidden="true" />
            Edit plan details
          </button>

          {contributionPlan.active && (
            <button
              onClick={() => {
                setOpenDisablePlanModal(true);
              }}
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              <XIcon className="-ml-1 mr-2 h-5 w-5 text-white" aria-hidden="true" />
              Disable
            </button>
          )}
        </div>
      </div>
      {/* Tabs */}
      <div className="mt-6 sm:mt-2 2xl:mt-5 border-b border-gray-200 overflow-x-auto scrollbar-hide">
        <nav className="flex space-x-8" aria-label="Tabs">
          {tabs.map((tab) => (
            <Link
              key={tab.name}
              to={tab.href}
              className={classNames(
                tab.current
                  ? 'border-purple-500 text-gray-900'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              {tab.name}
            </Link>
          ))}
        </nav>
      </div>
    </article>
  );
};

export default ViewPlanHeader;
