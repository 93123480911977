import { apiGetUserAccount } from 'api/userAccountsAPI';
import { AxiosRequestConfig } from 'axios';
import api from 'config/axiosConfig';
import { logOut } from 'config/firebaseConfig';
import { getAuth, User as FirebaseUser } from 'firebase/auth';
import { OrganizationUser } from 'interfaces/organizationUserInterfaces';
import { UserAccount } from 'interfaces/userAccountInterfaces';
import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useIdleTimer } from 'react-idle-timer';
import { clear, getItem, setItem } from 'utils/localStorage';

interface UserData {
  firebaseUser?: FirebaseUser;
  userAccount?: UserAccount;
  orgUsersById?: { [key: number]: OrganizationUser };
  currentOrganizationUser?: OrganizationUser;
  isAuthenticating: boolean;
  isLoadingUser: boolean;
  isUnexpectedError: boolean;
  switchCurrentOrganizationUser: (organizationUserId: string) => void;
  refreshUser: () => void;
  resetSessionExpiration: () => void;
  secondsUntilIdle: number;
  isIdlePrompt: boolean;
}

export const UserDataContext = React.createContext<UserData>({
  firebaseUser: undefined,
  userAccount: undefined,
  orgUsersById: undefined,
  currentOrganizationUser: undefined,
  isAuthenticating: true,
  isLoadingUser: false,
  isUnexpectedError: false,
  switchCurrentOrganizationUser: () => {},
  refreshUser: () => {},
  resetSessionExpiration: () => {},
  secondsUntilIdle: 60,
  isIdlePrompt: false,
});

interface Props {
  children: JSX.Element;
}

export const UserProvider: React.FC<Props> = ({ children }) => {
  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser>();
  const [userAccount, setUserAccount] = useState<UserAccount>();

  const [orgUsersById, setOrgUsersById] = useState<{ [key: string]: OrganizationUser }>();

  const [currentOrganizationUser, setCurrentOrganizationUser] = useState<OrganizationUser>();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [isUnexpectedError, setIsUnexpectedError] = useState(false);
  const [secondsUntilIdle, setSecondsUntilIdle] = useState(60);
  const [isIdlePrompt, setIsIdlePrompt] = useState(false);

  const clearUser = () => {
    setUserAccount(undefined);
    setOrgUsersById(undefined);
    setCurrentOrganizationUser(undefined);
    setIsLoadingUser(true);
  };

  const switchCurrentOrganizationUser = (organizationUserId: string) => {
    setIsLoadingUser(true);
    if (orgUsersById && organizationUserId in orgUsersById) {
      const newCurrentOrganizationUser = orgUsersById[organizationUserId];
      setCurrentOrganizationUser(newCurrentOrganizationUser);
      setItem('current_organization_user_id', newCurrentOrganizationUser.id);
    }
  };

  const refreshUser = useCallback(async () => {
    setIsLoadingUser(true);

    apiGetUserAccount().then(
      (data) => {
        setIsUnexpectedError(false);
        const returnedUserAccount: UserAccount | undefined = data.userAccount;
        if (returnedUserAccount) {
          setUserAccount(returnedUserAccount!);
        }

        const currentOrganizationUserIdLocalStorage = getItem('current_organization_user_id');
        const organizationUsers: OrganizationUser[] | undefined = data.organizationUsers;

        if (organizationUsers && organizationUsers.length > 0) {
          let newOrgUsersById: { [key: string]: OrganizationUser } = {};
          for (const organizationUser of organizationUsers) {
            newOrgUsersById[organizationUser.id] = organizationUser;
          }

          setOrgUsersById(newOrgUsersById);

          // check for an current org user in local storage, otherwise use the first returned org user
          if (currentOrganizationUserIdLocalStorage && currentOrganizationUserIdLocalStorage in newOrgUsersById) {
            const localStorageOrgUser = newOrgUsersById[currentOrganizationUserIdLocalStorage];
            setCurrentOrganizationUser(localStorageOrgUser);
          } else {
            const firstOrgUser = organizationUsers[0];
            setCurrentOrganizationUser(firstOrgUser);
            setItem('current_organization_user_id', firstOrgUser.id);
          }
        }
        setIsLoadingUser(false);
      },
      (error) => {
        clearUser();
        setIsUnexpectedError(true);
        setIsLoadingUser(false);
      }
    );
  }, []);

  useEffect(() => {
    // auth listener to keep track of user signing in and out
    const unsubscribe = getAuth().onAuthStateChanged((user) => {
      if (user) {
        let lastSignInTime = new Date();
        if (user.metadata.lastSignInTime) {
          lastSignInTime = new Date(user.metadata.lastSignInTime);
        }
        const lastSignInTimeTimeStamp = Math.round(lastSignInTime.getTime() / 1000);
        const oneHourAgoTimeStamp = Math.round(new Date().getTime() / 1000) - 1 * 60 * 60;
        if (lastSignInTimeTimeStamp < oneHourAgoTimeStamp) {
          logOut();
          toast('For your safety, you have been automatically signed out of your account.', {
            icon: '🔒',
          });
        } else {
          setFirebaseUser(user);
          refreshUser();
          resetSessionExpiration();
        }
      } else {
        setFirebaseUser(undefined);
        clearUser();
        clear();
      }
      setIsAuthenticating(false);
    });
    return () => {
      unsubscribe();
    };
  }, [refreshUser]);

  useEffect(() => {
    const authInterceptor = api.interceptors.request.use(async (config: AxiosRequestConfig) => {
      if (config.headers === undefined) {
        config.headers = {};
      }

      let firebaseIdToken;
      await firebaseUser?.getIdToken().then((idToken) => {
        firebaseIdToken = idToken;
      });

      config.headers.Authorization = `Bearer ${firebaseIdToken}`;

      if (currentOrganizationUser?.id) {
        config.headers['Unsaddl-Organization-User-ID'] = currentOrganizationUser?.id;
      }

      return config;
    });
    return () => {
      api.interceptors.request.eject(authInterceptor);
    };
  }, [currentOrganizationUser, firebaseUser]);

  useEffect(() => {
    const interval = setInterval(() => {
      let currentSecondsUntilIdle = Math.trunc(getRemainingTime() / 1000);
      if (currentSecondsUntilIdle < 61) {
        setSecondsUntilIdle(currentSecondsUntilIdle);
      }
    }, 1000);
  }, []);

  const resetSessionExpiration = () => {
    reset();
    setIsIdlePrompt(false);
  };

  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
    if (firebaseUser) {
      logOut();
      resetSessionExpiration();
      toast('For your safety, you have been signed out due to inactivity.', {
        icon: '🔒',
      });
    }
  };

  const onPrompt = () => {
    if (!firebaseUser) {
      resetSessionExpiration();
    } else {
      setIsIdlePrompt(true);
    }
  };

  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime,
  } = useIdleTimer({
    onPrompt,
    onIdle,
    timeout: 10 * 60 * 1000,
    promptTimeout: 1 * 60 * 1000,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    immediateEvents: [],
    debounce: 100,
    throttle: 0,
    eventsThrottle: 200,
    syncTimers: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: true,
    name: 'idle-timer',
    leaderElection: false,
  });

  const { Provider } = UserDataContext;
  return (
    <Provider
      value={{
        firebaseUser,
        userAccount,
        orgUsersById,
        currentOrganizationUser,
        isAuthenticating,
        isLoadingUser,
        isUnexpectedError,
        switchCurrentOrganizationUser,
        refreshUser,
        resetSessionExpiration,
        secondsUntilIdle,
        isIdlePrompt,
      }}
    >
      {children}
    </Provider>
  );
};
