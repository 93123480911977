/* This example requires Tailwind CSS v2.0+ */
import {
  CheckCircleIcon,
  ClockIcon,
  CurrencyDollarIcon,
  DownloadIcon,
  PaperClipIcon,
  TrashIcon,
  UserIcon,
  XCircleIcon,
} from '@heroicons/react/outline';
import { apiGetTuitionReimbursementDocumentUploadLink } from 'api/tuitionReimbursementAPI';
import DeleteTuitionReimbursementDocumentUploadModal from 'common/TuitionReimbursement/DeleteTuitionReimbursementDocumentUploadModal';
import { UserDataContext } from 'context/UserContext';
import {
  TuitionReimbursementDocumentUpload,
  TuitionReimbursementPayment,
  TuitionReimbursementRequest,
} from 'interfaces/tuitionReimbursementInterfaces';
import SelectTuitionReimbursementPaymentAccountSlideover from 'participant/TuitionReimbursement/SelectTuitionReimbursementPaymentAccountSlideover';

import React, { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { abbrevDate } from 'utils/dateUtils';
import { formatMoney } from 'utils/numberFormatter';

interface TuitionReimbursementDetailsProps {
  tuitionReimbursementRequest: TuitionReimbursementRequest;
  onDocumentDelete?: () => void;
}

const TuitionReimbursementDetails: React.FC<TuitionReimbursementDetailsProps> = ({
  tuitionReimbursementRequest,
  onDocumentDelete,
}) => {
  const { currentOrganizationUser } = useContext(UserDataContext);
  const tuitionReimbursementDocumentUploads = tuitionReimbursementRequest?.tuitionReimbursementDocumentUploads;
  const tuitionReimbursementPayments = tuitionReimbursementRequest?.tuitionReimbursementPayments;
  const [openDeleteTuitionReimbursementDocumentUploadModal, setOpenDeleteTuitionReimbursementDocumentUploadModal] =
    useState(false);
  const [toDeleteTuitionReimbursementDocumentUpload, setToDeleteTuitionReimbursementDocumentUpload] =
    useState<TuitionReimbursementDocumentUpload>();
  const [isUploadingDocument, setIsUploadingDocument] = useState(false);
  const [toSelectTuitionReimbursementPayment, setToSelectTuitionReimbursementPayment] =
    useState<TuitionReimbursementPayment>();
  const [
    openSelectTuitionReimbursementPaymentAccountSlideover,
    setOpenSelectTuitionReimbursementPaymentAccountSlideover,
  ] = useState(false);

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleDocumentDownload = async (
    tuitionReimbursementRequestId: string,
    tuitionReimbursementDocumentUploadId: string
  ) => {
    apiGetTuitionReimbursementDocumentUploadLink(
      tuitionReimbursementRequestId,
      tuitionReimbursementDocumentUploadId
    ).then(
      (data) => {
        window.open(data.documentUploadUrl);
      },
      (error) => {
        toast.error('Failed to download tuition reimbursement document.');
      }
    );
  };

  const startDeleteTuitionReimbursementDocumentUpload = (
    tuitionReimbursementDocumentUpload: TuitionReimbursementDocumentUpload
  ) => {
    setToDeleteTuitionReimbursementDocumentUpload(tuitionReimbursementDocumentUpload);
    setOpenDeleteTuitionReimbursementDocumentUploadModal(true);
  };

  const startSelectTuitionReimbursementAccountPayment = (tuitionReimbursementPayment: TuitionReimbursementPayment) => {
    setToSelectTuitionReimbursementPayment(tuitionReimbursementPayment);
    setOpenSelectTuitionReimbursementPaymentAccountSlideover(true);
  };

  return (
    <div>
      <div className=" border-t border-gray-200">
        <dl className="sm:divide-y sm:divide-gray-200">
          {tuitionReimbursementRequest.tuitionReimbursementGrant?.tuitionReimbursementPlan && (
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Plan</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {tuitionReimbursementRequest?.tuitionReimbursementGrant.tuitionReimbursementPlan.name}
              </dd>
            </div>
          )}
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">Description</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {tuitionReimbursementRequest?.courseDescription}
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">Created</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {abbrevDate(tuitionReimbursementRequest!.createdAt)}
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">Duration</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {abbrevDate(tuitionReimbursementRequest!.startDate)} - {abbrevDate(tuitionReimbursementRequest!.endDate)}
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">Cost</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              ${tuitionReimbursementRequest?.courseCost}{' '}
              {tuitionReimbursementRequest.approvedAmount &&
                `($${tuitionReimbursementRequest.approvedAmount} approved)`}
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">Credit hours</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {tuitionReimbursementRequest.creditHours ? tuitionReimbursementRequest?.creditHours : 'N/A'}
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">Reviewers</dt>
            {tuitionReimbursementRequest.unsaddlManaged ? (
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">Reviews managed by the Unsaddl team</dd>
            ) : tuitionReimbursementRequest.tuitionReimbursementRequestReviews &&
              tuitionReimbursementRequest.tuitionReimbursementRequestReviews?.length > 0 ? (
              <ul className="border border-gray-200 rounded-md divide-y divide-gray-200 mt-1 sm:mt-0 sm:col-span-2">
                {tuitionReimbursementRequest.tuitionReimbursementRequestReviews?.map((review) => (
                  <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm" key={review.id}>
                    <div className="w-0 flex-1 flex items-center">
                      <UserIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span className="ml-2 flex-1 w-0 truncate">
                        {review.reviewer.organizationUser?.firstName} {review.reviewer.organizationUser?.lastName} (
                        {review.reviewer.organizationUser?.email})
                      </span>
                    </div>
                    <div className="ml-4 flex-shrink-0 flex space-x-1">
                      {review.reviewStatus === 'submitted' || review.reviewStatus === 'course_completed' ? (
                        <ClockIcon
                          className="flex flex-shrink-0 h-5 w-5 cursor-pointer text-yellow-600 hover:text-yellow-800"
                          aria-hidden="true"
                        />
                      ) : review.reviewStatus === 'course_approved' || review.reviewStatus === 'completion_approved' ? (
                        <CheckCircleIcon
                          className="flex flex-shrink-0 h-5 w-5 cursor-pointer text-green-600 hover:text-green-800"
                          aria-hidden="true"
                        />
                      ) : (
                        <XCircleIcon
                          className="flex flex-shrink-0 h-5 w-5 cursor-pointer text-red-600 hover:text-red-800"
                          aria-hidden="true"
                        />
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">N/A</dd>
            )}
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">Reimbursement payments</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {tuitionReimbursementPayments && tuitionReimbursementPayments?.length > 0 ? (
                <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                  {tuitionReimbursementPayments.map((tuitionReimbursementPayment) => (
                    <li
                      className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                      key={tuitionReimbursementPayment.id}
                    >
                      <div className="w-0 flex-1 flex items-center">
                        <CurrencyDollarIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                        {tuitionReimbursementPayment.amountSubmitted && (
                          <span className="ml-2 flex-1 w-0 truncate">
                            {formatMoney(Number(tuitionReimbursementPayment.amountSubmitted))}
                          </span>
                        )}
                      </div>
                      <div
                        className="ml-4 flex-shrink-0 flex space-x-1 font-semibold text-indigo-600 hover:text-indigo-500 cursor-pointer"
                        onClick={() => startSelectTuitionReimbursementAccountPayment(tuitionReimbursementPayment)}
                      >
                        {tuitionReimbursementPayment.institutionName
                          ? `Processing payment to ${tuitionReimbursementPayment.institutionName}`
                          : 'Click here to select payment account'}
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <>N/A</>
              )}
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">Attachments</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {tuitionReimbursementDocumentUploads && tuitionReimbursementDocumentUploads?.length > 0 ? (
                <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                  {tuitionReimbursementDocumentUploads.map((tuitionReimbursementDocumentUpload) => (
                    <li
                      className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                      key={tuitionReimbursementDocumentUpload.id}
                    >
                      <div className="w-0 flex-1 flex items-center">
                        <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                        <span className="ml-2 flex-1 w-0 truncate">
                          {tuitionReimbursementDocumentUpload.gcsFileUpload.fileName}
                        </span>
                      </div>
                      <div className="ml-4 flex-shrink-0 flex space-x-1">
                        <DownloadIcon
                          className="flex flex-shrink-0 h-5 w-5 cursor-pointer text-gray-500 hover:text-gray-900"
                          onClick={() => {
                            handleDocumentDownload(
                              tuitionReimbursementRequest.id,
                              tuitionReimbursementDocumentUpload.id
                            );
                          }}
                          aria-hidden="true"
                        />
                        {tuitionReimbursementRequest.participantProfileId === currentOrganizationUser?.id && (
                          <TrashIcon
                            className="flex flex-shrink-0 h-5 w-5 cursor-pointer text-red-600 hover:text-red-800"
                            onClick={() => {
                              startDeleteTuitionReimbursementDocumentUpload(tuitionReimbursementDocumentUpload);
                            }}
                            aria-hidden="true"
                          />
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <>N/A</>
              )}
            </dd>
          </div>
        </dl>
      </div>
      <DeleteTuitionReimbursementDocumentUploadModal
        open={openDeleteTuitionReimbursementDocumentUploadModal}
        setOpen={setOpenDeleteTuitionReimbursementDocumentUploadModal}
        tuitionReimbursementRequest={tuitionReimbursementRequest}
        tuitionReimbursementDocumentUpload={toDeleteTuitionReimbursementDocumentUpload}
        onDocumentDelete={onDocumentDelete}
      />
      <SelectTuitionReimbursementPaymentAccountSlideover
        tuitionReimbursementPayment={toSelectTuitionReimbursementPayment}
        open={openSelectTuitionReimbursementPaymentAccountSlideover}
        setOpen={setOpenSelectTuitionReimbursementPaymentAccountSlideover}
      />
    </div>
  );
};

export default TuitionReimbursementDetails;
