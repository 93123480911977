import { firebaseUiConfig } from 'config/firebaseConfig';
import { getAuth } from 'firebase/auth';
import logo from 'images/logo-w-title.png';
import React from 'react';
import { StyledFirebaseAuth } from 'react-firebaseui';

const SignInPage: React.FC = () => {
  return (
    <>
      <div className="flex h-screen bg-gray-50 items-center justify-center py-12">
        <div className="max-w-sm">
          <div className="bg-white pt-12 pb-6 px-12 shadow border rounded-lg">
            <img className="h-16 mb-6 object-scale-down w-auto" src={logo} alt="unsaddl" />
            <h2 className="mb-6 text-3xl font-extrabold text-gray-900">Sign in</h2>
            <p className="mb-6 text-gray-500">
              Sign in with your work email to get started with flexible education benefits on Unsaddl!
            </p>
            <div className="h-48 flex flex-col justify-center">
              <StyledFirebaseAuth uiConfig={firebaseUiConfig} firebaseAuth={getAuth()} />
            </div>
            <div className="flex w-full mt-2">
              <span className="text-gray-500 text-sm">
                Need help? Reach out to us at{' '}
                <a href="mailto: support@unsaddl.com" className="cursor-pointer underline">
                  support@unsaddl.com
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignInPage;
