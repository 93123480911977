import { AcademicCapIcon, CalendarIcon, CashIcon, ClockIcon, DuplicateIcon, GiftIcon } from '@heroicons/react/outline';
import { ContributionGrant } from 'interfaces/contributionsInterfaces';
import ElectContributionsSlideover from 'participant/ContributionPlans/ElectContributions/ElectContributionsSlideover';

import React, { useState } from 'react';
import { getContributionPlanScheduleText } from 'utils/contributionUtils';
import { abbrevDate } from 'utils/dateUtils';
import { formatMoney } from 'utils/numberFormatter';

interface ParticipantPlanElementProps {
  participantGrant: ContributionGrant;
}

const ParticipantPlanElement: React.FC<ParticipantPlanElementProps> = ({ participantGrant }) => {
  const [openElectContributionsSlideover, setOpenElectContributionsSlideover] = useState(false);

  const participantPlan = participantGrant.contributionPlan;

  let grantAmount = Number(participantGrant.amount).toFixed(2);

  const cards = [
    {
      name: 'Maximum grant amount',
      href: '#',
      icon: CashIcon,
      amount: formatMoney(Number(grantAmount)),
    },
    { name: 'Schedule', href: '#', icon: ClockIcon, amount: getContributionPlanScheduleText(participantPlan) },
    {
      name: 'Next election deadline',
      href: '#',
      icon: CalendarIcon,
      amount: participantPlan?.nextElectionDeadline ? abbrevDate(participantPlan.nextElectionDeadline) : 'Pending...',
    },

    // More items...
  ];

  const startElectContributions = () => {
    setOpenElectContributionsSlideover(true);
  };

  return (
    <>
      <div className="px-8 py-6 ring-1 ring-black ring-opacity-10 rounded">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-auto">
            <h1 className="text-xl font-bold text-gray-900">{participantPlan?.name}</h1>
          </div>

          <button
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-full sm:w-auto my-2 sm:my-0"
            onClick={() => startElectContributions()}
          >
            Manage elections
          </button>
        </div>

        <div className="mt-1 space-x-1">
          {participantPlan?.contributeStudentLoansEnabled && (
            <span className="inline-flex rounded-md bg-sky-100 px-2 text-sm leading-5 text-sky-800">
              <AcademicCapIcon
                className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-sky-400"
                aria-hidden="true"
              />
              Student loans
            </span>
          )}
          {participantPlan?.contribute529Enabled && (
            <span className="inline-flex rounded-md bg-sky-100 px-2 text-sm leading-5 text-sky-800">
              <GiftIcon className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-sky-400" aria-hidden="true" />
              529
            </span>
          )}
        </div>

        {participantPlan?.employeeMatchRequired && (
          <div>
            <span className="inline-flex rounded-md bg-purple-100 px-2 text-sm leading-5 text-purple-800">
              <DuplicateIcon
                className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-purple-400"
                aria-hidden="true"
              />
              Employee contribution matched: {participantPlan.matchPercentage}%
            </span>
          </div>
        )}

        <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {/* Card */}
          {cards.map((card) => (
            <div
              key={card.name}
              className="bg-white ring-1 ring-black ring-opacity-5 overflow-hidden shadow-md rounded-md"
            >
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <card.icon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">{card.name}</dt>
                      <dd>
                        <div className="text-lg font-medium text-gray-900">{card.amount}</div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <ElectContributionsSlideover
        participantGrant={participantGrant}
        open={openElectContributionsSlideover}
        setOpen={setOpenElectContributionsSlideover}
      />
    </>
  );
};

export default ParticipantPlanElement;
