import { SearchIcon } from '@heroicons/react/outline';
import { Ring } from '@uiball/loaders';
import ListRequestsTable from 'admin/TuitionReimbursement/ListRequests/ListRequestsTable';
import RequestSlideover from 'admin/TuitionReimbursement/ListRequests/RequestSlideover';
import { apiAdminGetTuitionReimbursementRequestsAll } from 'api/tuitionReimbursementAPI';
import PageLoad from 'components/Load/PageLoad';
import { TuitionReimbursementRequest } from 'interfaces/tuitionReimbursementInterfaces';

import React, { useEffect, useState } from 'react';
import { useAsyncDebounce } from 'react-table';

const ListAllRequests: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [isLoadingRequests, setIsLoadingRequests] = useState(false);
  const [tuitionReimbursementRequests, setTuitionReimbursementRequests] = useState<TuitionReimbursementRequest[]>([]);
  const [slideoverRequestId, setSlideoverRequestId] = useState('');
  const [openRequestSlideover, setOpenRequestSlideover] = useState(false);

  const needsActionRequests = tuitionReimbursementRequests.filter(
    (tuitionReimbursementRequest) =>
      tuitionReimbursementRequest.reviewStatus === 'submitted' ||
      tuitionReimbursementRequest.reviewStatus === 'course_completed'
  );

  const setSearchTermDebounced = useAsyncDebounce((searchText: string | undefined) => {
    setSearchTerm(searchText || '');
    setIsLoadingSearch(false);
  }, 1000);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoadingSearch(true);
    setSearchTermDebounced(event.target.value);
  };

  const getTuitionReimbursementRequests = async () => {
    setIsLoadingRequests(true);

    apiAdminGetTuitionReimbursementRequestsAll().then(
      (data) => {
        setTuitionReimbursementRequests(data.tuitionReimbursementRequests);
        setIsLoadingRequests(false);
      },
      (error) => {
        setIsLoadingRequests(false);
      }
    );
  };

  const startRequestSlideover = (tuitionReimbursementRequestId: string) => {
    setSlideoverRequestId(tuitionReimbursementRequestId);
    setOpenRequestSlideover(true);
  };

  useEffect(() => {
    getTuitionReimbursementRequests();
  }, []);

  return (
    <>
      {isLoadingRequests ? (
        <PageLoad />
      ) : (
        <>
          <div className="flex flex-col md:flex-row md:justify-between md:items-center md:space-x-4 my-2">
            <div className="w-full md:max-w-md ml-auto">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  {isLoadingSearch ? (
                    <Ring size={15} lineWeight={7} speed={2.0} color="gray" />
                  ) : (
                    <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  )}
                </div>
                <input
                  id="search"
                  name="search"
                  className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-sm"
                  placeholder="Search"
                  type="search"
                  onChange={handleSearch}
                />
              </div>
            </div>
          </div>

          {needsActionRequests.length > 0 && (
            <div className="mt-2 space-y-2 divide-y divide-gray-200">
              <h1 className="text-xl font-bold text-gray-900">Needs action</h1>
              <ListRequestsTable
                tuitionReimbursementRequests={needsActionRequests}
                searchTerm={searchTerm}
                startRequestSlideover={startRequestSlideover}
              />
            </div>
          )}

          <div className="mt-2 space-y-2 divide-y divide-gray-200">
            <h1 className="text-xl font-bold text-gray-900">All requests</h1>
            <ListRequestsTable
              tuitionReimbursementRequests={tuitionReimbursementRequests}
              searchTerm={searchTerm}
              startRequestSlideover={startRequestSlideover}
            />
          </div>

          <RequestSlideover
            tuitionReimbursementRequestId={slideoverRequestId}
            open={openRequestSlideover}
            setOpen={setOpenRequestSlideover}
          />
        </>
      )}
    </>
  );
};

export default ListAllRequests;
