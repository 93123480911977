/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from '@headlessui/react';
import { LibraryIcon, UserIcon, XIcon } from '@heroicons/react/outline';
import { Ring } from '@uiball/loaders';
import OrganizationRequestActions from 'admin/TuitionReimbursement/ListRequests/OrganizationRequestActions/OrganizationRequestActions';
import { apiAdminGetTuitionReimbursementRequestById } from 'api/tuitionReimbursementAPI';
import TuitionReimbursementDetails from 'common/TuitionReimbursement/TuitionReimbursementDetails';
import TuitionReimbursementTimeline from 'common/TuitionReimbursement/TuitionReimbursementTimeline';
import { TuitionReimbursementRequest } from 'interfaces/tuitionReimbursementInterfaces';

import { Fragment, useEffect, useState } from 'react';

interface RequestSlideoverProps {
  tuitionReimbursementRequestId?: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const RequestSlideover: React.FC<RequestSlideoverProps> = ({ tuitionReimbursementRequestId, open, setOpen }) => {
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [tuitionReimbursementRequest, setTuitionReimbursementRequest] = useState<TuitionReimbursementRequest>();
  const requestUser = tuitionReimbursementRequest?.participantProfile?.organizationUser;
  const userInfoString = `${requestUser?.firstName} ${requestUser?.lastName} (${requestUser?.email})`;

  const getTuitionReimbursementRequest = async () => {
    if (tuitionReimbursementRequestId) {
      setIsLoadingRequest(true);
      apiAdminGetTuitionReimbursementRequestById(tuitionReimbursementRequestId).then(
        (data) => {
          setTuitionReimbursementRequest(data.tuitionReimbursementRequest);
          setIsLoadingRequest(false);
        },
        (error) => {
          setIsLoadingRequest(false);
        }
      );
    }
  };

  useEffect(() => {
    if (open) {
      getTuitionReimbursementRequest();
    }
  }, [open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll border-l bg-white shadow-xl">
                    <div className="px-4 sm:px-6 sticky top-0 z-10 border-b py-6 bg-white">
                      {isLoadingRequest ? (
                        <div className="py-8 flex justify-center">
                          <Ring size={40} lineWeight={5} speed={2.0} color="gray" />
                        </div>
                      ) : (
                        <div className="flex items-start justify-between">
                          <div className="flex flex-col">
                            <Dialog.Title className="text-lg font-medium text-gray-900">
                              {tuitionReimbursementRequest?.courseName}
                            </Dialog.Title>
                            <div className="mt-2 flex items-center text-sm text-gray-500">
                              <LibraryIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                              {tuitionReimbursementRequest?.institutionName}
                            </div>
                            <div className="mt-2 flex items-center text-sm text-gray-500">
                              <UserIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                              {userInfoString}
                            </div>
                          </div>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                    {isLoadingRequest ? (
                      <div className="py-8 flex justify-center">
                        <Ring size={40} lineWeight={5} speed={2.0} color="gray" />
                      </div>
                    ) : (
                      <div className="relative flex-1 px-4 sm:px-6 py-4">
                        {/* Replace with your content */}
                        <OrganizationRequestActions tuitionReimbursementRequest={tuitionReimbursementRequest} />

                        <div className="py-2">
                          <TuitionReimbursementTimeline tuitionReimbursementRequest={tuitionReimbursementRequest} />
                        </div>

                        <h2 className="text-lg font-medium text-gray-900 py-2">Request details</h2>
                        {tuitionReimbursementRequest && (
                          <TuitionReimbursementDetails tuitionReimbursementRequest={tuitionReimbursementRequest} />
                        )}

                        {/* /End replace */}
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default RequestSlideover;
