import { OfficeBuildingIcon, UserIcon } from '@heroicons/react/solid';
import { UserDataContext } from 'context/UserContext';
import { useContext } from 'react';
import { getRoleText } from 'utils/roleUtils';

const HomePageHeader: React.FC = () => {
  const { userAccount, currentOrganizationUser } = useContext(UserDataContext);
  const firstName = currentOrganizationUser?.firstName;
  const lastName = currentOrganizationUser?.lastName;
  const activeRole = currentOrganizationUser?.role;

  const roleText = getRoleText(activeRole);
  const organizationName = currentOrganizationUser?.organization?.name;

  return (
    <>
      {/* Page header */}
      <div className="bg-white shadow">
        <div className="px-4 sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-8">
          <div className="py-6 md:flex md:items-center md:justify-between lg:border-gray-200">
            <div className="flex-1 min-w-0">
              {/* Profile */}
              <div className="flex items-center">
                <span className="hidden items-center justify-center h-16 w-16 rounded-full bg-indigo-500 sm:inline-flex">
                  <span className="text-xl font-medium leading-none text-white">
                    {firstName?.charAt(0).toUpperCase()}
                    {lastName?.charAt(0).toUpperCase()}
                  </span>
                </span>
                <div>
                  <div className="flex items-center">
                    <span className="inline-flex items-center justify-center h-16 w-16 rounded-full bg-indigo-500 sm:hidden">
                      <span className="text-xl font-medium leading-none text-white">
                        {firstName?.charAt(0).toUpperCase()}
                        {lastName?.charAt(0).toUpperCase()}
                      </span>
                    </span>
                    <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                      Welcome, {firstName}
                    </h1>
                  </div>
                  <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                    <dt className="sr-only">Company</dt>
                    <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                      <OfficeBuildingIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                      {organizationName}
                    </dd>
                    <dt className="sr-only">Account status</dt>
                    <dd className="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
                      <UserIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                      {roleText}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
              <button
                type="button"
                className="hidden inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
              >
                Add money
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePageHeader;
