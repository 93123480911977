import PageLoad from 'components/Load/PageLoad';
import { UserDataContext } from 'context/UserContext';
import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

const AuthenticatedRoute: React.FC<RouteProps> = ({ ...routeProps }) => {
  const { firebaseUser, isAuthenticating } = useContext(UserDataContext);

  return (
    <>
      {isAuthenticating ? (
        <PageLoad />
      ) : firebaseUser?.emailVerified ? (
        <Route {...routeProps} />
      ) : (
        <Redirect to="/login" />
      )}
    </>
  );
};

export default AuthenticatedRoute;
