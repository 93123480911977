import { apiParticipantGetTuitionReimbursementRequests } from 'api/tuitionReimbursementAPI';
import EmptyTablePlaceholder from 'common/EmptyTablePlaceholder';
import PageLoad from 'components/Load/PageLoad';
import { UserDataContext } from 'context/UserContext';
import { TuitionReimbursementRequest } from 'interfaces/tuitionReimbursementInterfaces';
import ParticipantTuitionReimbursementRequestElement from 'participant/TuitionReimbursement/ParticipantTuitionReimbursementRequestElement/ParticipantTuitionReimbursementRequestElement';
import React, { useContext, useEffect, useState } from 'react';

const ParticipantTuitionReimbursementRequestsPage: React.FC = () => {
  const { firebaseUser, currentOrganizationUser } = useContext(UserDataContext);

  const [tuitionReimbursementRequests, setTuitionReimbursementRequests] = useState<TuitionReimbursementRequest[]>();
  const [isLoadingTuitionReimbursementRequests, setIsLoadingTuitionReimbursementRequests] = useState(true);

  const getParticipantTuitionReimbursementRequests = async () => {
    setIsLoadingTuitionReimbursementRequests(true);
    apiParticipantGetTuitionReimbursementRequests().then(
      (data) => {
        setTuitionReimbursementRequests(data.participantTuitionReimbursementRequests);
        setIsLoadingTuitionReimbursementRequests(false);
      },
      (error) => {
        setIsLoadingTuitionReimbursementRequests(false);
      }
    );
  };

  useEffect(() => {
    getParticipantTuitionReimbursementRequests();
  }, []);

  return (
    <>
      {isLoadingTuitionReimbursementRequests ? (
        <PageLoad />
      ) : (
        <div className="my-6 mx-auto max-w-7xl">
          <div>
            <>
              {currentOrganizationUser?.organization?.tuitionReimbursementEnabled === false ? (
                <div className="mt-4">
                  <EmptyTablePlaceholder
                    title={'Tuition reimbursement is not enabled for your organization'}
                    subtitle={
                      'Reach out to your organization administrator or Unsaddl support if you believe this is a mistake.'
                    }
                  />
                </div>
              ) : tuitionReimbursementRequests?.length === 0 ? (
                <div className="mt-4">
                  <EmptyTablePlaceholder
                    title={'No tuition reimbursement requests'}
                    subtitle={'You have not submitted any tuition reimbursement requests for review'}
                  />
                </div>
              ) : (
                tuitionReimbursementRequests?.map((tuitionReimbursementRequest) => (
                  <ParticipantTuitionReimbursementRequestElement
                    key={tuitionReimbursementRequest.id}
                    tuitionReimbursementRequest={tuitionReimbursementRequest}
                    refreshData={getParticipantTuitionReimbursementRequests}
                  />
                ))
              )}
            </>
          </div>
        </div>
      )}
    </>
  );
};

export default ParticipantTuitionReimbursementRequestsPage;
