import ConnectPayrollMenu from 'admin/PayrollConnections/ConnectPayrollMenu';
import PayrollConnectionElement from 'admin/PayrollConnections/PayrollConnectionElement';
import { apiGetPayrollConnections, apiSyncWithPayroll } from 'api/organizationUsersAPI';
import EmptyPlaceholder from 'common/EmptyPlaceholder';
import LoadingButton from 'components/Buttons/LoadingButton';
import PageLoad from 'components/Load/PageLoad';
import { UserDataContext } from 'context/UserContext';
import { PayrollConnection } from 'interfaces/organizationUserInterfaces';
import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

const PayrollConnectionsPage: React.FC = () => {
  const { firebaseUser, currentOrganizationUser } = useContext(UserDataContext);
  const [payrollConnections, setPayrollConnections] = useState<PayrollConnection[]>([]);
  const [isLoadingPayrollConnections, setIsLoadingPayrollConnections] = useState(true);
  const [isSubmittingSyncRequest, setIsSubmittingSyncRequest] = useState(false);

  const getPayrollConnections = async () => {
    setIsLoadingPayrollConnections(true);
    apiGetPayrollConnections().then(
      (data) => {
        setPayrollConnections(data.payrollConnections);
        setIsLoadingPayrollConnections(false);
      },
      (error) => {
        setIsLoadingPayrollConnections(false);
      }
    );
  };

  const handleSyncWithPayroll = async () => {
    setIsSubmittingSyncRequest(true);
    apiSyncWithPayroll().then(
      (data) => {
        setIsSubmittingSyncRequest(false);
        toast.success(`We've initiated a sync with your payroll providers!`);
      },
      (error) => {
        setIsSubmittingSyncRequest(false);
        toast.error(`There was an issue initiating a sync with your payroll providers. Please try again later.`);
      }
    );
  };

  useEffect(() => {
    if (firebaseUser) {
      getPayrollConnections();
    }
  }, []);

  return currentOrganizationUser?.adminProfile?.adminPermissions &&
    currentOrganizationUser?.adminProfile?.adminPermissions.includes('user_management') ? (
    <>
      {isLoadingPayrollConnections ? (
        <PageLoad />
      ) : (
        <div className="my-6 px-8 mx-auto max-w-7xl">
          <div>
            <div className="flex justify-between">
              <h1 className="text-2xl font-bold text-gray-900 capitalize">Payroll Connections</h1>
              {payrollConnections.length > 0 && (
                <LoadingButton
                  type="button"
                  styles="rounded-md inline-flex items-center justify-center border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={handleSyncWithPayroll}
                  text="Sync providers"
                  loadingText="Sync providers"
                  isLoading={isSubmittingSyncRequest}
                />
              )}
            </div>

            <>
              {payrollConnections.map((payrollConnection) => (
                <PayrollConnectionElement
                  key={payrollConnection.id}
                  payrollConnection={payrollConnection}
                  getPayrollConnections={getPayrollConnections}
                />
              ))}
            </>

            <ConnectPayrollMenu
              refreshPayrollConnections={getPayrollConnections}
              hasExistingPayrollConnections={payrollConnections.length > 0}
            />
          </div>
        </div>
      )}
    </>
  ) : (
    <div className="my-6 px-8 mx-auto max-w-7xl">
      <EmptyPlaceholder
        title="Access denied"
        subtitle="Your organization super admin has not given you permission to access this page"
      />
    </div>
  );
};

export default PayrollConnectionsPage;
