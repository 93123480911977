import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ClockIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/outline';
import EmptyTablePlaceholder from 'common/EmptyTablePlaceholder';
import { ContributionPlanRun } from 'interfaces/contributionsInterfaces';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { CellProps, Column, useSortBy, useTable } from 'react-table';
import { abbrevDate } from 'utils/dateUtils';

interface ViewPlanRunsTableProps {
  contributionPlanRuns: ContributionPlanRun[];
}

const ViewPlanRunsTable: React.FC<ViewPlanRunsTableProps> = ({ contributionPlanRuns }) => {
  const history = useHistory();

  const contributionPlanRunsMemo = React.useMemo<ContributionPlanRun[]>(
    () => contributionPlanRuns,
    [contributionPlanRuns]
  );
  const columns: Column<ContributionPlanRun>[] = React.useMemo(
    () => [
      {
        id: 'execution-date',
        Header: 'Execution date',

        Cell: (props: CellProps<ContributionPlanRun>) => {
          const contributionPlanRun = props.row.original;
          const createdAt = contributionPlanRun.createdAt;

          return (
            <div className="items-center">
              <div className="text-sm font-medium text-gray-900">{abbrevDate(createdAt)}</div>
            </div>
          );
        },
        className: 'py-4 text-sm font-medium text-gray-900',
        headerClassName: 'py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
      {
        id: 'status',
        Header: 'Status',
        Cell: (props: CellProps<ContributionPlanRun>) => {
          const contributionPlanRun = props.row.original;

          return (
            <div className="flex">
              {contributionPlanRun.fundStatus !== 'received' ? (
                <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-yellow-100 text-yellow-800">
                  <ClockIcon
                    className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                  Awaiting funding
                </span>
              ) : contributionPlanRun.disbursementStatus !== 'disbursed' ? (
                <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-yellow-100 text-yellow-800">
                  <CurrencyDollarIcon
                    className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                  Payments processing
                </span>
              ) : (
                <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-green-100 text-green-800">
                  <CheckCircleIcon
                    className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                    aria-hidden="true"
                  />
                  Complete
                </span>
              )}
            </div>
          );
        },
        className: 'py-4 whitespace-nowrap text-sm text-gray-500',
        headerClassName: 'py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
    ],
    []
  ) as any;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: contributionPlanRunsMemo,
    },
    useSortBy
  );

  return (
    <div>
      <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={`${column.headerClassName}`}
                    >
                      {
                        // Render the header
                        column.render('Header')
                      }
                      <span className="inline-flex ml-1">
                        {column.isSorted ? (
                          <span className="w-3 h-3">
                            {column.isSortedDesc ? <ChevronDownIcon /> : <ChevronUpIcon />}
                          </span>
                        ) : (
                          ''
                        )}
                      </span>
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()} className="divide-y divide-gray-200">
          {
            // Loop over the table rows
            rows.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr
                  {...row.getRowProps()}
                  onClick={() => history.push(`/runs/${row.original.id}/view`)}
                  className="hover:bg-gray-50 cursor-pointer"
                >
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td {...cell.getCellProps()} className={`${cell.column.className}`}>
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>

      {rows.length === 0 && (
        <EmptyTablePlaceholder
          title="No plan runs"
          subtitle="No runs have been executed for this contribution plan yet."
        />
      )}
    </div>
  );
};

export default ViewPlanRunsTable;
