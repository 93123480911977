import { apiCreateUserAccount } from 'api/userAccountsAPI';
import LoadingButton from 'components/Buttons/LoadingButton';
import { logOut } from 'config/firebaseConfig';
import { UserDataContext } from 'context/UserContext';
import logo from 'images/logo-w-title.png';
import { useContext, useState } from 'react';

const RegistrationPage: React.FC = () => {
  const { firebaseUser, refreshUser } = useContext(UserDataContext);
  const [isRegistering, setIsRegistering] = useState(false);
  const [registerErrorMessage, setRegisterErrorMessage] = useState('');

  const handleSubmit = async () => {
    setIsRegistering(true);

    apiCreateUserAccount().then(
      (data) => {
        refreshUser();
        setIsRegistering(false);
      },
      (error) => {
        setRegisterErrorMessage(error.message);
        setIsRegistering(false);
      }
    );
  };

  return (
    <>
      <div className="flex bg-gray-50 h-screen items-center justify-center py-12 sm:px-6 lg:px-8">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="sm:mx-auto sm:w-full mb-4">
              <img className="mx-auto h-16 w-auto" src={logo} alt="unsaddl" />
              <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">Accepting Terms & Conditions</h2>
            </div>

            <div className="flex w-full">
              <span className="text-gray-500 text-sm">
                Before using Unsaddl, you must agree to Unsaddl's{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.unsaddl.com/terms-of-use"
                  className="cursor-pointer underline"
                >
                  Terms of Use
                </a>{' '}
                and{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.unsaddl.com/privacy-policy"
                  className="cursor-pointer underline"
                >
                  Privacy Policy
                </a>
                {'.'}
              </span>
            </div>

            <div>
              <LoadingButton
                type="button"
                styles="w-full flex justify-center mt-4 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                text="Accept"
                loadingText="Processing..."
                isLoading={isRegistering}
                onClick={handleSubmit}
              />
            </div>
            <div className="flex w-full mt-2">
              <span className="text-gray-500 text-sm ml-auto">
                Not {firebaseUser?.email}?{' '}
                <a onClick={logOut} className="cursor-pointer underline">
                  Sign out
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrationPage;
