import { Menu } from '@headlessui/react';
import { CheckCircleIcon, ClockIcon } from '@heroicons/react/outline';
import MenuOptions from 'components/MenuOptions';
import { Savings529Account } from 'interfaces/savings529Interfaces';
import DeleteSavings529AccountModal from 'participant/Accounts/Savings529Account/modals/DeleteSavings529AccountModal';
import Manage529AccountDocumentUploadsModal from 'participant/Accounts/Savings529Account/modals/Manage529AccountDocumentUploadsModal';
import Savings529AccountSettingsOptions from 'participant/Accounts/Savings529Account/Savings529AccountSettingsOptions';
import React, { useState } from 'react';

interface Savings529AccountElementProps {
  savings529Account: Savings529Account;
  refreshAccountData: () => void;
}

const Savings529AccountElement: React.FC<Savings529AccountElementProps> = ({
  savings529Account,
  refreshAccountData,
}) => {
  const [openDeleteSavings529AccountModal, setOpenDeleteSavings529AccountModal] = useState(false);
  const [openManageDocumentUploadsModal, setOpenManageDocumentUploadsModal] = useState(false);

  return (
    <>
      <div className="px-8 py-4 ring-1 ring-black ring-opacity-10 rounded">
        <div>
          <div className="sm:items-center flex flex-col sm:flex-row">
            <div className="flex-auto">
              <h1 className="text-xl font-bold text-gray-900">{savings529Account.name}</h1>
            </div>

            <MenuOptions
              menuButton={
                <Menu.Button className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-full sm:w-auto my-2 sm:my-0">
                  Manage account
                </Menu.Button>
              }
              menuItems={
                <Savings529AccountSettingsOptions
                  savings529Account={savings529Account}
                  setOpenDeleteSavings529AccountModal={setOpenDeleteSavings529AccountModal}
                  setOpenManageDocumentUploadsModal={setOpenManageDocumentUploadsModal}
                />
              }
            />
          </div>

          {!savings529Account.verified ? (
            <div className="mt-1 ">
              <div className="inline-flex rounded-md bg-yellow-100 px-2 text-sm leading-5 text-yellow-800">
                <ClockIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-yellow-400" aria-hidden="true" />
                Awaiting verification
              </div>
            </div>
          ) : (
            <div className="mt-1 ">
              <div className="inline-flex rounded-md bg-green-100 px-2 text-sm leading-5 text-green-800">
                <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" aria-hidden="true" />
                Verified
              </div>
            </div>
          )}
        </div>
        {savings529Account.contributionType === 'ach' ? (
          <div className="mt-4 flex flex-col">
            <div className="inline-block min-w-full py-2 align-middle">
              <table className="min-w-full table-auto divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-500">
                      Institution name
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 px-3 text-right text-sm font-semibold text-gray-500 hidden sm:table-cell"
                    >
                      Routing #
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 px-3 text-right text-sm font-semibold text-gray-500 hidden xs:table-cell"
                    >
                      Account #
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  <tr key={savings529Account.id}>
                    <td className="py-4 pr-3 text-sm font-medium text-gray-900">{savings529Account.institutionName}</td>
                    <td className="py-4 px-3 text-sm text-right text-gray-500 hidden sm:table-cell">
                      {savings529Account.routingNumber}
                    </td>
                    <td className="py-4 px-3 text-sm text-right text-gray-500 hidden xs:table-cell">
                      {savings529Account.accountNumber}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="mt-4 flex flex-col">
            <div className="inline-block min-w-full py-2 align-middle">
              <table className="min-w-full table-auto divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-500">
                      Institution name
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 px-3 text-left text-sm font-semibold text-gray-500 hidden sm:table-cell"
                    >
                      Address
                    </th>
                    <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-500">
                      Account #
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 px-3 text-left text-sm font-semibold text-gray-500 hidden xs:table-cell"
                    >
                      Additional Instructions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  <tr key={savings529Account.id}>
                    <td className="py-4 pr-3 text-sm font-medium text-gray-900">{savings529Account.institutionName}</td>
                    <td className="py-4 px-3 text-sm text-left text-gray-500 hidden sm:table-cell">
                      <div>{savings529Account.institutionAddressStreet}</div>
                      {savings529Account.institutionAddressStreet2 && (
                        <div>{savings529Account.institutionAddressStreet2}</div>
                      )}
                      <div>
                        {savings529Account.institutionAddressCity}, {savings529Account.institutionAddressRegion}{' '}
                        {savings529Account.institutionAddressPostalCode}
                      </div>
                    </td>
                    <td className="py-4 px-3 text-sm text-left text-gray-500">{savings529Account.accountNumber}</td>
                    <td className="py-4 px-3 text-sm text-left text-gray-500 hidden xs:table-cell">
                      {savings529Account.additionalInstructions}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>

      <DeleteSavings529AccountModal
        savings529Account={savings529Account}
        open={openDeleteSavings529AccountModal}
        setOpen={setOpenDeleteSavings529AccountModal}
        refreshAccountData={refreshAccountData}
      />
      <Manage529AccountDocumentUploadsModal
        savings529Account={savings529Account}
        open={openManageDocumentUploadsModal}
        setOpen={setOpenManageDocumentUploadsModal}
      />
      {/* <DeactivateLoanAccountModal
        institutionName={loanServicer.name}
        loanAccount={toDeactivateAccount}
        open={openDeactivateAccountModal}
        setOpen={setOpenDeactivateAccountModal}
        refreshLoanData={refreshLoanData}
      />
      <ReactivateLoanAccountModal
        institutionName={loanServicer.name}
        loanAccount={toReactivateAccount}
        open={openReactivateAccountModal}
        setOpen={setOpenReactivateAccountModal}
        refreshLoanData={refreshLoanData}
      />
      <DeleteLoanServicerModal
        loanServicer={loanServicer}
        open={openDeleteLoanServicerModal}
        setOpen={setOpenDeleteLoanServicerModal}
        refreshLoanData={refreshLoanData}
      />
      <ManageStatementUploadsModal
        loanServicer={loanServicer}
        open={openManageStatementUploadsModal}
        setOpen={setOpenManageStatementUploadsModal}
      /> */}
    </>
  );
};

export default Savings529AccountElement;
