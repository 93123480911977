import moment from 'moment';

export const dateFormatter = (isoDate: string) => {
  const formattedDate = moment.utc(isoDate).format('LL');

  return formattedDate;
};

export const abbrevDate = (isoDate: string) => {
  const formattedDate = moment.utc(isoDate).format('MMM DD, YYYY');
  return formattedDate;
};

export const formDate = (isoDate: string) => {
  const formattedDate = moment.utc(isoDate).format('yyyy-MM-DD');
  return formattedDate;
};

export const calculateDateDiff = (startDate: Date, endDate: Date) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

  const diffDays = Math.round(Math.abs((endDate.getTime() - startDate.getTime()) / oneDay));

  return diffDays;
};
