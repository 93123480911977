import ViewParticipantStatementUploadsTable from 'admin/ManageOrganizationUsers/ViewUser/ViewParticipant/ViewParticipantStatementUploadsTable';
import EmptyPlaceholder from 'common/EmptyPlaceholder';
import { LoanServicerStatementUpload } from 'interfaces/loansInterfaces';
import { OrganizationUser } from 'interfaces/organizationUserInterfaces';
import React from 'react';

interface ViewParticipantStatementUploadsProps {
  participant: OrganizationUser;
}

const ViewParticipantStatementUploads: React.FC<ViewParticipantStatementUploadsProps> = ({ participant }) => {
  const participantGrants = participant.participantProfile?.contributionGrants;
  const statementUploads = participant.participantProfile?.loanServicers?.reduce(
    (allStatementUploads, loanServicer) => {
      let statementUploadsWithLoanServicerName = loanServicer.statementUploads.map((statementUpload) => {
        statementUpload.loanServicerName = loanServicer.name;
        return statementUpload;
      });
      return allStatementUploads.concat(statementUploadsWithLoanServicerName);
    },
    [] as LoanServicerStatementUpload[]
  );

  return (
    <>
      {/* Description list */}
      <div className="mt-6 mx-auto">
        {statementUploads && statementUploads.length > 0 ? (
          <ViewParticipantStatementUploadsTable statementUploads={statementUploads} />
        ) : (
          <EmptyPlaceholder
            title={'No statement uploads'}
            subtitle={'This participant has not uploaded any loan statements'}
          />
        )}
      </div>
    </>
  );
};

export default ViewParticipantStatementUploads;
