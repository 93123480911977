import { SearchIcon } from '@heroicons/react/outline';
import { Ring } from '@uiball/loaders';
import { apiAdminGetTuitionReimbursementPayments } from 'api/tuitionReimbursementAPI';
import TuitionReimbursementPaymentsTable from 'common/TuitionReimbursement/TuitionReimbursementPaymentsTable';
import ViewTuitionReimbursementPaymentModal from 'common/TuitionReimbursement/ViewTuitionReimbursementPaymentModal';
import PageLoad from 'components/Load/PageLoad';
import { TuitionReimbursementPayment } from 'interfaces/tuitionReimbursementInterfaces';

import React, { useEffect, useState } from 'react';
import { useAsyncDebounce } from 'react-table';

const ListPayments: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [tuitionReimbursementPayments, setTuitionReimbursementPayments] = useState<TuitionReimbursementPayment[]>([]);
  const [isLoadingTuitionReimbursementPayments, setIsLoadingTuitionReimbursementPayments] = useState(true);
  const [viewTuitionReimbursementPayment, setViewTuitionReimbursementPayment] = useState<TuitionReimbursementPayment>();
  const [openViewTuitionReimbursementPaymentModal, setOpenViewTuitionReimbursementPaymentModal] = useState(false);

  const setSearchTermDebounced = useAsyncDebounce((searchText: string | undefined) => {
    setSearchTerm(searchText || '');
    setIsLoadingSearch(false);
  }, 1000);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoadingSearch(true);
    setSearchTermDebounced(event.target.value);
  };

  const startViewTuitionReimbursementPayment = (tuitionReimbursementPayment: TuitionReimbursementPayment) => {
    setViewTuitionReimbursementPayment(tuitionReimbursementPayment);
    setOpenViewTuitionReimbursementPaymentModal(true);
  };

  const getTuitionReimbursementPayments = async () => {
    setIsLoadingTuitionReimbursementPayments(true);
    apiAdminGetTuitionReimbursementPayments().then(
      (data) => {
        setTuitionReimbursementPayments(data.tuitionReimbursementPayments);
        setIsLoadingTuitionReimbursementPayments(false);
      },
      (error) => {
        setIsLoadingTuitionReimbursementPayments(false);
      }
    );
  };

  useEffect(() => {
    getTuitionReimbursementPayments();
  }, []);

  return (
    <>
      {isLoadingTuitionReimbursementPayments ? (
        <PageLoad />
      ) : (
        <>
          <div className="flex flex-col md:flex-row md:justify-between md:items-center md:space-x-4 my-2">
            <div className="w-full md:max-w-md ml-auto">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  {isLoadingSearch ? (
                    <Ring size={15} lineWeight={7} speed={2.0} color="gray" />
                  ) : (
                    <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  )}
                </div>
                <input
                  id="search"
                  name="search"
                  className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-sm"
                  placeholder="Search"
                  type="search"
                  onChange={handleSearch}
                />
              </div>
            </div>
          </div>

          <div className="mt-2 space-y-2 divide-y divide-gray-200">
            <TuitionReimbursementPaymentsTable
              tuitionReimbursementPayments={tuitionReimbursementPayments}
              startViewTuitionReimbursementPayment={startViewTuitionReimbursementPayment}
              searchTerm={searchTerm}
            />
            <ViewTuitionReimbursementPaymentModal
              tuitionReimbursementPayment={viewTuitionReimbursementPayment}
              open={openViewTuitionReimbursementPaymentModal}
              setOpen={setOpenViewTuitionReimbursementPaymentModal}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ListPayments;
