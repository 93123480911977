import {
  ChevronDownIcon,
  ChevronUpIcon,
  CurrencyDollarIcon,
  ReceiptTaxIcon,
  UserCircleIcon,
} from '@heroicons/react/outline';
import EmptyTablePlaceholder from 'common/EmptyTablePlaceholder';
import Pagination from 'components/Tables/Pagination';
import { ContributionGrant, ContributionPlan } from 'interfaces/contributionsInterfaces';
import React, { useEffect } from 'react';
import Highlighter from 'react-highlight-words';
import { useHistory } from 'react-router-dom';
import { CellProps, Column, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { formatMoney } from 'utils/numberFormatter';
import { classNames } from 'utils/tailwindUtils';

interface ViewGrantsTableProps {
  searchTerm: string;
  contributionPlan: ContributionPlan;
  contributionGrants: ContributionGrant[];
}

const ViewGrantsTable: React.FC<ViewGrantsTableProps> = ({ searchTerm, contributionPlan, contributionGrants }) => {
  const history = useHistory();

  const contributionGrantsMemo = React.useMemo<ContributionGrant[]>(() => contributionGrants, [contributionGrants]);
  const columns: Column<ContributionGrant>[] = React.useMemo(
    () => [
      {
        id: 'recipient',
        Header: 'Recipient',
        accessor: (grant: ContributionGrant) => {
          const granteeOrganizationUser = grant.grantee.organizationUser;

          return (
            granteeOrganizationUser?.firstName +
            ' ' +
            granteeOrganizationUser?.lastName +
            ' ' +
            granteeOrganizationUser?.email
          );
        },
        Cell: (props: CellProps<ContributionGrant>) => {
          const grant = props.row.original;
          const granteeOrganizationUser = grant.grantee.organizationUser;
          const firstName = granteeOrganizationUser?.firstName;
          const lastName = granteeOrganizationUser?.lastName;
          const email = granteeOrganizationUser?.email;

          return (
            <div className="flex items-center">
              <div>
                <Highlighter
                  className="block font-medium text-gray-900 break-all"
                  highlightClassName="bg-yellow-200"
                  searchWords={[props.state.globalFilter]}
                  autoEscape={true}
                  textToHighlight={`${firstName} ${lastName}`}
                />
                <Highlighter
                  className="block text-gray-500 break-all"
                  highlightClassName="bg-yellow-200"
                  searchWords={[props.state.globalFilter]}
                  autoEscape={true}
                  textToHighlight={`${email}`}
                />
              </div>
            </div>
          );
        },
        className: 'py-4 text-sm font-medium text-gray-900',
        headerClassName: 'py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },

      {
        id: 'grant-details',
        Header: 'Grant details',
        accessor: (contributionGrant: ContributionGrant) => {
          let matchStatusText =
            Number(contributionGrant.matchableEmployeeContribution) === 0
              ? 'No match qualified'
              : `Match qualified: ${formatMoney(Number(contributionGrant.matchableEmployeeContribution))}`;
          return `${formatMoney(Number(contributionGrant.amount))} granted ${
            contributionGrant.totalElectionPercentage
          }% elected ${matchStatusText}`;
        },
        Cell: (props: CellProps<ContributionGrant>) => {
          const grant = props.row.original;

          // Fully allocated elections colors
          let electionSpanColor = 'bg-green-100 text-green-800';
          let electionIconColor = 'text-green-400';
          // Not fully allocated elections colors
          if (Number(grant.totalElectionPercentage) !== 100) {
            electionSpanColor = 'bg-yellow-100 text-yellow-800';
            electionIconColor = 'text-yellow-400';
          }

          let matchSpanColor = 'bg-sky-100 text-sky-800';
          let matchIconColor = 'text-sky-400';
          if (Number(grant.matchableEmployeeContribution) === 0) {
            matchSpanColor = 'bg-gray-200 text-gray-700';
            matchIconColor = 'text-gray-400';
          }
          let matchStatusText =
            Number(grant.matchableEmployeeContribution) === 0
              ? 'No match qualified'
              : `Match qualified: ${formatMoney(Number(grant.matchableEmployeeContribution))}`;

          return (
            <div className="flex-col space-y-1">
              <div className="flex">
                <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-purple-100 text-purple-800">
                  <CurrencyDollarIcon
                    className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-purple-400"
                    aria-hidden="true"
                  />
                  {formatMoney(Number(grant.amount))} granted
                </span>
              </div>
              <div className="flex">
                <span
                  className={classNames(
                    'inline-flex px-2 text-xs leading-5 font-semibold rounded-md',
                    electionSpanColor
                  )}
                >
                  <UserCircleIcon
                    className={classNames('flex-shrink-0 mr-1.5 h-5 w-5', electionIconColor)}
                    aria-hidden="true"
                  />
                  {Number(grant.totalElectionPercentage)}% elected
                </span>
              </div>

              {contributionPlan.employeeMatchRequired && (
                <div className="flex">
                  <span
                    className={classNames(
                      'inline-flex px-2 text-xs leading-5 font-semibold rounded-md',
                      matchSpanColor
                    )}
                  >
                    <ReceiptTaxIcon
                      className={classNames('flex-shrink-0 mr-1.5 h-5 w-5', matchIconColor)}
                      aria-hidden="true"
                    />
                    {matchStatusText}
                  </span>
                </div>
              )}
            </div>
          );
        },
        className: 'py-4 whitespace-nowrap text-sm text-gray-500',
        headerClassName: 'py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
    ],
    []
  ) as any;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex },
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: contributionGrantsMemo,
      initialState: { pageSize: 10, globalFilter: searchTerm },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setGlobalFilter(searchTerm);
  }, [searchTerm]);

  return (
    <div>
      <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={`${column.headerClassName}`}
                    >
                      {
                        // Render the header
                        column.render('Header')
                      }
                      <span className="inline-flex ml-1">
                        {column.isSorted ? (
                          <span className="w-3 h-3">
                            {column.isSortedDesc ? <ChevronDownIcon /> : <ChevronUpIcon />}
                          </span>
                        ) : (
                          ''
                        )}
                      </span>
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()} className="divide-y divide-gray-200">
          {
            // Loop over the table rows
            page.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr
                  {...row.getRowProps()}
                  onClick={() => history.push(`/participants/${row.original.grantee.organizationUserId}/view/profile`)}
                  className="hover:bg-gray-50 cursor-pointer"
                >
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td {...cell.getCellProps()} className={`${cell.column.className}`}>
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>

      {page.length === 0 && (
        <EmptyTablePlaceholder title="No grants" subtitle="This contribution plan does not have any active grants." />
      )}

      <Pagination
        pageIndex={pageIndex}
        numPages={pageOptions.length}
        nextPage={nextPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
      />
    </div>
  );
};

export default ViewGrantsTable;
