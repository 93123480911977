import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { apiDisablePlan } from 'api/contributionsAPI';
import LoadingButton from 'components/Buttons/LoadingButton';
import { ContributionPlan } from 'interfaces/contributionsInterfaces';
import { Fragment, useState } from 'react';
import toast from 'react-hot-toast';
import { classNames } from 'utils/tailwindUtils';

interface DisablePlanModalProps {
  contributionPlan: ContributionPlan;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onDisableSuccess: () => void;
}

const deleteText = 'delete';

const DisablePlanModal: React.FC<DisablePlanModalProps> = ({ contributionPlan, open, setOpen, onDisableSuccess }) => {
  const [error, setError] = useState('');
  const [planNameInput, setPlanNameInput] = useState('');
  const [disableErrorMessage, setDisableErrorMessage] = useState('');
  const [isDisablingPlan, setIsDisablingPlan] = useState(false);

  const handleChangePlanNameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPlanNameInput(e.target.value);
  };

  const matchesPlanName = (inputText: string) => {
    return inputText === contributionPlan.name;
  };

  const handleSubmitDelete = async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!matchesPlanName(planNameInput)) {
      return;
    }

    setIsDisablingPlan(true);
    apiDisablePlan(contributionPlan.id).then(
      (data) => {
        setOpen(false);
        setIsDisablingPlan(false);
        setDisableErrorMessage('');
        setPlanNameInput('');
        toast.success(`Disabled plan: ${contributionPlan.name}!`);
        onDisableSuccess();
      },
      (error) => {
        setIsDisablingPlan(false);
        setDisableErrorMessage(error.message);
      }
    );
  };

  const handleClose = () => {
    setOpen(false);
    setError('');
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={handleClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Disable Contribution Plan
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to disable this contribution plan? All employee elections on this plan
                        will be deleted. All contribution grants will be maintained in case you ever want to reactivate
                        this plan. Type the full contribution plan name in the box below to disable this plan.
                      </p>
                    </div>
                    <div>
                      <h4 className="mt-2 text-lg leading-6 font-medium text-gray-900">{contributionPlan.name}</h4>
                    </div>
                    <form className="mt-5 sm:flex sm:items-center" onSubmit={handleSubmitDelete}>
                      <div className="w-full sm:max-w-xs">
                        <label htmlFor="delete" className="sr-only">
                          Disable Contribution Plan
                        </label>
                        <input
                          name="disable"
                          id="disable"
                          value={planNameInput}
                          onChange={handleChangePlanNameInput}
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 px-3 py-2 block w-full sm:text-sm border border-gray-300 rounded-md"
                          required
                        />
                      </div>

                      <LoadingButton
                        text="DISABLE"
                        loadingText="Processing..."
                        isLoading={isDisablingPlan}
                        disabled={!matchesPlanName(planNameInput)}
                        styles={classNames(
                          matchesPlanName(planNameInput)
                            ? 'hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                            : 'opacity-50',
                          'mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-red-600  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                        )}
                        type="submit"
                      />
                    </form>

                    {error !== '' ? (
                      <div className="mt-2">
                        <p className="text-sm text-red-500">{error}</p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DisablePlanModal;
