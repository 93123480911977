import AssignGrants from 'admin/TuitionReimbursement/EditGrants/AssignGrants/AssignGrants';
import ReviewGrants from 'admin/TuitionReimbursement/EditGrants/ReviewGrants';
import SubmitGrantsConfirmation from 'admin/TuitionReimbursement/EditGrants/SubmitGrantsConfirmation';
import { apiGetParticipantsForPlan } from 'api/organizationUsersAPI';
import {
  apiGetOrganizationTuitionReimbursementPlan,
  apiSetTuitionReimbursementGrants,
} from 'api/tuitionReimbursementAPI';
import PageLoad from 'components/Load/PageLoad';
import { OrganizationUser } from 'interfaces/organizationUserInterfaces';
import { TuitionReimbursementPlan } from 'interfaces/tuitionReimbursementInterfaces';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import AuthenticatedRoute from 'routes/AuthenticatedRoute';

interface EditGrantsRouteParams {
  tuitionReimbursementPlanId: string;
}

const EditGrants: React.FC = () => {
  let { tuitionReimbursementPlanId } = useParams<EditGrantsRouteParams>();

  const history = useHistory();

  const [participants, setParticipants] = useState<OrganizationUser[]>([]);
  const [isLoadingTuitionReimbursementPlan, setIsLoadingTuitionReimbursementPlan] = useState(true);
  const [isSubmittingNewTuitionReimbursementGrants, setIsSubmittingNewTuitionReimbursementGrants] = useState(false);

  const [isLoadingParticipants, setIsLoadingParticipants] = useState(true);
  const [tuitionReimbursementPlan, setTuitionReimbursementPlan] = useState<TuitionReimbursementPlan>();
  const [oldTuitionReimbursementGrantsByGrantee, setOldTuitionReimbursementGrantsByGrantee] = useState<{
    [key: string]: number;
  }>({});
  const [newTuitionReimbursementGrantsByGrantee, setNewTuitionReimbursementGrantsByGrantee] = useState<{
    [key: string]: number;
  }>({});
  const [participantIdsByEmail, setParticipantIdsByEmail] = useState<{ [key: string]: string }>({});

  const setNewTuitionReimbursementGrantsByEmail = (grantsByEmail: Array<any>) => {
    const grantsByParticipantId: { [key: string]: number } = {};
    for (var i = 0; i < grantsByEmail.length; i++) {
      const grant: Array<any> = grantsByEmail[i];
      const email: string = grant[0];
      const amount: number = grant[1];
      const participantId = participantIdsByEmail[email.toLowerCase()];
      if (participantId) {
        grantsByParticipantId[participantId] = amount;
      }
    }
    setNewTuitionReimbursementGrantsByGrantee({ ...newTuitionReimbursementGrantsByGrantee, ...grantsByParticipantId });
  };

  const setNewTuitionReimbursementGrant = (participantId: string, amount: number) => {
    setNewTuitionReimbursementGrantsByGrantee({ ...newTuitionReimbursementGrantsByGrantee, [participantId]: amount });
  };

  const submitNewTuitionReimbursementGrants = async () => {
    setIsSubmittingNewTuitionReimbursementGrants(true);
    apiSetTuitionReimbursementGrants(
      tuitionReimbursementPlanId,
      JSON.stringify(newTuitionReimbursementGrantsByGrantee)
    ).then(
      (data) => {
        setIsSubmittingNewTuitionReimbursementGrants(false);
        history.push(`/tuition-reimbursement/plans/${tuitionReimbursementPlanId}/edit/confirmed`);
      },
      (error) => {
        setIsSubmittingNewTuitionReimbursementGrants(false);
        toast.error(error.message);
      }
    );
  };

  const getTuitionReimbursementPlan = async () => {
    setIsLoadingTuitionReimbursementPlan(true);
    apiGetOrganizationTuitionReimbursementPlan(tuitionReimbursementPlanId).then(
      (data) => {
        const tuitionReimbursementPlan: TuitionReimbursementPlan = data.tuitionReimbursementPlan;
        setTuitionReimbursementPlan(tuitionReimbursementPlan);
        const tuitionReimbursementGrants: { [key: string]: number } = {};
        for (const tuitionReimbursementGrant of tuitionReimbursementPlan.tuitionReimbursementGrants!) {
          tuitionReimbursementGrants[tuitionReimbursementGrant.granteeId] =
            tuitionReimbursementGrant.annualReimbursementMax;
        }
        setOldTuitionReimbursementGrantsByGrantee(tuitionReimbursementGrants);
        setIsLoadingTuitionReimbursementPlan(false);
      },
      (error) => {
        setIsLoadingTuitionReimbursementPlan(false);
      }
    );
  };

  const getParticipants = async () => {
    setIsLoadingParticipants(true);
    apiGetParticipantsForPlan().then(
      (data) => {
        const users: OrganizationUser[] = data.users;
        const getParticipantIdsByEmail: { [key: string]: string } = {};
        for (const organizationUser of users) {
          getParticipantIdsByEmail[organizationUser.email.toLowerCase()] = organizationUser.id;
        }
        setParticipantIdsByEmail(getParticipantIdsByEmail);
        setParticipants(users);
        setIsLoadingParticipants(false);
      },
      (error) => {
        setIsLoadingParticipants(false);
      }
    );
  };

  useEffect(() => {
    getTuitionReimbursementPlan();
    getParticipants();
  }, []);

  return (
    <>
      {isLoadingParticipants || isLoadingTuitionReimbursementPlan ? (
        <PageLoad />
      ) : (
        <div className="px-8 mt-8 mx-auto max-w-7xl">
          <h1 className="text-2xl font-bold text-gray-900">Edit "{tuitionReimbursementPlan?.name}" Plan Grants</h1>
          <AuthenticatedRoute
            path={`/tuition-reimbursement/plans/:tuitionReimbursementPlanId/edit`}
            render={() => {
              return (
                <AssignGrants
                  tuitionReimbursementPlan={tuitionReimbursementPlan}
                  participants={participants}
                  setNewTuitionReimbursementGrant={setNewTuitionReimbursementGrant}
                  setNewTuitionReimbursementGrantsByEmail={setNewTuitionReimbursementGrantsByEmail}
                  oldTuitionReimbursementGrantsByGrantee={oldTuitionReimbursementGrantsByGrantee}
                  newTuitionReimbursementGrantsByGrantee={newTuitionReimbursementGrantsByGrantee}
                />
              );
            }}
            exact
          />
          <AuthenticatedRoute
            path={`/tuition-reimbursement/plans/:tuitionReimbursementPlanId/edit/review`}
            render={() => {
              return (
                <ReviewGrants
                  tuitionReimbursementPlan={tuitionReimbursementPlan}
                  participants={participants}
                  oldTuitionReimbursementGrantsByGrantee={oldTuitionReimbursementGrantsByGrantee}
                  newTuitionReimbursementGrantsByGrantee={newTuitionReimbursementGrantsByGrantee}
                  submitNewTuitionReimbursementGrants={submitNewTuitionReimbursementGrants}
                  isSubmittingNewTuitionReimbursementGrants={isSubmittingNewTuitionReimbursementGrants}
                />
              );
            }}
            exact
          />
          <AuthenticatedRoute
            path={`/tuition-reimbursement/plans/:tuitionReimbursementPlanId/edit/confirmed`}
            render={() => {
              return <SubmitGrantsConfirmation />;
            }}
            exact
          />
        </div>
      )}
    </>
  );
};

export default EditGrants;
