/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Combobox } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/outline';
import { OrganizationUser } from 'interfaces/organizationUserInterfaces';

import { useState } from 'react';
import { classNames } from 'utils/tailwindUtils';

interface SelectReviewerComboboxProps {
  selectedReviewers: OrganizationUser[];
  tuitionReimbursementReviewers: OrganizationUser[];
  selectReviewer: (reviewer: OrganizationUser) => void;
  isLoadingReviewers: boolean;
}

const SelectReviewerCombobox: React.FC<SelectReviewerComboboxProps> = ({
  selectedReviewers,
  tuitionReimbursementReviewers,
  selectReviewer,
  isLoadingReviewers,
}) => {
  const [query, setQuery] = useState('');
  const [selectedReviewer, setSelectedReviewer] = useState(null);

  const selectableReviewers = tuitionReimbursementReviewers.filter((tuitionReimbursementReviewer) => {
    return !selectedReviewers.includes(tuitionReimbursementReviewer);
  });

  const filteredReviewers =
    query === ''
      ? selectableReviewers
      : selectableReviewers.filter((tuitionReimbursementReviewer) => {
          let reviewerString = `${tuitionReimbursementReviewer.firstName} ${tuitionReimbursementReviewer.lastName} (${tuitionReimbursementReviewer.email})`;
          return reviewerString.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Combobox as="div" value={selectedReviewer} onChange={selectReviewer} disabled={isLoadingReviewers}>
      <Combobox.Label className="block text-sm font-medium text-gray-700">Select reviewers</Combobox.Label>
      <div className="relative mt-1">
        <Combobox.Input
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
          onChange={(event) => setQuery(event.target.value)}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredReviewers.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredReviewers.map((reviewer) => (
              <Combobox.Option
                key={reviewer.id}
                value={reviewer}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block truncate', selected ? 'font-semibold' : '')}>
                      {reviewer.firstName} {reviewer.lastName} ({reviewer.email})
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-indigo-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

export default SelectReviewerCombobox;
