import DashboardBody from 'dashboard/DashboardBody';
import DashboardHeader from 'dashboard/DashboardHeader';
import { useState } from 'react';

const Dashboard: React.FC = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="z-0">
      <DashboardHeader setSidebarOpen={setSidebarOpen} />
      <DashboardBody sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
    </div>
  );
};

export default Dashboard;
