import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import ContributionGrantRunGrantedColumn from 'common/Contributions/ContributionGrantRunGrantedColumn';
import ContributionGrantRunPaymentColumn from 'common/Contributions/ContributionGrantRunPaymentColumn';
import EmptyTablePlaceholder from 'common/EmptyTablePlaceholder';
import { ContributionGrantRun } from 'interfaces/contributionsInterfaces';
import React from 'react';
import { CellProps, Column, useSortBy, useTable } from 'react-table';
import { abbrevDate } from 'utils/dateUtils';

interface PaymentsTableProps {
  contributionGrantRuns: ContributionGrantRun[];
  startViewGrantRun: (participantGrantRun: ContributionGrantRun) => void;
}

const PaymentsTable: React.FC<PaymentsTableProps> = ({ contributionGrantRuns, startViewGrantRun }) => {
  const contributionGrantRunsMemo = React.useMemo<ContributionGrantRun[]>(
    () => contributionGrantRuns,
    [contributionGrantRuns]
  );
  const columns: Column<ContributionGrantRun>[] = React.useMemo(
    () => [
      {
        id: 'plan-name',
        Header: 'Plan name',

        Cell: (props: CellProps<ContributionGrantRun>) => {
          const contributionGrantRun = props.row.original;
          const contributionPlan = contributionGrantRun.contributionPlanRun?.contributionPlan;

          return (
            <div className="flex items-center">
              <div>
                <div className="text-sm font-medium text-gray-900">{contributionPlan?.name}</div>
              </div>
            </div>
          );
        },
        className: 'py-4 text-sm font-medium text-gray-900',
        headerClassName: 'py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
      {
        id: 'execution-date',
        Header: 'Execution date',

        Cell: (props: CellProps<ContributionGrantRun>) => {
          const contributionGrantRun = props.row.original;
          const contributionPlanRun = contributionGrantRun.contributionPlanRun;

          return (
            <div className="flex items-center">
              <div>
                <div className="text-sm font-medium text-gray-900">
                  {contributionPlanRun?.createdAt && abbrevDate(contributionPlanRun?.createdAt)}
                </div>
              </div>
            </div>
          );
        },
        className: 'hidden sm:table-cell py-4 text-sm font-medium text-gray-900',
        headerClassName:
          'hidden sm:table-cell py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
      {
        id: 'max-amount-granted',
        Header: 'Max amount granted',
        Cell: (props: CellProps<ContributionGrantRun>) => {
          const contributionGrantRun = props.row.original;
          return <ContributionGrantRunGrantedColumn contributionGrantRun={contributionGrantRun} />;
        },
        className: 'hidden sm:table-cell py-4 whitespace-nowrap text-sm text-gray-500',
        headerClassName:
          'hidden sm:table-cell py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
      {
        id: 'payment',
        Header: 'Payment sent / submitted',
        Cell: (props: CellProps<ContributionGrantRun>) => {
          const contributionGrantRun = props.row.original;
          return <ContributionGrantRunPaymentColumn contributionGrantRun={contributionGrantRun} />;
        },
        className: 'py-4 whitespace-nowrap text-sm text-gray-500',
        headerClassName: 'py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
    ],
    []
  ) as any;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: contributionGrantRunsMemo,
    },
    useSortBy
  );

  return (
    <div>
      <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={`${column.headerClassName}`}
                    >
                      {
                        // Render the header
                        column.render('Header')
                      }
                      <span className="inline-flex ml-1">
                        {column.isSorted ? (
                          <span className="w-3 h-3">
                            {column.isSortedDesc ? <ChevronDownIcon /> : <ChevronUpIcon />}
                          </span>
                        ) : (
                          ''
                        )}
                      </span>
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()} className="divide-y divide-gray-200">
          {
            // Loop over the table rows
            rows.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr
                  {...row.getRowProps()}
                  onClick={() => startViewGrantRun(row.original)}
                  className="hover:bg-gray-50 cursor-pointer"
                >
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td {...cell.getCellProps()} className={`${cell.column.className}`}>
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>

      {rows.length === 0 && <EmptyTablePlaceholder title="No payments" subtitle="No payments have been made yet." />}
    </div>
  );
};

export default PaymentsTable;
