import CreateNewPlanModal from 'admin/TuitionReimbursement/ListPlans/CreateNewPlanModal';
import ListPlansBody from 'admin/TuitionReimbursement/ListPlans/ListPlansBody';
import { apiGetOrganizationTuitionReimbursementPlans } from 'api/tuitionReimbursementAPI';
import PageLoad from 'components/Load/PageLoad';
import { UserDataContext } from 'context/UserContext';
import { TuitionReimbursementPlan } from 'interfaces/tuitionReimbursementInterfaces';
import React, { useContext, useEffect, useState } from 'react';

const ListPlans: React.FC = () => {
  const { firebaseUser, currentOrganizationUser } = useContext(UserDataContext);
  const [tuitionReimbursementPlans, setTuitionReimbursementPlans] = useState<TuitionReimbursementPlan[]>([]);
  const [isLoadingTuitionReimbursementPlans, setIsLoadingTuitionReimbursementPlans] = useState(true);
  const [openCreateNewPlanModal, setOpenCreateNewPlanModal] = useState(false);
  const [selectedPlanStatus, setSelectedPlanStatus] = useState('Active');

  const handlePlanStatusChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setSelectedPlanStatus(value);
  };

  const getTuitionReimbursementPlans = async () => {
    setIsLoadingTuitionReimbursementPlans(true);
    apiGetOrganizationTuitionReimbursementPlans().then(
      (data) => {
        setTuitionReimbursementPlans(data.tuitionReimbursementPlans);
        setIsLoadingTuitionReimbursementPlans(false);
      },
      (error) => {
        setIsLoadingTuitionReimbursementPlans(false);
      }
    );
  };

  useEffect(() => {
    if (firebaseUser) {
      getTuitionReimbursementPlans();
    }
  }, []);
  return (
    <>
      {isLoadingTuitionReimbursementPlans ? (
        <PageLoad />
      ) : (
        <>
          <div>
            <div className="border-b border-gray-200 py-2 flex flex-col sm:flex-row sm:items-center space-y-2 sm:space-y-0">
              <div className="flex flex-col sm:flex-row space-y-1 sm:space-y-0 sm:space-x-2">
                <div>
                  <select
                    id="plan-status"
                    name="plan-status"
                    className="block w-full pl-3 pr-10 py-2 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm rounded-md"
                    onChange={handlePlanStatusChange}
                  >
                    <option>Active</option>
                    <option>Disabled</option>
                  </select>
                </div>
              </div>

              <div className="sm:ml-auto flex">
                <button
                  type="button"
                  className="w-full sm:w-auto justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => {
                    setOpenCreateNewPlanModal(true);
                  }}
                >
                  + Create New Plan
                </button>
              </div>
            </div>

            <ListPlansBody
              plans={tuitionReimbursementPlans}
              selectedPlanStatus={selectedPlanStatus}
              refreshTuitionReimbursementPlans={getTuitionReimbursementPlans}
            />
          </div>

          <CreateNewPlanModal open={openCreateNewPlanModal} setOpen={setOpenCreateNewPlanModal} />
        </>
      )}
    </>
  );
};

export default ListPlans;
