import { Menu } from '@headlessui/react';
import { DotsVerticalIcon, IdentificationIcon, UserAddIcon, UserCircleIcon } from '@heroicons/react/outline';
import ActiveOrganizationUserMenuOptions from 'admin/ManageOrganizationUsers/ActiveOrganizationUsers/ActiveOrganizationUsersTable/ActiveOrganizationUserMenuOptions';
import ManageOrganizationUsersTable from 'admin/ManageOrganizationUsers/ManageOrganizationUsersTable';
import MenuOptions from 'components/MenuOptions';
import { UserDataContext } from 'context/UserContext';
import { Role } from 'enums/RolesEnum';
import { OrganizationUser } from 'interfaces/organizationUserInterfaces';
import React, { useContext } from 'react';
import Highlighter from 'react-highlight-words';
import { CellProps, Column } from 'react-table';
import { abbrevDate } from 'utils/dateUtils';
import { classNames } from 'utils/tailwindUtils';

interface ActiveOrganizationUsersTableProps {
  searchTerm: string;
  activeOrganizationUsers: OrganizationUser[];
  startDisableOrganizationUser: (activeOrganizationUser: OrganizationUser) => void;
}

const ActiveOrganizationUsersTable: React.FC<ActiveOrganizationUsersTableProps> = ({
  searchTerm,
  activeOrganizationUsers,
  startDisableOrganizationUser,
}) => {
  const { userAccount } = useContext(UserDataContext);
  const activeOrganizationUsersMemo = React.useMemo<OrganizationUser[]>(
    () => activeOrganizationUsers,
    [activeOrganizationUsers]
  );
  const columns: Column<OrganizationUser>[] = React.useMemo(
    () => [
      {
        id: 'name',
        Header: 'Name',
        accessor: (activeOrganizationUser: OrganizationUser) => {
          return (
            activeOrganizationUser.firstName +
            ' ' +
            activeOrganizationUser.lastName +
            ' ' +
            activeOrganizationUser.email
          );
        },
        Cell: (props: CellProps<OrganizationUser>) => {
          const activeOrganizationUser = props.row.original;
          const firstName = activeOrganizationUser.firstName;
          const lastName = activeOrganizationUser.lastName;
          const email = activeOrganizationUser.email;
          const isSelfRow = userAccount?.email.toLowerCase() === activeOrganizationUser.email.toLowerCase();

          return (
            <div className="flex items-center">
              <span className="hidden sm:inline-flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500">
                <span className="text-xl font-medium leading-none text-white">
                  {firstName?.charAt(0).toUpperCase()}
                  {lastName?.charAt(0).toUpperCase()}
                </span>
              </span>
              <div className="sm:ml-4 text-sm">
                <div>
                  <Highlighter
                    className="font-medium text-gray-900 break-all"
                    highlightClassName="bg-yellow-200"
                    searchWords={[props.state.globalFilter]}
                    autoEscape={true}
                    textToHighlight={`${firstName} ${lastName}`}
                  />
                  {isSelfRow && <span className="font-light text-gray-400"> (You)</span>}
                </div>
                <Highlighter
                  className="block text-gray-500 break-all"
                  highlightClassName="bg-yellow-200"
                  searchWords={[props.state.globalFilter]}
                  autoEscape={true}
                  textToHighlight={email}
                />
              </div>
            </div>
          );
        },
        className: 'py-4 text-sm font-medium text-gray-900',
        headerClassName: 'py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },

      {
        id: 'source',
        Header: 'Source',
        Cell: (props: CellProps<OrganizationUser>) => {
          const activeOrganizationUser = props.row.original;
          return (
            <div className="flex">
              {activeOrganizationUser.inviter ? (
                <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-sky-100 text-sky-800">
                  <UserAddIcon className="flex flex-shrink-0 mr-1.5 h-5 w-5 text-sky-400" aria-hidden="true" />
                  Invite
                </span>
              ) : activeOrganizationUser.role === Role.SuperAdmin ? (
                <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-sky-100 text-sky-800">
                  <UserCircleIcon className="flex flex-shrink-0 mr-1.5 h-5 w-5 text-sky-400" aria-hidden="true" />
                  Super Admin
                </span>
              ) : (
                <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-sky-100 text-sky-800">
                  <IdentificationIcon className="flex flex-shrink-0 mr-1.5 h-5 w-5 text-sky-400" aria-hidden="true" />
                  Payroll
                </span>
              )}
            </div>
          );
        },
        className: 'hidden sm:table-cell py-4 text-sm text-gray-500',
        headerClassName:
          'hidden sm:table-cell py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },

      {
        id: 'date-registered',
        Header: 'Date Registered',
        accessor: (activeOrganizationUser: OrganizationUser) => {
          return new Date(activeOrganizationUser.claimedAt!);
        },
        Cell: (props: CellProps<OrganizationUser>) => {
          const activeOrganizationUser = props.row.original;
          const registeredDateString = abbrevDate(activeOrganizationUser.claimedAt!);
          return <div>{registeredDateString}</div>;
        },
        sortType: 'datetime',
        className: 'hidden sm:table-cell py-4 text-sm text-gray-500',
        headerClassName:
          'hidden sm:table-cell py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },

      {
        id: 'options',
        Cell: (props: CellProps<OrganizationUser>) => {
          const activeOrganizationUser = props.row.original;
          const isSuperAdmin = activeOrganizationUser.role === Role.SuperAdmin;

          return (
            <div className={classNames(isSuperAdmin ? 'hidden' : '')}>
              <MenuOptions
                menuButton={
                  <Menu.Button className="group-hover:visible inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <DotsVerticalIcon className="h-5 w-5 text-gray-500" />
                  </Menu.Button>
                }
                menuItems={
                  <ActiveOrganizationUserMenuOptions
                    activeOrganizationUser={activeOrganizationUser}
                    startDisableOrganizationUser={startDisableOrganizationUser}
                  />
                }
              />
            </div>
          );
        },
        className: 'py-4 text-right',
      },
    ],
    []
  ) as any;

  return (
    <ManageOrganizationUsersTable
      searchTerm={searchTerm}
      organizationUsers={activeOrganizationUsersMemo}
      columns={columns}
    />
  );
};

export default ActiveOrganizationUsersTable;
