import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import ProgressBar from 'admin/TuitionReimbursement/EditGrants/ProgressBar';

import TuitionReimbursementGrantColumn from 'admin/TuitionReimbursement/EditGrants/TuitionReimbursementGrantColumn';
import EmptyTablePlaceholder from 'common/EmptyTablePlaceholder';
import LoadingButton from 'components/Buttons/LoadingButton';
import { OrganizationUser } from 'interfaces/organizationUserInterfaces';
import { TuitionReimbursementPlan } from 'interfaces/tuitionReimbursementInterfaces';
import React from 'react';
import { Link, Prompt } from 'react-router-dom';
import { CellProps, Column, useSortBy, useTable } from 'react-table';

interface ReviewGrantsProps {
  tuitionReimbursementPlan: TuitionReimbursementPlan | undefined;
  participants: OrganizationUser[];
  oldTuitionReimbursementGrantsByGrantee: { [key: string]: number };
  newTuitionReimbursementGrantsByGrantee: { [key: string]: number };
  submitNewTuitionReimbursementGrants: () => void;
  isSubmittingNewTuitionReimbursementGrants: boolean;
}

const ReviewGrants: React.FC<ReviewGrantsProps> = ({
  tuitionReimbursementPlan,
  participants,
  oldTuitionReimbursementGrantsByGrantee,
  newTuitionReimbursementGrantsByGrantee,
  submitNewTuitionReimbursementGrants,
  isSubmittingNewTuitionReimbursementGrants,
}) => {
  const participantsMemo = React.useMemo<OrganizationUser[]>(
    () =>
      participants.filter(
        (participant) =>
          newTuitionReimbursementGrantsByGrantee[participant.id] ||
          oldTuitionReimbursementGrantsByGrantee[participant.id]
      ),
    [participants]
  );

  const columns: Column<OrganizationUser>[] = React.useMemo(
    () => [
      {
        id: 'recipient',
        Header: 'Recipient',

        Cell: (props: CellProps<OrganizationUser>) => {
          const participant = props.row.original;
          const firstName = participant.firstName;
          const lastName = participant.lastName;
          const email = participant.email;

          return (
            <div className="flex items-center">
              <div>
                <div className="text-sm font-medium text-gray-900">
                  {firstName} {lastName}
                </div>
                <div className="text-sm text-gray-500">{email}</div>
              </div>
            </div>
          );
        },
        className: 'py-4 text-sm font-medium text-gray-900',
        headerClassName: 'py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },

      {
        id: 'grant',
        Header: 'Tuition Reimbursement Grant',
        Cell: (props: CellProps<OrganizationUser>) => {
          const participant = props.row.original;

          return (
            <TuitionReimbursementGrantColumn
              participant={participant}
              oldTuitionReimbursementGrantsByGrantee={oldTuitionReimbursementGrantsByGrantee}
              newTuitionReimbursementGrantsByGrantee={newTuitionReimbursementGrantsByGrantee}
            />
          );
        },
        className: 'py-4 whitespace-nowrap text-sm text-gray-500',
        headerClassName: 'py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
    ],
    []
  ) as any;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: participantsMemo,
    },
    useSortBy
  );

  return (
    <div>
      <ProgressBar step={1} />

      <h3 className="text-lg leading-6 font-medium text-gray-900 mt-6">Review new grants</h3>

      <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={`${column.headerClassName}`}
                    >
                      {
                        // Render the header
                        column.render('Header')
                      }
                      <span className="inline-flex ml-1">
                        {column.isSorted ? (
                          <span className="w-3 h-3">
                            {column.isSortedDesc ? <ChevronDownIcon /> : <ChevronUpIcon />}
                          </span>
                        ) : (
                          ''
                        )}
                      </span>
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()} className="divide-y divide-gray-200">
          {
            // Loop over the table rows
            rows.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr {...row.getRowProps()}>
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td {...cell.getCellProps()} className={`${cell.column.className}`}>
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
      {rows.length === 0 && (
        <EmptyTablePlaceholder title="No results" subtitle="You have not submitted any tuition reimbursement grants." />
      )}
      <div className="py-5">
        <div className="flex justify-end">
          <Link
            to={`/tuition-reimbursement/plans/${tuitionReimbursementPlan?.id}/edit`}
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Cancel
          </Link>
          <LoadingButton
            text="Submit"
            loadingText="Submitting"
            type="button"
            isLoading={isSubmittingNewTuitionReimbursementGrants}
            onClick={submitNewTuitionReimbursementGrants}
            styles="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          />
        </div>
      </div>
      <Prompt
        when={newTuitionReimbursementGrantsByGrantee.size !== 0}
        message={(params) =>
          params.pathname !== `/tuition-reimbursement/plans/${tuitionReimbursementPlan?.id}/edit` &&
          params.pathname !== `/tuition-reimbursement/plans/${tuitionReimbursementPlan?.id}/edit/confirmed`
            ? 'Your tuition reimbursement plan has not been saved, are you sure you want to leave?'
            : true
        }
      />
    </div>
  );
};

export default ReviewGrants;
