import { AcademicCapIcon, CashIcon, CollectionIcon, ReceiptTaxIcon } from '@heroicons/react/solid';
import { DotWave, RaceBy } from '@uiball/loaders';
import { apiGetParticipantHomeData } from 'api/organizationUsersAPI';
import HomePageHeader from 'common/HomePageHeader';
import { LoanPayment } from 'interfaces/contributionsInterfaces';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { abbrevDate } from 'utils/dateUtils';
import { formatMoney } from 'utils/numberFormatter';
import { classNames } from 'utils/tailwindUtils';

const statusStyles: Record<string, string> = {
  complete: 'bg-green-100 text-green-800',
  processing: 'bg-yellow-100 text-yellow-800',
  awaiting_funding: 'bg-gray-100 text-gray-800',
};

const ParticipantHomePage: React.FC = () => {
  const [isLoadingHomeData, setIsLoadingHomeData] = useState(true);
  const [numEnrolledPlans, setNumEnrolledPlans] = useState(0);
  const [numConnectedLoans, setNumConnectedLoans] = useState(0);
  const [participantHomeLoanPayments, setParticipantHomeLoanPayments] = useState<LoanPayment[]>([]);
  const [participantLoanPaymentsTotal, setParticipantLoanPaymentsTotal] = useState(0);

  const getParticipantHomeData = async () => {
    setIsLoadingHomeData(true);
    apiGetParticipantHomeData().then(
      (data) => {
        setNumEnrolledPlans(data.numEnrolledPlans);
        setNumConnectedLoans(data.numConnectedLoans);
        setIsLoadingHomeData(false);
        setParticipantHomeLoanPayments(data.participantHomeLoanPayments);
        setParticipantLoanPaymentsTotal(data.participantLoanPaymentsTotal);
      },
      (error) => {
        setIsLoadingHomeData(false);
      }
    );
  };

  const stats = [
    {
      id: 1,
      name: 'Enrolled plans',
      stat: numEnrolledPlans,
      icon: CollectionIcon,
      link: 'View plans',
      href: '/plans',
    },
    {
      id: 2,
      name: 'Connected accounts',
      stat: numConnectedLoans,
      icon: AcademicCapIcon,
      link: 'View accounts',
      href: '/accounts',
    },
    {
      id: 3,
      name: 'Cumulative contributions',
      stat: formatMoney(Number(participantLoanPaymentsTotal)),
      icon: ReceiptTaxIcon,
      link: 'View payments',
      href: '/payments',
    },
  ];

  useEffect(() => {
    getParticipantHomeData();
  }, []);

  return (
    <>
      <HomePageHeader />

      <div className="my-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-lg leading-6 font-medium text-gray-900">Overview</h2>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            {stats.map((item) => (
              <div
                key={item.id}
                className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 rounded-lg overflow-hidden shadow-md ring-1 ring-black ring-opacity-10"
              >
                <dt>
                  <div className="absolute bg-indigo-500 rounded-md p-3">
                    <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-sm font-medium text-gray-500 truncate">{item.name}</p>
                </dt>
                <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                  {isLoadingHomeData ? (
                    <div className="py-3">
                      <RaceBy size={80} lineWeight={5} speed={1.4} color="gray" />
                    </div>
                  ) : (
                    <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
                  )}

                  <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                    <div className="text-sm">
                      <Link to={item.href} className="font-medium text-indigo-600 hover:text-indigo-500">
                        {' '}
                        {item.link}
                        <span className="sr-only"> {item.name} stats</span>
                      </Link>
                    </div>
                  </div>
                </dd>
              </div>
            ))}
          </dl>
        </div>

        <h2 className="max-w-7xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
          Recent activity
        </h2>

        {/* Activity list (smallest breakpoint only) */}
        <div className="shadow sm:hidden">
          <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
            {isLoadingHomeData ? (
              <li key="loading">
                <div className="flex px-4 py-4 justify-center">
                  <DotWave size={47} speed={1} color="gray" />
                </div>
              </li>
            ) : participantHomeLoanPayments.length > 0 ? (
              participantHomeLoanPayments.map((loanPayment) => (
                <li key={loanPayment.id}>
                  <div className="block px-4 py-4 bg-white">
                    <span className="flex items-center space-x-4">
                      <span className="flex-1 flex space-x-2">
                        <CashIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                        <span className="flex flex-col text-gray-500 text-sm space-y-1">
                          <span className="block">
                            {loanPayment.servicerName} ({loanPayment.accountNumber})
                          </span>
                          <span>
                            <span className="text-gray-900 font-medium">
                              {formatMoney(Number(loanPayment.amountSubmitted))}
                            </span>
                          </span>
                          <div>
                            <span
                              className={classNames(
                                statusStyles[loanPayment.status],
                                'inline-flex  px-2.5 py-0.5 rounded-md text-xs font-medium capitalize'
                              )}
                            >
                              {loanPayment.status.replace('_', ' ')}
                            </span>
                          </div>
                          <time dateTime={loanPayment.createdAt}>{abbrevDate(loanPayment.createdAt)}</time>
                        </span>
                      </span>
                    </span>
                  </div>
                </li>
              ))
            ) : (
              <li key="empty-list">
                <div className="block px-4 py-4 bg-white">
                  <span className="flex items-center space-x-4">
                    <span className="flex-1 flex space-x-2">
                      <CashIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span className="flex flex-col text-gray-500 text-sm ">
                        <span className="block">No payments yet!</span>
                      </span>
                    </span>
                  </span>
                </div>
              </li>
            )}
          </ul>

          {/* <nav
            className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
            aria-label="Pagination"
          >
            <div className="flex-1 flex justify-between">
              <a
                href="#"
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
              >
                Previous
              </a>
              <a
                href="#"
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
              >
                Next
              </a>
            </div>
          </nav> */}
        </div>

        {/* Activity table (small breakpoint and up) */}
        <div className="hidden sm:block">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col mt-2">
              <div className="align-middle min-w-full overflow-x-auto overflow-hidden sm:rounded-lg shadow-md ring-1 ring-black ring-opacity-10">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Transaction
                      </th>
                      <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Amount
                      </th>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Status
                      </th>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {isLoadingHomeData ? (
                      <tr key={'loading'} className="bg-white">
                        <td colSpan={4}>
                          <div className="flex px-4 py-6 justify-center">
                            <DotWave size={47} speed={1} color="gray" />
                          </div>
                        </td>
                      </tr>
                    ) : participantHomeLoanPayments.length > 0 ? (
                      participantHomeLoanPayments.map((loanPayment) => (
                        <tr key={loanPayment.id} className="bg-white">
                          <td className="max-w-0 w-full px-6 py-4  text-sm text-gray-900">
                            <div className="flex">
                              <div className="group inline-flex space-x-2 text-sm">
                                <CashIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                                <p className="text-gray-900">
                                  {loanPayment.servicerName} ({loanPayment.accountNumber})
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                            <span className="text-gray-900 font-medium">
                              {formatMoney(Number(loanPayment.amountSubmitted))}
                            </span>
                          </td>
                          <td className=" px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            <span
                              className={classNames(
                                statusStyles[loanPayment.status],
                                'inline-flex  px-2.5 py-0.5 rounded-md text-xs font-medium capitalize'
                              )}
                            >
                              {loanPayment.status.replace('_', ' ')}
                            </span>
                          </td>
                          <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                            <time dateTime={loanPayment.createdAt}>{abbrevDate(loanPayment.createdAt)}</time>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr key={'empty'} className="bg-white">
                        <td className="max-w-0 w-full px-6 py-4  text-sm text-gray-900">
                          <div className="flex">
                            <div className="group inline-flex space-x-2 text-sm">
                              <CashIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                              <p className="text-gray-900">No payments yet!</p>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500"></td>
                        <td className=" px-6 py-4 whitespace-nowrap text-sm text-gray-500"></td>
                        <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500"></td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {/* Pagination */}
                {/* <nav
                  className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                  aria-label="Pagination"
                >
                  <div className="hidden sm:block">
                    <p className="text-sm text-gray-700">
                      Showing <span className="font-medium">1</span> to <span className="font-medium">10</span> of{' '}
                      <span className="font-medium">20</span> results
                    </p>
                  </div>
                  <div className="flex-1 flex justify-between sm:justify-end">
                    <a
                      href="#"
                      className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Previous
                    </a>
                    <a
                      href="#"
                      className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Next
                    </a>
                  </div>
                </nav> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParticipantHomePage;
