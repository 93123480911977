import api from 'config/axiosConfig';

export const apiCreateSavings529Account = (
  contributionType: string,
  name: string,
  institutionName: string,
  routingNumber: string,
  accountNumber: string,
  additionalInstructions: string,
  institutionAddressCity: string,
  institutionAddressPostalCode: string,
  institutionAddressRegion: string,
  institutionAddressStreet: string,
  institutionAddressStreet2: string
) =>
  api
    .post(`/savings-529/accounts`, {
      contributionType,
      name,
      institutionName,
      routingNumber,
      accountNumber,
      additionalInstructions,
      institutionAddressCity,
      institutionAddressPostalCode,
      institutionAddressRegion,
      institutionAddressStreet,
      institutionAddressStreet2,
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiDeleteSavings529Account = (savings529AccountId: string) =>
  api
    .delete(`/savings-529/accounts/${savings529AccountId}`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiGetSavings529AccountDocuments = (savings529AccountId: string | undefined) =>
  api
    .get(`/savings-529/accounts/${savings529AccountId}/documents`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiGetSavings529AccountDocumentLink = (savings529AccountId: string, documentUploadId: string) =>
  api
    .get(`/savings-529/accounts/${savings529AccountId}/documents/${documentUploadId}/link`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiUploadSavings529AccountDocument = (savings529AccountId: string | undefined, formData: FormData) =>
  api
    .post(`/savings-529/accounts/${savings529AccountId}/documents`, formData, {})
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiDeleteSavings529AccountDocument = (savings529AccountId: string, documentUploadId: string) =>
  api
    .delete(`/savings-529/accounts/${savings529AccountId}/documents/${documentUploadId}`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });
