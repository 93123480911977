/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from '@headlessui/react';
import {
  CheckCircleIcon,
  DuplicateIcon,
  InformationCircleIcon,
  UserIcon,
  XCircleIcon,
  XIcon,
} from '@heroicons/react/outline';
import { RaceBy, Ring } from '@uiball/loaders';
import {
  apiGetContributionAccounts,
  apiGetParticipantGrant,
  apiSetParticipantElectionsByGrant,
} from 'api/contributionsAPI';
import LoadingButton from 'components/Buttons/LoadingButton';
import { ContributionGrant } from 'interfaces/contributionsInterfaces';
import { LoanServicer } from 'interfaces/loansInterfaces';
import { Savings529Account } from 'interfaces/savings529Interfaces';
import ElectContributionsBody from 'participant/ContributionPlans/ElectContributions/ElectContributionsBody';

import { Fragment, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { abbrevDate } from 'utils/dateUtils';
import { formatMoney } from 'utils/numberFormatter';
import { classNames } from 'utils/tailwindUtils';

interface ElectContributionsSlideoverProps {
  participantGrant: ContributionGrant;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ElectContributionsSlideover: React.FC<ElectContributionsSlideoverProps> = ({
  participantGrant,
  open,
  setOpen,
}) => {
  const contributionPlan = participantGrant.contributionPlan;
  const [isEditingContributions, setIsEditingContributions] = useState(false);
  const [loanServicers, setLoanServicers] = useState<LoanServicer[]>([]);
  const [savings529Accounts, setSavings529Accounts] = useState<Savings529Account[]>([]);
  const [isLoadingContributionAccounts, setIsLoadingContributionAccounts] = useState(true);
  const [contributionElectionAmountsByLoan, setContributionElectionAmountsByLoan] = useState<{
    [key: string]: number;
  }>({});
  const [contributionElectionPercentagesByLoan, setContributionElectionPercentagesByLoan] = useState<{
    [key: string]: number;
  }>({});
  const [contributionElectionAmountsBy529Account, setContributionElectionAmountsBy529Account] = useState<{
    [key: string]: number;
  }>({});
  const [contributionElectionPercentagesBy529Account, setContributionElectionPercentagesBy529Account] = useState<{
    [key: string]: number;
  }>({});
  const [isSubmittingContributionElections, setIsSubmittingContributionElections] = useState(false);
  const [isLoadingContributionElections, setIsLoadingContributionElections] = useState(true);
  const [electionType, setElectionType] = useState(participantGrant.electionType);

  let totalElectionAmount = 0.0;
  for (const loanId in contributionElectionAmountsByLoan) {
    totalElectionAmount = totalElectionAmount + Number(contributionElectionAmountsByLoan[loanId]);
  }
  for (const accountId in contributionElectionAmountsBy529Account) {
    totalElectionAmount = totalElectionAmount + Number(contributionElectionAmountsBy529Account[accountId]);
  }

  let grantAmount = Number(participantGrant.amount);

  const matchMultiplier = Number(contributionPlan?.matchPercentage) / 100;

  const matchableEmployeeContribution = loanServicers.reduce(
    (totalMatchableEmployeeContribution, loanServicer) =>
      totalMatchableEmployeeContribution +
      loanServicer.statementUploads.reduce(
        (loanServicerMatchableEmployeeContribution, statementUpload) =>
          loanServicerMatchableEmployeeContribution +
          (statementUpload.statementUploadReviewStatus === 'approved' && statementUpload.matchableEmployeeContribution
            ? Number(statementUpload.matchableEmployeeContribution)
            : 0),
        0
      ),
    0
  );

  const expectedEmployerMatch = Math.min(matchableEmployeeContribution * matchMultiplier, grantAmount);

  let totalElectionPercentage = 0;
  for (const loanId in contributionElectionPercentagesByLoan) {
    totalElectionPercentage = totalElectionPercentage + Number(contributionElectionPercentagesByLoan[loanId]);
  }
  for (const accountId in contributionElectionPercentagesBy529Account) {
    totalElectionPercentage = totalElectionPercentage + Number(contributionElectionPercentagesBy529Account[accountId]);
  }

  const setContributionElectionAmountForLoan = (loanAccountId: string, amount: number) => {
    setContributionElectionAmountsByLoan({ ...contributionElectionAmountsByLoan, [loanAccountId]: amount });
  };

  const setContributionElectionPercentageForLoan = (loanAccountId: string, percentage: number) => {
    setContributionElectionPercentagesByLoan({ ...contributionElectionPercentagesByLoan, [loanAccountId]: percentage });
  };

  const setContributionElectionAmountFor529Account = (savings529AccountId: string, amount: number) => {
    setContributionElectionAmountsBy529Account({
      ...contributionElectionAmountsBy529Account,
      [savings529AccountId]: amount,
    });
  };

  const setContributionElectionPercentageFor529Account = (savings529AccountId: string, percentage: number) => {
    setContributionElectionPercentagesBy529Account({
      ...contributionElectionPercentagesBy529Account,
      [savings529AccountId]: percentage,
    });
  };

  const submitContributionElections = async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmittingContributionElections(true);
    apiSetParticipantElectionsByGrant(
      participantGrant.id,
      electionType,
      JSON.stringify(contributionElectionAmountsByLoan),
      JSON.stringify(contributionElectionPercentagesByLoan),
      JSON.stringify(contributionElectionAmountsBy529Account),
      JSON.stringify(contributionElectionPercentagesBy529Account)
    ).then(
      (data) => {
        setIsSubmittingContributionElections(false);
        setIsEditingContributions(false);
        getParticipantGrant();
        toast.success('Elections submitted!');
      },
      (error) => {
        setIsSubmittingContributionElections(false);
        toast.error(error.message);
      }
    );
  };

  const getContributionAccountsForElections = async () => {
    setIsLoadingContributionAccounts(true);
    apiGetContributionAccounts().then(
      (data) => {
        setLoanServicers(data.loans);
        setSavings529Accounts(data.savings529Accounts);
        setIsLoadingContributionAccounts(false);
      },
      (error) => {
        setIsLoadingContributionAccounts(false);
      }
    );
  };

  const getParticipantGrant = async () => {
    setIsLoadingContributionElections(true);
    apiGetParticipantGrant(participantGrant.id).then(
      (data) => {
        const participantGrant = data.participantGrant;
        const contributionElections = participantGrant.contributionElections;
        const contributionElectionAmountsByLoanObject: { [key: string]: number } = {};
        const contributionElectionPercentagesByLoanObject: { [key: string]: number } = {};
        const contributionElectionAmountsBy529AccountObject: { [key: string]: number } = {};
        const contributionElectionPercentagesBy529AccountObject: { [key: string]: number } = {};

        for (const contributionElection of contributionElections) {
          if (contributionElection.contributionAccountType === 'student_loan') {
            contributionElectionAmountsByLoanObject[contributionElection.loanAccountId] = contributionElection.amount;
            contributionElectionPercentagesByLoanObject[contributionElection.loanAccountId] =
              contributionElection.percentage;
          } else if (contributionElection.contributionAccountType === 'savings_529') {
            contributionElectionAmountsBy529AccountObject[contributionElection.savings529AccountId] =
              contributionElection.amount;
            contributionElectionPercentagesBy529AccountObject[contributionElection.savings529AccountId] =
              contributionElection.percentage;
          }
        }
        setContributionElectionAmountsByLoan(contributionElectionAmountsByLoanObject);
        setContributionElectionPercentagesByLoan(contributionElectionPercentagesByLoanObject);
        setContributionElectionAmountsBy529Account(contributionElectionAmountsBy529AccountObject);
        setContributionElectionPercentagesBy529Account(contributionElectionPercentagesBy529AccountObject);
        setElectionType(participantGrant.electionType);
        setIsLoadingContributionElections(false);
      },
      (error) => {
        setIsLoadingContributionElections(false);
      }
    );
  };

  const handleElectionTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setElectionType(value);
  };

  useEffect(() => {
    if (open) {
      getContributionAccountsForElections();
      getParticipantGrant();
      setIsEditingContributions(false);
    }
  }, [open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <form
                    onSubmit={submitContributionElections}
                    className="flex h-full flex-col overflow-y-scroll border-l bg-white shadow-xl"
                  >
                    <div className="px-4 sm:px-6 sticky top-0 z-10 border-b py-6 bg-white">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          "{contributionPlan?.name}" Elections
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-4">
                        <label className="text-md font-medium text-gray-700">Election type</label>
                        <fieldset className="mt-2">
                          <legend className="sr-only">Election type</legend>
                          <div
                            className={classNames(
                              isEditingContributions ? 'opacity-100' : 'opacity-50',
                              'space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10'
                            )}
                          >
                            <div className="flex items-center">
                              <input
                                id="amount"
                                name="electionType"
                                type="radio"
                                value="amount"
                                checked={electionType === 'amount'}
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                onChange={handleElectionTypeChange}
                                disabled={!isEditingContributions}
                              />
                              <label htmlFor="recurring" className="ml-3 block text-sm font-medium text-gray-700">
                                Amount ($)
                              </label>
                            </div>
                            <div className="flex items-center">
                              <input
                                id="percentage"
                                name="electionType"
                                type="radio"
                                value="percentage"
                                checked={electionType === 'percentage'}
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                onChange={handleElectionTypeChange}
                                disabled={!isEditingContributions}
                              />
                              <label htmlFor="percentage" className="ml-3 block text-sm font-medium text-gray-700">
                                Percentage (%)
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <div className="mt-4 flex">
                        <div className="flex-1">
                          <dl>
                            {isEditingContributions ? (
                              <dt className="text-md font-medium text-indigo-500">Editing elections</dt>
                            ) : (
                              <dt className="text-md font-medium text-gray-700">Current election allocation</dt>
                            )}
                            <dd>
                              {isLoadingContributionElections || isLoadingContributionAccounts ? (
                                <div className="py-3">
                                  <RaceBy size={80} lineWeight={5} speed={1.4} color="gray" />
                                </div>
                              ) : (
                                <>
                                  {electionType === 'amount' ? (
                                    <div className="text-sm font-medium text-gray-700 flex flex-row">
                                      {Number(totalElectionAmount) === Number(grantAmount) ? (
                                        <CheckCircleIcon className="mr-1.5 h-5 w-5 text-green-400" aria-hidden="true" />
                                      ) : (
                                        <XCircleIcon className="mr-1.5 h-5 w-5 text-red-400" aria-hidden="true" />
                                      )}
                                      {Math.floor((Number(totalElectionAmount) / Number(grantAmount)) * 100)}%{' '}
                                      <span className="text-gray-500">
                                        ({formatMoney(Number(totalElectionAmount))} / {formatMoney(Number(grantAmount))}
                                        )
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="text-sm font-medium text-gray-700 flex flex-row">
                                      {Number(totalElectionPercentage) === 100 ? (
                                        <CheckCircleIcon className="mr-1.5 h-5 w-5 text-green-400" aria-hidden="true" />
                                      ) : (
                                        <XCircleIcon className="mr-1.5 h-5 w-5 text-red-400" aria-hidden="true" />
                                      )}
                                      {totalElectionPercentage}%
                                    </div>
                                  )}
                                </>
                              )}
                            </dd>
                          </dl>
                        </div>

                        <div className="flex-1">
                          <dl>
                            <dt className="text-md font-medium text-gray-700">Next election deadline</dt>
                            <dd>
                              {isLoadingContributionElections || isLoadingContributionAccounts ? (
                                <div className="py-3">
                                  <RaceBy size={80} lineWeight={5} speed={1.4} color="gray" />
                                </div>
                              ) : (
                                <div className="text-sm font-medium text-gray-700">
                                  {participantGrant.contributionPlan?.nextElectionDeadline
                                    ? abbrevDate(participantGrant.contributionPlan?.nextElectionDeadline)
                                    : 'Pending...'}
                                </div>
                              )}
                            </dd>
                          </dl>
                        </div>
                      </div>
                      {contributionPlan?.employeeMatchRequired && (
                        <div className="mt-4 space-y-1">
                          <div className="text-md font-medium text-gray-700">Employer match</div>
                          <div className="text-sm text-gray-700 flex flex-row">
                            <InformationCircleIcon
                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-sky-400"
                              aria-hidden="true"
                            />
                            {Number(contributionPlan?.matchPercentage)}% of qualifying payments, up to{' '}
                            {formatMoney(Number(grantAmount))}
                          </div>
                          <div className="text-sm  text-gray-700 flex flex-row">
                            <UserIcon className="mr-1.5 h-5 w-5 text-purple-400" aria-hidden="true" />
                            Qualifying payments:
                            {
                              <span className="font-medium ml-1">
                                {formatMoney(Number(matchableEmployeeContribution))}
                              </span>
                            }
                          </div>
                          <div className="text-sm  text-gray-700 flex flex-row">
                            <DuplicateIcon className="mr-1.5 h-5 w-5 text-green-400" aria-hidden="true" />
                            Expected match:
                            {<span className="font-medium ml-1">{formatMoney(Number(expectedEmployerMatch))}</span>}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="relative flex-1 px-4 sm:px-6 pb-4">
                      {/* Replace with your content */}
                      {isLoadingContributionElections || isLoadingContributionAccounts ? (
                        <div className="py-8 flex justify-center">
                          <Ring size={40} lineWeight={5} speed={2.0} color="gray" />
                        </div>
                      ) : (
                        <ElectContributionsBody
                          contributionPlan={contributionPlan!}
                          loanServicers={loanServicers}
                          savings529Accounts={savings529Accounts}
                          contributionElectionAmountsByLoan={contributionElectionAmountsByLoan}
                          setContributionElectionAmountForLoan={setContributionElectionAmountForLoan}
                          contributionElectionPercentagesByLoan={contributionElectionPercentagesByLoan}
                          setContributionElectionPercentageForLoan={setContributionElectionPercentageForLoan}
                          contributionElectionAmountsBy529Account={contributionElectionAmountsBy529Account}
                          setContributionElectionAmountFor529Account={setContributionElectionAmountFor529Account}
                          contributionElectionPercentagesBy529Account={contributionElectionPercentagesBy529Account}
                          setContributionElectionPercentageFor529Account={
                            setContributionElectionPercentageFor529Account
                          }
                          isEditingContributions={isEditingContributions}
                          electionType={electionType}
                        />
                      )}

                      {/* /End replace */}
                    </div>
                    <div className="sticky bottom-0 z-10 border-t bg-white py-6 px-2">
                      {isEditingContributions ? (
                        <div className="flex justify-end">
                          <button
                            type="button"
                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => {
                              setIsEditingContributions(false);
                              getParticipantGrant();
                            }}
                          >
                            Cancel
                          </button>
                          <LoadingButton
                            type="submit"
                            styles="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            text="Submit"
                            loadingText="Submitting..."
                            isLoading={isSubmittingContributionElections}
                          />
                        </div>
                      ) : (
                        <div className="flex justify-end">
                          <button
                            type="button"
                            className="py-2 px-4 border rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => setIsEditingContributions(true)}
                          >
                            Edit Elections
                          </button>
                        </div>
                      )}
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ElectContributionsSlideover;
