/* This example requires Tailwind CSS v2.0+ */
import { CurrencyDollarIcon, ReceiptTaxIcon, UserIcon } from '@heroicons/react/outline';
import { Match401kGrantRun } from 'interfaces/match401kInterfaces';
import { formatMoney } from 'utils/numberFormatter';

interface Match401kGrantedColumnProps {
  match401kGrantRun: Match401kGrantRun;
}

const Match401kGrantedColumn: React.FC<Match401kGrantedColumnProps> = ({ match401kGrantRun }) => {
  return (
    <div className="flex-col space-y-1">
      <div className="flex justify-end">
        <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-green-100 text-green-800">
          <UserIcon className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" aria-hidden="true" />
          {formatMoney(Number(match401kGrantRun.participantSalary))} salary
        </span>
      </div>

      <div className="flex justify-end">
        <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-purple-100 text-purple-800">
          <CurrencyDollarIcon
            className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-purple-400"
            aria-hidden="true"
          />
          {match401kGrantRun.matchPercentage}% dollar-for-dollar match (
          {formatMoney(Number(match401kGrantRun.amountGranted))} monthly max)
        </span>
      </div>

      <div className="flex justify-end">
        <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-sky-100 text-sky-800">
          <ReceiptTaxIcon className="hidden md:flex flex-shrink-0 mr-1.5 h-5 w-5 text-sky-400" aria-hidden="true" />
          {formatMoney(Number(match401kGrantRun.matchableEmployeeContribution))} paid on 12/25/2022
        </span>
      </div>
    </div>
  );
};

export default Match401kGrantedColumn;
