import api from 'config/axiosConfig';

export const apiCreateExternalBankAccount = (
  name: string,
  institutionName: string,
  accountType: string,
  routingNumber: string,
  accountNumber: string
) =>
  api
    .post(`/external-bank/accounts`, { name, institutionName, accountType, routingNumber, accountNumber })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiDeleteExternalBankAccount = (externalBankAccountId: string) =>
  api
    .delete(`/external-bank/accounts/${externalBankAccountId}`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });

export const apiGetExternalBankAccounts = () =>
  api
    .get(`/external-bank/accounts`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(response.status.toString());
    })
    .catch(({ response }) => {
      throw new Error(response.data);
    });
