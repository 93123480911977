import { apiGetParticipantTuitionReimbursementGrants } from 'api/tuitionReimbursementAPI';
import EmptyPlaceholder from 'common/EmptyPlaceholder';
import PageLoad from 'components/Load/PageLoad';
import { TuitionReimbursementGrant } from 'interfaces/tuitionReimbursementInterfaces';
import ParticipantTuitionReimbursementPlanElement from 'participant/TuitionReimbursement/ParticipantTuitionReimbursementPlanElement';

import React, { useEffect, useState } from 'react';

const ParticipantTuitionReimbursementGrantsPage: React.FC = () => {
  const [participantTuitionReimbursementGrants, setParticipantTuitionReimbursementGrants] = useState<
    TuitionReimbursementGrant[]
  >([]);
  const [isLoadingParticipantTuitionReimbursementGrants, setIsLoadingParticipantTuitionReimbursementGrants] =
    useState(true);

  const getParticipantTuitionReimbursementGrants = async () => {
    setIsLoadingParticipantTuitionReimbursementGrants(true);
    apiGetParticipantTuitionReimbursementGrants().then(
      (data) => {
        setParticipantTuitionReimbursementGrants(data.participantTuitionReimbursementGrants);
        setIsLoadingParticipantTuitionReimbursementGrants(false);
      },
      (error) => {
        setIsLoadingParticipantTuitionReimbursementGrants(false);
      }
    );
  };

  useEffect(() => {
    getParticipantTuitionReimbursementGrants();
  }, []);

  return (
    <>
      {isLoadingParticipantTuitionReimbursementGrants ? (
        <PageLoad />
      ) : (
        <div className="my-6 mx-auto max-w-7xl">
          <div>
            <>
              {participantTuitionReimbursementGrants.length === 0 ? (
                <div className="my-6">
                  <EmptyPlaceholder
                    title={'No grants'}
                    subtitle={'Your employer has not enrolled you in any tuition reimbursement plans.'}
                  />
                </div>
              ) : (
                <div className="mt-6 space-y-4">
                  {participantTuitionReimbursementGrants.map((participantTuitionReimbursementGrant) => (
                    <ParticipantTuitionReimbursementPlanElement
                      key={participantTuitionReimbursementGrant.id}
                      tuitionReimbursementGrant={participantTuitionReimbursementGrant}
                    />
                  ))}
                </div>
              )}
            </>
          </div>
        </div>
      )}
    </>
  );
};

export default ParticipantTuitionReimbursementGrantsPage;
