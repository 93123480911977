import { SearchIcon } from '@heroicons/react/outline';
import { Ring } from '@uiball/loaders';
import HSAContributionsBreadcrumb from 'admin/HSA/HSAContributionsBreadcrumb';
import HSAContributionsTable from 'admin/HSA/HSAContributionsTable';
import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';

const HSAContributions: React.FC = () => {
  const [hsaContributions, setHsaContributions] = useState({
    id: '9d1527f0-79c8-435a-9901-6a3d519b7b81',
    name: 'September Contributions',
    organizationId: 'string',
    match401kGrantRuns: [
      {
        id: 'id',
        participantProfile: {
          startDate: '01-01-2023',
          updatedAt: '01-01-2023',
          createdAt: '01-01-2023',
          organizationUserId: 'id',
          organizationUser: {
            id: 'id',
            firstName: 'Steve',
            lastName: 'Troy',
            email: 'steve@unsaddl.com',
            role: 'string',
            active: true,
            claimed: true,
            organizationId: 'org-id',
            createdAt: '01-01-2023',
          },
        },
        amountGranted: 500,
        matchPercentage: 6,
        participantSalary: 100000,
        status: 'sent',
        matchableEmployeeContribution: 500,
        amountSubmitted: 500,
        employeeContribution: 250,
        employerContribution: 250,
        total: 500,
        createdAt: '01-01-2023',
        updatedAt: '01-01-2023',
      },
      {
        id: 'id',
        participantProfile: {
          startDate: '01-01-2023',
          updatedAt: '01-01-2023',
          createdAt: '01-01-2023',
          organizationUserId: 'id',
          organizationUser: {
            id: 'id',
            firstName: 'Chris',
            lastName: 'Hsu',
            email: 'chris@unsaddl.com',
            role: 'string',
            active: true,
            claimed: true,
            organizationId: 'org-id',
            createdAt: '01-01-2023',
          },
        },
        amountGranted: 300,
        matchPercentage: 6,
        participantSalary: 60000,
        status: 'sent',
        matchableEmployeeContribution: 500,
        amountSubmitted: 300,
        employeeContribution: 250,
        employerContribution: 250,
        total: 500,
        createdAt: '01-01-2023',
        updatedAt: '01-01-2023',
      },
    ],
    createdAt: '01-01-2023',
    updatedAt: '01-01-2023',
  });

  const [searchTerm, setSearchTerm] = useState('');
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);

  const setSearchTermDebounced = useAsyncDebounce((searchText: string | undefined) => {
    setSearchTerm(searchText || '');
    setIsLoadingSearch(false);
  }, 1000);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoadingSearch(true);
    setSearchTermDebounced(event.target.value);
  };

  return (
    <>
      <div className="my-6 px-8 mx-auto max-w-7xl">
        <HSAContributionsBreadcrumb hsaContributions={hsaContributions} />

        <div className="flex flex-col mt-4 md:flex-row md:justify-between md:items-center md:space-x-4 space-y-2">
          <h1 className="text-2xl font-bold text-gray-900">September Contributions</h1>
          <div className="w-full md:max-w-md">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                {isLoadingSearch ? (
                  <Ring size={15} lineWeight={7} speed={2.0} color="gray" />
                ) : (
                  <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                )}
              </div>
              <input
                id="search"
                name="search"
                className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-sm"
                placeholder="Search for a user"
                type="search"
                onChange={handleSearch}
              />
            </div>
          </div>
        </div>
        <HSAContributionsTable searchTerm={searchTerm} match401kPlanRun={hsaContributions} />
      </div>
    </>
  );
};

export default HSAContributions;
