import ProgressBar from 'admin/ContributionPlans/EditGrants/ProgressBar';
import React from 'react';
import { Link } from 'react-router-dom';

const SubmitGrantsConfirmation: React.FC = () => {
  return (
    <>
      <ProgressBar step={2} />

      <h1 className="text-2xl text-center leading-6 font-medium text-gray-900 mt-6">Grants submitted!</h1>
      <p className="mt-4 text-md text-center text-gray-500">
        Email notifications have been sent out to all participants who are part of this plan
      </p>
      <div className="mt-4 text-center">
        <Link
          to="/tuition-reimbursement/list/plans"
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Return to Plans
        </Link>
      </div>
    </>
  );
};

export default SubmitGrantsConfirmation;
