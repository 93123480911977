import HSAChangePortfolioBreadcrumb from 'participant/HSA/HSAChangePortfolioBreadcrumb';

import React from 'react';
import toast from 'react-hot-toast';

interface HSAChangePortfolioProps {
  hsaInvestments: { [fundName: string]: number };
  addHsaInvestment: (fundName: string, allocation: number) => void;
}

const HSAChangePortfolio: React.FC<HSAChangePortfolioProps> = ({ hsaInvestments, addHsaInvestment }) => {
  const funds = [
    { name: 'vtsax', expenseRatio: '0.04%' },
    { name: 'vfiax', expenseRatio: '0.04%' },
    { name: 'vigax', expenseRatio: '0.05%' },
    { name: 'vlcax', expenseRatio: '0.05%' },
    { name: 'vviax', expenseRatio: '0.05%' },
    { name: 'vdadx', expenseRatio: '0.08%' },
    { name: 'vimax', expenseRatio: '0.05%' },
  ];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    addHsaInvestment(name, Number(value));
  };

  return (
    <>
      <div className=" mt-4 mx-auto max-w-7xl">
        <>
          <HSAChangePortfolioBreadcrumb />

          <div className="px-6 py-4 shadow-md ring-1 ring-black ring-opacity-10 rounded-lg bg-white my-6">
            <h1 className="text-lg font-bold text-gray-900">Available Funds</h1>
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pr-2 text-left text-sm font-semibold text-gray-500">
                    Fund name
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 px-2 text-left text-sm font-semibold text-gray-500 hidden xs:table-cell"
                  >
                    Expense Ratio
                  </th>

                  <th scope="col" className="py-3.5 pl-2 text-right text-sm font-semibold text-gray-500">
                    Your elections
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {funds.map((fund) => (
                  <tr key={fund.name}>
                    <td className="pr-2 py-4 text-sm font-medium text-gray-900">{fund.name.toUpperCase()}</td>
                    <td className="py-4 px-2 text-sm text-left text-gray-500 hidden xs:table-cell">
                      {fund.expenseRatio}
                    </td>

                    <td className="relative py-4 pl-2 text-right text-sm font-medium">
                      <div className="relative">
                        <>
                          <div>
                            <input
                              type="number"
                              step="1"
                              min="0"
                              max="100"
                              name={fund.name}
                              id={fund.name}
                              className="border-indigo-500 text-black shadow-lg block w-full text-sm rounded-md"
                              aria-describedby="price-currency"
                              onChange={handleChange}
                              value={hsaInvestments[fund.name]}
                            />{' '}
                          </div>
                          <div className="absolute inset-y-0 right-0 pr-8 flex items-center pointer-events-none">
                            <span className="text-gray-500 text-sm">%</span>
                          </div>
                        </>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => {
                toast.success(
                  `Successfully submitted portfolio changes! Orders will be processed in the next trading day.`
                );
              }}
            >
              Save
            </button>
          </div>
        </>
      </div>
    </>
  );
};

export default HSAChangePortfolio;
