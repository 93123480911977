import { Menu } from '@headlessui/react';
import { DotsVerticalIcon, IdentificationIcon } from '@heroicons/react/outline';
import InvitedUserMenuOptions from 'admin/ManageOrganizationUsers/InvitedUsers/InvitedUsersTable/InvitedUserMenuOptions';
import ManageOrganizationUsersTable from 'admin/ManageOrganizationUsers/ManageOrganizationUsersTable';

import MenuOptions from 'components/MenuOptions';
import { UserDataContext } from 'context/UserContext';
import { OrganizationUser } from 'interfaces/organizationUserInterfaces';
import moment from 'moment';
import React, { useContext } from 'react';
import Highlighter from 'react-highlight-words';
import { CellProps, Column } from 'react-table';

interface InvitedUsersTableProps {
  searchTerm: string;
  invitedUsers: OrganizationUser[];
  startRevokeInvitedUser: (invitedUser: OrganizationUser) => void;
}

const InvitedUsersTable: React.FC<InvitedUsersTableProps> = ({ searchTerm, invitedUsers, startRevokeInvitedUser }) => {
  const { userAccount } = useContext(UserDataContext);
  const invitedUsersMemo = React.useMemo<OrganizationUser[]>(() => invitedUsers, [invitedUsers]);
  const columns: Column<OrganizationUser>[] = React.useMemo(
    () => [
      {
        id: 'name',
        Header: 'Name',
        accessor: (invitedUser: OrganizationUser) => {
          return invitedUser.firstName + ' ' + invitedUser.lastName + ' ' + invitedUser.email;
        },
        Cell: (props: CellProps<OrganizationUser>) => {
          const invitedUser = props.row.original;
          const firstName = invitedUser.firstName;
          const lastName = invitedUser.lastName;
          const email = invitedUser.email;
          const isSelfRow = userAccount?.email.toLowerCase() === invitedUser.email.toLowerCase();

          return (
            <div className="flex items-center">
              <span className="hidden sm:inline-flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500">
                <span className="text-xl font-medium leading-none text-white">
                  {firstName?.charAt(0).toUpperCase()}
                  {lastName?.charAt(0).toUpperCase()}
                </span>
              </span>
              <div className="sm:ml-4 text-sm">
                <div>
                  <Highlighter
                    className="font-medium text-gray-900 break-all"
                    highlightClassName="bg-yellow-200"
                    searchWords={[props.state.globalFilter]}
                    autoEscape={true}
                    textToHighlight={`${firstName} ${lastName}`}
                  />
                  {isSelfRow && <span className="font-light text-gray-400"> (You)</span>}
                </div>
                <Highlighter
                  className="block text-gray-500 break-all"
                  highlightClassName="bg-yellow-200"
                  searchWords={[props.state.globalFilter]}
                  autoEscape={true}
                  textToHighlight={email}
                />
              </div>
            </div>
          );
        },
        className: 'py-4 text-sm font-medium text-gray-900',
        headerClassName: 'py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },
      {
        id: 'invited-by',
        Header: 'Invited By',
        Cell: (props: CellProps<OrganizationUser>) => {
          const invitedUser = props.row.original;
          const inviterFirstName = invitedUser.inviter?.firstName;
          const inviterLastName = invitedUser.inviter?.lastName;
          const inviterEmail = invitedUser.inviter?.email;

          return invitedUser.inviter ? (
            <>
              <div className="text-sm font-medium text-gray-900">{`${inviterFirstName} ${inviterLastName}`}</div>
              <div className="text-sm text-gray-500">{inviterEmail}</div>
            </>
          ) : (
            <span className="inline-flex px-2 text-xs leading-5 font-semibold rounded-md bg-sky-100 text-sky-800">
              <IdentificationIcon className="flex flex-shrink-0 mr-1.5 h-5 w-5 text-sky-400" aria-hidden="true" />
              Payroll
            </span>
          );
        },
        className: 'hidden sm:table-cell py-4 text-sm text-gray-500',
        headerClassName:
          'hidden sm:table-cell py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },

      {
        id: 'date-submitted',
        accessor: (invitedUser: OrganizationUser) => {
          return new Date(invitedUser.createdAt);
        },
        Header: 'Date Submitted',
        Cell: (props: CellProps<OrganizationUser>) => {
          const invitedUser = props.row.original;
          return <div>{moment.parseZone(invitedUser.createdAt).format('MMMM Do, YYYY')}</div>;
        },
        sortType: 'datetime',
        className: 'hidden sm:table-cell py-4 text-sm text-gray-500',
        headerClassName:
          'hidden sm:table-cell py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      },

      {
        id: 'options',
        Cell: (props: CellProps<OrganizationUser>) => {
          const invitedUser = props.row.original;
          return (
            <div>
              <MenuOptions
                menuButton={
                  <Menu.Button className="group-hover:visible inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <DotsVerticalIcon className="h-5 w-5 text-gray-500" />
                  </Menu.Button>
                }
                menuItems={
                  <InvitedUserMenuOptions invitedUser={invitedUser} startRevokeInvitedUser={startRevokeInvitedUser} />
                }
              />
            </div>
          );
        },
        className: 'py-4 text-right text-sm font-medium',
      },
    ],
    []
  ) as any;

  return (
    <ManageOrganizationUsersTable searchTerm={searchTerm} organizationUsers={invitedUsersMemo} columns={columns} />
  );
};

export default InvitedUsersTable;
